import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Sidebar from '../Sidebar'
import Header from '../Header'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    app: {
        backgroundColor: 'white',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        flexGrow: 1,
        background: theme.palette.type === 'dark' ? '#212121' : '#eeeeee',
    },
    appBarSpacer: theme.mixins.toolbar,
}))

type LayoutProps = {
    children: React.ReactChild
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = (): void => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <div className={classes.root}>
            <Sidebar
                isOpen={mobileOpen}
                handleDrawerClose={handleDrawerToggle}
                setDrawerState={setMobileOpen}
            />
            <div className={classes.app}>
                <Header onDrawerToggle={handleDrawerToggle} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="xl" className={classes.container}>
                        {children}
                    </Container>
                </main>
            </div>
        </div>
    )
}

export default Layout
