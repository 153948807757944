// @ts-nocheck
import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'

import AssetsActions from './AssetsActions'
import { assetsTableColumns } from '../config/tableColumns.js'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .assets-table-cell.hoverActive': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
    },
}))

const AssetsTable = ({
    history,
    match: { url },
    hoveredId,
    onHover,
}): JSX.Element => {
    const classes = useStyles()
    const { assets, loading } = useSelector((state) => state.project_data)
    const { role } = useSelector((state) => state.role)
    let columns = [...assetsTableColumns]
    const actionColumn = {
        field: 'actions',
        headerName: ' ',
        renderCell: ({ row }) => {
            return <AssetsActions data={row} onHover={onHover} />
        },
        disableClickEventBubbling: true,
        disableColumnMenu: true,
        sortable: false,
    }
    if (role !== 'unauthorized') columns.push(actionColumn)

    columns = columns.map((colDef) => ({
        ...colDef,
        cellClassName: (params: GridCellClassParams) =>
            clsx('assets-table-cell', {
                hoverActive: params.row?.id === hoveredId,
            }),
    }))

    const handleClick = (id): void => {
        history.push(`${url}/assets/${id}/overview`)
    }

    return (
        <DataGrid
            className={classes.root}
            autoHeight
            loading={loading}
            rows={loading ? [] : assets}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            onRowClick={({ row }) => {
                handleClick(row?.id)
            }}
            disableColumnMenu
            onRowEnter={(params) => onHover(params.row?.id)}
            onRowLeave={() => onHover(null)}
            sortModel={[
                {
                    field: 'updated_at',
                    sort: 'desc' as GridSortDirection,
                },
            ]}
        />
    )
}

export default withRouter(AssetsTable)
