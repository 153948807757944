import { put, takeLatest, call } from 'redux-saga/effects'
import {
    GET_SET_BREADCRUMBS_PATH_REQUEST,
    GET_SET_BREADCRUMBS_PATH_SUCCEEDED,
    GET_SET_BREADCRUMBS_PATH_FAILED,
    GET_CLEAR_BREADCRUMBS_PATH_REQUEST,
    GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED,
    GET_CLEAR_BREADCRUMBS_PATH_FAILED,
} from '../actions/system.js'

function* setBreadcrumbsPath(action) {
    try {
        yield put({
            type: GET_SET_BREADCRUMBS_PATH_SUCCEEDED,
            parent: action.parent,
            crumbs: action.crumbs,
        })
    } catch (e) {
        yield put({ type: GET_SET_BREADCRUMBS_PATH_FAILED, message: e.message })
    }
}

export function* setBreadcrumbsPathSaga() {
    yield takeLatest(GET_SET_BREADCRUMBS_PATH_REQUEST, setBreadcrumbsPath)
}

function* clearBreadcrumbsPath() {
    try {
        yield put({
            type: GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED,
        })
    } catch (e) {
        yield put({ type: GET_CLEAR_BREADCRUMBS_PATH_FAILED, message: e.message })
    }
}

export function* clearBreadcrumbsPathSaga() {
    yield takeLatest(GET_CLEAR_BREADCRUMBS_PATH_REQUEST, clearBreadcrumbsPath)
}
