// @ts-nocheck
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Carousel from 'react-material-ui-carousel'
import Typography from '@material-ui/core/Typography'

import Spinner from '../../Common/Spinner'

const useStyles = makeStyles({
    image: {
        width: 'auto',
        height: 'auto',
        maxHeight: '300px',
        maxWidth: '100%',
    },
    imageContainer: {
        height: '100%',
    },
})

const DamageImages = ({
    imageAmount = 0,
    imgUrl,
    imgName,
    onClick,
    loading,
}): JSX.Element | null => {
    const classes = useStyles()
    const [index, setIndex] = useState(0)

    if (!imageAmount)
        return (
            <Box display="flex" flexDirection="column" p={2}>
                <p>No images available</p>
            </Box>
        )

    return (
        <Box display="flex" className={classes.imageContainer}>
            {loading ? (
                <Spinner />
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                >
                    <Typography variant="h5" gutterBottom>
                        {imgName}
                    </Typography>
                    <Carousel
                        autoPlay={false}
                        index={index}
                        onChange={(next) => {
                            onClick(next)
                            setIndex(next)
                        }}
                        navButtonsAlwaysVisible
                        indicators={false}
                    >
                        {[...Array(imageAmount).keys()].map((num) => {
                            return (
                                <img
                                    src={`data:image/png;base64, ${imgUrl.slice(
                                        1,
                                        -1
                                    )}`}
                                    alt={`asset index ${num + 1}`}
                                    className={classes.image}
                                />
                            )
                        })}
                    </Carousel>
                </Box>
            )}
        </Box>
    )
}

export default DamageImages
