// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Can } from '@casl/react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import GetAppIcon from '@material-ui/icons/GetApp'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Hidden from '@material-ui/core/Hidden'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import CropIcon from '@material-ui/icons/Crop'
import AllOutIcon from '@material-ui/icons/AllOut'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControl from '@material-ui/core/FormControl'

import { ABILITIES } from '../../config/ability.js'
import { getToken } from '../../services/authService.js'
import DamagesLegend from '../Asset/Data/DamagesLegend'
// import { getImage, getOverlay } from '../../redux/actions/data.js'
import {
    getDamageCrop,
    getOverlayDamageCrop,
} from '../../redux/actions/sphere.js'
import { deleteData, downloadFile } from '../../redux/actions/asset.js'
import DeleteModal from '../Common/Modal/DeleteModal'
import Spinner from '../Common/Spinner'
import { jet_colour_map } from '../../config/color_palette.js'

const tooltip = (title, component): JSX.Element => (
    <Tooltip title={title}>{component}</Tooltip>
)

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    appBar: {
        position: 'relative',
    },
    imageContainerGrid: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 3,
        },
    },
    imageContainer: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    imageControls: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        },
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '80vh',
    },
    controlBox: {
        width: '100%',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    // eslint-disable-next-line
    return <Slide direction="up" ref={ref} {...props} />
})

const Image = ({ imgUrl, isBlendOn, legend_colours }): JSX.Element => {
    const classes = useStyles()

    return (
        <Box position="relative">
            <TransformWrapper doubleClick={{ disabled: true }}>
                <TransformComponent>
                    <img
                        src={`data:image/png;base64,${imgUrl.slice(1, -1)}`}
                        alt="Bigger version of file"
                        className={classes.image}
                    />
                </TransformComponent>
            </TransformWrapper>
            <Hidden mdUp>
                <DamagesLegend
                    status={isBlendOn}
                    label_legend={legend_colours}
                />
            </Hidden>
        </Box>
    )
}

const ImageContainer = ({
    img_data: { img_data },
    isBlendOn,
    isMaskOn,
    onToggleMask,
    legend_colours,
}): JSX.Element => {
    const classes = useStyles()
    const damageCrop = useSelector((state) => state.damage_crop)
    const { img_data: damage_data } = damageCrop
    const url = isMaskOn ? damage_data.mask : damage_data.img
    const doubleClick = isBlendOn ? onToggleMask : null
    const loading = damageCrop.loading || img_data.loading

    return loading ? (
        <Spinner />
    ) : (
        <Box
            onDoubleClick={doubleClick}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.imageContainer}
            pt={2}
        >
            <Image
                imgUrl={url}
                isBlendOn={isBlendOn}
                legend_colours={legend_colours}
            />
        </Box>
    )
}

const ImageControls = ({
    imageData: { name },
    sphereData: { id, image_data },
    isBlendOn,
    isFullImage,
    selectorProps,
    onToggleBlend,
    onToggleFullImage,
    damageLists,
    severityLists,
    legend_colours,
}): JSX.Element => {
    const classes = useStyles()

    // eslint-disable-next-line
    const renderBlendButton = (isBlendOn): JSX.Element => (
        <FormControlLabel
            control={
                <Switch
                    checked={isBlendOn}
                    onChange={onToggleBlend}
                    name="toggleOverlay"
                    color="primary"
                />
            }
            label={
                <Box display="flex" flexWrap="wrap">
                    {isBlendOn ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    <Typography>&nbsp; Overlay</Typography>
                </Box>
            }
        />
    )

    // eslint-disable-next-line
    const renderFullImage = (isFullImage): JSX.Element => (
        <FormControlLabel
            control={
                <Switch
                    checked={isFullImage}
                    onChange={onToggleFullImage}
                    name="toggleOverlay"
                    color="primary"
                />
            }
            label={
                <Box display="flex" flexWrap="wrap">
                    {isFullImage ? <AllOutIcon /> : <CropIcon />}
                    <Typography>&nbsp; Full Image</Typography>
                </Box>
            }
        />
    )

    const renderSelector = (selectors): JSX.Element =>
        selectors.map(({ title, selected, onSelect }) => {
            const lists = title === 'Damage Types' ? damageLists : severityLists
            const display = lists.length > 0

            return display ? (
                <FormControl key={title} fullWidth>
                    <InputLabel id={`3d-crop-dialog-${title}`}>
                        {title}
                    </InputLabel>
                    <Select
                        labelId={`3d-crop-dialog-${title}`}
                        id={`3d-crop-dialog-${title}-name`}
                        value={selected}
                        onChange={(event) => {
                            onSelect(event.target.value)
                        }}
                        input={<Input />}
                    >
                        {/* eslint-disable-next-line */}
                        {lists.map(({ id, type }) => (
                            <MenuItem key={type} value={id}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null
        })

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={2}
            className={classes.controlBox}
        >
            <Typography variant="h5" component="h2">
                {name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {`CODE ${id}`}
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" gutterBottom>
                Meta Information
            </Typography>
            {image_data && renderFullImage(isFullImage)}
            {renderBlendButton(isBlendOn)}
            {renderSelector(selectorProps)}
            <Hidden smDown>
                <DamagesLegend
                    status={isBlendOn}
                    label_legend={legend_colours}
                />
            </Hidden>
        </Box>
    )
}

const CropDialog = ({ open, sphereData, imageData, onClose }): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id, labels } = useSelector((state) => state.damage_crop)
    const { labels: asset_labels, severity_labels } = useSelector(
        (state) => state.asset_data.data
    )
    const img_data = useSelector((state) => state.img_data)
    const { role } = useSelector((state) => state.role)
    const [modalOpen, setModalOpen] = useState(false)
    const [isBlendOn, setIsBlendOn] = useState(false)
    const [isFullImage, setIsFullImage] = useState(false)
    const [isMaskOn, setIsMaskOn] = useState(false)
    const [damageId, setDamageId] = useState(1)
    const [severityId, setSeverityId] = useState('None')
    const [legendColours, setLegendColours] = useState(null)

    const allLabel = asset_labels
        .filter(({ label__name: type }) => type === 'All')
        // eslint-disable-next-line
        .map(({ label__id: id }) => ({
            id,
            type: 'all damage types',
        }))
    // eslint-disable-next-line
    const damageLists = labels.map(({ id, name: type }) => ({
        id,
        type,
    }))
    damageLists.unshift(allLabel[0])

    const severityLists = severity_labels.map(
        // eslint-disable-next-line
        ({ severity_label__id: id, severity_label__name: type }) => ({
            id,
            type: type === 'All' ? 'all severity types' : type,
        })
    )

    const resetSelectors = (): void => {
        setDamageId(1)
        setSeverityId('None')
    }

    const toggleMask = (): void => {
        setIsMaskOn((preState) => !preState)
        // resetSelectors(isMaskOn);
    }

    const toggleBlend = (): void => {
        if (!isBlendOn && damageId === 1 && labels.length > 0) {
            setDamageId(damageLists[0].id)
        }

        setIsBlendOn((preState) => !preState)
        // resetSelectors(isMaskOn);
    }

    const toggleFullImage = (): void => {
        setIsFullImage((preState) => !preState)
    }

    // eslint-disable-next-line
    const handleSelectDamage = (id): void => {
        setDamageId(id)
    }

    // eslint-disable-next-line
    const handleSelectSeverity = (id): void => {
        setSeverityId(id)
    }

    const handleCloseModal = (event): void => {
        event.stopPropagation()
        setModalOpen(false)
    }

    const handleAction = (action): void => {
        const token = getToken()
        if (action === 'download')
            dispatch(downloadFile(token, 'data', id, imageData?.name))
        else if (action === 'delete') dispatch(deleteData(token, id))
        onClose()
    }

    const selectorProps = [
        {
            title: 'Damage Types',
            selected: damageId,
            onSelect: handleSelectDamage,
        },
        {
            title: 'Severity Types',
            selected: severityId,
            onSelect: handleSelectSeverity,
        },
    ]

    useEffect(() => {
        const token = getToken()
        if (isBlendOn)
            dispatch(
                getOverlayDamageCrop(
                    token,
                    sphereData?.id,
                    damageId,
                    severityId,
                    isFullImage
                )
            )

        let legend_colours = labels
        if (severityId !== 'None') legend_colours = jet_colour_map
        else {
            // eslint-disable-next-line
            legend_colours = labels.filter(({ id }) => id === damageId)
            if (allLabel[0].id === damageId) {
                legend_colours = labels
            }
            legend_colours = legend_colours.map(
                ({
                    id: label__id,
                    name: label__name,
                    colour: label__colour,
                }) => ({
                    label__id,
                    label__name,
                    label__colour,
                })
            )
        }
        setLegendColours(legend_colours)
  }, [isFullImage, isBlendOn, sphereData, damageId, severityId]); // eslint-disable-line

    useEffect(() => {
        const token = getToken()
        if (!isBlendOn)
            dispatch(getDamageCrop(token, sphereData?.id, isFullImage))
  }, [isFullImage, isBlendOn, sphereData]); // eslint-disable-line

    useEffect(() => {
        resetSelectors()
    }, [id]) // #reset when image id changes

    useEffect(() => {
        if (!open) {
            const token = getToken()
            getDamageCrop(token, sphereData?.id, false)
        }
    }, [open])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Box
                    clone
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box display="flex" alignItems="center">
                            <Typography component="h2" variant="h6">
                                {imageData?.name}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                autoFocus
                                color="inherit"
                                onClick={() => handleAction('download')}
                            >
                                {tooltip('Download', <GetAppIcon />)}
                            </IconButton>
                            <Can
                                I="perform"
                                a="delete"
                                ability={ABILITIES[role]}
                            >
                                <IconButton
                                    onClick={() => setModalOpen(true)}
                                    color="inherit"
                                >
                                    {tooltip('Delete', <DeleteIcon />)}
                                </IconButton>
                            </Can>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
            <Grid container className={classes.root}>
                <Grid item md={10} className={classes.imageContainerGrid}>
                    <ImageContainer
                        img_data={img_data}
                        isBlendOn={isBlendOn}
                        isMaskOn={isMaskOn}
                        isFullImage={isFullImage}
                        onToggleMask={toggleMask}
                        legend_colours={legendColours}
                    />
                </Grid>
                <Grid item container md={2} className={classes.imageControls}>
                    <ImageControls
                        imageData={imageData}
                        sphereData={sphereData}
                        isBlendOn={isBlendOn}
                        isFullImage={isFullImage}
                        selectorProps={selectorProps}
                        onToggleBlend={toggleBlend}
                        onToggleFullImage={toggleFullImage}
                        damageLists={damageLists}
                        severityLists={severityLists}
                        legend_colours={legendColours}
                    />
                </Grid>
            </Grid>
            <DeleteModal
                folderName={imageData?.name}
                open={modalOpen}
                onDelete={() => handleAction('delete')}
                onClose={handleCloseModal}
            />
        </Dialog>
    )
}

export default CropDialog
