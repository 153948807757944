// @ts-nocheck
import React, { useEffect, useRef } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import ReactDOMServer from 'react-dom/server'
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { divIcon } from 'leaflet'
import Box from '@material-ui/core/Box'

import { statusColorScheme } from '../config/color_palette.js'
import { ReactComponent as AssetMarker } from '../assets/marker.svg'

const useStyles = makeStyles({
    mapContainer: {
        width: '100%',
        height: '100%',
        '& > div': {
            height: '100%',
            width: '100%',
        },
    },
    popupContent: {
        fontSize: '0.875rem',
        fontWeight: 500,
        margin: 'auto 0.5rem !important',
    },
})

const AssetsMap = ({
    data = [],
    hoveredId = null,
    onHover = null,
}): JSX.Element => {
    const theme = useTheme()
    const classes = useStyles()
    const center = [51.9244, 4.4777]
    const TILE_PROPS = {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
    const validMarkers = data.filter((o) => o.lat !== null && o.lon !== null)
    const intialBound = validMarkers.length > 0 ? [] : [center]
    const bounds = validMarkers.reduce((accumulator, { lat, lon }) => {
        return [...accumulator, [parseFloat(lat), parseFloat(lon)]]
    }, intialBound)

    const icon = (id, asset_status): JSX.Element =>
        divIcon({
            className: 'div-icon',
            html: ReactDOMServer.renderToString(
                <AssetMarker
                    key={`Spanicon${id}`}
                    style={{ transform: 'translate(-32%, -50%)' }}
                    fill={
                        // eslint-disable-next-line no-nested-ternary
                        onHover && id === hoveredId
                            ? theme.palette.primary.main
                            : asset_status
                            ? statusColorScheme[asset_status].color
                            : theme.palette.grey['500']
                    }
                />
            ),
        })

    const RenderMarkers = ({ markers }): JSX.Element => {
        const map = useMap()
        const markerRef = useRef()

        return markers.map(({ id, name, lat, lon, location, asset_status }) => {
            const coordinate = [parseFloat(lat), parseFloat(lon)]
            return (
                <Marker
                    key={`marker${id}`}
                    ref={markerRef}
                    className="marker"
                    position={coordinate}
                    icon={icon(id, asset_status)}
                    eventHandlers={{
                        mouseover: () => onHover && onHover(id),
                        click: () => map.flyTo(coordinate),
                    }}
                >
                    <Popup key={id}>
                        <p>{name}</p>
                        <p>{location}</p>
                    </Popup>
                </Marker>
            )
        })
    }

    return (
        <>
            {data?.length > 0 && (
                <Box className={classes.mapContainer}>
                    <MapContainer
                        center={
                            validMarkers.length > 1 ? null : validMarkers[0]
                        }
                        bounds={bounds}
                        zoom={13}
                        boundsOptions={{
                            padding: [10, 10],
                        }}
                    >
                        {/* eslint-disable-next-line */}
                        <TileLayer {...TILE_PROPS} />
                        <RenderMarkers markers={validMarkers} />
                    </MapContainer>
                </Box>
            )}
        </>
    )
}

export default AssetsMap
