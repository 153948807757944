import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCEEDED,
    GET_USERS_FAILED,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCEEDED,
    GET_USER_DETAILS_FAILED,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCEEDED,
    CHANGE_PASSWORD_FAILED,
    TOGGLE_PASSWORD_ALERT_OPEN,
} from '../types/users.js'

const usersIntialState = {
    data: [],
    loading: false,
}

export const usersReducer = (state = usersIntialState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_USERS_SUCCEEDED:
            const data = action.users_data.data.results
            return {
                ...state,
                loading: false,
                data,
            }
        case GET_USERS_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

const initialState = {
    user: {
        username: '',
        email: '',
    },
    loading: false,
}

export const getUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_USER_DETAILS_SUCCEEDED:
            delete action.user_data.groups
            return {
                ...state,
                user: action.user_data,
                loading: false,
            }
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

const changePasswordState = {
    alertOpen: false,
    status: {
        message: '',
        success: '',
    },
    loading: false,
}

export const changePasswordReducer = (state = changePasswordState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case CHANGE_PASSWORD_SUCCEEDED:
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                status: action.user_data,
                alertOpen: true,
                loading: false,
            }
        case TOGGLE_PASSWORD_ALERT_OPEN:
            return {
                ...state,
                alertOpen: !state.alertOpen,
            }
        default:
            return state
    }
}
