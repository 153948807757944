import {
    useQuery,
    UseQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from 'react-query'

import {
    fetchUserAssetsAPI,
    fetchAssetDetailsAPI,
    fetchSpheresAPI,
    fetchImageAPI,
    fetchDamageCropAPI,
    updateSphereAPI,
    fetchAssetDataAPI,
    updateDataValidationAPI,
    updateSpheresAPI,
    getMoveDataAPI,
} from '../../config/api.js'
import { IAsset, IDamageSphere, IAssetData, IAnnotation } from '../../interface'

export const useFetchUserAssets = (
    token: string,
    dataPerPage: number,
    currentPage: number,
    sortColumn: { column: string; order: string }
): UseQueryResult<{ results: IAsset[]; count: number }, Error> => {
    return useQuery(
        [
            'assets',
            dataPerPage,
            currentPage,
            sortColumn?.column,
            sortColumn?.order,
        ],
        async () => {
            const apiResult = await fetchUserAssetsAPI(token, {
                dataPerPage,
                currentPage,
                sortColumn,
            })
            return apiResult.data
        },
        { keepPreviousData: true }
    )
}

export const useFetchAssetDetails = (
    token: string,
    id: number
): UseQueryResult<IAsset, Error> => {
    return useQuery(
        ['asset', id],
        async () => {
            const apiResult = await fetchAssetDetailsAPI(token, id)
            // @ts-ignore
            apiResult.data.labels = apiResult.data?.labels?.map((label) => ({
                colour: label.label__colour,
                id: label.label__id,
                name: label.label__name,
            }))

            return apiResult.data
        },
        { refetchOnWindowFocus: false }
    )
}

export const useFetchAssetData = (
    token: string,
    assetId: number,
    params: Record<string, unknown>
): UseQueryResult<IAssetData[], Error> => {
    return useQuery(
        ['asset-data', assetId],
        async () => {
            const apiResult = await fetchAssetDataAPI(token, assetId, params)
            return apiResult.data
        },
        { refetchOnWindowFocus: false }
    )
}

export const useFetchSpheresAPI = (
    token: string,
    assetId: number,
    params: Record<string, unknown>
): UseQueryResult<IDamageSphere[], Error> => {
    return useQuery(
        ['spheres', assetId, ...Object.values(params)],
        async () => {
            const apiResult = await fetchSpheresAPI(token, {
                asset_id: assetId,
                ...params,
            })

            return apiResult.data
        },
        { refetchOnWindowFocus: false }
    )
}

export const useFetchImageAPI = (
    token: string,
    imageId: number,
    params = {}
): UseQueryResult<IAssetData, Error> => {
    return useQuery(
        ['data', imageId, ...Object.values(params)],
        async () => {
            const apiResult = await fetchImageAPI(token, imageId, params)

            return apiResult.data
        },
        { refetchOnWindowFocus: false }
    )
}

export const useFetchDamageCropAPI = (
    token: string,
    sphereId: number,
    mask: boolean,
    damageType: string,
    severityType: string,
    bbox: boolean,
    damageId: number | undefined,
    blendBbox: boolean
): UseQueryResult<
    {
        id: number
        damage_crop: {
            img: string
            mask: string
            boundingBoxes: IAnnotation[]
        }
        image: string
    },
    Error
> => {
    return useQuery(
        ['sphere', sphereId, mask, damageType, severityType, bbox, damageId],
        async () => {
            const apiResult = await fetchDamageCropAPI(
                token,
                sphereId,
                mask,
                damageType,
                severityType,
                bbox,
                damageId,
                blendBbox
            )

            return apiResult.data
        },
        { refetchOnWindowFocus: false }
    )
}

interface IuseUpdateSphereAPI {
    token: string
    sphereId: number
    data: Partial<IDamageSphere>
    params?: Record<string, unknown>
}

export const useUpdateSphereAPI = (): UseMutationResult<
    IDamageSphere,
    Error,
    IuseUpdateSphereAPI
> => {
    const queryClient = useQueryClient()

    return useMutation(
        async (args: IuseUpdateSphereAPI) => {
            const { token, sphereId, data, params } = args
            const apiResult = await updateSphereAPI(
                token,
                sphereId,
                data,
                params
            )
            return apiResult.data
        },
        {
            mutationKey: 'useUpdateSphereAPI',
            onSuccess: async (newData, variables) => {
                queryClient.refetchQueries(['spheres'])
                queryClient.refetchQueries(['sphere'])
                queryClient.refetchQueries(['data'])
            },
        }
    )
}

interface IuseUpdateSpheresAPI {
    token: string
    assetId: number
    data: Partial<IDamageSphere>[]
    params: Record<string, unknown>
}

export const useUpdateSpheresAPI = (
    key?: unknown[],
    callback?: () => unknown
): UseMutationResult<IDamageSphere[], Error, IuseUpdateSpheresAPI> => {
    const queryClient = useQueryClient()

    return useMutation(
        async (args: IuseUpdateSpheresAPI) => {
            const { token, assetId, data, params } = args
            const apiResult = await updateSpheresAPI(
                token,
                assetId,
                params,
                data
            )
            return apiResult.data
        },
        {
            mutationKey: 'useUpdateSpheresAPI',
            onSuccess: (newData, variables) => {
                if (key) {
                    queryClient.setQueryData(key, newData)
                }
                if (callback) {
                    callback()
                }
                queryClient.refetchQueries(['spheres', variables.assetId])
                queryClient.refetchQueries(['sphere'])
            },
        }
    )
}

interface IUseGetMoveDataAPI {
    token: string
    dataId: number
    data: Partial<IAssetData>
}

export const useGetMoveDataAPI = (
    key: unknown[]
): UseMutationResult<IAssetData, Error, IUseGetMoveDataAPI> => {
    const queryClient = useQueryClient()

    return useMutation(
        async (args: IUseGetMoveDataAPI) => {
            const { token, dataId, data } = args
            const apiResult = await getMoveDataAPI(token, dataId, data)
            return apiResult.data
        },
        {
            mutationKey: 'useGetMoveDataAPI',
            onSuccess: async (newData, variables) => {
                queryClient.refetchQueries(['asset-data'])
                queryClient.refetchQueries(['data', variables.dataId])
            },
        }
    )
}

interface IuseUpdateDataValidationAPI {
    token: string
    assetId: number
    dataId: number
    data: Partial<IAssetData>
}

export const useUpdateDataValidationAPI = (): UseMutationResult<
    IAssetData,
    Error,
    IuseUpdateDataValidationAPI
> => {
    const queryClient = useQueryClient()

    return useMutation(
        async (args: IuseUpdateDataValidationAPI) => {
            const { token, assetId, dataId, data } = args
            const apiResult = await updateDataValidationAPI(
                token,
                assetId,
                dataId,
                data
            )
            return apiResult.data
        },
        {
            mutationKey: 'useUpdateDataValidationAPI',
            onSuccess: async (newData, variables) => {
                queryClient.refetchQueries(['asset-data'])
            },
        }
    )
}
