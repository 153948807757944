export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST'
export const GET_LOGIN_SUCCEEDED = 'GET_LOGIN_SUCCEEDED'
export const GET_LOGIN_FAILED = 'GET_LOGIN_FAILED'
export const performLogin = (user_data) => {
    return {
        type: GET_LOGIN_REQUEST,
        user_data,
    }
}

export const GET_LOGOUT_REQUEST = 'GET_LOGOUT_REQUEST'
export const GET_LOGOUT_SUCCEEDED = 'GET_LOGOUT_SUCCEEDED'
export const GET_LOGOUT_FAILED = 'GET_LOGOUT_FAILED'
export const performLogout = () => {
    return {
        type: GET_LOGOUT_REQUEST,
    }
}

export const GET_CHECK_TOKEN_REQUEST = 'GET_CHECK_TOKEN_REQUEST'
export const GET_CHECK_TOKEN_SUCCEEDED = 'GET_CHECK_TOKEN_SUCCEEDED'
export const GET_CHECK_TOKEN_FAILED = 'GET_CHECK_TOKEN_FAILED'
export const checkToken = (token) => {
    return {
        type: GET_CHECK_TOKEN_REQUEST,
        token,
    }
}

export const GET_CURRENT_USER_GROUPS_REQUEST = 'GET_CURRENT_USER_GROUPS_REQUEST'
export const GET_CURRENT_USER_GROUPS_SUCCEEDED =
    'GET_CURRENT_USER_GROUPS_SUCCEEDED'
export const GET_CURRENT_USER_GROUPS_FAILED = 'GET_CURRENT_USER_GROUPS_FAILED'

export const getCurrentUserGroupsAction = (token) => {
    return { type: GET_CURRENT_USER_GROUPS_REQUEST, token }
}
