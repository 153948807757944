import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Box from '@material-ui/core/Box'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
// @ts-ignore
import Annotation from 'react-image-annotation'
// @ts-ignore
import {
    RectangleSelector,
    // @ts-ignore
} from 'react-image-annotation/lib/selectors'
import clsx from 'clsx'

import Spinner from '../../Common/Spinner'
import {
    useFetchImageAPI,
    useGetMoveDataAPI,
    useUpdateSphereAPI,
} from '../../Hook/queries'
import { IAnnotation } from '../../../interface'
import ThreadedEditor from './ThreadedEditor.jsx'
import ThreadedContent from './ThreadedContent.jsx'
import { IValidationFilterForm } from '../Filters'

interface IViewier {
    selectedDamage: number
    damageIDs: number[]
    imageId: number
    assetId: number
    damageCount: number
    hoverDamageId: number
    damageFilters: IValidationFilterForm
}

const useStyles = makeStyles({
    image: {
        width: '100%',
        height: 'auto',
    },
    container: {
        height: '100%',
    },
    imgContainer: {
        width: '100%',
    },
})

const Viewer = ({
    selectedDamage,
    damageIDs,
    imageId,
    assetId,
    damageCount,
    hoverDamageId,
    damageFilters,
}: IViewier): JSX.Element => {
    const theme = useTheme()
    const classes = useStyles()
    const [viewerState, setViewerState] = useState({
        allDamages: false,
        overlay: false,
        annotations: true,
    })
    // @ts-ignore
    const userToken = useSelector((state) => state.login.token)
    // @ts-ignore
    const userData = useSelector((state) => state.user_data)
    const segmentIDs = viewerState.allDamages ? damageIDs : [selectedDamage]
    const {
        isLoading: isImgLoading,
        isError: isImgError,
        error: imgError,
        data,
    } = useFetchImageAPI(userToken, imageId, {
        damage_type: damageFilters.damageType,
        severity_type: damageFilters.severity,
        damage_IDs: segmentIDs,
        fields: ['instance_segmentation', 'bounding_boxes', 'img_data'],
    })
    const { mutate: mutateAssetData } = useGetMoveDataAPI([
        'fetchAssetDataAPI',
        assetId,
    ])
    const { mutate: mutateSphere } = useUpdateSphereAPI()
    const { enqueueSnackbar } = useSnackbar()
    const initialAnnotations = {
        annotations: [],
        annotation: {},
        activeAnnotations: [],
    }
    const [annotationState, setAnnotationState] = useState<{
        annotations: IAnnotation[]
        annotation: Record<string, unknown>
        activeAnnotations: number[]
    }>(initialAnnotations)

    // @ts-ignore
    const renderHighlight = ({ annotation, active }): JSX.Element | null => {
        const { geometry } = annotation
        if (!geometry || !viewerState.annotations) return null
        const isDamageBox = annotation.data?.type === 'damage_bbox'
        const boxColor = `#${annotation.data.colour}`

        return (
            <Box
                key={annotation.data.id}
                position="absolute"
                fontWeight="fontWeightBold"
                fontSize={10}
                left={`${geometry.x}%`}
                top={`${geometry.y}%`}
                height={`${geometry.height}%`}
                width={`${geometry.width}%`}
                style={{
                    border: `solid 2px ${isDamageBox ? boxColor : 'black'}`,
                    boxShadow:
                        hoverDamageId === annotation.data.id
                            ? '0 0 20px 20px rgba(255, 255, 255, 0.3) inset'
                            : active && '0 0 1px 1px white inset',
                    overflowWrap: 'break-word',
                }}
            >
                <span
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <small>{isDamageBox ? annotation.data.id : '...'}</small>
                </span>
            </Box>
        )
    }

    const persistAnnotations = (list: IAnnotation[]): void => {
        // custom or image annotations
        mutateAssetData({
            token: userToken,
            dataId: imageId,
            data: {
                // @ts-ignore
                user_annotations: list.filter(
                    // @ts-ignore
                    (note) => note?.data?.type !== 'damage_bbox'
                ),
            },
        })

        // damage annotations
        list.filter(
            // @ts-ignore
            (note) => note?.data?.type === 'damage_bbox'
        ).forEach((item) => {
            mutateSphere({
                token: userToken,
                sphereId: item.data.id,
                data: {
                    // @ts-ignore
                    user_annotations: [item],
                },
                params: {
                    damageId: item.data.id,
                    bbox: true,
                },
            })
        })
    }

    const onAnnotationChange = (annotation: Record<string, unknown>): void => {
        setAnnotationState({ ...annotationState, annotation })
    }

    const onAnnotationSubmit = (annotation: Record<string, unknown>): void => {
        // eslint-disable-next-line no-debugger
        const { geometry, data: annotationData } = annotation
        const newAnnotations = [
            // @ts-ignore
            ...annotationState.annotations,
            {
                // @ts-ignore
                geometry,
                data: {
                    // @ts-ignore
                    ...annotationData,
                    // @ts-ignore
                    id: Math.random(),
                },
            },
        ]

        setAnnotationState({
            ...annotationState,
            annotation: {},
            // @ts-ignore
            annotations: newAnnotations,
        })

        // persist annotations
        // @ts-ignore
        persistAnnotations(newAnnotations)
    }

    const handleFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setViewerState({
            ...viewerState,
            [event.target.name]: event.target.checked,
        })
    }

    const handleDeleteAnnotation = (annotation: IAnnotation): void => {
        const { type, id } = annotation.data

        if (type === 'damage_bbox') {
            // damage annotation
            mutateSphere({
                token: userToken,
                sphereId: id,
                data: {
                    // @ts-ignore
                    user_annotations: [],
                    removed: true,
                },
                params: {
                    damageId: id,
                    bbox: true,
                },
            })
        } else {
            // custom or image annotations
            mutateAssetData({
                token: userToken,
                dataId: imageId,
                data: {
                    // @ts-ignore
                    user_annotations: annotationState.annotations.filter(
                        (note) =>
                            note.data.type !== 'damage_bbox' &&
                            note.data.id !== id
                    ),
                },
            })
        }
        // delete annotations state
        setAnnotationState({
            ...initialAnnotations,
            annotations: annotationState.annotations.filter(
                (note) => note.data.id !== id
            ),
        })
    }

    const handleClearAnnotation = (annotation: IAnnotation): void => {
        const { type, id } = annotation.data

        if (type === 'damage_bbox') {
            // damage annotation
            mutateSphere({
                token: userToken,
                sphereId: id,
                data: {
                    // @ts-ignore
                    user_annotations: [],
                },
                params: {
                    damageId: id,
                    bbox: true,
                },
            })
        } else {
            // custom or image annotations
            mutateAssetData({
                token: userToken,
                dataId: imageId,
                data: {
                    // @ts-ignore
                    user_annotations: annotationState.annotations
                        .filter((note) => note.data.type !== 'damage_bbox')
                        .map((note) =>
                            note.data.id === id
                                ? {
                                      ...note,
                                      data: { ...note.data, comments: [] },
                                  }
                                : note
                        ),
                },
            })
        }
        // clear annotations state
        setAnnotationState({
            ...initialAnnotations,
            annotations: annotationState.annotations.map((note) =>
                note.data.id === id
                    ? { ...note, data: { ...note.data, comments: [] } }
                    : note
            ),
        })
    }

    // @ts-ignore
    const renderEditor = (props): JSX.Element | null => {
        const { geometry } = props.annotation
        if (!geometry || !viewerState.annotations) return null

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ThreadedEditor {...props} user={userData?.user?.username || '?'} />
        )
    }

    // @ts-ignore
    const onFocus = (id) => (e) => {
        setAnnotationState({
            ...annotationState,
            // @ts-ignore
            activeAnnotations: [...annotationState.activeAnnotations, id],
        })
    }

    // @ts-ignore
    const onBlur = (id) => (e) => {
        // @ts-ignore
        const index = annotationState.activeAnnotations.indexOf(id)

        setAnnotationState({
            ...annotationState,
            activeAnnotations: [
                ...annotationState.activeAnnotations.slice(0, index),
                ...annotationState.activeAnnotations.slice(index + 1),
            ],
        })
    }

    // @ts-ignore
    const renderContent = ({ key, annotation }): JSX.Element | null => {
        if (!viewerState.annotations) return null

        return (
            <ThreadedContent
                key={key}
                annotation={annotation}
                annotations={annotationState.annotations}
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-shadow
                setAnnotations={(annotations) => {
                    setAnnotationState({
                        ...annotationState,
                        annotations,
                    })
                    persistAnnotations(annotations)
                }}
                onFocus={onFocus(key)}
                onBlur={onBlur(key)}
                onSubmit={onAnnotationSubmit}
                user={userData?.user?.username || '?'}
                onDelete={handleDeleteAnnotation}
                onClear={handleClearAnnotation}
            />
        )
    }

    // @ts-ignore
    const activeAnnotationComparator = (a, b): boolean => {
        return a.data.id === b
    }

    // API error
    useEffect(() => {
        if (isImgError) {
            enqueueSnackbar(imgError?.message, {
                variant: 'error',
            })
        }
    }, [isImgError, imgError])

    useEffect(() => {
        // do some checking here to ensure data exist
        if (data) {
            // mutate data if you need to
            setAnnotationState({
                ...annotationState,
                annotations: data.bounding_boxes,
            })
        }
    }, [data])

    return (
        <Box
            display="flex"
            flexDirection="column"
            className={classes.container}
            mr={1}
        >
            {isImgLoading ? (
                <Spinner />
            ) : (
                <>
                    <Box
                        position="relative"
                        className={classes.imgContainer}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <TransformWrapper
                            options={{
                                disabled: viewerState.annotations,
                            }}
                        >
                            {/* @ts-ignore */}
                            {({ resetTransform, setScale, ...rest }) => (
                                <>
                                    <Box clone mb={1}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            viewerState.allDamages
                                                        }
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        name="allDamages"
                                                        color="primary"
                                                        size="small"
                                                        disabled={
                                                            damageCount === 0
                                                        }
                                                    />
                                                }
                                                label="All damages"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            viewerState.overlay
                                                        }
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        name="overlay"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label="Damage overlays"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            viewerState.annotations
                                                        }
                                                        onChange={(event) => {
                                                            if (
                                                                event.target
                                                                    .checked
                                                            ) {
                                                                resetTransform()
                                                                setTimeout(
                                                                    () => {
                                                                        setViewerState(
                                                                            (
                                                                                currState
                                                                            ) => ({
                                                                                ...currState,
                                                                                annotations: !currState.annotations,
                                                                            })
                                                                        )
                                                                    },
                                                                    300
                                                                )
                                                            } else {
                                                                handleFormChange(
                                                                    event
                                                                )
                                                            }
                                                        }}
                                                        name="annotations"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label="Annotations"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <div
                                        className={clsx({
                                            overflowContainer: !viewerState.annotations,
                                        })}
                                    >
                                        <TransformComponent>
                                            <Annotation
                                                disableAnnotation={
                                                    !viewerState.annotations
                                                }
                                                disableSelector={
                                                    !viewerState.annotations
                                                }
                                                disableEditor={
                                                    !viewerState.annotations
                                                }
                                                disableOverlay={
                                                    !viewerState.annotations
                                                }
                                                src={`data:image/png;base64,${
                                                    viewerState.overlay
                                                        ? data?.instance_segmentation?.img.slice(
                                                              1,
                                                              -1
                                                          )
                                                        : data?.img_data?.img.slice(
                                                              1,
                                                              -1
                                                          )
                                                }`}
                                                alt="damage"
                                                activeAnnotationComparator={
                                                    activeAnnotationComparator
                                                }
                                                activeAnnotations={
                                                    annotationState.activeAnnotations
                                                }
                                                annotations={
                                                    annotationState.annotations
                                                }
                                                type={RectangleSelector.TYPE}
                                                value={
                                                    annotationState.annotation
                                                }
                                                onChange={onAnnotationChange}
                                                onSubmit={onAnnotationSubmit}
                                                renderHighlight={
                                                    renderHighlight
                                                }
                                                renderEditor={renderEditor}
                                                renderContent={renderContent}
                                                allowTouch
                                            />
                                        </TransformComponent>
                                    </div>
                                </>
                            )}
                        </TransformWrapper>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default Viewer
