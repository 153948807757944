// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { roleLists } from '../../config/selectorLists.js'
import { userForms } from '../../config/constants.js'
import { createProjectUser } from '../../redux/actions/project.js'

const AssetsUserForm = ({ token, project_id }): JSX.Element => {
    const dispatch = useDispatch()
    const { errorMessage } = useSelector((state) => state.project_data)
    const [formState, setFormState] = useState({
        role: '',
        username: '',
        email: '',
        password: '',
    })
    const activeForm = userForms[1]

    const formProps = {
        selectors: {
            label: 'role',
            title: 'select a role',
            options: roleLists,
        },
        lists: [
            { label: 'Username', key: 'username' },
            { label: 'Email', key: 'email', type: 'email' },
            { label: 'Password', key: 'password', type: 'password' },
        ],
        button: { text: activeForm.key, icon: <AddIcon /> },
        errorMessage,
    }

    const handleSubmit = (event): void => {
        event.preventDefault()

        const user = formState
        dispatch(createProjectUser(token, project_id, user))
    }

    // TODO: extract form component (json configuration driven)
    return (
        <>
            <Typography color="textSecondary" variant="subtitle1">
                {activeForm.text}
            </Typography>
            <form onSubmit={handleSubmit}>
                {typeof errorMessage !== 'object' && !!errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                )}

                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="assetuser-new-field-role">Role</InputLabel>
                    <Select
                        labelId="assetuser-new-field-role"
                        id="assetuser-new-field-role"
                        label="Role"
                        value={formState.role}
                        onChange={(event) => {
                            setFormState((state) => ({
                                ...state,
                                role: event.target.value,
                            }))
                        }}
                    >
                        {roleLists.map((item) => {
                            return (
                                <MenuItem key={item.type} value={item.id}>
                                    {item.type}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText>Select A Role</FormHelperText>
                </FormControl>

                {formProps.lists.map(({ label, key, type = 'text' }) => {
                    return (
                        <TextField
                            key={key}
                            id={`assetuser-new-field-${key}`}
                            label={label}
                            type={type}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formState[key]}
                            onChange={(event) => {
                                setFormState((state) => ({
                                    ...state,
                                    [key]: event.target.value,
                                }))
                            }}
                        />
                    )
                })}

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={<AddIcon />}
                    >
                        {formProps.button.text}
                    </Button>
                </Box>
            </form>
        </>
    )
}

export default AssetsUserForm
