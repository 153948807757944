export const TOGGLE_ASSET_FORM_OPEN = 'TOGGLE_ASSET_FORM_OPEN'
export const SET_ASSET_FORM_DATA = 'SET_ASSET_FORM_DATA'

export const GET_PROJECT_DETAILS_REQUEST = 'GET_PROJECT_DETAILS_REQUEST'
export const GET_PROJECT_DETAILS_SUCCEEDED = 'GET_PROJECT_DETAILS_SUCCEEDED'
export const GET_PROJECT_DETAILS_FAILED = 'GET_PROJECT_DETAILS_FAILED'

// ========================
//      PROJECT ASSETS
// ========================

export const ADD_ASSET_REQUEST = 'ADD_ASSET_REQUEST'
export const ADD_ASSET_SUCCEEDED = 'ADD_ASSET_SUCCEEDED'
export const ADD_ASSET_FAILED = 'ADD_ASSET_FAILED'

export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST'
export const UPDATE_ASSET_SUCCEEDED = 'UPDATE_ASSET_SUCCEEDED'
export const UPDATE_ASSET_FAILED = 'UPDATE_ASSET_FAILED'

export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST'
export const DELETE_ASSET_SUCCEEDED = 'DELETE_ASSET_SUCCEEDED'
export const DELETE_ASSET_FAILED = 'DELETE_ASSET_FAILED'

// =======================
//      PROJECT USERS
// =======================

export const TOGGLE_USER_FORM_OPEN = 'TOGGLE_USER_FORM_OPEN'

export const GET_PROJECT_USERS_REQUEST = 'GET_PROJECT_USERS_REQUEST'
export const GET_PROJECT_USERS_SUCCEEDED = 'GET_PROJECT_USERS_SUCCEEDED'
export const GET_PROJECT_USERS_FAILED = 'GET_PROJECT_USERS_FAILED'

export const CREATE_PROJECT_USER_REQUEST = 'CREATE_PROJECT_USER_REQUEST'
export const CREATE_PROJECT_USER_SUCCEEDED = 'CREATE_PROJECT_USER_SUCCEEDED'
export const CREATE_PROJECT_USER_FAILED = 'CREATE_PROJECT_USER_FAILED'

export const ADD_PROJECT_USER_REQUEST = 'ADD_PROJECT_USER_REQUEST'
export const ADD_PROJECT_USER_SUCCEEDED = 'ADD_PROJECT_USER_SUCCEEDED'
export const ADD_PROJECT_USER_FAILED = 'ADD_PROJECT_USER_FAILED'

export const REMOVE_PROJECT_USER_REQUEST = 'REMOVE_PROJECT_USER_REQUEST'
export const REMOVE_PROJECT_USER_SUCCEEDED = 'REMOVE_PROJECT_USER_SUCCEEDED'
export const REMOVE_PROJECT_USER_FAILED = 'REMOVE_PROJECT_USER_FAILED'

export const UPDATE_PROJECT_USER_REQUEST = 'UPDATE_PROJECT_USER_REQUEST'
export const UPDATE_PROJECT_USER_SUCCEEDED = 'UPDATE_PROJECT_USER_SUCCEEDED'
export const UPDATE_PROJECT_USER_FAILED = 'UPDATE_PROJECT_USER_FAILED'

// ==========================
//          SECTIONS
// ==========================

export const GET_SECTIONS_REQUEST = 'GET_SECTIONS_REQUEST'
export const GET_SECTIONS_SUCCEEDED = 'GET_SECTIONS_SUCCEEDED'
export const GET_SECTIONS_FAILED = 'GET_SECTIONS_FAILED'

// =========================
//           TASKS
// =========================

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST'
export const GET_TASKS_SUCCEEDED = 'GET_TASKS_SUCCEEDED'
export const GET_TASKS_FAILED = 'GET_TASKS_FAILED'
