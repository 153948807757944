// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import DonutChart from '../../Common/Vis/DonutChart'

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        height: '100px',
        [theme.breakpoints.up('md')]: {
            height: '200px',
        },
    },
}))

const DamageChart = ({ data = [], onClick, chartType }): JSX.Element => {
    const classes = useStyles()
    const [chartInfo, setChartInfo] = useState([])

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            const data_keys = []

            Object.assign(data_keys, Object.keys(data['All Components']))
            const index = data_keys.indexOf('All Damages')
            if (index > -1) {
                data_keys.splice(index, 1)
            }
            const res = data_keys.map((key) => {
                return {
                    id: key,
                    value: data['All Components'][key].count,
                    label: key,
                    color: data['All Components'][key].label__colour,
                }
            })
            setChartInfo(res)
        }
    }, [data])

    return (
        <Box display="flex" flexDirection="column" p={2}>
            <Box>
                <Typography variant="h5" gutterBottom align="center">
                    {chartType === 'count' ? 'Damage Count' : 'Damage Area'}
                </Typography>
            </Box>
            <Box flexGrow={1} className={classes.chartContainer}>
                {chartInfo.length > 0 ? (
                    <DonutChart
                        data={chartInfo}
                        showCenterMetric
                        onClick={onClick}
                        chartType={chartType}
                    />
                ) : (
                    <p>No data available</p>
                )}
            </Box>
        </Box>
    )
}

export default DamageChart
