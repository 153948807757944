// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'

import {
    createProject,
    updateProject,
    toggleFormOpen,
} from '../redux/actions/dashboard.js'
import { fetchCompanyStart } from '../redux/actions/company.js'
import { getToken } from '../services/authService.js'

// eslint-disable-next-line
const ProjectFormModal = ({ type = 'Project', open }): JSX.Element => {
    const dispatch = useDispatch()
    const { data: companies } = useSelector((state) => state.companies)
    const { formOpen, formData, errorMessage } = useSelector(
        (state) => state.projects
    )
    const initialState = { name: '', location: '', company: '' }
    const [newData, setNewData] = useState(initialState)
    const { name, company, location } = newData

    const [alertText, setAlertText] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const token = getToken()

    const formLists = [
        {
            label: 'Name',
            name: 'name',
            value: name,
            fieldType: 'text',
            autoFocus: true,
        },
        {
            label: 'Company',
            name: 'company',
            value: company,
            fieldType: 'autocomplete',
            autoFocus: false,
            options: companies.map((item) => item.name),
        },
        {
            label: 'Location',
            name: 'location',
            value: location,
            fieldType: 'text',
            autoFocus: false,
        },
    ]

    const handleChange = (key, value): void => {
        setNewData({ ...newData, [key]: value })
        setAlertText('')
        setIsDisabled(false)
    }

    const handleClose = (): void => {
        if (!formOpen) return
        dispatch(toggleFormOpen())
        setAlertText(null)
        setNewData(initialState)
        setIsDisabled(true)
    }

    // eslint-disable-next-line
    const findCompany = (inputCompany) =>
        companies.find(
            // eslint-disable-next-line
            ({ name }) =>
                name.toLowerCase() === inputCompany?.trim()?.toLowerCase()
        )

    // eslint-disable-next-line
    const mapToNewData = ({ id, name, location, company }) => ({
        id,
        name,
        location,
        company: company.name,
        company_id: company.id,
    })

    // eslint-disable-next-line
    const handleSubmit = (event) => {
        event.preventDefault()
        const result = findCompany(company)
        if (!result)
            return setAlertText({ company: ['Company does not exist'] })
        const newProject = { name, location, company_id: result.id }
        if (!formData) dispatch(createProject(token, newProject))
        else dispatch(updateProject(token, formData.id, newData))
    }

    useEffect(() => {
        dispatch(fetchCompanyStart(token))
        if (formOpen) dispatch(toggleFormOpen())
  }, []); // eslint-disable-line

    useEffect(() => {
        if (!formOpen) return
        // focusInput.current.focus()
        if (formData) setNewData(mapToNewData(formData))
  }, [formOpen]); // eslint-disable-line

    useEffect(() => {
        setAlertText(errorMessage)
        if (errorMessage === false) handleClose()
  }, [errorMessage]); // eslint-disable-line

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="projectform-dialog-title"
            fullWidth
        >
            <DialogTitle id="projectform-dialog-title">
                {formData ? 'Update' : 'New'} {type}
            </DialogTitle>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <DialogContent>
                    {formLists.map(
                        ({
                            label,
                            name,// eslint-disable-line
                            value,
                            fieldType,
                            autoFocus,
                            options,
                        }) => {
                            if (fieldType === 'autocomplete') {
                                return (
                                    <Autocomplete
                                        key={label}
                                        value={value}
                                        id={`projectform-field-${name}`}
                                        options={options}
                                        onChange={(event, val) => {
                                            handleChange(name, val)
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                // eslint-disable-next-line
                                                {...params}
                                                label={label}
                                                name={name}
                                                variant="outlined"
                                                autoFocus={autoFocus}
                                                error={
                                                    !!(
                                                        alertText &&
                                                        alertText[name]
                                                    )
                                                }
                                                helperText={
                                                    alertText && alertText[name]
                                                }
                                            />
                                        )}
                                    />
                                )
                            }
                            return (
                                <TextField
                                    key={label}
                                    id={`projectform-field-${name}`}
                                    label={label}
                                    name={name}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={value}
                                    autoFocus={autoFocus}
                                    onChange={(event) => {
                                        handleChange(name, event.target.value)
                                    }}
                                    error={!!(alertText && alertText[name])}
                                    helperText={alertText && alertText[name]}
                                />
                            )
                        }
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isDisabled}
                        startIcon={formData ? <CheckIcon /> : <AddIcon />}
                    >
                        {formData ? 'Save' : 'Create'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default ProjectFormModal
