// @ts-nocheck
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    expandIcon: {
        color: theme.palette.primary.contrastText,
    },
}))

const PointcloudSidebar = (): JSX.Element => {
    const classes = useStyles()
    const [accordionExpanded, setExpanded] = useState(false)
    const [pointBudget, setPointBudget] = useState(1000000)
    const [fieldView, setFieldView] = useState(60)

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleSetFieldView = (value): void => {
        setFieldView(value)
        window.viewer.fov = value
    }

    const handleSetPointBudget = (value): void => {
        setPointBudget(value)
        window.Potree.pointBudget = value
    }
    return (
        <>
            <div id="sidebar_root">
                <Accordion
                    id="potree-menu_scene"
                    expanded={accordionExpanded === 'panel2'}
                    onChange={handleAccordion('panel2')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-header"
                        id="panel2-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Scene</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <div id="scene_export" />
                            <div className="divider">
                                <span>Objects</span>
                            </div>
                            <div id="scene_objects" />
                            <div className="divider">
                                <span>Properties</span>
                            </div>
                            <div id="scene_object_properties" />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={accordionExpanded === 'panel3'}
                    onChange={handleAccordion('panel3')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Tools</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <div className="divider">
                                <span>Measurement</span>
                            </div>
                            <li id="potree-tools" />
                            <div className="divider">
                                <span>Navigation</span>
                            </div>
                            <li id="potree-navigation" />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={accordionExpanded === 'panel4'}
                    onChange={handleAccordion('panel4')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Appearance</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{ width: '100%' }}
                        >
                            <Typography id="point-budget-slider">
                                Point budget: {pointBudget}
                            </Typography>
                            <Slider
                                value={pointBudget}
                                aria-labelledby="point-budget-slider"
                                valueLabelDisplay="off"
                                min={100 * 1000}
                                max={10 * 1000 * 1000}
                                step={1000}
                                onChange={(e, value) =>
                                    handleSetPointBudget(value)
                                }
                            />
                            <li style={{ display: 'none' }}>
                                <span id="lblPointBudget" />{' '}
                                <div id="sldPointBudget" />
                            </li>
                            <Typography id="field-view-slider">
                                Field of View: {fieldView}
                            </Typography>
                            <Slider
                                value={fieldView}
                                aria-labelledby="field-view-slider"
                                valueLabelDisplay="auto"
                                min={20}
                                max={100}
                                onChange={(e, value) =>
                                    handleSetFieldView(value)
                                }
                            />
                            <li style={{ display: 'none' }}>
                                <span id="lblPointBudget" />{' '}
                                <div id="sldPointBudget" />
                            </li>
                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.field_view" />:{' '}
                                <span id="lblFOV" />
                                <div id="sldFOV" />
                            </li>

                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.edl_radius" />:{' '}
                                <span id="lblEDLRadius" />
                                <div id="sldEDLRadius" />
                            </li>
                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.edl_strength" />
                                <span id="lblEDLStrength" />
                                <div id="sldEDLStrength" />
                            </li>
                            <li style={{ display: 'none' }}>
                                <input type="checkbox" id="chkEDLEnabled" />
                                <span data-i18n="appearance.edl_enable" />
                            </li>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default PointcloudSidebar
