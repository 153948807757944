import {
    GET_IMAGE_REQUEST,
    GET_IMAGE_SUCCEEDED,
    GET_IMAGE_FAILED,
} from '../actions/data.js'

const initialState = {
    img_data: { img: '', mask: '' },
    id: '',
    labels: [],
    severity_labels: [
        { severity_label__name: 'None', severity_label__id: 'None' },
    ],
    legend_colours: [],
    loading: false,
    name: ''
}

export const getImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_IMAGE_SUCCEEDED:
            // let mask = action.image_data.data.mask ? action.image_data.data.mask : action.image_data.data.img
            const { severity_labels } = action.image_data.data
            severity_labels.unshift({
                severity_label__name: 'None',
                severity_label__id: 'None',
            })
            return {
                ...state,
                id: action.image_data.data.id,
                img_data: action.image_data.data.img_data,
                labels: action.image_data.data.labels,
                legend_colours: action.image_data.data.legend_colours,
                severity_labels,
                // colour_map: action.image_data.data.colour_map,
                loading: false,
                name: action.image_data.data.name
            }
        case GET_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
