import React from 'react'
import { ReactComponent as DashboardIcon } from '../assets/sideBar/dashboard.svg';
import { ReactComponent as AssetIcon } from '../assets/sideBar/asset.svg';
import { ReactComponent as ReportIcon } from '../assets/sideBar/reports.svg';
import { ReactComponent as SettingIcon } from '../assets/sideBar/settings.svg';
import { ReactComponent as LogoutIcon } from '../assets/actions/logout.svg'
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

export const mainListItems = [
    { text: 'Projects', url: '/projects', icon: DashboardIcon, color: 'primary' },
    { text: 'Assets', url: '/assets', icon: AssetIcon, color: 'primary' },
    { text: 'Reports', url: '/reports', icon: ReportIcon, color: 'primary' },
    { text: 'Validations', url: '/validations', icon: FindInPageOutlinedIcon, color: 'primary' },
]

export const secondaryListItems = [
    { text: 'Settings', url: '/settings', icon: SettingIcon, color: 'primary' },
    { text: 'Logout', url: '/logout', icon: LogoutIcon, color: 'secondary' },
]

export const logoClickPage = {
    url: '/projects'
}