export const TOGGLE_PROJECT_FORM_OPEN = 'TOGGLE_PROJECT_FORM_OPEN'
export const SET_PROJECT_FORM_DATA = 'SET_PROJECT_FORM_DATA'

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST'
export const ADD_PROJECT_SUCCEEDED = 'ADD_PROJECT_SUCCEEDED'
export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED'

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCEEDED = 'DELETE_PROJECT_SUCCEEDED'
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED'

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_SUCCEEDED = 'GET_PROJECTS_SUCCEEDED'
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCEEDED = 'UPDATE_PROJECT_SUCCEEDED'
export const UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED'

export const GET_LABELS_REQUEST = "GET_LABELS_REQUEST";
export const GET_LABELS_SUCCEEDED = "GET_LABELS_SUCCEEDED";
export const GET_LABELS_FAILED = "GET_LABELS_FAILED";
