/**
 * Github example:
 * https://github.com/Secretmapper/react-image-annotation/blob/master/demo/src/components/Samples/Threaded/index.js
 */

import React, { Component } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import TextEditor from 'react-image-annotation/lib/components/TextEditor'
import { colorDot } from '../../../utils/utils'

const Content = styled.div`
    background: white;
    border-radius: 2px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    margin: 8px 0;
`

const ContentClearanceTop = styled.div`
    position: absolute;
    height: 8px;
    top: -8px;
    left: -17px;
    right: -17px;
`

const ContentClearanceLeft = styled.div`
    position: absolute;
    height: 100%;
    left: -17px;
    width: 20px;
`

const ContentClearanceRight = styled.div`
    position: absolute;
    height: 100%;
    right: 0px;
    width: 20px;
`

const Comment = styled.div`
    border-bottom: 1px solid whitesmoke;
    padding: 8px 16px;
`

const CommentDescription = styled.div`
    margin: 10px 0;
`

const UserPill = styled.span`
    background-color: #2fb3c6;
    border-radius: 4px;
    color: white;
    padding: 2px 4px;
    font-size: 13.5px;
`

class ThreadedContent extends Component {
    state = {
        editorText: '',
    }

    onUpdateEditorText = (e) => {
        this.setState({ editorText: e.target.value })
    }

    renderComment(comment) {
        return (
            <Comment key={comment.id}>
                {comment.text}
                <CommentDescription>
                    <UserPill>{comment.user}</UserPill>
                </CommentDescription>
            </Comment>
        )
    }

    render() {
        const { props } = this
        const { annotation, onDelete, onClear } = props
        const { geometry } = annotation
        const comments = annotation.data && annotation.data.comments

        return (
            <>
                <Content
                    key={annotation.data.id}
                    style={{
                        position: 'absolute',
                        left: `${geometry.x}%`,
                        top: `${geometry.y + geometry.height}%`,
                    }}
                >
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" p={1}>
                            {annotation.data.type === 'damage_bbox' && (
                                <>
                                    {annotation.data.labels.map(
                                        ({ colour, name }) => (
                                            <Tooltip key={colour} title={name}>
                                                {colorDot(colour)}
                                            </Tooltip>
                                        )
                                    )}
                                    <small>
                                        {annotation.data.size?.toFixed(2)} m<sup>2</sup>
                                    </small>
                                </>
                            )}
                        </Box>
                        <Box>
                            {comments && comments.length > 0 && (
                                <Tooltip title="Clear comments">
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => onClear(annotation)}
                                    >
                                        <DeleteSweepIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Tooltip title="Remove annotation">
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    color="secondary"
                                    onClick={() => onDelete(annotation)}
                                >
                                    <DeleteForeverIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <ContentClearanceTop />
                    <ContentClearanceLeft />
                    <ContentClearanceRight />
                    {comments && comments.map(this.renderComment)}
                    <TextEditor
                        value={this.state.editorText}
                        onChange={this.onUpdateEditorText}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        onSubmit={(e) => {
                            const annotationIndex = props.annotations.indexOf(
                                annotation
                            )
                            const annotations = props.annotations.map(
                                (annotation, i) =>
                                    i === annotationIndex
                                        ? {
                                              ...annotation,
                                              data: {
                                                  ...annotation.data,
                                                  comments: [
                                                      ...comments,
                                                      {
                                                          id: Math.random(),
                                                          text: this.state
                                                              .editorText,
                                                          user: this.props.user,
                                                      },
                                                  ],
                                              },
                                          }
                                        : annotation
                            )

                            this.setState({ editorText: '' })
                            props.setAnnotations(annotations)
                        }}
                    />
                </Content>
            </>
        )
    }
}

export default ThreadedContent
