export const TOGGLE_PASSWORD_ALERT_OPEN = 'TOGGLE_PASSWORD_ALERT_OPEN'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST'
export const GET_USER_DETAILS_SUCCEEDED = 'GET_USER_DETAILS_SUCCEEDED'
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
