// @ts-nocheck
import React, { useEffect, useState } from 'react'
import {
    Link,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useHistory,
    useLocation,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Hidden from '@material-ui/core/Hidden'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

import Reports from './Report/Reports'
import Data from './Data/Data'
import ThreeDModel from '../ThreeDModel/ThreeDModel.js'
import Pointcloud from '../Pointcloud'
// import Mesh from './Mesh/Mesh.js'
import { getSetBreadcrumbsPath } from '../../redux/actions/system.js'
import Overview from './Overview'
import Spinner from '../Common/Spinner'

// TODO: extract tabs to common component
const Asset = ({ assetId }): JSX.Element => {
    const dispatch = useDispatch()
    const {
        data: { project, damages_found, three_d_model, name: asset_name },
        errorMessage,
        loading,
    } = useSelector((state) => state.asset_data)
    const username = useSelector((state) => state.user_data.user.username)
    const role = useSelector((state) => state.role.role)
    const { path, url } = useRouteMatch()
    const history = useHistory()
    const location = useLocation()
    const [isPotreeReady, setIsPotreeReady] = useState(false)

    const crumbs = {
        parent: '/projects',
        crumbs: [
            { label: project.name, path: `/projects/${project.id}` },
            { label: asset_name, path: '' },
        ],
    }

    const handlePotreeEvent = (): void => {
        setTimeout(() => {
            setIsPotreeReady(true)
        }, 0)
    }

    const display3DResult =
        project.project_type === 'predictive_maintenance' &&
        three_d_model.length > 0

    const hasDamages = damages_found.length > 0

    const tabContents = [
        {
            key: 'overview',
            title: 'Overview',
            render: <Overview />,
            link: '/overview',
        },
        {
            key: '3dmodel',
            title: '3D Model',
            render: <Pointcloud />,
            link: '/3dmodel',
            show: display3DResult && !hasDamages,
        },
        {
            key: '3dmodel',
            title: '3D Model',
            render: <ThreeDModel asset_id={assetId} />,
            link: '/3dmodel',
            show: display3DResult && hasDamages,
        },
        // {
        //     key: 'mesh',
        //     title: 'Mesh',
        //     render: <Mesh />,
        //     link: '/mesh',
        //     show: ['NorthSeaPort'].includes(username) || role === 'admin',
        // },
        {
            key: 'data',
            title: 'Data',
            render: <Data asset_id={assetId} />,
            link: '/data',
        },
        {
            key: 'reports',
            title: 'Reports',
            render: <Reports asset_id={assetId} />,
            link: '/reports',
        },
    ]

    useEffect(() => {
        if (!errorMessage) return
        if (errorMessage.endsWith(404)) history.replace('/404')
      }, [errorMessage]); // eslint-disable-line

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getSetBreadcrumbsPath(crumbs))
    }, [crumbs])

    // Check whether potree has been loaded
    useEffect(() => {
        if (window.viewer) {
            handlePotreeEvent()
        } else {
            window.addEventListener('potree-viewer-init', handlePotreeEvent)
        }

        return () => {
            window.removeEventListener('potree-viewer-init', handlePotreeEvent)
        }
    }, [])

    const tabHeaders = tabContents.map(({ key, title, link, show = true }) => {
        if (show) {
            return (
                <Tab
                    key={key}
                    value={`${url}${link}`}
                    label={title}
                    component={Link}
                    to={`${url}${link}`}
                />
            )
        }
        return null
    })

    return (
        <Paper>
            {!loading ? (
                <Box px={2}>
                    {/* smaller screen - fullwidth */}
                    <Hidden mdUp>
                        <Tabs
                            value={location.pathname}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {tabHeaders}
                        </Tabs>
                    </Hidden>
                    {/* larger screen - centered */}
                    <Hidden smDown>
                        <Tabs
                            value={location.pathname}
                            centered
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {tabHeaders}
                        </Tabs>
                    </Hidden>

                    <Switch>
                        <Box p={2}>
                            {tabContents.map(
                                ({ key, render, link, show = true }) => {
                                    if (show) {
                                        if (key === '3dmodel') {
                                            if (isPotreeReady) {
                                                return (
                                                    <Route
                                                        key={key}
                                                        path={`${path}${link}`}
                                                        render={() => render}
                                                    />
                                                )
                                            }
                                            return null
                                        }
                                        return (
                                            <Route
                                                key={key}
                                                path={`${path}${link}`}
                                                render={() => render}
                                            />
                                        )
                                    }
                                    return null
                                }
                            )}
                        </Box>

                        <Redirect to="/404" />
                    </Switch>
                </Box>
            ) : (
                <Spinner />
            )}
        </Paper>
    )
}

export default Asset
