export const GET_SPHERES_REQUEST = 'GET_SPHERES_REQUEST'
export const GET_SPHERES_SUCCEEDED = 'GET_SPHERES_SUCCEEDED'
export const GET_SPHERES_FAILED = 'GET_SPHERES_FAILED'

export const ADD_SPHERE_REQUEST = 'ADD_SPHERE_REQUEST'
export const ADD_SPHERE_SUCCEEDED = 'ADD_SPHERE_SUCCEEDED'
export const ADD_SPHERE_FAILED = 'ADD_SPHERE_FAILED'

export const UPDATE_SPHERE_REQUEST = 'UPDATE_SPHERE_REQUEST'
export const UPDATE_SPHERE_SUCCEEDED = 'UPDATE_SPHERE_SUCCEEDED'
export const UPDATE_SPHERE_FAILED = 'UPDATE_SPHERE_FAILED'

export const DELETE_SPHERE_REQUEST = 'DELETE_SPHERE_REQUEST'
export const DELETE_SPHERE_SUCCEEDED = 'DELETE_SPHERE_SUCCEEDED'
export const DELETE_SPHERE_FAILED = 'DELETE_SPHERE_FAILED'

export const TOGGLE_SPHERE_FORM_OPEN = "TOGGLE_SPHERE_FORM_OPEN";

export const GET_DAMAGE_CROP_REQUEST = "GET_DAMAGE_CROP_REQUEST";
export const GET_DAMAGE_CROP_SUCCEEDED = "GET_DAMAGE_CROP_SUCCEEDED";
export const GET_DAMAGE_CROP_FAILED = "GET_DAMAGE_CROP_FAILED";
