import {
    GET_SPHERES_REQUEST,
    GET_SPHERES_SUCCEEDED,
    GET_SPHERES_FAILED,
    ADD_SPHERE_REQUEST,
    ADD_SPHERE_SUCCEEDED,
    ADD_SPHERE_FAILED,
    DELETE_SPHERE_REQUEST,
    DELETE_SPHERE_SUCCEEDED,
    DELETE_SPHERE_FAILED,
    UPDATE_SPHERE_REQUEST,
    UPDATE_SPHERE_FAILED,
    UPDATE_SPHERE_SUCCEEDED,
    TOGGLE_SPHERE_FORM_OPEN,
    GET_DAMAGE_CROP_REQUEST,
    GET_DAMAGE_CROP_SUCCEEDED,
    GET_DAMAGE_CROP_FAILED,
} from '../types/sphere.js'

const spheresInitialState = {
    data: [],
    formOpen: false,
    errorMessage: undefined,
    loading: true,
}

export const spheresReducer = (state = spheresInitialState, action) => {
    switch (action.type) {
        case TOGGLE_SPHERE_FORM_OPEN:
            return {
                ...state,
                formOpen: !state.formOpen,
                errorMessage: undefined,
            }
        case GET_SPHERES_SUCCEEDED:
            return {
                ...state,
                data: action.data,
                loading: false,
                errorMessage: false,
            }
        case ADD_SPHERE_SUCCEEDED:
            return {
                ...state,
                data: [...state.data, action.data],
                loading: false,
                errorMessage: false,
            }
        case GET_SPHERES_FAILED:
        case ADD_SPHERE_FAILED:
        case DELETE_SPHERE_FAILED:
        case UPDATE_SPHERE_FAILED:
            return {
                ...state,
                errorMessage: action.errorMessage,
                loading: false,
            }
        case GET_SPHERES_REQUEST:
        case ADD_SPHERE_REQUEST:
        case DELETE_SPHERE_REQUEST:
        case UPDATE_SPHERE_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
                loading: true,
            }
        case DELETE_SPHERE_SUCCEEDED:
            return {
                ...state,
                data: state.data.filter(
                    ({ id }) => parseInt(action.data) !== id
                ),
                errorMessage: false,
                loading: false,
            }
        case UPDATE_SPHERE_SUCCEEDED: {
            const idx = state.data.findIndex(
                (el) => el.id === parseInt(action.data.id)
            )
            const data = state.data
            data[idx] = action.data
            return {
                ...state,
                data,
                errorMessage: false,
                loading: false,
            }
        }
        default:
            return state
    }
}

const initialState = {
    img_data: undefined,
    id: '',
    labels: [],
    loading: false,
}

export const getDamageCropReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DAMAGE_CROP_SUCCEEDED:
            return {
                ...state,
                id: action.image_data.data.id,
                img_data: action.image_data.data.damage_crop,
                labels: action.image_data.data.labels,
                loading: false,
            }
        case GET_DAMAGE_CROP_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_DAMAGE_CROP_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
