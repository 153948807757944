// @ts-nocheck
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { DataGrid } from '@material-ui/data-grid'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import ReportActions from './ReportActions'

const ReportTable = ({
    columns,
    sortColumn,
    onSort,
    onClick,
    onAddReport,
    onReportCreation,
    paginationProps,
    editable,
}: RouteComponentProps): JSX.Element => {
    const role = useSelector((state) => state.role.role)
    const report_data = useSelector((state) => state.report_data)
    const user_reports_data = useSelector((state) => state.user_reports_data)
    const loading = report_data.loading || user_reports_data.loading
    const data = editable ? report_data : user_reports_data
    const displayActions = editable && role !== 'unauthorized'

    const actionColumn = {
        field: 'actions',
        headerName: ' ',
        renderCell: ({ row }) => {
            return <ReportActions report={row} />
        },
        disableClickEventBubbling: true,
        disableColumnMenu: true,
        sortable: false,
    }

    if (displayActions && !columns.find((col) => col.field === 'actions'))
        columns.push(actionColumn)

    return (
        <Box>
            {displayActions && (
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        onClick={onAddReport}
                    >
                        upload report
                    </Button>
                    <Box ml={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={onReportCreation}
                        >
                            create report
                        </Button>
                    </Box>
                </Box>
            )}
            <DataGrid
                paginationMode="server"
                sortingMode="server"
                sortModel={sortColumn}
                onSortModelChange={onSort}
                rows={loading ? [] : data.reports}
                columns={columns}
                pageSize={paginationProps.dataPerPage}
                rowCount={data.totalCount || 0}
                loading={loading}
                pagination
                rowsPerPageOptions={[5, 10, 20]}
                autoHeight
                page={paginationProps.currentPage - 1}
                onPageChange={({ page }) => {
                    paginationProps.onChangePage(page + 1)
                }}
                onPageSizeChange={({ pageSize }) => {
                    paginationProps.onChangeDataPerPage(pageSize)
                }}
                onRowClick={(params) => onClick(params.row)}
                disableColumnMenu
            />
        </Box>
    )
}

export default withRouter(ReportTable)
