// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { removeProjectUser } from '../../redux/actions/project.js'
import { stringToColor } from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },
}))

const AssetsUserList = ({ token, project_id }): JSX.Element => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const { users } = useSelector((state) => state.project_data)
    const [open, setOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const handleClickOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    const userLists = [...users].sort((a, b) => {
        return a.username.toLowerCase().localeCompare(b.username.toLowerCase())
    })

    const handleDelete = (id): void => {
        dispatch(removeProjectUser(token, project_id, id))
    }

    return (
        <div>
            <Typography color="textSecondary" variant="subtitle1">
                <b>{users.length}</b> project{' '}
                {users.length > 1 ? 'members' : 'member'}
            </Typography>
            <List dense>
                {userLists.map((user) => {
                    const backgroundColor = stringToColor(user.username)
                    const textColor = theme.palette.getContrastText(
                        backgroundColor
                    )
                    return (
                        <ListItem key={user.username}>
                            <ListItemAvatar>
                                <Avatar
                                    style={{
                                        backgroundColor,
                                        color: textColor,
                                    }}
                                >
                                    {user.username?.charAt(0)?.toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={user.username} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    color="secondary"
                                    onClick={() => {
                                        setSelectedUser(user)
                                        handleClickOpen()
                                    }}
                                >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-user-delete-description"
                aria-labelledby="alert-user-delete-title"
            >
                <DialogTitle id="alert-user-delete-title">
                    Remove {selectedUser?.username}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-user-delete-description">
                        Remove{' '}
                        <strong className={classes.error}>
                            {selectedUser?.username}
                        </strong>{' '}
                        from the team?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete(selectedUser?.id)
                            handleClose()
                        }}
                        color="secondary"
                        autoFocus
                        variant="contained"
                        startIcon={<RemoveCircleOutlineIcon />}
                    >
                        Yes, remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AssetsUserList
