import { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { performLogout } from '../redux/actions/login.js'

const LogoutPage = (props: RouteComponentProps): null => {
    const dispatch = useDispatch()
    const { history } = props

    useEffect(() => {
        dispatch(performLogout())
        history.replace('/login')
    }, [dispatch, history])

    return null
}

export default withRouter(LogoutPage)
