// @ts-nocheck
import React from 'react'
import { format } from 'date-fns'

export const dateFormatter = (dateString: string): string =>
    format(new Date(dateString), 'HH:mm dd/MM/yyyy')

export const stringToColor = (string: string): string => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.substr(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

// eslint-disable-next-line
export function connectScripts(libPath: string, parent: HTMLElement, className = 'check') {
    const script = document.createElement('script')
    script.src = libPath
    if (className !== 'check') {
        script.className = className
    }
    script.async = false
    parent.appendChild(script)
}

// eslint-disable-next-line
export function connectCSS(css_path, parent) {
    const encodedId = btoa(css_path)

    if (!document.getElementById(encodedId)) {
        const link = document.createElement('link')
        link.href = css_path
        link.rel = 'stylesheet'
        link.type = 'text/css'
        link.id = encodedId
        parent.appendChild(link)
    }
}

// eslint-disable-next-line
export function vh(v) {
    const h = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    )
    return (v * h) / 100
}

// eslint-disable-next-line
export function vw(v) {
    const w = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
    )
    return (v * w) / 100
}

export const colorDot = (color = '#FFFFFF'): JSX.Element => (
    <div
        style={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            backgroundColor: color,
        }}
    />
)

export const colorTriangle = (color = '#FFFFFF'): JSX.Element => (
    <div
        style={{
            display: 'inline-block',
            width: '0',
            height: '0',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: `8px solid ${color}`,
        }}
    />
)
