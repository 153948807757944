import { all } from 'redux-saga/effects'
import {
    getReportsSaga,
    getReportSaga,
    createReportSaga,
    uploadReportSaga,
    deleteReportSaga,
    downloadReportSaga,
    getUserReportsSaga,
} from './report.js'
import {
    getSpheresSaga,
    createSphereSaga,
    deleteSphereSaga,
    updateSphereSaga,
    getDamageCropSaga,
} from './sphere.js'
import { getImageSaga } from './data.js'
import {
    getAssetDetailsSaga,
    getDownloadFileSaga,
    getFolderDataSaga,
    createNewFolderSaga,
    deleteFolderSaga,
    updateFolderSaga,
    uploadFileSaga,
    deleteFileSaga,
    uploadDataSaga,
    deleteDataSaga,
    getCoordinateDataSaga,
    getUserAssetsSaga,
    getFolderPathsSaga,
    moveFolderSaga,
    moveDataSaga,
} from './asset.js'
import {
    getProjectDetailsSaga,
    projectAssetsSagas,
    projectUsersSagas,
    getSectionsSaga,
} from './project.js'
import { companySagas } from './company.js'
import { getUsersSaga, getUserSaga, changePasswordSaga } from './users.js'
import {
    getProjectsSaga,
    createProjectSaga,
    deleteProjectSaga,
    updateProjectSaga,
    getDashboardSaga,
    getLabelsSaga,
} from './dashboard.js'
import {
    loginSaga,
    logoutSaga,
    checkTokenSaga,
    getCurrentUserGroupsSaga,
} from './login.js'
import {
    setBreadcrumbsPathSaga,
    clearBreadcrumbsPathSaga,
} from './system.js'

export default function* rootSaga() {
    yield all([
        loginSaga(),
        logoutSaga(),
        checkTokenSaga(),
        getProjectsSaga(),
        createProjectSaga(),
        deleteProjectSaga(),
        updateProjectSaga(),
        getUsersSaga(),
        getCurrentUserGroupsSaga(),
        getSectionsSaga(),
        getProjectDetailsSaga(),
        getAssetDetailsSaga(),
        getDownloadFileSaga(),
        getFolderDataSaga(),
        createNewFolderSaga(),
        deleteFolderSaga(),
        updateFolderSaga(),
        uploadFileSaga(),
        deleteFileSaga(),
        uploadDataSaga(),
        deleteDataSaga(),
        getImageSaga(),
        getUserSaga(),
        changePasswordSaga(),
        getReportsSaga(),
        getReportSaga(),
        createReportSaga(),
        uploadReportSaga(),
        deleteReportSaga(),
        downloadReportSaga(),
        getCoordinateDataSaga(),
        getUserAssetsSaga(),
        getUserReportsSaga(),
        getSpheresSaga(),
        createSphereSaga(),
        deleteSphereSaga(),
        updateSphereSaga(),
        companySagas(),
        getDashboardSaga(),
        projectAssetsSagas(),
        projectUsersSagas(),
        getFolderPathsSaga(),
        moveFolderSaga(),
        moveDataSaga(),
        getLabelsSaga(),
        getDamageCropSaga(),
        setBreadcrumbsPathSaga(),
        clearBreadcrumbsPathSaga(),
    ])
}
