import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { serverityColors } from '../../config/color_palette.js'
import { componentsList } from '../../config/selectorLists.js'
import Spinner from '../Common/Spinner'
import {
    toggleFormOpen,
    createSphere,
    updateSphere,
} from '../../redux/actions/sphere.js'
import { getToken } from '../../services/authService.js'

const SphereForm = ({
    clickedSphere,
    labels_data: { data: labels_data },
    asset_data,
    sphere_data: { loading, formOpen, errorMessage },
    createSphere,
    updateSphere,
    toggleFormOpen,
    ...props
}) => {
    const initialState = {
        labels: '',
        severity_score: '',
        image: '',
        height: '',
        width: '',
        note: '',
        checked: '',
    }
    const [newData, setNewData] = useState(initialState)

    const { severity_score, image, height, width, note, checked } = newData
    const [alertText, setAlertText] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const [labelsIndex, setLabelsIndex] = useState([0])
    const [componentsIndex, setComponentsIndex] = useState(0)

    const token = getToken()

    const labelsLists = labels_data
        .map(({ name, colour, ...rest }) => ({
            ...rest,
            type: name,
            color: colour,
        }))
        .filter(({ type }) => type !== 'All')
    labelsLists.push({ id: 0, type: 'None' , 'color': "#6B7584"})
    labelsLists.sort((a, b) => a.id - b.id)

    const sphereForm = [
        { label: 'Image', key: 'image', value: image, disabled: true },
        {
            label: 'Severity',
            key: 'severity_score',
            value: severity_score,
            type: 'number',
            min: 0,
            max: 4,
            autoFocus: true,
        },
        { label: 'Labels', key: 'labels' },
        {
            label: 'Height',
            key: 'height',
            value: height,
            type: 'number',
            step: 0.01,
            precision: 2,
            min: 0,
            required: false,
        },
        {
            label: 'Width',
            key: 'width',
            value: width,
            type: 'number',
            step: 0.01,
            precision: 2,
            min: 0,
            required: false,
        },
        { label: 'Note', key: 'note', value: note, required: false },
    ]
    const isSaved = clickedSphere.userData.sphere_backend

    const handleCheckChange = (event) => {
        setNewData({ ...newData, checked: event.target.checked })
        setIsDisabled(false)
    }
    const handleSelectLabels = (values) => {
        // Check is 'none' was selected previously
        if (labelsIndex.length == 1 && labelsIndex.includes(0)) {
            values.splice(values.indexOf(0), 1)
            setLabelsIndex(values)
        }
        // check if 'none' was recently added
        else if (values.includes(0)) {
            setLabelsIndex([0])
        } else {
            setLabelsIndex(values)
        }
        setAlertText('')
        setIsDisabled(false)
    }

    const handleSelectComponents = (value) => {
        setComponentsIndex(value)
        setAlertText('')
        setIsDisabled(false)
    }

    const handleChange = (name, value) => {
        setNewData({ ...newData, [name]: value })
        setAlertText('')
        setIsDisabled(false)
        if (name === 'severity_score' && value) {
            clickedSphere.material.color.set(`#${serverityColors[value]}`)
        }
    }
    const isNone = labelsIndex.length === 1 && labelsIndex[0] === 0

    const handleSubmit = (event) => {
        event.preventDefault()
        const asset_id = asset_data.id.toString()
        const component =
            componentsIndex === 0
                ? null
                : componentsList.find(({ id }) => id === componentsIndex).type
        if (!isSaved) {
            const newData = {
                data_id: clickedSphere.userData.image_data.id,
                asset_id,
                // three_d_model_id: asset_data.three_d_model[0].id,
                X: clickedSphere.position.x,
                Y: clickedSphere.position.y,
                Z: clickedSphere.position.z,
                colour: clickedSphere.material.color.getHexString(),
                height: height || null,
                width: width || null,
                note,
                size: 1,
                severity_score: severity_score,
                labels_id: isNone ? [] : labelsIndex,
                component,
                checked,
            }
            createSphere(token, asset_id, newData)
        } else {
            const newData = {
                height: height || null,
                width: width || null,
                severity_score: severity_score,
                note,
                colour: clickedSphere.material.color.getHexString(),
                labels_id: isNone ? [] : labelsIndex,
                component,
                checked,
            }
            const sphereId = clickedSphere.userData.sphere_backend.id
            updateSphere(token, sphereId, newData, asset_id)
        }
    }

    const handleClose = () => {
        if (!formOpen) return

        toggleFormOpen()
        setAlertText(null)
        setNewData(initialState)
        setLabelsIndex([0])
        setComponentsIndex(0)

        setIsDisabled(true)
        if (!isSaved) {
            window.viewer.scene.scene.remove(clickedSphere)
            const index = window.damage_points.indexOf(clickedSphere)
            if (index > -1) window.damage_points.splice(index, 1)
        }
    }

    useEffect(() => {
        if (!errorMessage && errorMessage !== undefined) return handleClose()

        setAlertText(errorMessage?.response?.data)
    }, [errorMessage])

    useEffect(() => {
        if (!formOpen) return
        // focusInput.current?.focus()
        const sphereData = clickedSphere.userData.sphere_backend
        const labels = sphereData
            ? [...new Set(sphereData.labels.map((item) => item.name))]
            : []
        const image = clickedSphere.userData.image_data.name
        const severity_score = sphereData ? sphereData.severity_score : ''
        const note = sphereData && sphereData.note ? sphereData.note : ''
        const component = sphereData ? sphereData.component : null
        const checked = sphereData ? sphereData.checked : false
        const formData = {
            severity_score,
            image,
            height:
                sphereData && sphereData.height
                    ? Math.round(sphereData.height * 100) / 100
                    : '',
            width:
                sphereData && sphereData.width
                    ? Math.round(sphereData.width * 100) / 100
                    : '',
            note,
            checked,
        }

        setNewData(formData)
        if (labels.length) {
            const elem = labelsLists.filter(({ type }) => labels.includes(type))
            const ids = elem.map(({ id }) => id)
            setLabelsIndex(ids)
        } else setLabelsIndex([0])

        const id = component
            ? componentsList.find(({ type }) => type === component).id
            : 0
        setComponentsIndex(id)
    }, [formOpen, clickedSphere]) // eslint-disable-line

    return (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <FormControlLabel
                    label="Validated"
                    control={
                        <Checkbox
                            color="primary"
                            size="small"
                            checked={checked}
                            onChange={handleCheckChange}
                            name="checked"
                        />
                    }
                />
                {sphereForm.map(
                    ({
                        label,
                        key,
                        required,
                        value,
                        type = 'text',
                        disabled = false,
                        autoFocus = false,
                        ...rest
                    }) => {
                        return key === 'labels' ? (
                            <Box mb={1} key="labels">
                                <FormControl fullWidth>
                                    <InputLabel id="sphere-form-labels">
                                        {label}
                                    </InputLabel>
                                    <Select
                                        labelId="sphere-form-labels"
                                        id="sphere-form-labels-name"
                                        multiple
                                        value={labelsIndex}
                                        onChange={(event) => {
                                            handleSelectLabels(
                                                event.target.value
                                            )
                                        }}
                                        input={<Input />}
                                        inputProps={{ ...rest }}
                                        renderValue={(value) =>
                                            value.reduce((acc, val, index) => {
                                                const label = labelsLists.find(
                                                    (item) => item.id === val
                                                )
                                                return `${acc}${
                                                    index === value.length - 1
                                                        ? label?.type
                                                        : `${label?.type}, `
                                                }`
                                            }, '')
                                        }
                                        // prevent dropdown from jumping up/down
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {labelsLists.map(({ id, type, color }) => (
                                            <MenuItem key={type} value={id}>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        marginRight: '8px',
                                                        backgroundColor: color
                                                    }}
                                                />
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <Box mb={0} key={key}>
                                <TextField
                                    label={label}
                                    type={type}
                                    size="small"
                                    value={value}
                                    disabled={disabled}
                                    required={required}
                                    error={!!(alertText && alertText[key])}
                                    helperText={alertText && alertText[key]}
                                    multiline={key === 'note'}
                                    rows={2}
                                    rowsMax={4}
                                    autoFocus={autoFocus}
                                    onChange={(event) => {
                                        handleChange(key, event.target.value)
                                    }}
                                    inputProps={{ ...rest }}
                                />
                            </Box>
                        )
                    }
                )}
                <Box mb={1}>
                    <FormControl key="structural-components" fullWidth>
                        <InputLabel id="sphere-form-structural-components">
                            Structural Components
                        </InputLabel>
                        <Select
                            labelId="sphere-form-structural-components"
                            id="sphere-form-structural-components-name"
                            value={componentsIndex}
                            onChange={(event) => {
                                handleSelectComponents(event.target.value)
                            }}
                            input={<Input />}
                        >
                            {componentsList.map(({ id, type }) => (
                                <MenuItem key={type} value={id}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {!loading ? (
                    <Box mb={1}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={isSaved ? <CheckIcon /> : <AddIcon />}
                            disabled={isDisabled}
                        >
                            {isSaved ? 'Update' : 'Save'}
                        </Button>
                    </Box>
                ) : (
                    formOpen && <Spinner />
                )}
            </Box>
        </form>
    )
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ createSphere, updateSphere, toggleFormOpen }, dispatch)

const mapStateToProps = ({
    labels_data,
    asset_data: { data },
    sphere_data,
}) => ({
    labels_data,
    asset_data: data,
    sphere_data,
})

export default connect(mapStateToProps, mapDispatchToProps)(SphereForm)
