// @ts-nocheck
import React from 'react'
import AssetsPage from './AssetsPage'
import AssetInfoPage from './AssetInfoPage'
import { PrivateRoute } from '../components/Common/Routes.js'

const AssetPage = ({ match: { path } }): JSX.Element => (
    <>
        <PrivateRoute path={`${path}`} component={AssetsPage} exact />
        <PrivateRoute path={`${path}/:id`} component={AssetInfoPage} />
    </>
)

export default AssetPage
