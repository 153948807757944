// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}))

const DamagesLegend = ({ status, label_legend = [] }): JSX.Element => {
    const classes = useStyles()

    return status ? (
        <List dense>
            {label_legend
                .filter(({ label__name: name }) => name !== 'All')
                .map(({ label__colour, label__name }, index) => (
                    // eslint-disable-next-line
                    <ListItem key={index + label__colour}>
                        <ListItemAvatar>
                            <Avatar
                                variant="square"
                                className={classes.avatar}
                                style={{
                                    backgroundColor: label__colour,
                                }}
                            >
                                {' '}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={label__name} />
                    </ListItem>
                ))}
        </List>
    ) : null
}

export default DamagesLegend
