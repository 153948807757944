import { defineAbility, Ability } from '@casl/ability';

const admin = defineAbility(can => {
    can('perform', 'all');
});

const employee = defineAbility((can, cannot) => {
    cannot('perform', ['AI']);
});

const client = defineAbility((can, cannot) => {
    cannot('perform', ['upload', 'create', 'delete', 'generation', 'AI']);
});

const unauthorized = new Ability([]);

export const ABILITIES = {
    'admin': admin,
    'user': employee,
    'client': client,
    'unauthorized': unauthorized
};
