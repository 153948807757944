// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Can } from '@casl/react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControl from '@material-ui/core/FormControl'
import Slider from '@material-ui/core/Slider'

import { ABILITIES } from '../config/ability.js'
import { getToken } from '../services/authService.js'
import DamagesLegend from './Asset/Data/DamagesLegend'
import { getImage, getOverlay } from '../redux/actions/data.js'
import { deleteData, downloadFile } from '../redux/actions/asset.js'
import DeleteModal from './Common/Modal/DeleteModal'
import Spinner from './Common/Spinner'
import useKeypress from './Hook/useKeypress'

const tooltip = (title, component): JSX.Element => (
    <Tooltip title={title}>{component}</Tooltip>
)

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    appBar: {
        position: 'relative',
    },
    imageContainerGrid: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 3,
        },
    },
    imageContainer: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    imageControls: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        },
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '95vh',
    },
    controlBox: {
        width: '100%',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    // eslint-disable-next-line
    return <Slide direction="up" ref={ref} {...props} />
})

const Image = ({ imgUrl, isBlendOn, legend_colours }): JSX.Element => {
    const classes = useStyles()

    return (
        <Box position="relative">
            <Paper elevation={6}>
                <TransformWrapper doubleClick={{ disabled: true }}>
                    <TransformComponent>
                        <img
                            src={`data:image/png;base64,${imgUrl.slice(1, -1)}`}
                            alt="Bigger version of file"
                            className={classes.image}
                        />
                    </TransformComponent>
                </TransformWrapper>
            </Paper>

            <Hidden mdUp>
                <DamagesLegend
                    status={isBlendOn}
                    label_legend={legend_colours}
                />
            </Hidden>
        </Box>
    )
}

const ImageContainer = ({
    data,
    img_data: { img_data },
    // isBlendOn,
    blendLevel,
    isMaskOn,
    onToggleMask,
    // onToggleBlend,
    onNavigation,
}): JSX.Element => {
    const classes = useStyles()
    const legend_colours = useSelector((state) => state.img_data.legend_colours)
    const loading = useSelector(
        (state) => state.img_data.loading || state.folder_data.loading
    )
    // const handleMaskOff = (): void => {
    //     if (isMaskOn) onToggleMask()
    // }

    // const handleBlendOff = (): void => {
    //     if (isBlendOn) onToggleBlend()
    // }

    const handlePrevious = (): void => {
        onNavigation(-1)
        // handleMaskOff()
        // handleBlendOff()
    }

    const handleNext = (): void => {
        onNavigation(1)
        // handleMaskOff()
        // handleBlendOff()
    }

    const url = isMaskOn ? img_data.mask : img_data.img
    const doubleClick = blendLevel > 0 ? onToggleMask : null

    useKeypress(
        'ArrowLeft',
        () => {
            if (!data.isFirst) {
                handlePrevious()
            }
        },
        [data]
    )

    useKeypress(
        'ArrowRight',
        () => {
            if (!data.isLast) {
                handleNext()
            }
        },
        [data]
    )

    return (
        <Box
            onDoubleClick={doubleClick}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.imageContainer}
            py={1}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {data.file_type === 'image' ? (
                onNavigation ? (
                    <Box
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <Box clone>
                            <IconButton
                                aria-label="previous"
                                color="primary"
                                onClick={handlePrevious}
                                disabled={data.isFirst || loading}
                            >
                                <ArrowBackOutlinedIcon fontSize="large" />
                            </IconButton>
                        </Box>
                        <Box clone flexGrow={1}>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Image
                                    imgUrl={url}
                                    isBlendOn={blendLevel > 0}
                                    legend_colours={legend_colours}
                                />
                            )}
                        </Box>
                        <Box clone>
                            <IconButton
                                aria-label="next"
                                color="primary"
                                onClick={handleNext}
                                disabled={data.isLast || loading}
                            >
                                <ArrowForwardOutlinedIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    </Box>
                ) : (
                    <Image
                        imgUrl={url}
                        isBlendOn={blendLevel > 0}
                        legend_colours={legend_colours}
                    />
                )
            ) : (
                <Typography color="error">No Preview available</Typography>
            )}
        </Box>
    )
}

const ImageControls = ({
    img_data: { labels, severity_labels },
    data: { name, ai_checked },
    selectorProps,
    onBlendLevelChange,
    blendLevel,
}): JSX.Element => {
    const classes = useStyles()
    const legend_colours = useSelector((state) => state.img_data.legend_colours)
    const damageLists = labels
        .map(({ label__id: id, label__name: type }) => ({
            id,
            type: type === 'All' ? 'All Damage Types' : type,
        }))
        .sort((a, b) => {
            if (a.type === 'All Damage Types') {
                return -1
            }
            return 0
        })
    const severityLists = severity_labels.map(
        // eslint-disable-next-line
        ({ severity_label__id: id, severity_label__name: type }) => ({
            id,
            type: type === 'All' ? 'All Severity Types' : type,
        })
    )

    // eslint-disable-next-line
    const renderBlendButton = (): JSX.Element => {
        const marks = [
            {
                value: 0,
                label: '0',
            },
            {
                value: 0.25,
                label: '25%',
            },
            {
                value: 0.5,
                label: '50%',
            },
            {
                value: 0.75,
                label: '75%',
            },
            {
                value: 1,
                label: '100%',
            },
        ]

        return (
            <Box width="100%" px={1}>
                <Box display="flex" justifyContent="space-evenly">
                    <PictureInPictureIcon />
                    <Typography id="discrete-slider-always">
                        Damage Overlay
                    </Typography>
                </Box>
                <Slider
                    // defaultValue={0.5}
                    aria-labelledby="discrete-slider-always"
                    step={0.25}
                    min={0}
                    max={1}
                    marks={marks}
                    onChange={(ev, val) => {
                        onBlendLevelChange(val)
                    }}
                    value={blendLevel}
                />
            </Box>
            // <FormControlLabel
            //     control={
            //         <Switch
            //             checked={isBlendOn}
            //             onChange={onToggleBlend}
            //             name="toggleOverlay"
            //             color="primary"
            //         />
            //     }
            //     label={
            //         <Box display="flex" flexWrap="wrap">
            //             {isBlendOn ? <VisibilityIcon /> : <VisibilityOffIcon />}
            //             <Typography>&nbsp; Overlay</Typography>
            //         </Box>
            //     }
            // />
        )
    }

    const renderSelector = (selectors): JSX.Element =>
        selectors.map(({ title, selected, onSelect }) => {
            const lists = title === 'Damage Types' ? damageLists : severityLists
            const display = lists.length > 0

            return display ? (
                <FormControl key={title} fullWidth>
                    <InputLabel id={`3d-dialog-${title}`}>{title}</InputLabel>
                    <Select
                        labelId={`3d-dialog-${title}`}
                        id={`3d-dialog-${title}-name`}
                        value={selected}
                        onChange={(event) => {
                            onSelect(event.target.value)
                        }}
                        input={<Input />}
                    >
                        {/* eslint-disable-next-line */}
                        {lists.map(({ id, type }) => (
                            <MenuItem key={type} value={id}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null
        })

    return (
        <Box
            display="flex"
            clone
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={2}
            className={classes.controlBox}
        >
            <Paper elevation={6}>
                {ai_checked && renderBlendButton()}
                {ai_checked && renderSelector(selectorProps)}
                <Hidden smDown>
                    <DamagesLegend
                        status={blendLevel > 0}
                        label_legend={legend_colours}
                    />
                </Hidden>
            </Paper>
        </Box>
    )
}

const ImageDialog = ({ open, data, onClose, onNavigation }): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id, labels } = useSelector((state) => state.img_data)
    const img_data = useSelector((state) => state.img_data)
    const { role } = useSelector((state) => state.role)
    const [modalOpen, setModalOpen] = useState(false)
    // const [isBlendOn, setIsBlendOn] = useState(true)
    const [blendLevel, setBlendLevel] = useState(0.5)
    const [isMaskOn, setIsMaskOn] = useState(false)
    const [damageId, setDamageId] = useState(1)
    const [severityId, setSeverityId] = useState('None')

    const resetSelectors = (): void => {
        setDamageId(1)
        setSeverityId('None')
    }

    const toggleMask = (): void => {
        setIsMaskOn((preState) => !preState)
        // resetSelectors(isMaskOn);
    }

    // const toggleBlend = (): void => {
    //     // if (!isBlendOn && damageId === 1 && labels.length > 0) {
    //     //     setDamageId(labels[0].label__id)
    //     // }

    //     setIsBlendOn((preState) => !preState)
    //     // resetSelectors(isMaskOn);
    // }

    // eslint-disable-next-line
    const handleSelectDamage = (id): void => {
        setDamageId(id)
    }

    // eslint-disable-next-line
    const handleSelectSeverity = (id): void => {
        setSeverityId(id)
    }

    const handleCloseModal = (event): void => {
        event.stopPropagation()
        setModalOpen(false)
    }

    const handleAction = (action): void => {
        const token = getToken()
        if (action === 'download')
            dispatch(downloadFile(token, 'data', id, data.name))
        else if (action === 'delete') dispatch(deleteData(token, id))
        onClose()
    }

    const selectorProps = [
        {
            title: 'Damage Types',
            selected: damageId,
            onSelect: handleSelectDamage,
        },
        {
            title: 'Severity Types',
            selected: severityId,
            onSelect: handleSelectSeverity,
        },
    ]

    //     useEffect(() => {
    //         const token = getToken()
    //         if (isBlendOn) {
    //             dispatch(getOverlay(token, data?.id, damageId, severityId))
    //         }
    //   }, [isBlendOn, data, damageId, severityId]); // eslint-disable-line

    //     useEffect(() => {
    //         const token = getToken()
    //         if (!isBlendOn) dispatch(getImage(token, data?.id))
    //   }, [isBlendOn, data]); // eslint-disable-line

    useEffect(() => {
        const token = getToken()
        if (blendLevel === 0) {
            dispatch(getImage(token, data?.id))
        } else {
            dispatch(
                getOverlay(token, data?.id, damageId, severityId, blendLevel)
            )
        }
    }, [blendLevel, data, damageId, severityId])

    // useEffect(() => {
    //     resetSelectors()
    // }, [id]) // #reset when image id changes

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Box
                    clone
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box display="flex" alignItems="center">
                            <Typography component="h2" variant="h6">
                                {data.name}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                autoFocus
                                color="inherit"
                                onClick={() => handleAction('download')}
                            >
                                {tooltip('Download', <GetAppIcon />)}
                            </IconButton>
                            <Can
                                I="perform"
                                a="delete"
                                ability={ABILITIES[role]}
                            >
                                <IconButton
                                    onClick={() => setModalOpen(true)}
                                    color="inherit"
                                >
                                    {tooltip('Delete', <DeleteIcon />)}
                                </IconButton>
                            </Can>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
            <Grid container className={classes.root}>
                <Grid item md={10} className={classes.imageContainerGrid}>
                    <ImageContainer
                        data={data}
                        img_data={img_data}
                        // isBlendOn={isBlendOn}
                        blendLevel={blendLevel}
                        isMaskOn={isMaskOn}
                        onToggleMask={toggleMask}
                        // onToggleBlend={toggleBlend}
                        onNavigation={onNavigation}
                    />
                </Grid>
                <Grid item container md={2} className={classes.imageControls}>
                    <ImageControls
                        data={data}
                        img_data={img_data}
                        // isBlendOn={isBlendOn}
                        selectorProps={selectorProps}
                        // onToggleBlend={toggleBlend}
                        onBlendLevelChange={setBlendLevel}
                        blendLevel={blendLevel}
                    />
                </Grid>
            </Grid>
            <DeleteModal
                folderName={data.name}
                open={modalOpen}
                onDelete={() => handleAction('delete')}
                onClose={handleCloseModal}
            />
        </Dialog>
    )
}

export default ImageDialog
