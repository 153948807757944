// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

import { statusColorScheme as statusColors } from '../config/color_palette.js'
import { assetStatusLists as statusLists } from '../config/selectorLists.js'
import {
    toggleFormOpen,
    createAsset,
    updateAsset,
} from '../redux/actions/project.js'
import { getToken } from '../services/authService.js'

// eslint-disable-next-line
const AssetFormModal = ({ type, open, project_id }): JSX.Element => {
    const dispatch = useDispatch()
    const {
        name: project_name,
        formOpen,
        formData,
        errorMessage,
    } = useSelector((state) => state.project_data)
    const initialState = {
        name: '',
        location: '',
        lat: '',
        lon: '',
        asset_status: '',
    }
    const [newData, setNewData] = useState(initialState)
    const { name, location, lat, lon, asset_status } = newData

    const [alertText, setAlertText] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const token = getToken()

    const formLists = [
        {
            label: 'Project',
            name: 'project',
            value: project_name,
            disabled: true,
            fieldType: 'text',
        },
        {
            label: 'Name',
            name: 'name',
            value: name,
            autoFocus: true,
            fieldType: 'text',
        },
        {
            label: 'Location',
            name: 'location',
            value: location,
            autoFocus: false,
            fieldType: 'text',
        },
        {
            label: 'Latitude',
            name: 'lat',
            value: lat,
            required: false,
            autoFocus: false,
            fieldType: 'text',
        },
        {
            label: 'Longitude',
            name: 'lon',
            value: lon,
            required: false,
            autoFocus: false,
            fieldType: 'text',
        },
        {
            label: 'Status',
            name: 'asset_status',
            value: asset_status,
            autoFocus: false,
            fieldType: 'select',
            options: statusLists,
        },
    ]

    // Called when FormInputs are changed
    const handleChange = (key, value): void => {
        setNewData({ ...newData, [key]: value })
        setAlertText('')
        setIsDisabled(false)
    }

    // Called when AssetForm is closed(x)
    const handleClose = (): void => {
        if (!formOpen) return
        dispatch(toggleFormOpen())
        setAlertText(null)
        setNewData(initialState)
        setIsDisabled(true)
    }

    // Called when AssetForm is submitted
    const handleSubmit = (event): void => {
        event.preventDefault()
        const newAsset = {
            project_id,
            name,
            location,
            lat,
            lon,
            asset_status,
        }
        if (!formData) dispatch(createAsset(token, { ...newAsset }))
        else dispatch(updateAsset(token, formData.id, newAsset))
    }
    // Called when form is opened/closed
    useEffect(() => {
        if (!formOpen) return
        if (formData) {
            setNewData(formData)
        }
  }, [formOpen]); // eslint-disable-line

    // Workaround to keep form closed on init
    useEffect(() => { // eslint-disable-line
        if (!errorMessage) return handleClose()
        setAlertText(errorMessage)
  }, [errorMessage]); // eslint-disable-line

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="assetform-dialog-title"
            fullWidth
        >
            <DialogTitle id="assetform-dialog-title">
                {formData ? 'Update' : 'New'} {type}
            </DialogTitle>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <DialogContent>
                    {formLists.map(
                        ({
                            label,
                            name, // eslint-disable-line
                            value,
                            autoFocus,
                            disabled = false,
                            fieldType,
                            options,
                        }) => {
                            if (fieldType === 'select') {
                                return (
                                    <FormControl
                                        key={name}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        error={!!(alertText && alertText[name])}
                                    >
                                        <InputLabel
                                            id={`assetform-field-${name}`}
                                        >
                                            {label}
                                        </InputLabel>
                                        <Select
                                            labelId={`assetform-field-${name}`}
                                            id={`assetform-field-${name}`}
                                            value={value}
                                            onChange={(event) => {
                                                handleChange(
                                                    name,
                                                    event.target.value
                                                )
                                            }}
                                            label={label}
                                            aria-describedby={`assetform-helper-${name}`}
                                            inputProps={{
                                                name,
                                            }}
                                            style={{
                                                color:
                                                    statusColors[value]?.color,
                                            }}
                                        >
                                            {options.map((item) => {
                                                return (
                                                    <MenuItem
                                                        value={item.type}
                                                        key={item.type}
                                                        style={{
                                                            color:
                                                                statusColors[
                                                                    item.type
                                                                ].color,
                                                        }}
                                                    >
                                                        {item.type}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText
                                            id={`assetform-helper-${name}`}
                                        >
                                            {alertText && alertText[name]}
                                        </FormHelperText>
                                    </FormControl>
                                )
                            }
                            return (
                                <TextField
                                    key={name}
                                    id={`assetform-field-${name}`}
                                    label={label}
                                    name={name}
                                    disabled={disabled}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={value}
                                    autoFocus={autoFocus}
                                    onChange={(event) => {
                                        handleChange(name, event.target.value)
                                    }}
                                    error={!!(alertText && alertText[name])}
                                    helperText={alertText && alertText[name]}
                                />
                            )
                        }
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isDisabled}
                        startIcon={formData ? <CheckIcon /> : <AddIcon />}
                    >
                        {formData ? 'Save' : 'Create'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AssetFormModal
