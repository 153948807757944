export const GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST'
export const GET_IMAGE_SUCCEEDED = 'GET_IMAGE_SUCCEEDED'
export const GET_IMAGE_FAILED = 'GET_IMAGE_FAILED'

export const getImage = (token, id) => {
    return {
        type: GET_IMAGE_REQUEST,
        token,
        id,
        mask: false,
        filters: [],
    }
}

export const getOverlay = (token, id, damage_type, severity_type, blendLevel=1) => {
    return {
        type: GET_IMAGE_REQUEST,
        token,
        id,
        mask: true,
        damage_type,
        severity_type,
        blendLevel
    }
}
