// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SvgIcon from '@material-ui/core/SvgIcon'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import usePrevious from '../../Hook/usePrevious'
import {
    getReports,
    downloadReport,
    uploadReport,
    deleteReport,
    createReport,
} from '../../../redux/actions/report.js'

import { getToken } from '../../../services/authService.js'
import { ReactComponent as ImgIcon } from '../../../assets/table/img.svg'
import { ReactComponent as FileIcon } from '../../../assets/table/file.svg'
import { ReactComponent as FolderIcon } from '../../../assets/table/folder_solid.svg'
import ReportTable from './ReportTable'
import { dateFormatter } from '../../../utils/utils'
import AddReportDialog from '../../AddReportDialog'
import ReportActionPopup from './ReportActionPopup'

const Reports = ({ asset_id }): JSX.Element => {
    const token = getToken()
    const dispatch = useDispatch()
    const { reports, errorMessage, loading } = useSelector(
        (state) => state.report_data
    )
    const report_download = useSelector((state) => state.report_download)

    // const report_status = useSelector((state) => state.report_status)
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState([
        { field: 'updated_at', sort: 'desc' },
    ])

    const columns = [
        {
            field: 'dataType',
            headerName: ' ',
            renderCell: ({ row }) => {
                const { type, file_type } = row
                if (type === 'folder')
                    return <SvgIcon component={FolderIcon} color="primary" />
                if (file_type === 'image')
                    return <SvgIcon component={ImgIcon} color="primary" />
                return <SvgIcon component={FileIcon} color="primary" />
            },
            filterable: false,
        },
        { headerName: 'Name', field: 'name', flex: 1 },
        {
            headerName: 'Creator',
            field: 'username',
            valueGetter: (params) => params.row?.user?.username,
            flex: 1,
        },
        {
            headerName: 'Created',
            field: 'created_at',
            valueGetter: (params) => dateFormatter(params.row?.created_at),
            flex: 1,
        },
        {
            headerName: 'Updated',
            field: 'updated_at',
            valueGetter: (params) => dateFormatter(params.row?.updated_at),
            flex: 1,
        },
    ]

    const prevFile = usePrevious(report_download.file)
    const reportData = Object.values(reports)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const [reportPopupData, setReportPopupData] = useState({})
    const [isUploadReportOpen, setIsUploadReportOpen] = useState(false)
    const [createdReport, setCreatedReport] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleReportCreation = (): void => {
        dispatch(createReport(token, asset_id.toString()))
        setCreatedReport(true)
    }

    const toggleReportPopup = (): void => {
        setIsReportOpen((preState) => !preState)
    }

    // eslint-disable-next-line
    const handleSort = (params): void => {
        setSortColumn(params.sortModel)
    }

    const handleChangePage = (newPage): void => {
        setCurrentPage(parseInt(newPage, 10))
    }

    const handleChangeDataPerPage = (size): void => {
        const pageSize = parseInt(size, 10)
        setDataPerPage(pageSize)
        setCurrentPage(1)
    }

    const paginationProps = {
        currentPage,
        onChangePage: handleChangePage,
        dataPerPage,
        onChangeDataPerPage: handleChangeDataPerPage,
    }

    const handleReportActions = (action): void => {
        const { id, name } = reportPopupData
        if (action === 'download') {
            dispatch(downloadReport(token, id, name))
        } else if (action === 'delete') {
            dispatch(deleteReport(token, id))
        }
        toggleReportPopup()
    }

    const handleClick = (item): void => {
        toggleReportPopup()
        setReportPopupData(item)
    }

    const handleReportNavigation = (currentId, skip): void => {
        let currentIndex = -1
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(reports)) {
            if (value.id.toString() === currentId) {
                currentIndex = parseInt(key, 10)
            }
        }
        const newIndex = currentIndex + skip
        setReportPopupData(reportData[newIndex])
    }

    const toggleUploadReportOpen = (): void => {
        setIsUploadReportOpen((preState) => !preState)
    }

    const handleUploadReport = (files): void => {
        dispatch(uploadReport(token, asset_id, files))
        toggleUploadReportOpen()
    }

    useEffect(() => {
        if (createdReport && loading) {
            enqueueSnackbar(
                'We are creating your report! This might take a while. Please wait.'
            )
        }
        if (errorMessage && createdReport) {
            setCreatedReport(false)
            enqueueSnackbar('Error: Report generated failed!', {
                variant: 'error',
            })
        } else if (createdReport && !loading) {
            enqueueSnackbar('Report created!', { variant: 'success' })
            setCreatedReport(false)
            setReportPopupData(reports[0])
            toggleReportPopup()
        }
    }, [loading, createdReport, errorMessage]) // eslint-disable-line

    useEffect(() => {
        if (report_download.file.length === 0) return
        if (prevFile !== report_download.file) {
            fileDownload(report_download.file, report_download.name)
        }
    }, [prevFile, report_download])

    useEffect(() => {
        const params = {
            sortColumn,
            dataPerPage,
            currentPage,
        }
        dispatch(getReports(token, asset_id, params))
    }, [sortColumn, dataPerPage, currentPage]) // eslint-disable-line

    return (
        <>
            <ReportTable
                sortColumn={sortColumn}
                onSort={handleSort}
                onClick={handleClick}
                onAddReport={toggleUploadReportOpen}
                onReportCreation={handleReportCreation}
                columns={columns}
                paginationProps={paginationProps}
                editable
            />

            {isReportOpen && (
                <ReportActionPopup
                    id={reportPopupData.id.toString()}
                    name={reportPopupData.name}
                    open={isReportOpen}
                    handleClose={toggleReportPopup}
                    onReportActions={handleReportActions}
                    onReportNavigation={handleReportNavigation}
                />
            )}

            <AddReportDialog
                open={isUploadReportOpen}
                onUploadData={handleUploadReport}
                onClose={toggleUploadReportOpen}
            />
        </>
    )
}

export default Reports
