// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
    media: {
        width: 'auto',
        marginTop: '8px',
    },
    card: {
        display: 'flex',
        height: '100%',
    },
    cardAction: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    cardContent: {
        wordBreak: 'break-all',
    },
    link: {
        textDecoration: 'none',
    },
})

// eslint-disable-next-line
const StatusIndicator = ({ amount, danger, children, text, image, to }) => {
    const classes = useStyles()

    return (
        <RouterLink to={to} className={classes.link}>
            <Card className={classes.card} variant="outlined">
                <CardActionArea className={classes.cardAction}>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={image}
                        title={text}
                        alt={text}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography
                            color={danger ? 'error' : 'textPrimary'}
                            gutterBottom
                            variant="h6"
                            component="h2"
                        >
                            <Box fontWeight="fontWeightBold">{amount}</Box>
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Typography
                                color={danger ? 'error' : 'textPrimary'}
                                variant="h6"
                                component="h2"
                            >
                                {text}
                            </Typography>
                            <ArrowForwardIcon
                                color={danger ? 'secondary' : 'primary'}
                            />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </RouterLink>
    )
}

export default StatusIndicator
