// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import MenuIcon from '@material-ui/icons/Menu'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import Box from '@material-ui/core/Box'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
// import ListItemIcon from '@material-ui/core/ListItemIcon'
// import ListItemText from '@material-ui/core/ListItemText'
// import SvgIcon from '@material-ui/core/SvgIcon'
// import Avatar from '@material-ui/core/Avatar'
import { useDispatch } from 'react-redux'

// import { performLogout } from '../redux/actions/login.js'
import { getToken } from '../services/authService.js'
import { getUserDetails } from '../redux/actions/users.js'
// import { ReactComponent as SettingIcon } from '../assets/sideBar/settings.svg'
// import { ReactComponent as LogoutIcon } from '../assets/actions/logout.svg'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
}))

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Header = (props) => {
    const classes = useStyles()
    const { onDrawerToggle } = props
    const dispatch = useDispatch()

    useEffect(() => {
        const token = getToken()
        if (token) {
            dispatch(getUserDetails(token))
        }
    }, [])

    return (
        <Hidden mdUp>
            <AppBar position="static" elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box className={classes.title} />
                </Toolbar>
            </AppBar>
        </Hidden>
    )
}

export default withRouter(Header)
