import {
    TOGGLE_PROJECT_FORM_OPEN,
    SET_PROJECT_FORM_DATA,
    GET_PROJECTS_REQUEST,
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_SUCCEEDED,
    ADD_PROJECT_FAILED,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCEEDED,
    UPDATE_PROJECT_FAILED,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCEEDED,
    DELETE_PROJECT_FAILED,
    GET_LABELS_REQUEST
} from '../types/dashboard.js'

export const toggleFormOpen = () => ({
    type: TOGGLE_PROJECT_FORM_OPEN,
})

export const setFormData = (data) => ({
    type: SET_PROJECT_FORM_DATA,
    data,
})

export const getProjects = (token, params) => ({
    type: GET_PROJECTS_REQUEST,
    token,
    params,
})

export const createProject = (token, data) => ({
    type: ADD_PROJECT_REQUEST,
    token,
    data,
})

export const createProjectSuccess = (data) => ({
    type: ADD_PROJECT_SUCCEEDED,
    data,
})

export const createProjectFailed = (errorMessage) => ({
    type: ADD_PROJECT_FAILED,
    errorMessage,
})

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCEEDED = 'GET_DASHBOARD_SUCCEEDED'
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED'

export const getDashboard = (token) => ({
    type: GET_DASHBOARD_REQUEST,
    token,
})

export const updateProject = (token, id, data) => ({
    type: UPDATE_PROJECT_REQUEST,
    token,
    id,
    data,
})

export const updateProjectSuccess = (data) => ({
    type: UPDATE_PROJECT_SUCCEEDED,
    data,
})

export const updateProjectFailed = (errorMessage) => ({
    type: UPDATE_PROJECT_FAILED,
    errorMessage,
})

export const deleteProject = (token, id) => ({
    type: DELETE_PROJECT_REQUEST,
    token,
    id,
})

export const deleteProjectSuccess = (id) => ({
    type: DELETE_PROJECT_SUCCEEDED,
    id,
})

export const deleteProjectFailed = (errorMessage) => ({
    type: DELETE_PROJECT_FAILED,
    errorMessage,
})

// ==========================
//         LABELS
// ==========================

export const getLabels = (token) => ({
    type: GET_LABELS_REQUEST,
    token,
  });
  