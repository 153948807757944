import { token_name } from '../../config/constants.js'
import {
    GET_LOGIN_REQUEST,
    GET_LOGIN_SUCCEEDED,
    GET_LOGIN_FAILED,
    GET_LOGOUT_REQUEST,
    GET_LOGOUT_SUCCEEDED,
    GET_LOGOUT_FAILED,
    GET_CHECK_TOKEN_REQUEST,
    GET_CHECK_TOKEN_SUCCEEDED,
    GET_CHECK_TOKEN_FAILED,
    GET_CURRENT_USER_GROUPS_REQUEST,
    GET_CURRENT_USER_GROUPS_SUCCEEDED,
    GET_CURRENT_USER_GROUPS_FAILED,
} from '../actions/login.js'

const loginIntialState = {
    isAuthenticated: false,
    token: '',
    loading: true,
    warning: false,
}

const abilityInitialState = {
    role: 'unauthorized',
}

export const loginReducer = (state = loginIntialState, action) => {
    switch (action.type) {
        case GET_LOGIN_SUCCEEDED:
            const { data } = action.login_data
            localStorage.setItem(token_name, data.key) // response.token);
            // localStorage.setItem('crsf_token', data.crsf_token);

            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: data.key,
                warning: '',
            }
        case GET_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                token: '',
                warning: 'Incorrect login details',
            }
        case GET_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                warning: '',
            }
        case GET_LOGOUT_SUCCEEDED:
            localStorage.removeItem(token_name) // response.token);

            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                token: '',
                warning: '',
            }
        case GET_LOGOUT_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_CHECK_TOKEN_SUCCEEDED:
            const { token } = action.payload
            // Check results
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                token: token,
            }
        case GET_CHECK_TOKEN_FAILED:
            const warning = localStorage.getItem(token_name)
                ? 'Login expired'
                : ''
            localStorage.removeItem(token_name)
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                token: '',
                warning,
            }
        case GET_CHECK_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const getHigherRole = (groups) => {
    if (groups.includes('admin')) return 'admin'
    if (groups.includes('employee')) return 'employee'
    if (groups.includes('user')) return 'user'
    if (groups.includes('client')) return 'client'
    return 'unauthorized'
}

export const abilityReducer = (state = abilityInitialState, action) => {
    switch (action.type) {
        case GET_CURRENT_USER_GROUPS_SUCCEEDED:
            return {
                ...state,
                role: getHigherRole(action.response_data.groups),
            }

        case GET_CURRENT_USER_GROUPS_REQUEST:
        case GET_CURRENT_USER_GROUPS_FAILED:
            return abilityInitialState

        default:
            return state
    }
}
