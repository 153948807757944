// @ts-nocheck
import React from 'react'

import Validations from '../components/Validations'
import ValidationDetail from '../components/ValidationDetail'
import { PrivateRoute } from '../components/Common/Routes.js'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ValidationPage = ({ match: { path } }): JSX.Element => (
    <>
        <PrivateRoute path={`${path}`} component={Validations} exact />
        <PrivateRoute path={`${path}/:id`} component={ValidationDetail} />
    </>
)

export default ValidationPage
