// @ts-nocheck
import React from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'

const AddReportDialog = ({ open, onUploadData, onClose }): JSX.Element => {
    return (
        <DropzoneDialog
            open={open}
            filesLimit={1}
            onSave={onUploadData}
            acceptedFiles={['application/pdf']}
            maxFileSize={100000000}
            onClose={onClose}
            submitButtonText="Upload"
            dialogTitle="Upload Report"
        />
    )
}

export default AddReportDialog
