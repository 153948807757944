const lightTheme = {
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#091932',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with
            // palette.primary.main
        },
        secondary: {
            main: '#d94948',
        },
        info: {
            main: '#1957d4',
        },
    },
}

const darkTheme = {
    palette: {
        type: 'dark',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#688eff',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with
            // palette.primary.main
        },
        secondary: {
            main: '#f44336',
        },
    },
}

export { lightTheme, darkTheme }
