// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
    spinnerOverlay: {
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 4,
        backgroundColor: (props) =>
            `rgba(255, 255, 255, ${
                props.opacity === undefined ? 1 : props.opacity
            })`,
    },
})

const Spinner = (props): JSX.Element => {
    const classes = useStyles(props)
    return (
        <Box
            className={classes.spinnerOverlay}
            display="flex"
            bgcolor="background.paper"
            justifyContent="center"
            alignItems="center"
            p={8}
            data-testid="spinner"
        >
            <CircularProgress />
        </Box>
    )
}

export default Spinner
