// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import Box from '@material-ui/core/Box'
import SvgIcon from '@material-ui/core/SvgIcon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ReportTable from '../components/Asset/Report/ReportTable'
import ReportActionPopup from '../components/Asset/Report/ReportActionPopup'
import { ReactComponent as FileIcon } from '../assets/table/file.svg'
import { ReactComponent as ImgIcon } from '../assets/table/img.svg'
import { ReactComponent as FolderIcon } from '../assets/table/folder_solid.svg'
import {
    getUserReports,
    deleteReport,
    downloadReport,
} from '../redux/actions/report.js'
import { getToken } from '../services/authService.js'
import { dateFormatter } from '../utils/utils'
import { getClearBreadcrumbsPath } from '../redux/actions/system.js'
import usePrevious from '../components/Hook/usePrevious'

const ReportsPage = (): JSX.Element => {
    const dispatch = useDispatch()
    const report_download = useSelector((state) => state.report_download)
    const { reports } = useSelector((state) => state.user_reports_data)
    const columns = [
        {
            field: 'dataType',
            headerName: ' ',
            renderCell: ({ row }) => {
                const { type, file_type } = row
                if (type === 'folder')
                    return <SvgIcon component={FolderIcon} color="primary" />
                if (file_type === 'image')
                    return <SvgIcon component={ImgIcon} color="primary" />
                return <SvgIcon component={FileIcon} color="primary" />
            },
            filterable: false,
        },
        { headerName: 'Name', field: 'name', flex: 1 },
        {
            headerName: 'Asset',
            field: 'asset',
            valueGetter: (params) => params.row?.asset?.name,
            flex: 1,
        },
        {
            headerName: 'Project',
            field: 'project',
            valueGetter: (params) => params.row?.asset?.project?.name,
            flex: 1,
        },
        {
            headerName: 'Creator',
            field: 'creator',
            valueGetter: (params) => params.row?.user?.username,
            flex: 1,
        },
        {
            headerName: 'Created',
            field: 'created',
            valueGetter: (params) => dateFormatter(params.row?.created_at),
            flex: 1,
        },
        {
            headerName: 'Updated',
            field: 'updated',
            valueGetter: (params) => dateFormatter(params.row?.updated_at),
            flex: 1,
        },
    ]
    const token = getToken()

    const [sortColumn, setSortColumn] = useState([
        { field: 'updated_at', sort: 'desc' },
    ])
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(15)

    const prevFile = usePrevious(report_download.file)
    const reportData = Object.values(reports)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const [reportPopupData, setReportPopupData] = useState([])

    const toggleReportPopup = useCallback(() => {
        setIsReportOpen(!isReportOpen)
    }, [isReportOpen])

    const handleSort = (params): void => {
        setSortColumn(params.sortModel)
    }

    const handleChangePage = (newPage): void => {
        setCurrentPage(parseInt(newPage, 10))
    }

    const handleChangeDataPerPage = (size): void => {
        const pageSize = parseInt(size, 10)
        setDataPerPage(pageSize)
        setCurrentPage(1)
    }

    const paginationProps = {
        currentPage,
        onChangePage: handleChangePage,
        dataPerPage,
        onChangeDataPerPage: handleChangeDataPerPage,
    }

    const handleReportActions = (action): void => {
        const { id, name } = reportPopupData
        if (action === 'download') {
            dispatch(downloadReport(token, id, name))
        } else if (action === 'delete') {
            dispatch(deleteReport(token, id))
        }
        toggleReportPopup()
    }

    const handleClick = (item): void => {
        toggleReportPopup()
        setReportPopupData(item)
    }

    const handleReportNavigation = (currentId, skip): void => {
        let currentIndex = -1
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(reports)) {
            if (value.id.toString() === currentId) {
                currentIndex = parseInt(key, 10)
            }
        }
        const newIndex = currentIndex + skip
        setReportPopupData(reportData[newIndex])
    }

    useEffect(() => {
        if (report_download.file.length === 0) return
        if (prevFile !== report_download.file) {
            fileDownload(report_download.file, report_download.name)
        }
    }, [prevFile, report_download])

    useEffect(() => {
        const params = {
            sortColumn: sortColumn?.map((col) => ({
                column: col.field,
                order: col.sort,
            }))[0],
            dataPerPage,
            currentPage,
        }
        dispatch(getUserReports(token, params))
        // eslint-disable-next-line
	}, [getUserReports, sortColumn, dataPerPage, currentPage]);

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getClearBreadcrumbsPath())
    }, [dispatch])

    return (
        <Box clone p={2}>
            <Paper>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5" component="h2" gutterBottom>
                        Reports
                    </Typography>
                </Box>
                <ReportTable
                    sortColumn={sortColumn}
                    onSort={handleSort}
                    onClick={handleClick}
                    columns={columns}
                    paginationProps={paginationProps}
                />
                {isReportOpen && (
                    <ReportActionPopup
                        id={reportPopupData.id.toString()}
                        name={reportPopupData.name}
                        open={isReportOpen}
                        handleClose={toggleReportPopup}
                        onReportActions={handleReportActions}
                        onReportNavigation={handleReportNavigation}
                    />
                )}
            </Paper>
        </Box>
    )
}

export default ReportsPage
