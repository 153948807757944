import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

interface IExportOptions {
    handleExport: () => any
}

const ExportOptions = ({ handleExport }: IExportOptions): JSX.Element => {
    return (
        <FormControl
            variant="outlined"
            margin="dense"
            size="small"
            color="primary"
        >
            <InputLabel id="export-select-label">
                <Box display="flex" justifyContent="space-between">
                    <Box clone mr={1}>
                        <CloudDownloadIcon color="primary" />
                    </Box>
                    <Typography color="primary">Export damages</Typography>
                </Box>
            </InputLabel>
            <Select
                labelId="export-select-label"
                id="export-select-outlined"
                onChange={handleExport}
                label="Export damages"
                value=""
            >
                <MenuItem value="json">JSON</MenuItem>
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="xls">XLS</MenuItem>
                <MenuItem value="xml">XML</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
            </Select>
        </FormControl>
    )
}

export default ExportOptions
