import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import Slider from '@material-ui/core/Slider'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { format } from 'date-fns'
import exportFromJSON from 'export-from-json'

import { getCoordinateData } from '../../redux/actions/asset.js'
import { createSphere, getSpheres } from '../../redux/actions/sphere.js'
import { getToken } from '../../services/authService.js'
import DamageSeverityFilter from './DamageSeverityFilter.jsx'
import {
    removeAllDamages,
    showSavedDamages,
    removeSavedDamages,
    makeExtraVisible,
    makeNotExtraVisible,
} from './damagesHelper.js'
import { checkAnnotation, uncheckAnnotation, drawLine } from './helper.js'
import { checkedList } from '../../config/selectorLists.js'
import { severityScore } from '../../config/constants.js'

import ExportOptions from '../Common/ExportOptions'
import { downloadPDFReportAPI, sendReportRequestAPI, checkReportFinishedAPI, downloadCustomedReport } from '../../config/api.js'

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    expandIcon: {
        color: theme.palette.primary.contrastText,
    },
})

const filterSpheres = (
    spheres = [],
    filters = {
        severity: null,
        label: null,
        component: null,
        size: null,
        checked: null,
    }
) => {
    let result = spheres

    if (filters.label) {
        result = result.filter((sphere) =>
            sphere.labels.some((label) => label.id === filters.label)
        )
    }

    if (filters.component) {
        result = result.filter(
            (sphere) => sphere.component === filters.component
        )
    }

    if (Array.isArray(filters.size) && filters.size.length === 2) {
        result = result.filter(
            (sphere) =>
                sphere.width >= filters.size[0] &&
                sphere.width <= filters.size[1]
        )
    }

    if (filters.checked !== null) {
        result = result.filter((sphere) => sphere.checked === filters.checked)
    }

    if (filters.severity !== null) {
        result = result.filter((sphere) =>
            filters.severity.includes(sphere.severity_score)
        )
    }

    return result
}

class SideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_damage: null,
            selected_component: null,
            selected_checked: null,
            selected_severity: null,
            spheres_size: 3,
            damage_size: [null, null],
            pointBudget: 1000000,
            loadPointBudget: false,
            fieldView: 60,
            isDamageVisible: false,
            toggleShowAIDamages: false,
            toggleShowUserDamages: false,
            availableUserDamages: true,
            localSphereData: [],
            sphere_data_ai: [],
            sphere_data_user: [],
            areImagesConnected: false,
            accordionExpanded: 'panel1',
        }

        this.handleChangeDamageSize = this.handleChangeDamageSize.bind(this)
        this.handleRemoveAllDamages = this.handleRemoveAllDamages.bind(this)
        this.handleShowUserDamages = this.handleShowUserDamages.bind(this)
        this.handleShowAIDamages = this.handleShowAIDamages.bind(this)
        this.handleDamageExtraVison = this.handleDamageExtraVison.bind(this)
        this.handleDamageChange = this.handleDamageChange.bind(this)
        this.handleComponentChange = this.handleComponentChange.bind(this)
        this.handleImagesConnect = this.handleImagesConnect.bind(this)
        this.handleSizeRangeChange = this.handleSizeRangeChange.bind(this)
        this.handleCheckedChange = this.handleCheckedChange.bind(this)
    }

    componentDidMount() {
        this.setState({
            damage_size: [0, this.props.asset_data?.damage_size_limits?.max],
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            asset_data,
            sphere_data: { data: globalSphereData },
        } = this.props
        const {
            toggleShowAIDamages,
            toggleShowUserDamages,
            isDamageVisible,
            spheres_size,
            areImagesConnected,
            selected_component,
            damage_size,
            selected_damage,
            selected_checked,
            loadPointBudget,
            sphere_data_ai,
            sphere_data_user,
            selected_severity,
        } = this.state

        if (
            prevProps.sphere_data.loading &&
            prevProps.sphere_data.data != globalSphereData
        ) {
            this.setState({
                localSphereData: globalSphereData,
                sphere_data_ai: [
                    ...new Set(
                        globalSphereData.filter(
                            (item) => item.ai_generated === true
                        )
                    ),
                ],
                sphere_data_user: [
                    ...new Set(
                        globalSphereData.filter(
                            (item) => item.ai_generated === false
                        )
                    ),
                ],
            })
        }

        if (
            prevState.selected_component !== selected_component ||
            prevState.selected_damage !== selected_damage ||
            prevState.damage_size !== damage_size ||
            prevState.selected_checked !== selected_checked ||
            prevState.selected_severity !== selected_severity
        ) {
            const asset_id = asset_data.id
            const token = getToken()
            const componentsList = asset_data.severity_scores_found.map(
                (el, idx) => ({
                    id: el.component === null ? null : idx,
                    type: el.component === null ? 'None' : el.component,
                })
            )
            const component =
                selected_component === null
                    ? selected_component
                    : componentsList
                          .find(({ id }) => id === selected_component)
                          .type.toString()
            const checked =
                selected_checked === null
                    ? selected_checked
                    : !!selected_checked

            // filter local sphere data
            const filteredSpheres = filterSpheres(globalSphereData, {
                label: selected_damage,
                component,
                size: damage_size,
                checked,
                severity: selected_severity,
            })
            const sphere_data_ai = [
                ...new Set(
                    filteredSpheres.filter((item) => item.ai_generated === true)
                ),
            ]
            const sphere_data_user = [
                ...new Set(
                    filteredSpheres.filter(
                        (item) => item.ai_generated === false
                    )
                ),
            ]

            this.setState({
                localSphereData: filteredSpheres,
                sphere_data_ai,
                sphere_data_user,
            })
        }

        if (toggleShowAIDamages) {
            if (sphere_data_ai.length > 0) {
                removeSavedDamages(true)

                showSavedDamages(
                    sphere_data_ai,
                    spheres_size,
                    isDamageVisible,
                    areImagesConnected
                )
            } else {
                removeSavedDamages(true)
            }
        }

        if (toggleShowUserDamages) {
            if (sphere_data_user.length > 0) {
                removeSavedDamages(false)

                showSavedDamages(
                    sphere_data_user,
                    spheres_size,
                    isDamageVisible,
                    areImagesConnected
                )
            } else {
                removeSavedDamages(false)
            }
        }

        if (window.viewer !== undefined && !loadPointBudget) {
            this.setState({
                pointBudget: window.viewer.getPointBudget(),
                fieldView: window.viewer.getFOV(),
                radius: window.viewer.getEDLRadius(),
                strength: window.viewer.getEDLStrength(),
                loadPointBudget: true,
            })
        }
    }

    handleDamageExtraVison() {
        const { isDamageVisible } = this.state
        this.setState({ isDamageVisible: !isDamageVisible })

        if (!isDamageVisible) {
            if (window.damage_points && window.damage_points.length > 0) {
                for (let i = 0; i < window.damage_points.length; i++) {
                    const dmg_pt = window.damage_points[i]
                    if (!dmg_pt.userData.sphere_data.fav) {
                        makeExtraVisible(dmg_pt)
                    }
                }
            }
        } else if (window.damage_points !== undefined) {
            if (window.damage_points.length > 0) {
                for (let i = 0; i < window.damage_points.length; i++) {
                    const dmg_pt = window.damage_points[i]
                    if (dmg_pt.userData.sphere_data.fav) {
                        makeNotExtraVisible(dmg_pt)
                    }
                }
            }
        }
    }

    handleSetStrength(value) {
        this.setState({
            strength: value,
        })
        window.viewer.setEDLStrength(value)
    }

    handleSetRadius(value) {
        this.setState({
            radius: value,
        })
        window.viewer.setEDLRadius(value)
    }

    handleSetFieldView(value) {
        this.setState({
            fieldView: value,
        })
        window.viewer.fov = value
    }

    handleSetPointBudget(value) {
        this.setState({
            pointBudget: value,
        })
        window.Potree.pointBudget = value
    }

    handleChangeDamageSize(scale) {
        this.setState({ spheres_size: scale })
        if (window.damage_points !== undefined) {
            if (window.damage_points.length > 0) {
                for (let i = 0; i < window.damage_points.length; i++) {
                    window.damage_points[i].scale.x = scale
                    window.damage_points[i].scale.y = scale
                    window.damage_points[i].scale.z = scale
                }
            }
        }
    }

    handleImagesConnect() {
        const { areImagesConnected } = this.state

        if (!areImagesConnected) {
            if (window.damage_points.length > 0) {
                for (let i = 0; i < window.damage_points.length; i++) {
                    const { image_data } = window.damage_points[i].userData
                    const { sphere_backend } = window.damage_points[i].userData

                    const sphere_pos = window.damage_points[i].position
                    const point3d = new window.THREE.Vector3(
                        ...[sphere_pos.x, sphere_pos.y, sphere_pos.z]
                    )
                    const origin = new window.THREE.Vector3(
                        ...[image_data.X, image_data.Y, image_data.Z]
                    )
                    drawLine(image_data, point3d, origin, sphere_backend)
                    checkAnnotation(image_data.name)
                }
            }
        } else {
            for (let i = 0; i < window.all_damage_lines.length; i++) {
                const rem = window.all_damage_lines[i]
                const { image_data } = rem.userData
                window.viewer.scene.scene.remove(rem)
                uncheckAnnotation(image_data.name)
            }
            window.all_damage_lines = []
        }
        this.setState({ areImagesConnected: !areImagesConnected })
    }

    handleRemoveAllDamages() {
        removeAllDamages()
        this.setState({
            toggleShowAIDamages: false,
            toggleShowUserDamages: false,
            isDamageVisible: false,
            areImagesConnected: false,
        })
    }

    handleShowUserDamages() {
        const {
            isDamageVisible,
            spheres_size,
            sphere_data_user,
            toggleShowUserDamages,
            areImagesConnected,
        } = this.state
        if (!toggleShowUserDamages) {
            showSavedDamages(
                sphere_data_user,
                spheres_size,
                isDamageVisible,
                areImagesConnected
            )
        } else {
            if (areImagesConnected && window.damage_points.length)
                this.setState({ areImagesConnected: false })
            removeSavedDamages(false)
        }

        this.setState({
            toggleShowUserDamages: !this.state.toggleShowUserDamages,
        })
    }

    handleShowAIDamages() {
        const {
            isDamageVisible,
            spheres_size,
            sphere_data_ai,
            areImagesConnected,
        } = this.state

        if (!this.state.toggleShowAIDamages) {
            removeSavedDamages(true)
            showSavedDamages(
                sphere_data_ai,
                spheres_size,
                isDamageVisible,
                areImagesConnected
            )
        } else {
            if (areImagesConnected && window.damage_points.length)
                this.setState({ areImagesConnected: false })
            removeSavedDamages(true)
        }
        this.setState({
            toggleShowAIDamages: !this.state.toggleShowAIDamages,
        })
    }

    handleDamageChange(value) {
        this.setState({ selected_damage: value })
    }

    handleComponentChange(value) {
        this.setState({ selected_component: value })
    }

    handleSizeRangeChange(value) {
        this.setState({ damage_size: value })
    }

    handleAccordion = (panel) => (event, isExpanded) => {
        this.setState({ accordionExpanded: isExpanded ? panel : false })
    }

    handleCheckedChange(value) {
        this.setState({ selected_checked: value })
    }

    handleSeverityChange = (value) => {
        this.setState({ selected_severity: value })
    }

    handleSpheresExport = (event) => {
        const { asset_data } = this.props

        if (event.target.value === 'pdf') {
            const token = getToken()
            const { user } = this.props
            downloadPDFReportAPI(
                token,
                asset_data?.id,
                this.state.localSphereData.map((sphere) => sphere.id),
                user.username
            ).then(function (response) {
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `asset_${asset_data?.id}_damages_${format(
                    new Date(),
                    "dd-MM-yyyy'T'HH:mm:ss"
                )}`
                link.click()
            })
        } else {
            const fields = [
                'ID',
                'X',
                'Y',
                'note',
                'severity_label',
                'severity_description',
                'labels',
                'damage_area',
            ]
            const mappedData = this.state.localSphereData
                .map((sphere) => ({
                    ...sphere,
                    ID: sphere.id,
                    severity_label: severityScore.find(
                        (element) => element.value === sphere.severity_score
                    )?.label,
                    severity_description: severityScore.find(
                        (element) => element.value === sphere.severity_score
                    )?.description,
                    labels: sphere.labels.map((label) => label.name).join(),
                    damage_area: sphere.area,
                }))
                .map((sphere) => {
                    const filtered = Object.keys(sphere)
                        .filter((key) => fields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: sphere[key],
                            }
                        }, {})
                    return filtered
                })
            const fileName = `asset_${asset_data?.id}_damages_${format(
                new Date(),
                "dd-MM-yyyy'T'HH:mm:ss"
            )}`
            const exportType = exportFromJSON.types[event.target.value]
            exportFromJSON({ data: mappedData, fileName, exportType })
        }
    }

    handleCustomReportExport = (event) => {

        const { asset_data } = this.props

        if (event.target.value === 'pdf') {

            const token = getToken()
            const { user } = this.props

            sendReportRequestAPI(
                token,
                asset_data?.id,
                this.state.localSphereData.map((sphere) => sphere.id),
                user.username
            ).then(function pollAsyncResult(response) {
                checkReportFinishedAPI(token, response.data.task_id
                ).then(function(asyncData) {
                    if (asyncData.status !== 202) {
                        console.log('task finished, downloading the report ....')
                        clearTimeout(pollAsyncResult)
                        downloadCustomedReport( token,asyncData.data.location
                        ).then(function (downloadResult) {
                            const blob = new Blob([downloadResult.data], {
                                type: 'application/pdf',
                            })
                            const link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = `asset_${asset_data?.id}_damages_${format(
                                new Date(),
                                "dd-MM-yyyy'T'HH:mm:ss"
                            )}`
                            link.click()
                        })
                    }
                    else {
                        console.log('task not finished!')
                        setTimeout(function() {pollAsyncResult(response)}, 5000)
                    }
                })
            })

        } else {
            const fields = [
                'ID',
                'X',
                'Y',
                'note',
                'severity_label',
                'severity_description',
                'labels',
                'damage_area',
            ]
            const mappedData = this.state.localSphereData
                .map((sphere) => ({
                    ...sphere,
                    ID: sphere.id,
                    severity_label: severityScore.find(
                        (element) => element.value === sphere.severity_score
                    )?.label,
                    severity_description: severityScore.find(
                        (element) => element.value === sphere.severity_score
                    )?.description,
                    labels: sphere.labels.map((label) => label.name).join(),
                    damage_area: sphere.area,
                }))
                .map((sphere) => {
                    const filtered = Object.keys(sphere)
                        .filter((key) => fields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: sphere[key],
                            }
                        }, {})
                    return filtered
                })
            const fileName = `asset_${asset_data?.id}_damages_${format(
                new Date(),
                "dd-MM-yyyy'T'HH:mm:ss"
            )}`
            const exportType = exportFromJSON.types[event.target.value]
            exportFromJSON({ data: mappedData, fileName, exportType })
        }

    }

    render() {
        const { asset_data, classes } = this.props
        const {
            spheres_size,
            selected_damage,
            toggleShowUserDamages,
            toggleShowAIDamages,
        } = this.state

        let damages_labels = asset_data.labels

        damages_labels = damages_labels.filter(
            ({ label__name }) => label__name !== 'All'
        )
        const componentsList = asset_data.severity_scores_found.map(
            (el, idx) => ({
                id: el.component === null ? null : idx,
                type: el.component === null ? 'None' : el.component,
            })
        )

        const damagePoints =
            undefined !== window.damage_points &&
            window.damage_points.length > 0 &&
            toggleShowAIDamages

        return (
            <div id="sidebar_root" className="potree_sidebar_container">
                <Accordion
                    expanded={this.state.accordionExpanded === 'panel1'}
                    onChange={this.handleAccordion('panel1')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Damages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            color="primary"
                                            checked={
                                                this.state.toggleShowAIDamages
                                            }
                                            onChange={this.handleShowAIDamages}
                                            id="3d-sidebar-ai-damages"
                                        />
                                    }
                                    label="AI damages"
                                />
                                {this.state.availableUserDamages && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                color="primary"
                                                checked={
                                                    this.state
                                                        .toggleShowUserDamages
                                                }
                                                onChange={
                                                    this.handleShowUserDamages
                                                }
                                            />
                                        }
                                        label="User damages"
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            color="primary"
                                            checked={
                                                this.props.moveToCameraView
                                            }
                                            onChange={
                                                this.props.handleCheckCameraView
                                            }
                                        />
                                    }
                                    label="Change to camera view"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            color="primary"
                                            checked={this.props.showCameraIcon}
                                            onChange={
                                                this.props
                                                    .handleCheckShowCameraIcon
                                            }
                                        />
                                    }
                                    label="Show camera icon"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            color="primary"
                                            checked={this.state.isDamageVisible}
                                            onChange={
                                                this.handleDamageExtraVison
                                            }
                                            id="3d-sidebar-extra-vision"
                                        />
                                    }
                                    label="Damage extra vision"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            color="primary"
                                            checked={
                                                this.state.areImagesConnected
                                            }
                                            onChange={this.handleImagesConnect}
                                        />
                                    }
                                    label="Connected images"
                                />
                                <Typography id="sphere-size-slider">
                                    Size of Spheres: {spheres_size}
                                </Typography>
                                <Slider
                                    value={spheres_size}
                                    aria-labelledby="sphere-size-slider"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    min={1}
                                    max={10}
                                    onChange={(e, value) =>
                                        this.handleChangeDamageSize(value)
                                    }
                                    id="3d-sidebar-size-slider"
                                />
                            </FormGroup>
                            <div className="divider">
                                <span>Filters</span>
                            </div>
                            <FormGroup row>
                                <Typography id="sphere-size-slider">
                                    By Severity
                                </Typography>
                                <DamageSeverityFilter
                                    damagePoints={damagePoints}
                                    selectedDamage={selected_damage}
                                    showUserDamages={toggleShowUserDamages}
                                    showAIDamages={toggleShowAIDamages}
                                    onChange={this.handleSeverityChange}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="selected_damage-label">
                                        By Label
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        labelId="selected_damage-label"
                                        id="selected_damage"
                                        value={selected_damage}
                                        onChange={(event) => {
                                            event.stopPropagation()
                                            this.handleDamageChange(
                                                event.target.value
                                            )
                                        }}
                                    >
                                        {damages_labels.map(
                                            ({
                                                label__id: id,
                                                label__name: name,
                                                label__colour: color,
                                            }) => (
                                                <MenuItem key={id} value={id}>
                                                    <div
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            marginRight: '8px',
                                                            backgroundColor: color,
                                                        }}
                                                    />
                                                    {name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="selected_component-label">
                                        By Component
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        labelId="selected_component-label"
                                        id="selected_component"
                                        value={this.state.selected_component}
                                        onChange={(event) => {
                                            event.stopPropagation()
                                            this.handleComponentChange(
                                                event.target.value
                                            )
                                        }}
                                    >
                                        {componentsList.map(({ id, type }) => (
                                            <MenuItem key={type} value={id}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="checked-label">
                                        By Validation
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        labelId="checked-label"
                                        id="checked"
                                        value={this.state.selected_checked}
                                        onChange={(event) => {
                                            event.stopPropagation()
                                            this.handleCheckedChange(
                                                event.target.value
                                            )
                                        }}
                                    >
                                        {checkedList.map(({ id, type }) => (
                                            <MenuItem key={type} value={id}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Box mt={2} width={1}>
                                    <Typography id="damage-size-range-slider">
                                        By Size:{' '}
                                        {`[${this.state.damage_size[0]} - ${this.state.damage_size[1]}] m`}
                                    </Typography>
                                    <Slider
                                        value={this.state.damage_size}
                                        aria-labelledby="damage-size-range-slider"
                                        valueLabelDisplay="auto"
                                        step={0.01}
                                        min={0}
                                        max={
                                            asset_data?.damage_size_limits?.max
                                        }
                                        onChange={(e, value) =>
                                            this.handleSizeRangeChange(value)
                                        }
                                    />
                                </Box>
                            </FormGroup>
                            <ExportOptions
                                handleExport={this.handleCustomReportExport}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    id="potree-menu_scene"
                    expanded={this.state.accordionExpanded === 'panel2'}
                    onChange={this.handleAccordion('panel2')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-header"
                        id="panel2-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Scene</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <div id="scene_export" />
                            <div className="divider">
                                <span>Objects</span>
                            </div>
                            <div id="scene_objects" />
                            <div className="divider">
                                <span>Properties</span>
                            </div>
                            <div id="scene_object_properties" />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={this.state.accordionExpanded === 'panel3'}
                    onChange={this.handleAccordion('panel3')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Tools</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <div className="divider">
                                <span>Measurement</span>
                            </div>
                            <li id="potree-tools" />
                            <div className="divider">
                                <span>Navigation</span>
                            </div>
                            <li id="potree-navigation" />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={this.state.accordionExpanded === 'panel4'}
                    onChange={this.handleAccordion('panel4')}
                    square
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        classes={{
                            root: classes.root,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography>Appearance</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{ width: '100%' }}
                        >
                            <Typography id="point-budget-slider">
                                Point budget: {this.state.pointBudget}
                            </Typography>
                            <Slider
                                value={this.state.pointBudget}
                                aria-labelledby="point-budget-slider"
                                valueLabelDisplay="off"
                                min={100 * 1000}
                                max={10 * 1000 * 1000}
                                step={1000}
                                onChange={(e, value) =>
                                    this.handleSetPointBudget(value)
                                }
                            />
                            <li style={{ display: 'none' }}>
                                <span id="lblPointBudget" />{' '}
                                <div id="sldPointBudget" />
                            </li>
                            <Typography id="field-view-slider">
                                Field of View: {this.state.fieldView}
                            </Typography>
                            <Slider
                                value={this.state.fieldView}
                                aria-labelledby="field-view-slider"
                                valueLabelDisplay="auto"
                                min={20}
                                max={100}
                                onChange={(e, value) =>
                                    this.handleSetFieldView(value)
                                }
                            />
                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.field_view" />:{' '}
                                <span id="lblFOV" />
                                <div id="sldFOV" />
                            </li>
                            {/* <div className="divider">
                                <span>Eye-Dome-Lighting</span>
                            </div> */}
                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.edl_radius" />:{' '}
                                <span id="lblEDLRadius" />
                                <div id="sldEDLRadius" />
                            </li>
                            <li style={{ display: 'none' }}>
                                <span data-i18n="appearance.edl_strength" />:{' '}
                                <span id="lblEDLStrength" />
                                <div id="sldEDLStrength" />
                            </li>
                            <li style={{ display: 'none' }}>
                                <label>
                                    <input type="checkbox" id="chkEDLEnabled" />
                                    <span data-i18n="appearance.edl_enable" />
                                </label>
                            </li>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCoordinateData,
            createSphere,
            getSpheres,
        },
        dispatch
    )
}

const mapStateToProps = ({
    asset_data: { data },
    sphere_data,
    coordinate_data,
    data_change,
    user_data: { user },
}) => ({
    asset_data: data,
    sphere_data,
    coordinate_data,
    data_change,
    user,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SideBar))
