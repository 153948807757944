import React, { useEffect, useState, useCallback } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'

import { getToken } from '../../services/authService.js'
import { severityScore } from '../../config/constants.js'
import { getSpheres } from '../../redux/actions/sphere.js'
import SeverityLegend from './SeverityLegend.js'
import { removeSavedDamages } from './damagesHelper.js'

const DamageSeverityFilter = ({
    sphere_data: { data, loading },
    asset_data: { id: asset_id },
    getSpheres,
    damagePoints,
    selectedDamage,
    showUserDamages,
    showAIDamages,
    onChange,
}) => {
    const [availableSeverity, setAvailableSeverity] = useState([0, 1, 2, 3, 4])
    const [checkAvailability, setCheckAvailability] = useState(true)
    const [severityShown, setSeverityShown] = useState([0, 1, 2, 3, 4])
    const [changeSeverity, setChangeSeverity] = useState(false)
    const [isDisabled, setIsDisable] = useState(false)

    useEffect(() => {
        let sphere_data = []

        if (showAIDamages) {
            sphere_data = [
                ...new Set(data.filter((item) => item.ai_generated === true)),
            ]
        }

        if (showUserDamages) {
            sphere_data = [
                ...sphere_data,
                ...new Set(data.filter((item) => item.ai_generated === false)),
            ]
        }

        const availableSeverityScores = [
            ...new Set(sphere_data.map((item) => item.severity_score)),
        ]
        if (!loading && checkAvailability) {
            setAvailableSeverity(availableSeverityScores)
            setSeverityShown(availableSeverityScores)
            setCheckAvailability(false)
        }
        if (!loading && !checkAvailability) {
            setAvailableSeverity(availableSeverityScores)
            setSeverityShown(availableSeverityScores)
        }
    }, [loading, showUserDamages, showAIDamages])

    useEffect(() => {
        setCheckAvailability(true)
    }, [selectedDamage])

    const toggleSeverity = (id) => {
        const contains = severityShown.includes(id)
        if (contains) {
            setSeverityShown(severityShown.filter((e) => e !== id))
        }
        else {
            setSeverityShown((severityShown) => [...severityShown, id])
        }
    }

    useEffect(() => {
        onChange(severityShown)
    }, [severityShown])

    useEffect(() => {
        if (loading || !damagePoints) {
            setIsDisable(true)
        } else if (!loading && damagePoints) {
            setIsDisable(false)
        }
    }, [loading, damagePoints])

    return (
        <>
            <div>
                {severityScore.map(({ label, value, color }) => {
                    return (
                        <FormControlLabel
                            key={value}
                            control={
                                <Checkbox
                                    style={{
                                        color,
                                    }}
                                    value={value}
                                    size="small"
                                    disabled={
                                        isDisabled ||
                                        !availableSeverity.includes(value)
                                    }
                                    onChange={() => toggleSeverity(value)}
                                    checked={
                                        availableSeverity.includes(value) &&
                                        severityShown.includes(value)
                                    }
                                />
                            }
                            label={label}
                        />
                    )
                })}
            </div>
            <SeverityLegend />
        </>
    )
}

const mapStateToProps = ({ sphere_data, asset_data: { data } }) => ({
    asset_data: data,
    sphere_data,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSpheres,
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageSeverityFilter)
