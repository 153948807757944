import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DataGrid } from '@material-ui/data-grid'

import { useFetchSpheresAPI } from '../Hook/queries'
import { validationDamagesColumns } from '../../config/tableColumns.js'
import { IValidationFilterForm } from './Filters'

const useStyles = makeStyles({
    dataGrid: {
        '& .MuiDataGrid-cell': {
            flexWrap: 'wrap',
        },
        maxHeight: '70vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
})

interface IDamagesList {
    assetId: number
    onRowClick: (id: number) => void
    dataId: number
    selectedDamage: number
    onLoad: (damageIDs: Array<number>) => void
    onRowEnter: (id: number) => void
    onRowLeave: () => void
    damageFilters: IValidationFilterForm
}

const colorDot = (color = '#FFFFFF'): JSX.Element => (
    <span
        style={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            backgroundColor: color,
        }}
    />
)

const DamagesList = ({
    assetId,
    onRowClick,
    dataId,
    selectedDamage,
    onLoad,
    onRowEnter,
    onRowLeave,
    damageFilters,
}: IDamagesList): JSX.Element => {
    const classes = useStyles()
    // @ts-ignore
    const userToken = useSelector((state) => state.login.token)
    // @ts-ignore
    let damageLabels = useSelector((state) => state.asset_data.data.labels)
    damageLabels = damageLabels?.filter(
        // @ts-ignore
        ({ label__name }) => label__name !== 'All'
    )
    // @ts-ignore
    const legend = damageLabels?.map(({ label__name, label__colour }) => ({
        name: label__name,
        colour: label__colour,
    }))
    const {
        isLoading: isFetchSpheresLoading,
        isFetching,
        isError,
        error,
        data: spheres,
    } = useFetchSpheresAPI(userToken, assetId, {
        data: dataId,
        no_page: true,
        severity_score: damageFilters.severity,
        labels: damageFilters.damageType,
    })
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!isFetchSpheresLoading && !isFetching && Array.isArray(spheres)) {
            // load image of first damage in list
            if (spheres[0]) {
                onRowClick(spheres[0].id)
            }

            // count damages
            onLoad(spheres.map((sphere) => sphere.id))
        }
    }, [isFetchSpheresLoading, isFetching])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            })
        }
    }, [isError, error])

    return (
        <>
            <Box display="flex">
                {/* @ts-ignore */}
                {legend?.map(({ name, colour }) => (
                    <Box clone px={1} key={name}>
                        <Typography variant="caption">
                            {colorDot(colour)}
                            {name}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <DataGrid
                className={classes.dataGrid}
                density="compact"
                hideFooter
                autoHeight
                loading={isFetchSpheresLoading}
                rows={Array.isArray(spheres) ? spheres : []}
                columns={validationDamagesColumns}
                onRowClick={({ row }) => {
                    onRowClick(row?.id)
                }}
                disableColumnMenu
                selectionModel={[selectedDamage]}
                onRowEnter={({ row }) => {
                    onRowEnter(row.id)
                }}
                onRowLeave={() => {
                    onRowLeave()
                }}
            />
        </>
    )
}

export default DamagesList
