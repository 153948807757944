// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'

import ProjectsActions from './ProjectsActions'
import { projectsTableColumns } from '../config/tableColumns.js'

// eslint-disable-next-line
const ProjectsTable = ({
    sortColumn,
    onSort,
    paginationProps,
    history,
}) => {
    const role = useSelector((state) => state.role.role)
    const projects = useSelector((state) => state.projects)
    const data = useSelector((state) => state.projects.data)
    const totalCount = useSelector((state) => state.projects.totalCount)
    const loading = projects.loading || !projects.data
    const columns = [...projectsTableColumns]
    const actionColumn = {
        field: 'actions',
        headerName: ' ',
        renderCell: ({ row }) => {
            return <ProjectsActions data={row} />
        },
        disableClickEventBubbling: true,
        disableColumnMenu: true,
        sortable: false,
    }

    if (
        role !== 'unauthorized' &&
        !columns.find((col) => col.field === 'actions')
    )
        columns.push(actionColumn)

    const handleClick = (id): void => {
        history.push(`projects/${id}`)
    }

    return (
        <DataGrid
            autoHeight
            paginationMode="server"
            sortingMode="server"
            sortModel={sortColumn}
            onSortModelChange={onSort}
            pageSize={10}
            loading={loading}
            rows={loading ? [] : data}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            onRowClick={({ row }) => {
                handleClick(row?.id)
            }}
            onPageChange={({ page }) => {
                paginationProps.onChangePage(page + 1)
            }}
            onPageSizeChange={({ pageSize }) => {
                paginationProps.onChangeDataPerPage(pageSize)
            }}
            page={paginationProps.currentPage - 1}
            rowCount={totalCount || 0}
            disableColumnMenu
        />
    )
}

export default withRouter(ProjectsTable)
