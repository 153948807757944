export const background = "#F4F6FF";
export const border = "#D5D5EA";
export const text = {
  light: "rgba(9, 25, 50, 0.6)",
  main: "#091932",
};
export const primary = {
  light: "#F0F5FF",
  main: "#1957D4",
  dark: "#1445A9",
};
export const secondary = {
  // light: 'rgba(255, 137, 151, 0.32)',
  light: "#FFE6E8",
  main: "#F22D4B",
  dark: "#D20034",
};
export const boxShadow = "0px 0px 35px rgba(187, 190, 221, 0.26)";

export const colorScheme = [
  { background: "#3be8b0", color: "#3be8b0" },
  { background: "#1aafd0", color: "#1aafd0" },
  { background: "#6a67ce", color: "#6a67ce" },
  { background: "#ffb900", color: "#ffb900" },
  { background: "#fc636b", color: "#fc636b" },
  { background: "#A5A8AA", color: "#A5A8AA" },
];

export const severityScheme = [
  { label: "A", color: "#0062b1" },
  { label: "B", color: "#3fcc46" },
  { label: "C", color: "#ffcf00" },
  { label: "D", color: "#fe7600" },
  { label: "E", color: "#d33115" },
];

export const serverityColors = {
  0: "0062b1",
  1: "3fcc46",
  2: "ffcf00",
  3: "fe7600",
  4: "d33115",
};

export const statusColorScheme = {
  "To Be Inspected": {
    color: "#6B7584",
    background: "rgba(107, 117, 132, 0.1)",
  },
  "Technical Inspection Needed": {
    color: "#d6a529",
    background: "rgba(255, 185, 0, 0.1)",
  },
  "Inspection Scheduled": {
    color: "#1AAFD0",
    background: "rgba(26, 175, 208, 0.1)",
  },
  "Repairs Needed": {
    color: "#FC636B",
    background: "rgba(252, 99, 107, 0.1)",
  },
  "Inspection Complete": {
    color: "#6A67CE",
    background: "rgba(106, 103, 206, 0.1)",
  },
};

export const jet_colour_map = [
  { label__name: "High", label__colour: "#920001" },
  { label__name: "", label__colour: "#FF8000" },
  { label__name: "", label__colour: "#FEF400" },
  { label__name: "Medium", label__colour: "#7EFF80" },
  { label__name: "", label__colour: "#00B4FE" },
  { label__name: "", label__colour: "#0131FF" },
  { label__name: "Low", label__colour: "#0100A3" },
];

export const assetHealthIndexColorMap = {
  low : {
    color: "#d33115",
    label: "Low"
  },
  fair: {
    color: "#FEF400",
    label: "Fair"
  },
  good: {
    color: "#3fcc46",
    label: "Good"
  },
}