export const assetStatusLists = [
    { id: 0, type: 'To Be Inspected' },
    { id: 1, type: 'Inspection Scheduled' },
    { id: 2, type: 'Inspection Complete' },
    { id: 3, type: 'Technical Inspection Needed' },
    { id: 4, type: 'Repairs Needed' },
]

export const severityLists = [
    { id: 0, type: 'all severities' },
    { id: 1, type: 'area' },
    { id: 2, type: 'confidence' },
    { id: 3, type: 'connected' },
    { id: 4, type: 'overlapped' },
]

export const roleLists = [
    { id: 0, type: 'admin' },
    { id: 1, type: 'user' }, // employee?
    { id: 2, type: 'client' },
    { id: 3, type: 'unauthorized' },
]

export const labelsLists = [
    { id: 0, type: 'None' },
    { id: 1, type: 'Crack' },
    { id: 2, type: 'Exposed Metal' },
    { id: 3, type: 'Missing' },
    { id: 4, type: 'Exposed Bricks' },
]

export const componentsList = [
    { id: 0, type: 'None' },
    { id: 1, type: 'Pillars' },
    { id: 2, type: 'Railing' },
    { id: 3, type: 'Concrete Slab' },
]

export const checkedList = [
    { id: null, type: 'None' },
    { id: 1, type: 'Checked' },
    { id: 0, type: 'To be checked' },
]

export const checkedList_v2 = [
    { id: 1, type: 'Checked', value: true },
    { id: 0, type: 'To be checked', value: false },
]

export const validationSortList = [
    {label: 'Largest damage', fieldName: '-width'},
    {label: 'Smallest damage', fieldName: 'width'},
    {label: 'Highest severity', fieldName: '-severity_score'},
    {label: 'Lowest severity', fieldName: 'severity_score'},
    {label: 'AI confidence', fieldName: '-confidence'},
]
