// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '@material-ui/core/Select'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import { updateProjectUser } from '../../redux/actions/project.js'
import { userForms } from '../../config/constants.js'
import { roleLists } from '../../config/selectorLists.js'

// eslint-disable-next-line
const AssetsExistingUserForm = ({ token, project_id }): JSX.Element => {
    const dispatch = useDispatch()
    const { users, errorMessage } = useSelector((state) => state.project_data)
    const [role, setRole] = useState('')
    const [error, setError] = useState('')
    const [username, setUsername] = useState('')
    const activeForm = userForms[0]

    // const formProps = {
    //     selectors: {
    //         label: 'role',
    //         title: 'select a role',
    //         options: roleLists,
    //     },
    //     lists: [{ label: 'username' }],
    //     button: { text: activeForm.key, icon: <AddIcon /> },
    //     errorMessage,
    // }

    const handleSubmit = (event): void => {
        event.preventDefault()

        const user = {
            role,
            username,
        }
        const isExisting = users.find(
            ({ username }) => username === user.username // eslint-disable-line
        )
        if (isExisting) return setError('Already in the project team')

        dispatch(updateProjectUser(token, project_id, user))
        return null
    }

    // TODO: extract form component (json configuration driven)
    return (
        <>
            <Typography color="textSecondary" variant="subtitle1">
                {activeForm.text}
            </Typography>
            <form onSubmit={handleSubmit}>
                {typeof errorMessage !== 'object' && !!errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                )}
                {!!error && <Alert severity="error">{error}</Alert>}

                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="assetuser-existing-field-role">
                        Role
                    </InputLabel>
                    <Select
                        labelId="assetuser-existing-field-role"
                        id="assetuser-existing-field-role"
                        label="Role"
                        value={role}
                        onChange={(event) => {
                            setRole(event.target.value)
                        }}
                    >
                        {roleLists.map((item) => {
                            return (
                                <MenuItem key={item.type} value={item.id}>
                                    {item.type}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText>Select A Role</FormHelperText>
                </FormControl>

                <TextField
                    id="assetuser-existing-field-name"
                    label="Username"
                    name="username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                />

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={<AddIcon />}
                    >
                        {activeForm.key}
                    </Button>
                </Box>
            </form>
        </>
    )
}

export default AssetsExistingUserForm
