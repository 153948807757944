// @ts-nocheck
import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import { withRouter } from 'react-router-dom'

import loginImage from '../assets/login/image.jpg'
import logoImage from '../assets/logo/mavisoft_white.png'
import { performLogin } from '../redux/actions/login.js'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'inline-block',
            background: 'rgba(9, 25, 50, 0.75)',
            width: '100%',
            height: '100%',
        },
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoBox: {
        color: 'rgba(255,255,255,0.8)',
    },
    logoImage: {
        width: '80%',
        maxWidth: '448px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}))

const LoginPage = ({ location, history }): JSX.Element => {
    const classes = useStyles()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.login.loading)
    const warning = useSelector((state) => state.login.warning)
    const isAuthenticated = useSelector((state) => state.login.isAuthenticated)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        dispatch(performLogin({ username, password }))
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (location?.state?.from) {
                history.replace(location.state.from.pathname)
            } else {
                history.replace('/projects')
            }
        }
    }, [location.state.from, history, isAuthenticated])

    return (
        <Grid
            container
            component="main"
            title="login screen"
            className={classes.root}
        >
            <Hidden xsDown>
                <Grid item sm={4} md={7} className={classes.image}>
                    <Box
                        position="absolute"
                        top="40%"
                        left="0"
                        right="0"
                        className={classes.logoBox}
                        p={4}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Welcome to
                        </Typography>
                        <img
                            alt="Mavisoft logo"
                            src={logoImage}
                            className={classes.logoImage}
                        />
                    </Box>
                </Grid>
            </Hidden>
            <Grid
                item
                container
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
                direction="column"
                justify="center"
            >
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                        {loading && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {warning && <Alert severity="error">{warning}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            size="large"
                            disabled={loading}
                        >
                            Login
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link
                                    href="https://www.mavisoft.com/"
                                    variant="body2"
                                >
                                    Don&apos;t have an account? Contact us
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}

export default withRouter(LoginPage)
