import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack';

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
import ThemeHandler from './components/ThemeHandler'
import './index.css'

const init = (): void => {
    const queryClient = new QueryClient()

    ReactDOM.render(
        <Provider store={store}>
            <ThemeHandler>
                <SnackbarProvider maxSnack={3} hideIconVariant>
                    <CssBaseline />
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <React.StrictMode>
                                <App />
                            </React.StrictMode>
                        </Router>
                    </QueryClientProvider>
                </SnackbarProvider>
            </ThemeHandler>
        </Provider>,
        document.getElementById('root')
    )
}

if (process.env.NODE_ENV !== 'production') {
    // Web accessibility testing in browser console
    import('@axe-core/react').then((axe) => {
        axe.default(React, ReactDOM, 1000)
        init()
    })
} else {
    init()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
