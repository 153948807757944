import { takeLatest, call, put, all } from 'redux-saga/effects'
import { FETCH_COMPANY_START } from '../types/company.js'
import { fetchCompanyAPI } from '../../config/api.js'
import { fetchCompanySuccess, fetchCompanyFailed } from '../actions/company.js'

function* getCompanies(action) {
    try {
        const companies = yield call(fetchCompanyAPI, action.token)
        yield put(fetchCompanySuccess(companies.data.results))
    } catch (error) {
        yield put(fetchCompanyFailed(error.message))
    }
}

export function* getCompaniesSaga() {
    yield takeLatest(FETCH_COMPANY_START, getCompanies)
}

export function* companySagas() {
    yield all([call(getCompaniesSaga)])
}
