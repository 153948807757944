import React from 'react'

const SeverityLegend = () => (
    <div style={{ position: 'relative', width: 'auto' }}>
        <img
            name="legend"
            alt="legend"
            src="/images/linear_gradient_many_colors_colored-removebg.png"
            style={{ width: '100%' }}
        />
    </div>
)

export default SeverityLegend
