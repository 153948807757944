import {
    FETCH_COMPANY_START,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILED,
} from '../types/company.js'

export const fetchCompanyStart = (token) => ({
    type: FETCH_COMPANY_START,
    token,
})

export const fetchCompanySuccess = (companyList) => ({
    type: FETCH_COMPANY_SUCCESS,
    payload: companyList,
})

export const fetchCompanyFailed = (errorMessage) => ({
    type: FETCH_COMPANY_FAILED,
    payload: errorMessage,
})
