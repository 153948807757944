import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { DataGrid, GridSortModelParams } from '@material-ui/data-grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { useFetchUserAssets } from './Hook/queries'
import { validationsTableColumns } from '../config/tableColumns.js'
import { getClearBreadcrumbsPath } from '../redux/actions/system.js'

const Validations = ({
    history,
    match: { url },
}: RouteComponentProps): JSX.Element => {
    // @ts-ignore
    const userToken = useSelector((state) => state.login.token)
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(5)
    const [sortColumn, setSortColumn] = useState([
        { field: 'updated_at', sort: 'desc' },
    ])
    const { isLoading, isError, error, data } = useFetchUserAssets(
        userToken,
        dataPerPage,
        currentPage,
        sortColumn.map((col) => ({
            column: col.field,
            order: col.sort,
        }))[0]
    )
    const { results: validations, count: totalCount } = data || {}

    const handleSort = (dataTableParams: GridSortModelParams): void => {
        // @ts-ignore
        setSortColumn(dataTableParams.sortModel)
    }

    const handleChangePage = (newPage: number): void => {
        setCurrentPage(newPage)
    }

    const handleChangeDataPerPage = (size: number): void => {
        setDataPerPage(size)
        setCurrentPage(1)
    }

    const handleClick = (id: number): void => {
        history.push(`${url}/${id}`)
    }

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getClearBreadcrumbsPath())
    }, [])

    return (
        <Box clone p={2}>
            <Paper>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5" component="h2" gutterBottom>
                        Validations
                    </Typography>
                </Box>
                {isError ? (
                    <Alert severity="error">{error?.message}</Alert>
                ) : (
                    <DataGrid
                        autoHeight
                        paginationMode="server"
                        sortingMode="server"
                        // @ts-ignore
                        sortModel={sortColumn}
                        // @ts-ignore
                        onSortModelChange={handleSort}
                        pageSize={dataPerPage}
                        loading={isLoading}
                        rows={validations || []}
                        columns={validationsTableColumns}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableColumnMenu
                        pagination
                        onRowClick={({ row }) => {
                            handleClick(row?.id)
                        }}
                        // @ts-ignore
                        onPageChange={({ page }) => {
                            handleChangePage(page + 1)
                        }}
                        // @ts-ignore
                        onPageSizeChange={({ pageSize }) => {
                            handleChangeDataPerPage(pageSize)
                        }}
                        page={currentPage - 1}
                        rowCount={totalCount || 0}
                    />
                )}
            </Paper>
        </Box>
    )
}

export default withRouter(Validations)
