import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const NoMatchPage = (): JSX.Element => (
    <Box clone p={2}>
        <Paper>
            <Box display="flex" justifyContent="center">
                <Typography variant="h5" component="h2" gutterBottom>
                    Page does not exist
                </Typography>
            </Box>
        </Paper>
    </Box>
)

export default NoMatchPage
