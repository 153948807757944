// @ts-nocheck
import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
// import IconButton from '@material-ui/core/IconButton'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
// import ListItemIcon from '@material-ui/core/ListItemIcon'
// import ListItemText from '@material-ui/core/ListItemText'
// import SvgIcon from '@material-ui/core/SvgIcon'
// import Box from '@material-ui/core/Box'

import DeleteModal from './Common/Modal/DeleteModal'
import { ReactComponent as EditIcon } from '../assets/actions/edit.svg'
import { ReactComponent as DeleteIcon } from '../assets/actions/trash.svg'
import { ReactComponent as CancelIcon } from '../assets/actions/cancel.svg'
import Action from './Common/Action'
import { getToken } from '../services/authService.js'
import {
    deleteProject,
    toggleFormOpen,
    setFormData,
} from '../redux/actions/dashboard.js'

// eslint-disable-next-line
const ProjectsActions = ({ data }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(null)

    const handleModalOpen = (): void => {
        setOpen('modal')
    }

    const handleOptionsClick = (): void => {
        setOpen('menu')
        dispatch(setFormData(data))
    }

    const handleOptionsClose = (): void => {
        setOpen(null)
    }

    const handleEdit = (): void => {
        dispatch(toggleFormOpen())
        handleOptionsClose()
    }

    const handleDelete = (): void => {
        const token = getToken()
        dispatch(deleteProject(token, data.id))
        handleOptionsClose()
    }

    const actionLists = [
        {
            icon: EditIcon,
            label: 'Edit Project',
            onAction: handleEdit,
            color: 'primary',
        },
        {
            icon: DeleteIcon,
            label: 'Delete',
            onAction: handleModalOpen,
            color: 'error',
        },
        {
            icon: CancelIcon,
            label: 'Cancel',
            onAction: handleOptionsClose,
            color: 'primary',
            closeOnClick: true,
        },
    ]

    return (
        <>
            <Action
                name={`project-actions-${data.id}`}
                list={actionLists}
                onActionButtonClick={handleOptionsClick}
                onOptionsClose={handleOptionsClose}
            />
            <DeleteModal
                folderName={data.name}
                fileName="assets"
                open={open === 'modal'}
                onDelete={handleDelete}
                onClose={handleOptionsClose}
            />
        </>
    )
}

export default ProjectsActions
