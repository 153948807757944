function multiplyMatrices(m1, m2) {
    const result = []
    for (let i = 0; i < m1.length; i++) {
        result[i] = []
        for (let j = 0; j < m2[0].length; j++) {
            let sum = 0
            for (let k = 0; k < m1[0].length; k++) {
                sum += m1[i][k] * m2[k][j]
            }
            result[i][j] = sum
        }
    }
    return result
}

function transpose(mat) {
    for (let i = 0; i < mat.length; i++) {
        for (let j = 0; j < i; j++) {
            const tmp = mat[i][j]
            mat[i][j] = mat[j][i]
            mat[j][i] = tmp
        }
    }
    return mat
}

function undistortPoints_Brown(_src, _cameraMatrix, _distCoeffs) {
    const k = _distCoeffs

    const fx = _cameraMatrix[0][0]
    const fy = _cameraMatrix[1][1]
    const ifx = 1 / fx
    const ify = 1 / fy
    const cx = _cameraMatrix[0][2]
    const cy = _cameraMatrix[1][2]
    const u = _src[0]
    const v = _src[1]
    const xd = u * ifx
    const yd = v * ify
    const xc = cx * ifx
    const yc = cy * ify

    // Distortion center
    const x0 = xd - xc
    const y0 = yd - yc

    const r2 = x0 * x0 + y0 * y0
    const icdist = ((k[4] * r2 + k[1]) * r2 + k[0]) * r2

    let x = xd + (xd - xc) * icdist
    let y = yd + (yd - yc) * icdist

    x = parseFloat(x)
    y = parseFloat(y)
    return [x, y]
}

export function getRealWordlPoint(
    point2d,
    data,
    w_old = null,
    h_old = null,
    dist = null
) {
    // Calculates 3d point along the Z axis.
    // It is not possible to obtain the exact point but it is possible
    // to obtain a line of possible positions of the 3d point
    const { w } = data
    const { h } = data

    let u = 0
    let v = 0

    // Point in the image plane
    // Point in the image plane
    if (point2d != null) {
        if (w_old === null && h_old === null) {
            u = point2d.x - w / 2
            v = point2d.y - h / 2
        } else {
            u = Math.floor((point2d.x + 0.5) * (w / w_old) + 0.5) - w / 2
            v = Math.floor((point2d.y + 0.5) * (h / h_old) + 0.5) - h / 2
        }
    }
    let z = 1.0

    if (dist) z = dist

    let Rc = [
        [data.r11, data.r12, data.r13],
        [data.r21, data.r22, data.r23],
        [data.r31, data.r32, data.r33],
    ]
    Rc = transpose(Rc)

    const { X } = data
    const { Y } = data
    const { Z } = data

    const { fx } = data
    const { fy } = data

    const { cx } = data
    const { cy } = data

    const cam_matrix = [
        [fx, 0, cx],
        [0, fy, cy],
        [0, 0, 1],
    ]

    const dist_coeff = [data.k1, data.k2, data.k3, data.k4, data.k5]

    const pt = undistortPoints_Brown([u, v], cam_matrix, dist_coeff)
    const x = pt[0]
    const y = pt[1]

    // Point
    const pt_cam = [[x * z], [y * z], [1]]

    const p2 = multiplyMatrices(Rc, pt_cam)
    const d = 1
    // if (dist) d = dist;
    p2[0] = parseFloat(p2[0]) + X
    p2[1] = parseFloat(p2[1]) + Y
    p2[2] = parseFloat(p2[2]) + Z

    return p2
}

export function getCameraCoordFromWorld(point3d, data) {
    const Rc = [
        [data.r11, data.r12, data.r13],
        [data.r21, data.r22, data.r23],
        [data.r31, data.r32, data.r33],
    ]
    // Rc = transpose(Rc);

    const { X } = data
    const { Y } = data
    const { Z } = data

    // Point
    const pt_cam = multiplyMatrices(Rc, [
        [X - point3d.x],
        [Y - point3d.y],
        [Z - point3d.z],
    ])

    return pt_cam
}

export function get_images_from_3dpt(point3d, data_) {
    const images = []
    for (let i = 0; i < data_.length; i++) {
        const data = data_[i]

        const Rc = [
            [data.r11, data.r12, data.r13],
            [data.r21, data.r22, data.r23],
            [data.r31, data.r32, data.r33],
        ]
        const { X } = data
        const { Y } = data
        const { Z } = data

        const { fx } = data
        const { fy } = data

        const { cx } = data
        const { cy } = data

        // let cam_matrix = [
        // 	[fx, 0, cx],
        // 	[0, fy, cy],
        // 	[0, 0, 1],
        // ];

        const k = [data.k1, data.k2, data.k3, data.k4, data.k5]

        const { w } = data
        const { h } = data
        // Rc = transpose(Rc)
        let t = multiplyMatrices(Rc, [[X], [Y], [Z]])
        t = t.map(function (x) {
            return x * -1
        })

        point3d = [[point3d[0]], [point3d[1]], [point3d[2]]]
        // console.log('multiplication', Rc, point3d)
        const pt_cam = multiplyMatrices(Rc, point3d)

        pt_cam[0] = parseFloat(pt_cam[0]) + t[0]
        pt_cam[1] = parseFloat(pt_cam[1]) + t[1]
        pt_cam[2] = parseFloat(pt_cam[2]) + t[2]
        // console.log(pt_cam)

        const x = pt_cam[0] / pt_cam[2]
        const y = pt_cam[1] / pt_cam[2]
        // console.log(x, y)

        const r2 = x * x + y * y
        // let icdist = 0.6 + ((k[4] * r2 + k[1]) * r2 + k[0]) * r2;

        let x_ = x * (1 + ((k[4] * r2 + k[1]) * r2 + k[0]) * r2)
        x_ = x_ + 2 * k[2] * x * y + k[3] * (r2 + 2 * x * x)
        let y_ = y * (1 + ((k[4] * r2 + k[1]) * r2 + k[0]) * r2)
        y_ = y_ + k[2] * (r2 + 2 * y * y) + 2 * k[3] * x * y

        let u = x_ * fx + cx
        let v = y_ * fy + cy
        u += w / 2
        v += h / 2
        // let pt = { x: u, y: v };

        if (u > 0 && u < w && v > 0 && v < h) {
            images.push(data)
        }
    }

    return images
}

export function getMousePointCloudIntersection(
    mouse,
    camera,
    viewer,
    pointclouds,
    params = {}
) {
    const { renderer } = viewer

    const nmouse = {
        x: (mouse.x / renderer.domElement.clientWidth) * 2 - 1,
        y: -(mouse.y / renderer.domElement.clientHeight) * 2 + 1,
    }

    const pickParams = {}

    if (params.pickClipped) {
        pickParams.pickClipped = params.pickClipped
    }

    pickParams.x = mouse.x
    pickParams.y = renderer.domElement.clientHeight - mouse.y

    const raycaster = new window.THREE.Raycaster()

    raycaster.setFromCamera(nmouse, camera)

    const { ray } = raycaster

    let selectedPointcloud = null
    let closestDistance = Infinity
    let closestIntersection = null
    let closestPoint = null

    for (const pointcloud of pointclouds) {
        const point = pointcloud.pick(viewer, camera, ray, pickParams)

        if (!point) {
            continue
        }

        const distance = camera.position.distanceTo(point.position)

        if (distance < closestDistance) {
            closestDistance = distance
            selectedPointcloud = pointcloud
            closestIntersection = point.position
            closestPoint = point
        }
    }

    if (selectedPointcloud) {
        return {
            location: closestIntersection,
            distance: closestDistance,
            pointcloud: selectedPointcloud,
            point: closestPoint,
        }
    }
    return null
}

export function getMousePos(canvas, evt) {
    const rect = canvas.getBoundingClientRect()
    return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top,
    }
}

export function drawSphere(
    image_data,
    point,
    scale,
    colour,
    fav,
    sphere_backend
) {
    let sphere = new window.THREE.Mesh()
    if (fav) {
        const sg = new window.THREE.SphereGeometry(0.1, 10, 10)
        const sm = new window.THREE.MeshBasicMaterial({
            color: `#${colour}`,
            transparent: true,
            opacity: 0.7,
        })

        sphere = new window.THREE.Mesh(sg, sm)
        sphere.renderOrder = 1
        sphere.material.depthTest = false

        const geo = new window.THREE.EdgesGeometry(sphere.geometry)
        const mat = new window.THREE.LineBasicMaterial({
            color: 0xffffff,
            linewidth: 2,
        })

        const wireframe = new window.THREE.LineSegments(geo, mat)
        sphere.add(wireframe)
        wireframe.renderOrder = 1
        wireframe.material.depthTest = false
    } else {
        const sg = new window.THREE.SphereGeometry(0.1, 10, 10)
        const sm = new window.THREE.MeshBasicMaterial({
            color: `#${colour}`,
            transparent: true,
            opacity: 0.7,
        })
        sphere = new window.THREE.Mesh(sg, sm)
        sphere.rendererOrder = 0
    }
    sphere.scale.x = scale
    sphere.scale.y = scale

    sphere.scale.z = scale
    sphere.userData.sphere_backend = sphere_backend
    sphere.userData.image_data = image_data
    const sphere_data = { fav, last_opacity: 0 }
    sphere.userData.sphere_data = sphere_data

    sphere.position.copy(point)

    window.damage_points.push(sphere)
    window.viewer.scene.scene.add(sphere)

    return sphere
}

export function drawLine(image_data, point, origin, sphere_backend) {
    let name_id = 0
    if (!sphere_backend) name_id = window.count_damage_points
    else name_id = sphere_backend.id

    const animationPath = new window.Potree.AnimationPath([origin, point])
    animationPath.closed = true
    const geometry = animationPath.getGeometry()
    const material = new window.THREE.LineDashedMaterial({
        color: 0xffffff,
        dashSize: 0.03,
        gapSize: 0.1,
        linewidth: 3,
    })

    const line = new window.THREE.Line(geometry, material, {
        closed: animationPath.closed,
    })
    line.computeLineDistances()

    line.userData.image_data = image_data
    line.userData.sphere_backend = sphere_backend
    window.all_damage_lines.push(line)
    window.count_damage_points += 1
    window.viewer.scene.scene.add(line)

    return line
}

export function removeLines(images_id) {
    const removeValFromLines = []

    for (let i = 0; i < window.all_damage_lines.length; i++) {
        const rem = window.all_damage_lines[i]
        if (images_id.includes(rem.userData.image_data.name)) {
            window.viewer.scene.scene.remove(rem)
            const index = window.all_damage_lines.indexOf(rem)
            if (index > -1) removeValFromLines.push(index)
        }
    }

    for (let i = removeValFromLines.length - 1; i >= 0; i--)
        window.all_damage_lines.splice(removeValFromLines[i], 1)
}

export function select_images(images) {
    for (let i = 0; i < images.length; i++) {
        const div = document.getElementById(`annotation-titlebar_${images[i]}`)
        div.style.border = '5px solid rgba(255, 255, 255, 1)'
    }
}

export function deselect_images(images) {
    for (let i = 0; i < images.length; i++) {
        const div = document.getElementById(`annotation-titlebar_${images[i]}`)
        div.style.border = '1px solid rgba(255, 255, 255, 0.7)'
    }
}

export function init(pointclouds) {
    window.mouse = new window.THREE.Vector2()
    window.INTERSECTED = null
    window.INTERSECTED_CLICK = null
    window.current_opacity = null
    window.damage_fav = []
    window.damage_points = []
    window.saved_damage_lines = []
    window.all_damage_lines = []
    window.count_damage_points = 0

    window.viewer = new window.Potree.Viewer(
        document.getElementById('potree_render_area')
    )

    window.viewer.scene.view.position.set(-34.834, -3.465, 32.544)
    window.viewer.scene.view.lookAt(8.184, -2.056, 19.153)
    window.viewer.useHQ = true
    window.viewer.lock = false

    window.scene = window.viewer.scene.scene
    window.camera = window.viewer.scene.cameraP
    window.scene.add(window.camera)

    window.raycaster = new window.THREE.Raycaster()
    window.renderer = new window.THREE.WebGLRenderer({ alpha: true })
    window.renderer.setClearColor(0xffffff, 1)

    window.renderer.setPixelRatio(window.devicePixelRatio)
    window.renderer.setSize(window.innerWidth, window.innerHeight)

    document
        .getElementById('potree_render_area')
        .appendChild(window.renderer.domElement)
    window.viewer.setEDLEnabled(true)
    window.viewer.setFOV(60)
    window.viewer.setPointBudget(1000000)
    window.viewer.setMinNodeSize(0)
    window.viewer.loadSettingsFromURL()

    window.viewer.loadGUI(() => {
        window.viewer.setLanguage('en')
        window.$('#menu_damages').next().show()
        // viewer.toggleSidebar();
    })
    for (let i=0; i<pointclouds.length; i++){
        let pointcloud = pointclouds[i].location + 'cloud.js'
        let name = pointclouds[i].name
        window.Potree.loadPointCloud(pointcloud, name, function (e) {
            window.viewer.scene.addPointCloud(e.pointcloud)
             
            const { material } = e.pointcloud
            material.size = 1
            material.pointSizeType = window.Potree.PointSizeType.ADAPTIVE
            window.viewer.fitToScreen()
            // Trigger event saying pointcloud has loaded
            const event = new CustomEvent("potree-pointcloud-init", { "detail": "Potree pointcloud initialized" });
            window.dispatchEvent(event);
            if (name != 'All' && pointclouds.length != 1) e.pointcloud.visible = false
        })
    }
}

export function checkAnnotation(text) {
    const annotationsRoot = window
        .$('#jstree_scene')
        .jstree()
        .get_json('annotations')

    if (annotationsRoot.children.length > 0) {
        for (let i = 0; i < annotationsRoot.children.length; i++) {
            const folder = annotationsRoot.children[i].children
            if (folder.length > 0) {
                for (let j = 0; j < folder.length; j++) {
                    if (folder[j].text === text) {
                        window
                            .$('#jstree_scene')
                            .jstree('check_node', folder[j].id)
                    }
                }
            }
            if (annotationsRoot.children[i].text === text) {
                window
                    .$('#jstree_scene')
                    .jstree('check_node', annotationsRoot.children[i].id)
            }
        }
    }
}

export function uncheckAnnotation(text) {
    const annotationsRoot = window
        .$('#jstree_scene')
        .jstree()
        .get_json('annotations')
    if (annotationsRoot.children.length > 0) {
        for (let i = 0; i < annotationsRoot.children.length; i++) {
            const folder = annotationsRoot.children[i].children
            if (folder.length > 0) {
                for (let j = 0; j < folder.length; j++) {
                    if (folder[j].text === text) {
                        window
                            .$('#jstree_scene')
                            .jstree('uncheck_node', folder[j].id)
                    }
                }
            }
            if (annotationsRoot.children[i].text === text) {
                window
                    .$('#jstree_scene')
                    .jstree('uncheck_node', annotationsRoot.children[i].id)
            }
        }
    }
}

export function uncheckAnnotations() {
    const annotationsRoot = window
        .$('#jstree_scene')
        .jstree()
        .get_json('annotations')

    if (annotationsRoot.children.length > 0) {
        for (let i = 0; i < annotationsRoot.children.length; i++) {
            const folder = annotationsRoot.children[i].children
            if (folder.length > 0) {
                for (let j = 0; j < folder.length; j++) {
                    window
                        .$('#jstree_scene')
                        .jstree('uncheck_node', folder[j].id)
                }
            }
            window
                .$('#jstree_scene')
                .jstree('uncheck_node', annotationsRoot.children[i].id)
        }
    }
}

export function addAnnotations(data) {
    const anTitle = document.createElement('span')
    anTitle.innerText = data.name
    const anPosition = [data.X, data.Y, data.Z]
    const camLookAt = getRealWordlPoint(null, data, null, null)

    let dirVector = [
        camLookAt[0] - anPosition[0],
        camLookAt[1] - anPosition[1],
        camLookAt[2] - anPosition[2],
    ]

    const normVector = Math.sqrt(
        Math.pow(dirVector[0], 2) +
            Math.pow(dirVector[1], 2) +
            Math.pow(dirVector[2], 2)
    )

    dirVector = [
        dirVector[0] / normVector,
        dirVector[1] / normVector,
        dirVector[2] / normVector,
    ]

    const camPosition = [...anPosition]
    camPosition[0] -= dirVector[0]
    camPosition[1] -= dirVector[1]
    camPosition[2] -= dirVector[2]

    const annotation = window.viewer.scene.addAnnotation(anPosition, {
        cameraPosition: camPosition,
        cameraTarget: camLookAt,
        title: anTitle,
        description: ' ',
        folder: data.folder,
        agglo: false,
        severity_score: data.severity_score,
    })

    const annElement = annotation.domElement[0]

    const imgDesc = document.createElement('img')
    imgDesc.className += 'annotation-img'
    imgDesc.id = data.name
    imgDesc.loading = 'lazy'

    const descriptionConten = annElement.getElementsByClassName(
        'annotation-description-content'
    )[0]

    descriptionConten.appendChild(imgDesc)
    const closeBtn = annElement.getElementsByClassName(
        'annotation-description-close'
    )[0]
    closeBtn.addEventListener('click', () => {
        uncheckAnnotation(data.name)
        removeLines([data.name])
    })
    const labelBox = document.createElement('span')
    labelBox.className += 'fa fa-eye-slash'
    labelBox.id = `label-icon-${data.name}`
    labelBox.style.cssFloat = 'right'
    labelBox.style.top = '0'
    labelBox.style.cursor = 'pointer'
    labelBox.style.opacity = '0.7'
    labelBox.style.padding = '5px'
    labelBox.addEventListener('mouseover', (event) => {
        labelBox.style.opacity = 1
    })
    labelBox.addEventListener('mouseout', (event) => {
        labelBox.style.opacity = 0.7
    })
    const descriptionElement = annElement.getElementsByClassName(
        'annotation-description'
    )[0]

    // descriptionElement.appendChild(labelBox);
    // descriptionElement.insertBefore(labelBox, descriptionConten);

    const closeIcon = descriptionElement.getElementsByClassName(
        'annotation-description-close'
    )[0]
    closeIcon.style.cursor = 'pointer'

    const expandBox = document.createElement('span')
    expandBox.className += 'fas fa-expand'
    expandBox.id = `expand-icon-${data.name}`
    expandBox.style.top = '0'
    expandBox.style.cssFloat = 'right'
    expandBox.style.cursor = 'pointer'
    expandBox.style.opacity = '0.7'
    expandBox.style.padding = '5px'
    expandBox.addEventListener('mouseover', (event) => {
        expandBox.style.opacity = 1
    })
    expandBox.addEventListener('mouseout', (event) => {
        expandBox.style.opacity = 0.7
    })
    descriptionElement.appendChild(expandBox)
    if (data.damage_points) {
        const damageIcon = document.createElement('img')
        damageIcon.src = '/images/threeDmodel/alert-triangle-line.svg'
        damageIcon.className += 'alert-triangle-line'
        damageIcon.id = `damage-icon-${data.name}`
        damageIcon.style.top = '0'
        damageIcon.style.cssFloat = 'right'
        damageIcon.style.cursor = 'pointer'
        damageIcon.style.opacity = '0.5'
        damageIcon.style.padding = '5px'
        damageIcon.addEventListener('mouseover', (event) => {
            damageIcon.style.opacity = 1
        })
        damageIcon.addEventListener('mouseout', (event) => {
            damageIcon.style.opacity = 0.7
        })
        descriptionElement.appendChild(damageIcon)
    }
    descriptionElement.appendChild(labelBox)

    annElement.addEventListener('mouseover', (event) => {
        event.target.style.opacity = 1
    })

    return annElement
}
