// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import ProjectsOverview from './ProjectsOverview.jsx'
import {
    getDashboard,
    getProjects,
    toggleFormOpen,
    setFormData,
} from '../redux/actions/dashboard.js'
import { getClearBreadcrumbsPath } from '../redux/actions/system.js'
import { getToken } from '../services/authService.js'
import ProjectsTable from './ProjectsTable'
import ProjectFormModal from './ProjectFormModal'

// eslint-disable-next-line
const Projects = () => {
    const dispatch = useDispatch()
    const projects = useSelector((state) => state.projects)
    const { formOpen, data, errorMessage } = projects
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(5)
    const [sortColumn, setSortColumn] = useState([
        { field: 'updated_at', sort: 'desc' },
    ])

    const params = {
        sortColumn: sortColumn?.map((col) => ({
            column: col.field,
            order: col.sort,
        }))[0],
        dataPerPage,
        currentPage,
    }

    const handleSort = (dataTableParams): void => {
        setSortColumn(dataTableParams.sortModel)
    }

    const handleChangePage = (newPage): void => {
        setCurrentPage(parseInt(newPage, 10))
    }

    const handleChangeDataPerPage = (size): void => {
        const pageSize = parseInt(size, 10)
        setDataPerPage(pageSize)
        setCurrentPage(1)
    }

    const paginationProps = {
        currentPage,
        onChangePage: handleChangePage,
        dataPerPage,
        onChangeDataPerPage: handleChangeDataPerPage,
    }

    useEffect(() => {
        const token = getToken()
        dispatch(getProjects(token, params))
  }, [sortColumn, dataPerPage, currentPage]); // eslint-disable-line

    useEffect(() => {
        const token = getToken()
        if (errorMessage !== undefined) getProjects(token, params)
        // eslint-disable-next-line
  }, [getProjects, sortColumn, dataPerPage, currentPage, errorMessage]);

    useEffect(() => {
        const token = getToken()
        dispatch(getDashboard(token))
  }, [data]); // eslint-disable-line

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getClearBreadcrumbsPath())
    }, [dispatch])

    const handleAddButton = (): void => {
        dispatch(setFormData(null))
        dispatch(toggleFormOpen())
    }

    return (
        <>
            <ProjectsOverview />
            <Box clone p={2} mt={4}>
                <Paper>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Projects
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleAddButton}
                        >
                            Add Project
                        </Button>
                    </Box>
                    <ProjectsTable
                        sortColumn={sortColumn}
                        onSort={handleSort}
                        paginationProps={paginationProps}
                    />
                    <ProjectFormModal open={formOpen} />
                </Paper>
            </Box>
        </>
    )
}

export default Projects
