import { put, takeLatest, call } from 'redux-saga/effects'
import {
    fetchReportsAPI,
    fetchReportAPI,
    createReportAPI,
    deleteReportAPI,
    downloadReportAPI,
    fetchUserReportsAPI,
    uploadReportAPI,
} from '../../config/api.js'
import {
    GET_REPORTS_REQUEST,
    GET_REPORTS_SUCCEEDED,
    GET_REPORTS_FAILED,
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCEEDED,
    GET_REPORT_FAILED,
    GET_CREATE_REPORT_REQUEST,
    GET_CREATE_REPORT_SUCCEEDED,
    GET_CREATE_REPORT_FAILED,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_FAILED,
    GET_DOWNLOAD_REPORT_REQUEST,
    GET_DOWNLOAD_REPORT_SUCCEEDED,
    GET_DOWNLOAD_REPORT_FAILED,
    GET_USER_REPORTS_REQUEST,
    GET_USER_REPORTS_SUCCEEDED,
    GET_USER_REPORTS_FAILED,
    GET_UPLOAD_REPORT_REQUEST,
    GET_UPLOAD_REPORT_SUCCEEDED,
    GET_UPLOAD_REPORT_FAILED,
    deleteReportSuccess,
} from '../actions/report.js'

function* getReports(action) {
    try {
        const report_data = yield call(
            fetchReportsAPI,
            action.token,
            action.asset_id,
            action.params
        )
        yield put({ type: GET_REPORTS_SUCCEEDED, report_data })
    } catch (e) {
        yield put({ type: GET_REPORTS_FAILED, message: e.message })
    }
}

function* getReport(action) {
    try {
        const report_data = yield call(
            fetchReportAPI,
            action.token,
            action.report_id
        )
        yield put({ type: GET_REPORT_SUCCEEDED, report_data })
    } catch (e) {
        yield put({ type: GET_REPORT_FAILED, message: e.message })
    }
}

function* deleteReport(action) {
    try {
        yield call(deleteReportAPI, action.token, action.report_id)
        yield put(deleteReportSuccess(action.report_id))
    } catch (e) {
        yield put({ type: DELETE_REPORT_FAILED, message: e.message })
    }
}

function* downloadReport(action) {
    try {
        const download_data = yield call(
            downloadReportAPI,
            action.token,
            action.report_id
        )
        yield put({
            type: GET_DOWNLOAD_REPORT_SUCCEEDED,
            download_data,
            name: action.name,
        })
    } catch (e) {
        yield put({ type: GET_DOWNLOAD_REPORT_FAILED, message: e.message })
    }
}

function* createReport(action) {
    try {
        yield call(createReportAPI, action.token, action.asset_id)
        const report_data = yield call(
            fetchReportsAPI,
            action.token,
            action.asset_id,
            action.params
        )
        yield put({ type: GET_CREATE_REPORT_SUCCEEDED, report_data })
    } catch (e) {
        yield put({ type: GET_CREATE_REPORT_FAILED, message: e.message })
    }
}

function* getUserReports(action) {
    try {
        const user_reports = yield call(
            fetchUserReportsAPI,
            action.token,
            action.params
        )
        yield put({ type: GET_USER_REPORTS_SUCCEEDED, user_reports })
    } catch (e) {
        yield put({ type: GET_USER_REPORTS_FAILED, message: e.message })
    }
}

function* uploadReport(action) {
    try {
        yield call(uploadReportAPI, action.token, action.asset_id, action.files)
        const report_data = yield call(
            fetchReportsAPI,
            action.token,
            action.asset_id,
            action.params
        )
        yield put({
            type: GET_UPLOAD_REPORT_SUCCEEDED,
            report_data,
        })
    } catch (e) {
        yield put({ type: GET_UPLOAD_REPORT_FAILED, message: e.message })
    }
}

export function* getReportsSaga() {
    yield takeLatest(GET_REPORTS_REQUEST, getReports)
}

export function* getReportSaga() {
    yield takeLatest(GET_REPORT_REQUEST, getReport)
}

export function* deleteReportSaga() {
    yield takeLatest(DELETE_REPORT_REQUEST, deleteReport)
}

export function* downloadReportSaga() {
    yield takeLatest(GET_DOWNLOAD_REPORT_REQUEST, downloadReport)
}

export function* createReportSaga() {
    yield takeLatest(GET_CREATE_REPORT_REQUEST, createReport)
}

export function* getUserReportsSaga() {
    yield takeLatest(GET_USER_REPORTS_REQUEST, getUserReports)
}

export function* uploadReportSaga() {
    yield takeLatest(GET_UPLOAD_REPORT_REQUEST, uploadReport)
}
