// @ts-nocheck
import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Can } from '@casl/react'

import { ABILITIES } from '../../../config/ability.js'
import { getToken } from '../../../services/authService.js'
import { vw } from '../../../utils/utils'
import { getReport } from '../../../redux/actions/report.js'
import Spinner from '../../Common/Spinner'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    pdfContainer: {
        flexGrow: 1,
        backgroundColor: '#ebebeb',
    },
    pdfPage: {
        width: '100%',
        marginTop: '20px',
        boxShadow: '0px 0px 20px rgb(0 0 0 / 20%)',
        '&:last-child': {
            marginBottom: '20px',
        },
    },
    dialog: {
        backgroundColor: '#ebebeb',
    },
    documentContainer: {
        backgroundColor: '#ebebeb',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    // eslint-disable-next-line
    return <Slide direction="up" ref={ref} {...props} />
})

const tooltip = (title, component): JSX.Element => (
    <Tooltip title={title}>{component}</Tooltip>
)

const ReportActionPopup = ({
    id,
    name,
    open,
    handleClose,
    onReportActions,
    onReportNavigation,
}): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const role = useSelector((state) => state.role)
    const report_detail_data = useSelector((state) => state.report_detail_data)
    const token = getToken()

    useEffect(() => {
        dispatch(getReport(token, id))
    }, [id])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            className={classes.dialog}
        >
            <AppBar className={classes.appBar}>
                <Box
                    clone
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box display="flex" alignItems="center">
                            {tooltip(
                                'Prev',
                                <IconButton
                                    aria-label="previous"
                                    color="inherit"
                                    onClick={() => onReportNavigation(id, -1)}
                                >
                                    <ArrowLeftIcon />
                                </IconButton>
                            )}
                            <Typography component="h2" variant="h6">
                                {name}
                            </Typography>
                            {tooltip(
                                'Next',
                                <IconButton
                                    aria-label="next"
                                    color="inherit"
                                    onClick={() => onReportNavigation(id, 1)}
                                >
                                    <ArrowRightIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Box>
                            <IconButton
                                autoFocus
                                color="inherit"
                                onClick={() => onReportActions('download')}
                            >
                                {tooltip('Download', <GetAppIcon />)}
                            </IconButton>
                            <Can
                                I="perform"
                                a="delete"
                                ability={ABILITIES[role.role]}
                            >
                                <IconButton
                                    onClick={() => onReportActions('delete')}
                                    color="inherit"
                                >
                                    {tooltip('Delete', <DeleteIcon />)}
                                </IconButton>
                            </Can>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
            <Container className={classes.pdfContainer}>
                {report_detail_data.loading ? (
                    <Spinner />
                ) : (
                    <Document
                        file={`data:application/pdf;base64,${report_detail_data.report}`}
                        loading=""
                        className={classes.documentContainer}
                    >
                        {[...Array(report_detail_data.num_pages)].map(
                            (x, i) => (
                                <Page
                                    key={`page_${i + 1}`}
                                    pageNumber={i + 1}
                                    className={classes.pdfPage}
                                    width={vw(80)}
                                />
                            )
                        )}
                    </Document>
                )}
            </Container>
        </Dialog>
    )
}

export default ReportActionPopup
