// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'

import {
    getProjectDetails,
    toggleUserFormOpen,
    toggleFormOpen,
    setFormData,
} from '../redux/actions/project.js'
import { getSetBreadcrumbsPath } from '../redux/actions/system.js'
import { getToken } from '../services/authService.js'
import AssetFormModal from './AssetFormModal'
import AssetsTable from './AssetsTable'
import AssetsMap from './AssetsMap'
import AssetsUserModal from './AssetsUserModal/AssetsUserModal'
import Spinner from './Common/Spinner'

const useStyles = makeStyles({
    mapContainer: {
        height: '400px',
    },
})

// eslint-disable-next-line
const Assets = ({
    history,
    match: {
        params: { id: project_id },
    },
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const role = useSelector((state) => state.role.role)
    const { name, assets, formOpen, errorMessage, loading } = useSelector(
        (state) => state.project_data
    )
    const [hoveredId, setHoveredId] = useState(null)
    const token = getToken()

    const crumbs = {
        parent: '/projects',
        crumbs: [{ label: name, path: '' }],
    }

    const handleHover = (id): void => {
        setHoveredId(id)
    }

    const handleAddButton = (): void => {
        dispatch(setFormData(null))
        dispatch(toggleFormOpen())
    }

    useEffect(() => {
        dispatch(getProjectDetails(token, project_id))
  }, [dispatch]); // eslint-disable-line

    useEffect(() => {
        if (errorMessage === 404) history.replace('/404')
  }, [errorMessage]); // eslint-disable-line

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getSetBreadcrumbsPath(crumbs))
    }, [dispatch, crumbs])

    return !loading ? (
        <Paper>
            <Box display="flex" justifyContent="flex-end" p={2}>
                {role === 'admin' && (
                    <Box mr={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PeopleOutlinedIcon />}
                            onClick={() => dispatch(toggleUserFormOpen('add'))}
                        >
                            Manage Team
                        </Button>
                    </Box>
                )}

                {role !== 'unauthorized' && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddButton}
                    >
                        Add Asset
                    </Button>
                )}
            </Box>
            <Box px={2}>
                <AssetsTable onHover={handleHover} hoveredId={hoveredId} />
                <Box py={2} className={classes.mapContainer}>
                    <AssetsMap
                        data={assets}
                        hoveredId={hoveredId}
                        onHover={handleHover}
                    />
                </Box>
                <AssetFormModal
                    open={!!formOpen}
                    type="Asset"
                    project_id={project_id}
                />
                <AssetsUserModal />
            </Box>
        </Paper>
    ) : (
        <Spinner />
    )
}

export default Assets
