import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CheckIcon from '@material-ui/icons/Check'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'

import {
    useFetchAssetDetails,
    useFetchAssetData,
    useUpdateSpheresAPI,
    useUpdateDataValidationAPI,
} from '../Hook/queries'
import DamagesList from './DamagesList'
import Viewer from './Viewer'
import Filters, { IValidationFilterForm } from './Filters'
import Spinner from '../Common/Spinner'
import { token_name_validation_checkpoint } from '../../config/constants.js'
import { getToken } from '../../services/authService.js'
import { getAssetDetails } from '../../redux/actions/asset.js'
import { getClearBreadcrumbsPath } from '../../redux/actions/system.js'

export interface IFiltersFormInputs {
    damageLabels: Array<number>
    severityScores: Array<number>
    damageComponents: Array<string>
    checkedList: Array<number>
    damageSizeLimits: Array<number>
}

const ValidationDetail = ({ match }: RouteComponentProps): JSX.Element => {
    const dispatch = useDispatch()
    // @ts-ignore
    const assetId = match?.params?.id
    // @ts-ignore
    const userToken = useSelector((state) => state.login.token)
    // @ts-ignore
    const userData = useSelector((state) => state.user_data)
    const [cachedProgress] = useLocalStorage<{
        user: string
        dataIndex: number
        assetId: string
    }>(token_name_validation_checkpoint)
    const [selectedDamage, setSelectedDamage] = useState<null | number>(null)
    const [damageIDs, setDamageIDs] = useState<Array<number>>([])
    const [selectedDataIndex, setSelectedDataIndex] = useState<null | number>(
        null
    )
    const [hoverDamageId, setHoverDamageId] = useState<number>(-1)
    const [damageFilters, setDamageFilters] = useState<IValidationFilterForm>({
        severity: [],
        damageType: [],
        severityType: 'None',
    })
    const {
        isLoading: isAssetDetailsLoading,
        isFetching: isAssetDetailsFetching,
        isError: isAssetDetailsError,
        error: assetDetailsError,
        data: assetDetailsData,
        refetch: assetDetailsRefetch,
    } = useFetchAssetDetails(userToken, assetId)
    const {
        isLoading: isAssetDataLoading,
        isFetching: isAssetDataFetching,
        isError: isAssetDataError,
        error: assetDataError,
        data: assetDataData,
        refetch: assetDataRefetch,
    } = useFetchAssetData(userToken, assetId, {
        file_type: 'image',
        no_page: true,
    })
    const assetDataSorted = assetDataData?.sort((a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })
    const { name, validation_progress: progress } = assetDetailsData || {}
    const currentImage =
        (assetDataSorted &&
            selectedDataIndex !== null &&
            assetDataSorted[selectedDataIndex]) ||
        undefined
    const currentImageId = currentImage?.id || -1
    const isCurrentImageValidated = currentImage?.validated
    const userAnnotations = currentImage?.user_annotations || []
    const { mutate, isLoading: isUpdateSpheresLoading } = useUpdateSpheresAPI(
        [
            'fetchSpheresAPI',
            assetId,
            ...Object.values({
                data: currentImageId,
                no_page: true,
            }),
        ],
        () => {
            assetDetailsRefetch()
            assetDataRefetch()
        }
    )
    const {
        mutate: dataMutate,
        isLoading: isUpdateDataValidationLoading,
    } = useUpdateDataValidationAPI()
    const { enqueueSnackbar } = useSnackbar()

    const handleDamageRowClick = (id: number): void => {
        setSelectedDamage(id)
    }

    const toggleValidation = (isValidated: boolean): void => {
        // mutate({
        //     token: userToken,
        //     assetId,
        //     data: [...Array(damageIDs.length)].map(() => ({
        //         checked: !isValidated,
        //     })),
        //     params: { data: currentImageId },
        // })
        dataMutate({
            token: userToken,
            assetId,
            dataId: currentImageId,
            data: {
                validated: !isValidated,
            },
        })
    }

    // save progress in local storage
    const saveProgress = (dataIndex: number): void => {
        writeStorage(token_name_validation_checkpoint, {
            user: userData?.user?.username,
            dataIndex,
            assetId,
        })
    }

    // Show API error messages
    useEffect(() => {
        if (isAssetDetailsError) {
            enqueueSnackbar(assetDetailsError?.message, {
                variant: 'error',
            })
        }
        if (isAssetDataError) {
            enqueueSnackbar(assetDataError?.message, {
                variant: 'error',
            })
        }
    }, [
        isAssetDetailsError,
        assetDetailsError,
        isAssetDataError,
        assetDataError,
    ])

    // initialize list and viewer
    useEffect(() => {
        if (!isAssetDataLoading) {
            // restore previous session
            if (
                cachedProgress?.user === userData?.user?.username &&
                cachedProgress?.assetId === assetId
            ) {
                setSelectedDataIndex(cachedProgress?.dataIndex || 0)
            } else {
                setSelectedDataIndex(0)
            }
        }
    }, [isAssetDataLoading])

    // Load asset data
    useEffect(() => {
        const token = getToken()
        dispatch(getAssetDetails(token, assetId))
  }, []); // eslint-disable-line

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getClearBreadcrumbsPath())
    }, [])

    return (
        <Box clone p={2} className="validation-tool-container">
            <Paper>
                {isAssetDetailsLoading ? (
                    <Spinner />
                ) : (
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Box display="flex" flexDirection="column" mb={2}>
                                <Typography variant="h5" component="h2">
                                    Survey Details
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    {name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box display="flex" alignItems="center">
                                <Box width="100%" mr={1}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                    />
                                </Box>
                                <Box minWidth={35}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {`${progress}%`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                {assetDataSorted && selectedDataIndex !== null && (
                                    <>
                                        <ButtonGroup
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            disabled={
                                                isAssetDetailsLoading ||
                                                isAssetDetailsFetching ||
                                                isAssetDataFetching ||
                                                isAssetDataLoading ||
                                                isUpdateSpheresLoading
                                            }
                                        >
                                            <Button
                                                startIcon={<ArrowBackIosIcon />}
                                                disabled={
                                                    selectedDataIndex === 0
                                                }
                                                onClick={() => {
                                                    setSelectedDataIndex(
                                                        selectedDataIndex - 1
                                                    )
                                                    saveProgress(
                                                        selectedDataIndex - 1
                                                    )
                                                }}
                                            >
                                                Prev.
                                            </Button>
                                            <Button
                                                startIcon={
                                                    <ArrowForwardIosIcon />
                                                }
                                                disabled={
                                                    Array.isArray(
                                                        assetDataSorted
                                                    ) &&
                                                    selectedDataIndex ===
                                                        assetDataSorted.length -
                                                            1
                                                }
                                                onClick={() => {
                                                    setSelectedDataIndex(
                                                        selectedDataIndex + 1
                                                    )
                                                    saveProgress(
                                                        selectedDataIndex + 1
                                                    )
                                                }}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                startIcon={<CheckIcon />}
                                                onClick={() => {
                                                    toggleValidation(
                                                        isCurrentImageValidated ||
                                                            false
                                                    )
                                                }}
                                                variant={
                                                    isCurrentImageValidated
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                            >
                                                Validate
                                            </Button>
                                        </ButtonGroup>
                                        <Box clone pl={1}>
                                            <Typography
                                                color="textSecondary"
                                                variant="subtitle1"
                                            >
                                                {`${selectedDataIndex + 1} of ${
                                                    assetDataSorted?.length
                                                }`}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}

                <Grid container>
                    <Box clone position="relative" zIndex="1">
                        <Grid item xs={12} lg={8}>
                            {selectedDamage !== null && (
                                <Viewer
                                    selectedDamage={selectedDamage}
                                    damageIDs={damageIDs}
                                    imageId={currentImageId}
                                    assetId={assetId}
                                    damageCount={damageIDs.length}
                                    hoverDamageId={hoverDamageId}
                                    damageFilters={damageFilters}
                                />
                            )}
                        </Grid>
                    </Box>

                    <Grid item xs={12} lg={4}>
                        {assetDataSorted && selectedDataIndex !== null && (
                            <>
                                <Filters onUpdate={setDamageFilters} />
                                <DamagesList
                                    assetId={assetId}
                                    dataId={
                                        assetDataSorted[selectedDataIndex].id
                                    }
                                    onRowClick={handleDamageRowClick}
                                    selectedDamage={
                                        selectedDamage === null
                                            ? -1
                                            : selectedDamage
                                    }
                                    onLoad={setDamageIDs}
                                    onRowEnter={setHoverDamageId}
                                    onRowLeave={() => setHoverDamageId(-1)}
                                    damageFilters={damageFilters}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default withRouter(ValidationDetail)
