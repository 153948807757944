// @ts-nocheck
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SvgIcon from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
    actionBox: {
        width: '100%',
    },
})

// eslint-disable-next-line
const Action = ({
    name,
    list = [],
    onActionButtonClick,
    onOptionsClose,
}): JSX.Element => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleOptionsClick = (event): void => {
        setAnchorEl(event.currentTarget)
    }

    const handleOptionsClose = (): void => {
        setAnchorEl(null)
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.actionBox}
            >
                <IconButton
                    color="inherit"
                    aria-label="more options"
                    aria-controls={name}
                    aria-haspopup="true"
                    onClick={(event) => {
                        handleOptionsClick(event)
                        if (onActionButtonClick) {
                            onActionButtonClick()
                        }
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>

            <Menu
                id={name}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleOptionsClose()
                    if (onOptionsClose) {
                        onOptionsClose()
                    }
                }}
            >
                {list.map((item) => {
                    return (
                        <MenuItem
                            key={item.label}
                            onClick={() => {
                                if (item.onAction) item.onAction()
                                if (item.closeOnClick) handleOptionsClose()
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon
                                    component={item.icon}
                                    color={item.color}
                                />
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

export default Action
