import { call, put, takeLatest } from 'redux-saga/effects'

import {
    fetchProjectsAPI,
    createProjectAPI,
    deleteProjectAPI,
    updateProjectAPI,
    fetchDashboardAPI,
    fetchLabelsAPI,
} from '../../config/api.js'
import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCEEDED,
    GET_PROJECTS_FAILED,
    ADD_PROJECT_REQUEST,
    DELETE_PROJECT_REQUEST,
    UPDATE_PROJECT_REQUEST,
    GET_LABELS_REQUEST,
    GET_LABELS_SUCCEEDED,
    GET_LABELS_FAILED,  
} from '../types/dashboard.js'
import {
    createProjectSuccess,
    createProjectFailed,
    GET_DASHBOARD_FAILED,
    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCEEDED,
    deleteProjectSuccess,
    deleteProjectFailed,
    updateProjectSuccess,
    updateProjectFailed,
} from '../actions/dashboard.js'

function* getProjects(action) {
    try {
      const projects = yield call(fetchProjectsAPI, action.token, action.params);
      yield put({ type: GET_PROJECTS_SUCCEEDED, projects_data: projects });
    } catch (e) {
      console.log("Error e ", e);
      yield put({ type: GET_PROJECTS_FAILED, message: e.message });
    }
  }
  
  export function* getProjectsSaga() {
    yield takeLatest(GET_PROJECTS_REQUEST, getProjects);
  }
  
  function* createProject(action) {
    try {
      yield call(createProjectAPI, action.token, action.data);
      const projects = yield call(fetchProjectsAPI, action.token);
      yield put(createProjectSuccess(projects.data.results));
    } catch (error) {
      yield put(createProjectFailed(error.response.data));
    }
  }
  
  export function* createProjectSaga() {
    yield takeLatest(ADD_PROJECT_REQUEST, createProject);
  }
  
  function* getDashboard(action) {
    try {
      const dashboard = yield call(fetchDashboardAPI, action.token);
      yield put({ type: GET_DASHBOARD_SUCCEEDED, dashboard_data: dashboard });
    } catch (e) {
      console.log("Error e ", e);
      yield put({ type: GET_DASHBOARD_FAILED, message: e.message });
    }
  }
  
  export function* getDashboardSaga() {
    yield takeLatest(GET_DASHBOARD_REQUEST, getDashboard);
  }
  
  function* getLabels(action) {
    try {
      const labels = yield call(fetchLabelsAPI, action.token);
      yield put({ type: GET_LABELS_SUCCEEDED, labels });
    } catch (e) {
      console.log("Error e ", e);
      yield put({ type: GET_LABELS_FAILED, message: e.message });
    }
  }
  
  export function* getLabelsSaga() {
    yield takeLatest(GET_LABELS_REQUEST, getLabels);
  }
  
  function* deleteProject(action) {
    try {
      yield call(deleteProjectAPI, action.token, action.id);
      yield put(deleteProjectSuccess(action.id));
    } catch (error) {
      yield put(deleteProjectFailed(error.response.data));
    }
  }
  
  export function* deleteProjectSaga() {
    yield takeLatest(DELETE_PROJECT_REQUEST, deleteProject);
  }
  
  function* updateProject(action) {
    try {
      const projects = yield call(
        updateProjectAPI,
        action.token,
        action.id,
        action.data
      );
      yield put(updateProjectSuccess(projects.data));
    } catch (error) {
      yield put(updateProjectFailed(error.response.data));
    }
  }
  
  export function* updateProjectSaga() {
    yield takeLatest(UPDATE_PROJECT_REQUEST, updateProject);
  }
  
