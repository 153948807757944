import { GET_PROJECTS_REQUEST } from '../types/dashboard.js'
import {
    TOGGLE_USER_FORM_OPEN,
    TOGGLE_ASSET_FORM_OPEN,
    SET_ASSET_FORM_DATA,
    GET_PROJECT_DETAILS_REQUEST,
    GET_PROJECT_DETAILS_SUCCEEDED,
    GET_PROJECT_DETAILS_FAILED,
    ADD_ASSET_SUCCEEDED,
    ADD_ASSET_REQUEST,
    ADD_ASSET_FAILED,
    DELETE_ASSET_REQUEST,
    DELETE_ASSET_SUCCEEDED,
    DELETE_ASSET_FAILED,
    UPDATE_ASSET_REQUEST,
    UPDATE_ASSET_SUCCEEDED,
    UPDATE_ASSET_FAILED,
    CREATE_PROJECT_USER_REQUEST,
    CREATE_PROJECT_USER_SUCCEEDED,
    CREATE_PROJECT_USER_FAILED,
    REMOVE_PROJECT_USER_REQUEST,
    REMOVE_PROJECT_USER_SUCCEEDED,
    REMOVE_PROJECT_USER_FAILED,
    UPDATE_PROJECT_USER_SUCCEEDED,
    UPDATE_PROJECT_USER_REQUEST,
    UPDATE_PROJECT_USER_FAILED,
} from '../types/project.js'

const projectDetailsState = {
    form: null,
    formOpen: false,
    formData: undefined,
    name: '',
    type: '',
    users: [],
    assets: [],
    errorMessage: undefined,
    loading: false,
}

export const projectDetailsReducer = (state = projectDetailsState, action) => {
    switch (action.type) {
        case TOGGLE_USER_FORM_OPEN:
            return {
                ...state,
                form: action.form,
                errorMessage: undefined,
            }
        case TOGGLE_ASSET_FORM_OPEN:
            return {
                ...state,
                formOpen: !state.formOpen,
                // errorMessage: null,
            }
        case SET_ASSET_FORM_DATA:
            return {
                ...state,
                formData: action.data,
            }
        case ADD_ASSET_REQUEST:
        case UPDATE_ASSET_REQUEST:
        case DELETE_ASSET_REQUEST:
        case GET_PROJECT_DETAILS_REQUEST:
        case CREATE_PROJECT_USER_REQUEST:
        case REMOVE_PROJECT_USER_REQUEST:
        case UPDATE_PROJECT_USER_REQUEST:
            return {
                ...state,
                loading: true,
                // errorMessage: undefined,
            }
        case GET_PROJECTS_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
            }
        case GET_PROJECT_DETAILS_SUCCEEDED:
            const { data } = action
            for (let i = 0; i < data.assets.length; i++) {
                // colourArray[Math.floor(Math.random()*colourArray.length)]
                data.assets[i].colour = 'red'
            }
            return {
                ...state,
                name: data.name,
                users: data.users,
                assets: data.assets,
                type: data.project_type,
                // errorMessage: false,
                loading: false,
            }
        case ADD_ASSET_SUCCEEDED:
        case UPDATE_ASSET_SUCCEEDED:
            return {
                ...state,
                assets: action.data.assets,
                errorMessage: false,
                loading: false,
            }
        case DELETE_ASSET_SUCCEEDED:
            return {
                ...state,
                assets: state.assets.filter(({ id }) => action.id !== id),
                errorMessage: false,
                loading: false,
            }
        case CREATE_PROJECT_USER_SUCCEEDED:
        case UPDATE_PROJECT_USER_SUCCEEDED:
            return {
                ...state,
                users: [...state.users, action.data],
                errorMessage: false,
                loading: false,
            }
        case REMOVE_PROJECT_USER_SUCCEEDED:
            return {
                ...state,
                users: state.users.filter(({ id }) => action.id !== id),
                errorMessage: false,
                loading: false,
            }
        case ADD_ASSET_FAILED:
        case UPDATE_ASSET_FAILED:
        case DELETE_ASSET_FAILED:
        case GET_PROJECT_DETAILS_FAILED:
        case CREATE_PROJECT_USER_FAILED:
        case REMOVE_PROJECT_USER_FAILED:
        case UPDATE_PROJECT_USER_FAILED:
            return {
                ...state,
                errorMessage: action.errorMessage,
                loading: false,
            }
        default:
            return state
    }
}
