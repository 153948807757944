import React from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import LinearProgressWithLabel from '../components/Common/LinearProgressWithLabel'
import { statusColorScheme, assetHealthIndexColorMap } from './color_palette.js'
import { dateFormatter } from '../utils/utils'
import { severityScore } from './constants.js'
import { colorDot, colorTriangle } from '../utils/utils'

export const projectsTableColumns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'assets_count', headerName: 'Assets', flex: 1 },
    {
        field: 'owner',
        headerName: 'Owner',
        valueGetter: (params) => params.row?.company?.name,
        flex: 1,
    },
    { field: 'location', headerName: 'Location', flex: 2 },
    {
        field: 'created_at',
        headerName: 'Created',
        flex: 1,
        valueFormatter: (params) => dateFormatter(params.value),
        type: 'dateTime',
    },
    {
        field: 'updated_at',
        headerName: 'Updated',
        flex: 1,
        valueFormatter: (params) => dateFormatter(params.value),
        type: 'dateTime',
    },
]

export const assetsTableColumns = [
    { headerName: 'Name', field: 'name', flex: 1 },
    {
        headerName: 'Status',
        field: 'asset_status',
        flex: 1,
        renderCell: ({ value }) => {
            return (
                <>
                    <div
                        style={{
                            display: 'inline-block',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '8px',
                            backgroundColor: statusColorScheme[value].color,
                        }}
                    />
                    <span style={{ color: statusColorScheme[value].color }}>
                        <strong>{value}</strong>
                    </span>
                </>
            )
        },
    },
    {
        headerName: 'Health index',
        field: 'health_index',
        flex: 1,
        renderCell: ({ value }) => {
            if (typeof value === 'number') {
                let health = ''
                if (value > 66) {
                    health = 'good'
                } else if (value > 33) {
                    health = 'fair'
                } else {
                    health = 'low'
                }
                return (
                    <span style={{ color: assetHealthIndexColorMap[health].color }}>
                        <strong>{assetHealthIndexColorMap[health].label}</strong>
                    </span>
                )
            }
            return <span>-</span>
        },
    },
    { headerName: 'Location', field: 'location', flex: 2 },
    {
        headerName: 'Created',
        field: 'created_at',
        flex: 1,
        valueFormatter: (params) => dateFormatter(params.value),
        type: 'dateTime',
    },
    {
        headerName: 'Updated',
        field: 'updated_at',
        flex: 1,
        valueFormatter: (params) => dateFormatter(params.value),
        type: 'dateTime',
    },
]

export const validationsTableColumns = [
    { headerName: 'Name', field: 'name', flex: 1 },
    { headerName: 'Location', field: 'location', flex: 2 },
    {
        headerName: 'Progress',
        field: 'validation_progress',
        flex: 2,
        renderCell: ({ value }) => {
            return (
                <div style={{ width: '100%' }}>
                    <LinearProgressWithLabel value={value} />
                </div>
            )
        },
        sortable: false,
    },
    {
        headerName: 'Updated',
        field: 'updated_at',
        flex: 1,
        valueFormatter: (params) => dateFormatter(params.value),
        type: 'dateTime',
    },
]

export const validationDamagesColumns = [
    { headerName: '#', field: 'id', flex: 1 },
    {
        headerName: 'Sev.',
        field: 'severity_score',
        flex: 1,
        renderCell: ({ value }) => {
            const severityItem = severityScore.find(
                (item) => item.value === value
            )
            return (
                <Typography variant="caption">
                    {colorTriangle(severityItem?.color)}
                    {" "}
                    {severityItem?.label}
                </Typography>
            )
        },
    },
    {
        headerName: 'Label',
        field: 'labels',
        flex: 1,
        renderCell: ({ value: labels }) =>
            labels.map((label) => (
                <Tooltip key={label.colour} title={label.name}>
                    {colorDot(label.colour)}
                </Tooltip>
            )),
        sortable: false,
    },
    {
        headerName: 'Size (m^2)',
        renderHeader: (params) => (<span>Size m<sup>2</sup></span>),
        field: 'damaged_area',
        flex: 1,
        valueGetter: ({row}) => row.damaged_area,
        valueFormatter: ({value}) => value.toFixed(2)
    },
]
