// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import BarChart from '../../Common/Vis/BarChart'

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        height: '100px',
        [theme.breakpoints.up('md')]: {
            height: '200px',
        },
    },
}))

const SeverityChart = ({ data = [], dmgClicked, chartType }): JSX.Element => {
    const classes = useStyles()
    const [severityCount, setSeverityCount] = useState([])
    const [keys, setKeys] = useState([])

    useEffect(() => {
        if (
            data &&
            dmgClicked &&
            Object.keys(data).length > 0 &&
            data['All Components'][dmgClicked]
        ) {
            const data_keys = Object.keys(data)
            const index = data_keys.indexOf('All Components')
            if (index > -1) {
                data_keys.splice(index, 1)
            }

            const res = data_keys.map((key) => {
                return {
                    id: key,
                    'A (Good)': data[key][dmgClicked].severity_score_count[0]
                        ? data[key][dmgClicked].severity_score_count[0]
                        : 0,
                    'B (Low)': data[key][dmgClicked].severity_score_count[1]
                        ? data[key][dmgClicked].severity_score_count[1]
                        : 0,
                    'C (Medium)': data[key][dmgClicked].severity_score_count[2]
                        ? data[key][dmgClicked].severity_score_count[2]
                        : 0,
                    'D (High)': data[key][dmgClicked].severity_score_count[3]
                        ? data[key][dmgClicked].severity_score_count[3]
                        : 0,
                    'E (Very high)': data[key][dmgClicked]
                        .severity_score_count[4]
                        ? data[key][dmgClicked].severity_score_count[4]
                        : 0,
                }
            })
            setSeverityCount(res)
            setKeys(Object.keys(res[0]).filter((i) => i !== 'id'))
        }
    }, [data, dmgClicked])

    return (
        <Box display="flex" flexDirection="column" p={2}>
            <Box>
                <Typography variant="h5" gutterBottom align="center">
                    Asset Health: {dmgClicked}
                </Typography>
            </Box>
            <Box flexGrow={1} className={classes.chartContainer}>
                {severityCount.length > 0 ? (
                    <BarChart
                        data={severityCount}
                        keys={keys}
                        chartType={chartType}
                    />
                ) : (
                    <p>No data available</p>
                )}
            </Box>
        </Box>
    )
}

export default SeverityChart
