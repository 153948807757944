// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { NavLink as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    activeLink: {
        borderRight: `6px solid ${theme.palette.primary.main}`,
        boxSizing: 'border-box',
    },
}))

// eslint-disable-next-line
const ListItemLink = (props): JSX.Element => {
    const { icon, primary, to } = props
    const classes = useStyles()

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} /> // eslint-disable-line
            )),
        [to]
    )

    return (
        <ListItem
            button
            component={renderLink}
            activeClassName={classes.activeLink}
        >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>
    )
}

export default ListItemLink
