import { call, put, takeLatest } from 'redux-saga/effects'

import { AccordionActions } from '@material-ui/core'
import {
    GET_ASSET_DETAILS_REQUEST,
    GET_ASSET_DETAILS_SUCCEEDED,
    GET_ASSET_DETAILS_FAILED,
    GET_DOWNLOAD_FILE_REQUEST,
    GET_DOWNLOAD_FILE_SUCCEEDED,
    GET_DOWNLOAD_FILE_FAILED,
    GET_DELETE_FILE_REQUEST,
    GET_DELETE_FILE_SUCCEEDED,
    GET_DELETE_FILE_FAILED,
    GET_UPLOAD_FILE_REQUEST,
    GET_UPLOAD_FILE_SUCCEEDED,
    GET_UPLOAD_FILE_FAILED,
    GET_UPLOAD_DATA_REQUEST,
    GET_UPLOAD_DATA_SUCCEEDED,
    GET_UPLOAD_DATA_FAILED,
    GET_DELETE_DATA_REQUEST,
    GET_DELETE_DATA_SUCCEEDED,
    GET_DELETE_DATA_FAILED,
    GET_FOLDER_DATA_REQUEST,
    GET_FOLDER_DATA_SUCCEEDED,
    GET_FOLDER_DATA_FAILED,
    GET_ADD_FOLDER_REQUEST,
    GET_ADD_FOLDER_SUCCEEDED,
    GET_ADD_FOLDER_FAILED,
    GET_DELETE_FOLDER_REQUEST,
    GET_DELETE_FOLDER_SUCCEEDED,
    GET_DELETE_FOLDER_FAILED,
    GET_UPDATE_FOLDER_REQUEST,
    GET_UPDATE_FOLDER_SUCCEEDED,
    GET_UPDATE_FOLDER_FAILED,
    GET_COORDINATE_DATA_REQUEST,
    GET_COORDINATE_DATA_SUCCEEDED,
    GET_COORDINATE_DATA_FAILED,
    GET_USER_ASSETS_REQUEST,
    GET_USER_ASSETS_SUCCEEDED,
    GET_USER_ASSETS_FAILED,
    GET_FOLDER_PATHS_REQUEST,
    GET_FOLDER_PATHS_SUCCEEDED,
    GET_FOLDER_PATHS_FAILED,
    GET_MOVE_FOLDER_REQUEST,
    GET_MOVE_FOLDER_SUCCEEDED,
    GET_MOVE_FOLDER_FAILED,
    GET_MOVE_DATA_REQUEST,
    GET_MOVE_DATA_SUCCEEDED,
    GET_MOVE_DATA_FAILED,
} from '../actions/asset.js'
import {
    fetchAssetDetailsAPI,
    fetchDownloadFileAPI,
    fetchFolderDataAPI,
    fetchCreateFolderAPI,
    fetchDeleteFolderAPI,
    fetchUpdateFolderAPI,
    fetchUploadFileAPI,
    fetchDeleteFileAPI,
    fetchUploadDataAPI,
    fetchDeleteDataAPI,
    fetchCoordinateDataAPI,
    fetchUserAssetsAPI,
    getFolderPathsAPI,
    getMoveFolderAPI,
    getMoveDataAPI,
} from '../../config/api.js'

function* getAssetDetails(action) {
    try {
        const asset_data = yield call(
            fetchAssetDetailsAPI,
            action.token,
            action.id
            )
        yield put({
            type: GET_ASSET_DETAILS_SUCCEEDED,
            asset_detail_data: asset_data,
        })
    } catch (e) {
        console.log('Error e ', e)
        yield put({
            type: GET_ASSET_DETAILS_FAILED,
            status: e.message, // .response.status can't use response as internal js error don't have response
        })
    }
}

function* getDownloadFile(action) {
    try {
        const file_data = yield call(
            fetchDownloadFileAPI,
            action.token,
            action.file_type,
            action.id
        )
        yield put({
            type: GET_DOWNLOAD_FILE_SUCCEEDED,
            file_data,
            file_name: action.name,
        })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_DOWNLOAD_FILE_FAILED, message: e.message })
    }
}

function* uploadFile(action) {
    try {
        const file_data = yield call(
            fetchUploadFileAPI,
            action.token,
            action.asset_id,
            action.file_type,
            action.file_data
        )
        yield put({ type: GET_UPLOAD_FILE_SUCCEEDED, file_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_UPLOAD_FILE_FAILED, message: e.message })
    }
}

function* deleteFile(action) {
    try {
        const file_data = yield call(
            fetchDeleteFileAPI,
            action.token,
            action.file_type,
            action.id
        )
        yield put({ type: GET_DELETE_FILE_SUCCEEDED, file_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_DELETE_FILE_FAILED, message: e.message })
    }
}

function* uploadData(action) {
    try {
        const file_data = yield call(
            fetchUploadDataAPI,
            action.token,
            action.asset_id,
            action.folder_id,
            action.files,
            action.file_type
        )
        yield put({ type: GET_UPLOAD_DATA_SUCCEEDED, file_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_UPLOAD_DATA_FAILED, message: e.message })
    }
}

function* deleteData(action) {
    try {
        const file_data = yield call(
            fetchDeleteDataAPI,
            action.token,
            action.data_id
        )
        yield put({ type: GET_DELETE_DATA_SUCCEEDED, file_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_DELETE_DATA_FAILED, message: e.message })
    }
}

function* getFolderData(action) {
    try {
        const folder_data = yield call(
            fetchFolderDataAPI,
            action.token,
            action.asset_id,
            action.params
        )
        yield put({ type: GET_FOLDER_DATA_SUCCEEDED, folder_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_FOLDER_DATA_FAILED, message: e.message })
    }
}

function* createFolder(action) {
    try {
        const folder_data = yield call(
            fetchCreateFolderAPI,
            action.token,
            action.asset_id,
            action.name,
            action.parent
        )
        yield put({ type: GET_ADD_FOLDER_SUCCEEDED, folder_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_ADD_FOLDER_FAILED, message: e.message })
    }
}

function* deleteFolder(action) {
    try {
        const folder_data = yield call(
            fetchDeleteFolderAPI,
            action.token,
            action.folder_id
        )
        yield put({
            type: GET_DELETE_FOLDER_SUCCEEDED,
            folder_data,
        })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_DELETE_FOLDER_FAILED, message: e.message })
    }
}

function* updateFolder(action) {
    try {
        const folder_data = yield call(
            fetchUpdateFolderAPI,
            action.token,
            action.folder_id,
            action.name
        )
        yield put({
            type: GET_UPDATE_FOLDER_SUCCEEDED,
            folder_data,
        })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_UPDATE_FOLDER_FAILED, message: e.message })
    }
}

function* getCoordinateData(action) {
    try {
        const data = yield call(
            fetchCoordinateDataAPI,
            action.token,
            action.asset_id,
            action.limit,
            action.severity_filter,
            action.data_id
        )
        yield put({
            type: GET_COORDINATE_DATA_SUCCEEDED,
            coordinate_data: data,
        })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_COORDINATE_DATA_FAILED, message: e.message })
    }
}

function* getUserAssets(action) {
    try {
        const user_assets = yield call(
            fetchUserAssetsAPI,
            action.token,
            action.params
        )
        yield put({ type: GET_USER_ASSETS_SUCCEEDED, user_assets })
    } catch (e) {
        yield put({ type: GET_USER_ASSETS_FAILED, message: e.message })
    }
}

function* getFolderPaths(action) {
    try {
        const folder_paths = yield call(
            getFolderPathsAPI,
            action.token,
            action.asset_id
        )
        yield put({
            type: GET_FOLDER_PATHS_SUCCEEDED,
            folder_paths: folder_paths.data,
        })
    } catch (e) {
        yield put({ type: GET_FOLDER_PATHS_FAILED, message: e.message })
    }
}

function* moveFolder(action) {
    try {
        const move_folder = yield call(
            getMoveFolderAPI,
            action.token,
            action.folder_id,
            action.parent_folder_id
        )
        yield put({
            type: GET_MOVE_FOLDER_SUCCEEDED,
            move_folder: move_folder.data,
        })
    } catch (e) {
        yield put({ type: GET_MOVE_FOLDER_FAILED, message: e.message })
    }
}

function* moveData(action) {
    try {
        const move_data = yield call(
            getMoveDataAPI,
            action.token,
            action.data_id,
            {
                folder_id: action.folder_id
            }
        )
        yield put({
            type: GET_MOVE_DATA_SUCCEEDED,
            move_data: move_data.data,
        })
    } catch (e) {
        yield put({ type: GET_MOVE_DATA_FAILED, message: e.message })
    }
}

export function* getAssetDetailsSaga() {
    yield takeLatest(GET_ASSET_DETAILS_REQUEST, getAssetDetails)
}

export function* getDownloadFileSaga() {
    yield takeLatest(GET_DOWNLOAD_FILE_REQUEST, getDownloadFile)
}

export function* uploadFileSaga() {
    yield takeLatest(GET_UPLOAD_FILE_REQUEST, uploadFile)
}

export function* deleteFileSaga() {
    yield takeLatest(GET_DELETE_FILE_REQUEST, deleteFile)
}

export function* getFolderDataSaga() {
    yield takeLatest(GET_FOLDER_DATA_REQUEST, getFolderData)
}

export function* createNewFolderSaga() {
    yield takeLatest(GET_ADD_FOLDER_REQUEST, createFolder)
}

export function* deleteFolderSaga() {
    yield takeLatest(GET_DELETE_FOLDER_REQUEST, deleteFolder)
}

export function* updateFolderSaga() {
    yield takeLatest(GET_UPDATE_FOLDER_REQUEST, updateFolder)
}

export function* uploadDataSaga() {
    yield takeLatest(GET_UPLOAD_DATA_REQUEST, uploadData)
}

export function* deleteDataSaga() {
    yield takeLatest(GET_DELETE_DATA_REQUEST, deleteData)
}

export function* getCoordinateDataSaga() {
    yield takeLatest(GET_COORDINATE_DATA_REQUEST, getCoordinateData)
}

export function* getUserAssetsSaga() {
    yield takeLatest(GET_USER_ASSETS_REQUEST, getUserAssets)
}

export function* getFolderPathsSaga() {
    yield takeLatest(GET_FOLDER_PATHS_REQUEST, getFolderPaths)
}

export function* moveFolderSaga() {
    yield takeLatest(GET_MOVE_FOLDER_REQUEST, moveFolder)
}

export function* moveDataSaga() {
    yield takeLatest(GET_MOVE_DATA_REQUEST, moveData)
}
