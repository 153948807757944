// @ts-nocheck
import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Spinner from './Common/Spinner'
import PointcloudSidebar from './PointcloudSidebar'
import { connectCSS } from '../utils/utils'
import { init } from './ThreeDModel/helper.js'
import './ThreeDModel/ThreeDModel.scss'

const Pointcloud = (): JSX.Element => {
    const {
        data: { three_d_model },
    } = useSelector((state) => state.asset_data)
    const head = document.getElementsByTagName('head')[0]
    const theme = useTheme()
    connectCSS('/libs/potree/potree_MODIFIED.css', head)
    connectCSS('/style/fontawesome-free-5.10.2-web/css/all.css', head)
    connectCSS('/libs/jquery-ui/jquery-ui.min.css', head)
    connectCSS('/libs/openlayers3/ol.css', head)
    connectCSS('/libs/spectrum/spectrum.css', head)
    connectCSS('/libs/jstree/themes/mixed/style.css', head)

    useEffect(() => {
        setTimeout(() => {
            init(three_d_model)
        }, 0)
        return () => {
            window.location.reload()
        }
      }, []); // eslint-disable-line
    return (
        <>
            {window.Potree ? (
                <>
                    <div className="three_d_section_full">
                        <div className="potree_container">
                            <div id="potree_render_area" />
                            <div
                                id="potree_sidebar_container"
                                style={{
                                    backgroundColor:
                                        theme.palette.background.default,
                                }}
                            >
                                <PointcloudSidebar />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default Pointcloud
