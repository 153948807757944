import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { CompactPicker } from "react-color";
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Slider from '@material-ui/core/Slider'
import { Can } from '@casl/react'

import { ABILITIES } from '../../config/ability.js'
import { getToken } from '../../services/authService.js'
import {
    toggleFormOpen,
    createSphere,
    deleteSphere,
    updateSphere,
} from '../../redux/actions/sphere.js'
import { getCoordinateData } from '../../redux/actions/asset.js'
import { getLabels } from '../../redux/actions/dashboard.js'
import DeleteModal from '../Common/Modal/DeleteModal'
import SphereForm from './SphereForm'
import Spinner from '../Common/Spinner'
import {
    checkAnnotation,
    drawLine,
    getMousePointCloudIntersection,
    get_images_from_3dpt,
    removeLines,
} from './helper'

const styles = (theme) => ({
    barTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    closeIcon: {
        color: theme.palette.primary.contrastText,
    },
    image: {
        width: '100%',
        height: 'auto',
    },
})

class SphereSideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // color: "#FFFFFF",
            images_showed: null,
            modalOpen: false,
            sphereSize: 1,
        }

        this.handleShow = this.handleShow.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        // this.handleColor = this.handleColor.bind(this);
        this.handleSize = this.handleSize.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    componentDidMount() {
        const token = getToken()
        this.props.getLabels(token)
        this.setState({ sphereSize: this.props.clickedSphere?.scale?.x })
    }

    componentDidUpdate(prevProps, prevState) {
        const { clickedSphere } = this.props
        if (clickedSphere && prevProps.clickedSphere !== clickedSphere)
            this.setState({
                sphereSize: this.props.clickedSphere?.scale?.x,
                images_showed: null,
            })
        if (prevState.clickedSphere && clickedSphere) {
            this.props.toggleFormOpen()
        }
    }

    handleCloseModal(event) {
        this.setState({ modalOpen: false })
    }

    handleSize(scale) {
        const { clickedSphere } = this.props
        clickedSphere.scale.x = scale
        clickedSphere.scale.y = scale
        clickedSphere.scale.z = scale
        this.setState({ sphereSize: scale })
    }

    handleRemove() {
        const { clickedSphere } = this.props
        const { images_showed } = this.state
        const lines_rem = [...new Set(images_showed.map((item) => item.name))]

        lines_rem.push(clickedSphere.userData.image_data.name)
        removeLines(lines_rem)

        if (clickedSphere.userData.sphere_backend) {
            const token = getToken()
            this.props.deleteSphere(
                token,
                clickedSphere.userData.sphere_backend.id
            )
        }

        window.viewer.scene.scene.remove(clickedSphere)
        const index = window.damage_points.indexOf(clickedSphere)
        if (index > -1) {
            window.damage_points.splice(index, 1)
        }
        this.setState({ modalOpen: false })
        this.props.toggleFormOpen()
    }

    handleShow(e) {
        // TODO: change get_images_from_3dpt distortion method
        const { coordinateData, clickedSphere } = this.props
        const image_subset = []
        const clicked_pos = new window.THREE.Vector3(
            ...clickedSphere.position.toArray()
        )

        for (let i = 0; i < coordinateData.length; i++) {
            const coordinates = [
                coordinateData[i].X,
                coordinateData[i].Y,
                coordinateData[i].Z,
            ]
            const point_3d = new window.THREE.Vector3(...coordinates)

            const camera = window.viewer.scene.cameraP.clone()
            camera.position.set(clicked_pos.x, clicked_pos.y, clicked_pos.z)
            camera.lookAt(point_3d)
            camera.updateProjectionMatrix()

            const { renderer } = window.viewer
            const mouse = {
                x: renderer.domElement.clientWidth / 2,
                y: renderer.domElement.clientHeight / 2,
            }
            const point = getMousePointCloudIntersection(
                mouse,
                camera,
                window.viewer,
                window.viewer.scene.pointclouds
            )

            if (!point) {
                image_subset.push(coordinateData[i])
            }
        }

        const dataToShow = get_images_from_3dpt(
            clickedSphere.position.toArray(),
            image_subset
        )
        for (let i = 0; i < dataToShow.length; i++) {
            checkAnnotation(dataToShow[i].name)

            const origin = new window.THREE.Vector3(
                ...[dataToShow[i].X, dataToShow[i].Y, dataToShow[i].Z]
            )
            const line = drawLine(
                dataToShow[i],
                clicked_pos,
                origin,
                clickedSphere.userData.sphere_backend
            )
        }
        this.setState({
            images_showed: dataToShow,
        })
    }

    render() {
        const { modalOpen } = this.state
        const { clickedSphere, isDisable, role, classes } = this.props
        return (
            <>
                <DeleteModal
                    folderName="Damage"
                    open={modalOpen}
                    onDelete={this.handleRemove}
                    onClose={this.handleCloseModal}
                />
                {clickedSphere && (
                    <div className="sphereButton">
                        <Box
                            display="flex"
                            className={classes.barTitle}
                            p={1}
                            mb={1}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography>Sphere Tools</Typography>
                            <IconButton
                                className={classes.closeIcon}
                                aria-label="close"
                                onClick={this.props.toggleFormOpen}
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {clickedSphere.userData.sphere_backend && (
                            <Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="right"
                                    px={2}
                                >
                                    <Typography>
                                        Damage code: {clickedSphere.id}
                                    </Typography>
                                </Box>

                                {!this.props.damage_crop.loading ? (
                                    <img
                                        src={`data:image/png;base64,${this.props.damage_crop.img_data.crop.slice(
                                            1,
                                            -1
                                        )}`}
                                        alt="cropped image"
                                        onClick={this.props.toggleDialogShow}
                                        className={classes.image}
                                    />
                                ) : (
                                    <Spinner />
                                )}
                            </Box>
                        )}

                        <div className="divider">
                            <span>Tools</span>
                        </div>

                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            px={2}
                        >
                            <Typography id="tool-sphere-size-slider">
                                Size of sphere: {this.state.sphereSize}
                            </Typography>
                            <Slider
                                value={
                                    clickedSphere ? clickedSphere.scale.x : 1
                                }
                                aria-labelledby="tool-sphere-size-slider"
                                valueLabelDisplay="auto"
                                step={1}
                                min={1}
                                max={10}
                                onChange={(e, value) => this.handleSize(value)}
                            />
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Box my={1}>
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        startIcon={<VisibilityIcon />}
                                        onClick={this.handleShow}
                                        size="small"
                                    >
                                        Other views
                                    </Button>
                                    
                                </Box>
                                {this.state.images_showed && (
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="left"
                                            overflow="auto"
                                            px={2}
                                            width={1}
                                        >
                                            {this.state.images_showed.map(
                                                (key) => {
                                                    return (
                                                        <Typography width={1}>
                                                            {key.name}
                                                        </Typography>
                                                    )
                                                }
                                            )}
                                        </Box>
                                    )}
                                <Can
                                    I="perform"
                                    a="delete"
                                    ability={ABILITIES[role]}
                                >
                                    <Box mb={1}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DeleteOutlinedIcon />}
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    modalOpen: true,
                                                })
                                            }
                                            disabled={isDisable}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Can>
                            </Box>
                        </Box>

                        <div className="divider">
                            <span>Properties</span>
                        </div>

                        <SphereForm clickedSphere={clickedSphere} />
                    </div>
                )}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            deleteSphere,
            createSphere,
            updateSphere,
            getCoordinateData,
            toggleFormOpen,
            getLabels,
        },
        dispatch
    )
}

const mapStateToProps = ({
    sphere_data,
    asset_data: { data },
    role: { role },
    labels_data,
    damage_crop,
}) => ({
    sphere_data,
    asset_data: data,
    role,
    labels_data,
    damage_crop,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SphereSideBar))
