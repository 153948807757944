export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST'
export const GET_REPORTS_SUCCEEDED = 'GET_REPORTS_SUCCEEDED'
export const GET_REPORTS_FAILED = 'GET_REPORTS_FAILED'

export const getReports = (token, asset_id, params) => {
    return {
        type: GET_REPORTS_REQUEST,
        token,
        asset_id,
        params,
    }
}

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST'
export const GET_REPORT_SUCCEEDED = 'GET_REPORT_SUCCEEDED'
export const GET_REPORT_FAILED = 'GET_REPORT_FAILED'

export const getReport = (token, report_id) => {
    return {
        type: GET_REPORT_REQUEST,
        token,
        report_id,
    }
}

export const GET_DOWNLOAD_REPORT_REQUEST = 'GET_DOWNLOAD_REPORT_REQUEST'
export const GET_DOWNLOAD_REPORT_SUCCEEDED = 'GET_DOWNLOAD_REPORT_SUCCEEDED'
export const GET_DOWNLOAD_REPORT_FAILED = 'GET_DOWNLOAD_REPORT_FAILED'

export const downloadReport = (token, report_id, name) => {
    return {
        type: GET_DOWNLOAD_REPORT_REQUEST,
        token,
        report_id,
        name,
    }
}

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST'
export const DELETE_REPORT_SUCCEEDED = 'DELETE_REPORT_SUCCEEDEDD'
export const DELETE_REPORT_FAILED = 'DELETE_REPORT_FAILED'

export const deleteReport = (token, report_id) => {
    return {
        type: DELETE_REPORT_REQUEST,
        token,
        report_id,
    }
}

export const deleteReportSuccess = (id) => ({
    type: DELETE_REPORT_SUCCEEDED,
    id,
})

export const GET_CREATE_REPORT_REQUEST = 'GET_CREATE_REPORT_REQUEST'
export const GET_CREATE_REPORT_SUCCEEDED = 'GET_CREATE_REPORT_SUCCEEDED'
export const GET_CREATE_REPORT_FAILED = 'GET_CREATE_REPORT_FAILED'

export const createReport = (token, asset_id) => {
    return {
        type: GET_CREATE_REPORT_REQUEST,
        token,
        asset_id,
    }
}

export const GET_USER_REPORTS_REQUEST = 'GET_USER_REPORTS_REQUEST'
export const GET_USER_REPORTS_SUCCEEDED = 'GET_USER_REPORTS_SUCCEEDED'
export const GET_USER_REPORTS_FAILED = 'GET_USER_REPORTS_FAILED'

export const getUserReports = (token, params) => ({
    type: GET_USER_REPORTS_REQUEST,
    token,
    params,
})

export const GET_UPLOAD_REPORT_REQUEST = 'GET_UPLOAD_REPORT_REQUEST'
export const GET_UPLOAD_REPORT_SUCCEEDED = 'GET_UPLOAD_REPORT_SUCCEEDED'
export const GET_UPLOAD_REPORT_FAILED = 'GET_UPLOAD_REPORT_FAILED'

export const uploadReport = (token, asset_id, files) => {
    return {
        type: GET_UPLOAD_REPORT_REQUEST,
        token,
        asset_id,
        files,
    }
}
