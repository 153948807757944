import { put, takeLatest, call } from 'redux-saga/effects'
import { fetchImageAPI } from '../../config/api.js'
import {
    GET_IMAGE_REQUEST,
    GET_IMAGE_SUCCEEDED,
    GET_IMAGE_FAILED,
} from '../actions/data.js'

function* getImage(action) {
    try {
        const image_data = yield call(
            fetchImageAPI,
            action.token,
            action.id,
            {
                blend: action.mask,
                damage_type: action.damage_type,
                severity_type: action.severity_type,
                blend_level: action.blendLevel
            }
        )
        yield put({ type: GET_IMAGE_SUCCEEDED, image_data })
    } catch (e) {
        yield put({ type: GET_IMAGE_FAILED, message: e.message })
    }
}

export function* getImageSaga() {
    yield takeLatest(GET_IMAGE_REQUEST, getImage)
}
