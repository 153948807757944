// @ts-nocheck
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import Switch from '@material-ui/core/Switch'
import { writeStorage } from '@rehooks/local-storage'

import {
    changePassword,
    togglePasswordAlertOpen,
} from '../redux/actions/users.js'
import { toggleDarkMode } from '../redux/actions/system.js'
import { getToken } from '../services/authService.js'
import Spinner from '../components/Common/Spinner'
import { token_name_theme } from '../config/constants.js'

const useStyles = makeStyles({
    avatar: {
        height: '160px',
        width: '160px',
        fontSize: '5rem',
    },
    form: {
        width: '100%',
    },
})

const SettingsPage = (): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
        user: { username, email },
    } = useSelector((state) => state.user_data)
    const isDarkMode = useSelector((state) => state.system.darkMode)
    const {
        loading,
        status: { success, message },
        alertOpen,
    } = useSelector((state) => state.password_data)
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [error, setError] = useState('')

    const passwordProps = {
        lists: [
            {
                type: 'password',
                label: 'Current Password',
                placeholder: `Enter Current Password`,
                value: formData.currentPassword,
                name: 'currentPassword',
            },
            {
                type: 'password',
                label: 'New Password',
                placeholder: `Enter New Password`,
                value: formData.newPassword,
                name: 'newPassword',
            },
            {
                type: 'password',
                label: 'Confirm Password',
                placeholder: `Re-type New Password`,
                value: formData.confirmNewPassword,
                name: 'confirmNewPassword',
                error: !!error,
                helperText: error,
            },
        ],
        button: { text: 'Save Changes' },
        resetButton: true,
    }

    const handleSubmit = (event): void => {
        event.preventDefault()
        if (formData.newPassword !== formData.confirmNewPassword) {
            setError('Password does not match')
        } else {
            setError('')
            const token = getToken()
            dispatch(
                changePassword(
                    token,
                    formData.currentPassword,
                    formData.newPassword
                )
            )
        }
    }

    return (
        <Box clone p={2}>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Avatar className={classes.avatar}>
                                {username?.charAt(0)}
                            </Avatar>
                            <Typography variant="h5">{username}</Typography>
                            <Typography color="textSecondary" variant="h5">
                                email: {email}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                    >
                        <Box>
                            <Typography variant="h5">Password</Typography>
                            <form
                                className={classes.form}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                {passwordProps.lists.map(
                                    ({
                                        type,
                                        label,
                                        placeholder,
                                        value,
                                        name,
                                    error,  // eslint-disable-line
                                        helperText,
                                    }) => (
                                        <TextField
                                            key={name}
                                            error={error}
                                            helperText={helperText}
                                            id={`seetings-${label}`}
                                            label={label}
                                            placeholder={placeholder}
                                            type={type}
                                            value={value}
                                            name={name}
                                            onChange={(event) => {
                                                setFormData((props) => ({
                                                    ...props,
                                                    [name]: event.target.value,
                                                }))
                                            }}
                                            fullWidth
                                        />
                                    )
                                )}
                                {alertOpen && (
                                    <Alert
                                        severity={success ? 'success' : 'error'}
                                        onClose={() => {
                                            dispatch(togglePasswordAlertOpen())
                                        }}
                                    >
                                        {message}
                                    </Alert>
                                )}
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    p={2}
                                >
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {passwordProps.button.text}
                                        </Button>
                                    )}
                                </Box>
                            </form>
                        </Box>
                        <Box display="flex">
                            <Typography variant="h5">Dark mode</Typography>
                            <Switch
                                checked={isDarkMode}
                                onChange={(event) => {
                                    dispatch(
                                        toggleDarkMode(event.target.checked)
                                    )
                                    writeStorage(token_name_theme, {
                                        darkMode: event.target.checked,
                                    })
                                }}
                                name="darkMode"
                                inputProps={{
                                    'aria-label': 'dark mode',
                                }}
                                color="primary"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default SettingsPage
