import {
    GET_USERS_REQUEST,
    GET_USER_DETAILS_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    TOGGLE_PASSWORD_ALERT_OPEN,
} from '../types/users.js'

export const getUsers = (token) => ({
    type: GET_USERS_REQUEST,
    token,
})

export const getUserDetails = (token) => ({
    type: GET_USER_DETAILS_REQUEST,
    token,
})

export const changePassword = (token, current_password, new_password) => ({
    type: CHANGE_PASSWORD_REQUEST,
    token,
    current_password,
    new_password,
})

export const togglePasswordAlertOpen = () => ({
    type: TOGGLE_PASSWORD_ALERT_OPEN,
})
