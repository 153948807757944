export const GET_ASSET_DETAILS_REQUEST = 'GET_ASSET_DETAILS_REQUEST'
export const GET_ASSET_DETAILS_SUCCEEDED = 'GET_ASSET_DETAILS_SUCCEEDED'
export const GET_ASSET_DETAILS_FAILED = 'GET_ASSET_DETAILS_FAILED'

export const getAssetDetails = (token, id) => {
    return {
        type: GET_ASSET_DETAILS_REQUEST,
        token,
        id,
    }
}

export const GET_DOWNLOAD_FILE_REQUEST = 'GET_DOWNLOAD_FILE_REQUEST'
export const GET_DOWNLOAD_FILE_SUCCEEDED = 'GET_DOWNLOAD_FILE_SUCCEEDED'
export const GET_DOWNLOAD_FILE_FAILED = 'GET_DOWNLOAD_FILE_FAILED'

export const downloadFile = (token, file_type, id, name) => {
    return {
        type: GET_DOWNLOAD_FILE_REQUEST,
        token,
        file_type,
        id,
        name,
    }
}

export const GET_UPLOAD_FILE_REQUEST = 'GET_UPLOAD_FILE_REQUEST'
export const GET_UPLOAD_FILE_SUCCEEDED = 'GET_UPLOAD_FILE_SUCCEEDED'
export const GET_UPLOAD_FILE_FAILED = 'GET_UPLOAD_FILE_FAILED'

export const uploadFile = (token, asset_id, file_type, file_data) => {
    return {
        type: GET_UPLOAD_FILE_REQUEST,
        token,
        file_type,
        file_data,
        asset_id,
    }
}

export const GET_DELETE_FILE_REQUEST = 'GET_DELETE_FILE_REQUEST'
export const GET_DELETE_FILE_SUCCEEDED = 'GET_DELETE_FILE_SUCCEEDED'
export const GET_DELETE_FILE_FAILED = 'GET_DELETE_FILE_FAILED'

export const deleteFile = (token, file_type, id) => {
    return {
        type: GET_DELETE_FILE_REQUEST,
        token,
        file_type,
        id,
    }
}

export const GET_ADD_FOLDER_REQUEST = 'GET_ADD_FOLDER_REQUEST'
export const GET_ADD_FOLDER_SUCCEEDED = 'GET_ADD_FOLDER_SUCCEEDED'
export const GET_ADD_FOLDER_FAILED = 'GET_ADD_FOLDER_FAILED'

export const addFolder = (token, asset_id, name, parent) => {
    return {
        type: GET_ADD_FOLDER_REQUEST,
        token,
        asset_id,
        name,
        parent,
    }
}

export const GET_DELETE_FOLDER_REQUEST = 'GET_DELETE_FOLDER_REQUEST'
export const GET_DELETE_FOLDER_SUCCEEDED = 'GET_DELETE_FOLDER_SUCCEEDED'
export const GET_DELETE_FOLDER_FAILED = 'GET_DELETE_FOLDER_FAILED'

export const deleteFolder = (token, folder_id) => {
    console.log('Delete folder ', token, folder_id)
    return {
        type: GET_DELETE_FOLDER_REQUEST,
        token,
        folder_id,
    }
}

export const GET_UPDATE_FOLDER_REQUEST = 'GET_UPDATE_FOLDER_REQUEST'
export const GET_UPDATE_FOLDER_SUCCEEDED = 'GET_UPDATE_FOLDER_SUCCEEDED'
export const GET_UPDATE_FOLDER_FAILED = 'GET_UPDATE_FOLDER_FAILED'

export const updateFolder = (token, folder_id, name) => {
    return {
        type: GET_UPDATE_FOLDER_REQUEST,
        token,
        folder_id,
        name,
    }
}

export const GET_UPLOAD_DATA_REQUEST = 'GET_UPLOAD_DATA_REQUEST'
export const GET_UPLOAD_DATA_SUCCEEDED = 'GET_UPLOAD_DATA_SUCCEEDED'
export const GET_UPLOAD_DATA_FAILED = 'GET_UPLOAD_DATA_FAILED'

export const uploadData = (token, asset_id, folder_id, files, file_type) => {
    return {
        type: GET_UPLOAD_DATA_REQUEST,
        token,
        files,
        folder_id,
        asset_id,
        file_type,
    }
}

export const GET_DELETE_DATA_REQUEST = 'GET_DELETE_DATA_REQUEST'
export const GET_DELETE_DATA_SUCCEEDED = 'GET_DELETE_DATA_SUCCEEDED'
export const GET_DELETE_DATA_FAILED = 'GET_DELETE_DATA_FAILED'

export const deleteData = (token, data_id) => {
    return {
        type: GET_DELETE_DATA_REQUEST,
        token,
        data_id,
    }
}

export const GET_FOLDER_DATA_REQUEST = 'GET_FOLDER_DATA_REQUEST'
export const GET_FOLDER_DATA_SUCCEEDED = 'GET_FOLDER_DATA_SUCCEEDED'
export const GET_FOLDER_DATA_FAILED = 'GET_FOLDER_DATA_FAILED'

export const getFolderData = (token, asset_id, params) => ({
    type: GET_FOLDER_DATA_REQUEST,
    token,
    asset_id,
    params,
})

export const GET_COORDINATE_DATA_REQUEST = 'GET_COORDINATE_DATA_REQUEST'
export const GET_COORDINATE_DATA_SUCCEEDED = 'GET_COORDINATE_DATA_SUCCEEDED'
export const GET_COORDINATE_DATA_FAILED = 'GET_COORDINATE_DATA_FAILED'

export const getCoordinateData = (
    token,
    asset_id,
    limit = null,
    severity_filter = null,
    data_id = null
) => {
    return {
        type: GET_COORDINATE_DATA_REQUEST,
        token,
        asset_id,
        limit,
        severity_filter,
        data_id,
    }
}

export const GET_USER_ASSETS_REQUEST = 'GET_USER_ASSETS_REQUEST'
export const GET_USER_ASSETS_SUCCEEDED = 'GET_USER_ASSETS_SUCCEEDED'
export const GET_USER_ASSETS_FAILED = 'GET_USER_ASSETS_FAILED'

export const getUserAssets = (token, params) => ({
    type: GET_USER_ASSETS_REQUEST,
    token,
    params,
})

export const GET_FOLDER_PATHS_REQUEST = 'GET_FOLDER_PATHS_REQUEST'
export const GET_FOLDER_PATHS_SUCCEEDED = 'GET_FOLDER_PATHS_SUCCEEDED'
export const GET_FOLDER_PATHS_FAILED = 'GET_FOLDER_PATHS_FAILED'

export const getFolderPaths = (token, asset_id) => {
    return {
        type: GET_FOLDER_PATHS_REQUEST,
        token,
        asset_id,
    }
}

export const GET_MOVE_DATA_REQUEST = 'GET_MOVE_DATA_REQUEST'
export const GET_MOVE_DATA_SUCCEEDED = 'GET_MOVE_DATA_SUCCEEDED'
export const GET_MOVE_DATA_FAILED = 'GET_MOVE_DATA_FAILED'

export const moveData = (token, data_id, folder_id) => {
    return {
        type: GET_MOVE_DATA_REQUEST,
        token,
        data_id,
        folder_id,
    }
}

export const GET_MOVE_FOLDER_REQUEST = 'GET_MOVE_FOLDER_REQUEST'
export const GET_MOVE_FOLDER_SUCCEEDED = 'GET_MOVE_FOLDER_SUCCEEDED'
export const GET_MOVE_FOLDER_FAILED = 'GET_MOVE_FOLDER_FAILED'

export const moveFolder = (token, folder_id, parent_folder_id) => {
    return {
        type: GET_MOVE_FOLDER_REQUEST,
        token,
        folder_id,
        parent_folder_id,
    }
}
