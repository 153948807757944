// @ts-nocheck
import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
// import WebAssetIcon from '@material-ui/icons/WebAsset'
// import AssessmentIcon from '@material-ui/icons/Assessment'
// import WarningIcon from '@material-ui/icons/Warning'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ReportsIcon from '../assets/overview/reports.svg'
import ReportsIconDark from '../assets/overview/reports_dark.svg'
import DamagesIcon from '../assets/overview/damages.svg'
import AssetsIcon from '../assets/overview/assets.svg'
import AssetsIconDark from '../assets/overview/assets_dark.svg'
import StatusIndicator from './Common/StatusIndicator.jsx'
import { stringToColor } from '../utils/utils'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

// eslint-disable-next-line
const ProjectsOverview = () => {
    const theme = useTheme()
    const classes = useStyles()
    const userData = useSelector((state) => state.user_data)
    const isDarkMode = useSelector((state) => state.system.darkMode)
    const { assets, damages, reports } = useSelector(
        (state) => state.dashboard_data
    )
    const overviewLists = [
        {
            image: isDarkMode ? AssetsIconDark : AssetsIcon,
            amount: assets,
            text: 'Assets processed',
            to: '/assets',
        },
        {
            image: isDarkMode ? ReportsIconDark : ReportsIcon,
            amount: reports,
            text: 'Reports uploaded',
            to: '/reports',
        },
        {
            image: DamagesIcon,
            amount: damages,
            text: 'Damages found',
            to: '/validations',
        },
    ]
    const backgroundColor = stringToColor(userData?.user?.username)
    const textColor = theme.palette.getContrastText(backgroundColor)

    return (
        <Box clone p={2}>
            <Paper>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={4}
                        direction="row"
                        alignItems="center"
                    >
                        <Box px={2} display="flex" alignItems="center">
                            <Avatar
                                alt="user avatar"
                                className={classes.large}
                                style={{
                                    backgroundColor,
                                    color: textColor,
                                }}
                            >
                                {userData?.user?.username
                                    ?.charAt(0)
                                    ?.toUpperCase()}
                            </Avatar>
                            <Typography component="div">
                                <Box fontSize="h5.fontSize" m={1}>
                                    Welcome {userData?.user?.username}
                                </Box>
                                <Box m={1}>Here is your dashboard</Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} lg={8} spacing={3}>
                        {overviewLists.map((props) => (
                            <Grid item xs={12} md={4} key={props.text}>
                                <StatusIndicator
                                    danger={props.text.includes('Damages')}
                                    amount={props.amount}
                                    text={props.text}
                                    image={props.image}
                                    to={props.to}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default ProjectsOverview
