// @ts-nocheck
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Asset from '../components/Asset/Asset'
import { getToken } from '../services/authService.js'
import { external_script_id } from '../config/constants.js'
import { connectScripts } from '../utils/utils'
import { getAssetDetails } from '../redux/actions/asset.js'

const AssetInfoPage = ({ match: { params } }): JSX.Element => {
    const dispatch = useDispatch()

    useEffect(() => {
        const token = getToken()
        dispatch(getAssetDetails(token, params.id))
  }, []); // eslint-disable-line

    useEffect(() => {
        if (window.i18n !== undefined) {
            window.i18n = undefined
        }
        const elem = document.createElement('span')
        elem.id = external_script_id

        connectScripts('/libs/jquery/jquery-3.1.1.min.js', elem)
        connectScripts('/libs/spectrum/spectrum.js', elem)
        connectScripts('/libs/jquery-ui/jquery-ui.min.js', elem)
        connectScripts('/libs/three.js/three.min.js', elem)
        connectScripts('/libs/i18next/i18next.js', elem)
        connectScripts('/libs/other/BinaryHeap.js', elem)
        connectScripts('/libs/tween/tween.min.js', elem)
        connectScripts('/libs/d3/d3.js', elem)
        connectScripts('/libs/proj4/proj4.js', elem)
        connectScripts('/libs/openlayers3/ol.js', elem)
        connectScripts('/libs/jstree/jstree.js', elem)
        connectScripts('/libs/potree/potree_MODIFIED.js', elem, 'potree_script')
        document.body.appendChild(elem)

        return () => {
            const element = document.getElementById(external_script_id)
            if (typeof element !== 'undefined' && element != null) {
                element.remove()
            }
        }
  }, []); // eslint-disable-line

    return (
        <>
            <Asset assetId={params.id} />
        </>
    )
}

export default withRouter(AssetInfoPage)
