import axios from 'axios'
import qs from 'qs'

require('dotenv').config()
const urljoin = require('url-join')

const portalURL = process.env.REACT_APP_PORTAL_API_URL
export const labelBaseUrl = process.env.REACT_APP_LABEL_API_URL
const labelURL = `${labelBaseUrl}api/v1/`
// const baseURL = "";
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";

export const adminUrl = 'http://127.0.0.1:8000/admin/'

// **
// *   Utils
// **

const parsePaginationParams = (params) => ({
    limit: params?.dataPerPage,
    offset:
        params?.currentPage === 1
            ? null
            : params?.dataPerPage * (params?.currentPage - 1),
    ordering:
        params?.sortColumn?.order === 'asc'
            ? params?.sortColumn?.column
            : '-'.concat(params?.sortColumn?.column),
})

/* LOGIN */

export function checkAuthentication(id) {
    return id // axios.get(baseURL + '/updateFrames?html=false&driver=' + id)
}

export function fetchLoginAPI(user_data) {
    return axios.post(`${portalURL}token-auth/`, user_data)
}

export function fetchLogoutAPI(user_data) {
    // return axios.post(baseURL + 'token-auth/', user_data); // maybe useful for statistics
}

export function fetchCheckTokenAPI(token) {
    return axios.post(
        `${portalURL}user_management/current_user/`,
        {},
        { headers: { Authorization: `Token ${token}` } }
    )
}

/* USER */

export function fetchCurrentUserGroups(token) {
    return axios.get(`${portalURL}user_management/current_user/groups/`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export function fetchUsersAPI(token) {
    return axios.get(`${labelURL}users`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export function fetchChangePasswordAPI(token, current_password, new_password) {
    return axios.post(
        urljoin(portalURL, 'user_management/change_password/'),
        { current_password, new_password },
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function fetchUserDetailsAPI(token) {
    return axios.post(
        urljoin(portalURL, 'user_management/details/'),
        {},
        { headers: { Authorization: `Token ${token}` } }
    )
}

/* COMPANIES */
export const fetchCompanyAPI = (token) =>
    axios.get(`${portalURL}project_management/companies/`, {
        headers: { Authorization: `Token ${token}` },
    })

/* PROJECTS */

export function fetchProjectsAPI(token, params) {
    const url = `${portalURL}project_management/projects/`
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params: parsePaginationParams(params),
    }
    return axios.get(url, config)
}

export function fetchProjectDetailsAPI(token, id) {
    const url = `${portalURL}project_management/projects/${id}/`

    return axios.get(url, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export const createProjectAPI = (token, data) => {
    return axios.post(`${portalURL}project_management/projects/`, data, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export function deleteProjectAPI(token, id) {
    return axios.delete(
        urljoin(portalURL, `project_management/projects/${id}/`),
        {
            headers: {
                Authorization: `Token ${token}`,
                'content-type': 'application/json',
            },
        }
    )
}

export function updateProjectAPI(token, id, data) {
    return axios.patch(
        urljoin(portalURL, `project_management/projects/${id}/`),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

/* PROJECT USERS */

const projectUrl = `${portalURL}project_management/projects`

export const fetchProjectUsersAPI = (token, project_id) =>
    axios.get(`${projectUrl}/${project_id}/users/`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })

export const createProjectUserAPI = (token, project_id, data) => {
    return axios.post(`${projectUrl}/${project_id}/users/`, data, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export const deleteProjectUserAPI = (token, project_id, id) => {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            'content-type': 'application/json',
        },
        params: { remove: true },
    }
    return axios.delete(`${projectUrl}/${project_id}/users/${id}/`, config)
}

export const addProjectUserAPI = (token, project_id, id) =>
    axios.patch(`${projectUrl}/${project_id}/users/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })

export const updateProjectUserAPI = (token, project_id, data) => {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            'content-type': 'application/json',
        },
    }
    return axios.post(
        `${projectUrl}/${project_id}/users/add_existing_user/`,
        data,
        config
    )
}

// export const addProjectUserAPI = (token, project_id, id) =>
//   axios.patch(`${projectUrl}/${project_id}/users/${id}/`, {
//     headers: {
//       Authorization: "Token " + token,
//       accept: "application/json",
//       "content-type": "application/json",
//     },
//   });

// ??
export function fetchSectionsAPI(token, id) {
    return axios.get(`${portalURL}data_management/projects/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

/* ASSETS */

export function fetchAssetDetailsAPI(token, id) {
    return axios.get(`${portalURL}project_management/assets/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export function fetchAssetDataAPI(token, assetId, params) {
    const url = urljoin(
        portalURL,
        'project_management/assets/',
        assetId,
        `/data/`
    )

    return axios.get(url, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params,
    })
}

export function fetchUserAssetsAPI(token, params) {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params: parsePaginationParams(params),
    }
    return axios.get(urljoin(portalURL, 'project_management/assets/'), config)
}

export function createAssetAPI(token, data) {
    return axios.post(urljoin(portalURL, 'project_management/assets/'), data, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

export function deleteAssetAPI(token, id) {
    return axios.delete(
        urljoin(portalURL, `project_management/assets/${id}/`),
        {
            headers: {
                Authorization: `Token ${token}`,
                'content-type': 'application/json',
            },
        }
    )
}

export function updateAssetAPI(token, id, data) {
    return axios.patch(
        urljoin(portalURL, `project_management/assets/${id}/`),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

/* DATA */

export function fetchDownloadFileAPI(token, file_type, id) {
    return axios.get(`${portalURL}project_management/data/${id}/download/`, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
    })
}

export function fetchUploadFileAPI(token, asset_id, file_type, file_data) {
    const formData = new FormData()
    formData.append('name', file_data.name)
    formData.append('file', file_data.file[0])
    formData.append('asset_id', asset_id)

    return axios.post(
        `${portalURL}project_management/${file_type}/`,
        formData,
        {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

export function fetchDeleteFileAPI(token, file_type, id) {
    return axios.delete(`${portalURL}project_management/${file_type}/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
        },
    })
}

export function fetchUploadDataAPI(
    token,
    asset_id,
    folder_id,
    files,
    file_type
) {
    const formData = new FormData()
    // Todo loop here for multiple files - probably won't work here better to do in saga
    if (folder_id !== 'None') {
        formData.append('folder_id', folder_id)
    }
    formData.append('asset_id', asset_id)
    formData.append('file', files[0])
    formData.append('name', files[0].name)
    formData.append('file_type', file_type)

    // files = files.files;
    // for (var i = 0; i < files.length; i++) {
    //   formData.append("file_" + i, files[i]);
    // }
    const url = `${portalURL}project_management/assets/${asset_id}/data/`

    return axios.post(url, formData, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })
}

export function fetchDeleteDataAPI(token, data_id) {
    return axios.delete(`${portalURL}project_management/data/${data_id}/`, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
        },
    })
}

export function updateDataValidationAPI(token, asset_id, data_id, data) {
    return axios.patch(
        `${portalURL}project_management/assets/${asset_id}/data/${data_id}/validate/`,
        data,
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function fetchFolderDataAPI(token, asset_id, params) {
    const url = `${portalURL}project_management/assets/${asset_id}/data_tab/`
    const config = {
        headers: { Authorization: `Token ${token}` },
        params: {
            folder__id: params?.folder_id || 'None',
            label__id: params?.label_id !== 'None' ? params?.label_id : null,
            ...parsePaginationParams(params),
        },
    }
    return axios.get(url, config)
}

export function fetchCreateFolderAPI(token, asset_id, folder_name, parent_id) {
    if (parent_id === 'None') {
        parent_id = null
    }

    return axios.post(
        `${portalURL}project_management/assets/${asset_id}/folders/`,
        { name: folder_name, parent_folder_id: parent_id },
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function fetchDeleteFolderAPI(token, folder_id) {
    return axios.delete(
        `${portalURL}project_management/folders/${folder_id}/`,
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function fetchUpdateFolderAPI(token, folder_id, name) {
    return axios.patch(
        `${portalURL}project_management/folders/${folder_id}/`,
        { name },
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function getFolderPathsAPI(token, asset_id) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/assets/',
            asset_id,
            '/folders/paths/'
        ),
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function getMoveDataAPI(token, data_id, apiData) {
    const url = `${portalURL}project_management/data/${data_id}/`
    return axios.patch(url, apiData, {
        headers: { Authorization: `Token ${token}` },
    })
}

export function getMoveFolderAPI(token, folder_id, parent_folder_id) {
    const url = `${portalURL}project_management/folders/${folder_id}/`
    return axios.patch(
        url,
        { parent_folder_id },
        { headers: { Authorization: `Token ${token}` } }
    )
}

export function fetchImageAPI(
    token,
    image_id,
    params
) {
    const url = `${portalURL}project_management/data/${image_id}/`
    return axios.get(url, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params,
        paramsSerializer: function(params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        },
    })
}

export function fetchDamageCropAPI(
    token,
    sphere_id,
    mask,
    damage_type,
    severity_type,
    bbox,
    damageId,
    blendBbox = true
) {
    const params = {
        blend: !!mask,
        damage_type,
        severity_type,
        bbox: !!bbox,
        damageId,
        blendBbox
    }

    return axios.get(
        urljoin(portalURL, `project_management/spheres/${sphere_id}/`),
        {
            headers: { Authorization: `Token ${token}` },
            params,
        }
    )
}

/* REPORTS */

export function fetchReportsAPI(token, asset_id, params) {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params: parsePaginationParams(params),
    }
    return axios.get(
        urljoin(portalURL, 'project_management/assets/', asset_id, 'reports/'),
        config
    )
}

export function fetchReportAPI(token, report_id) {
    return axios.get(
        urljoin(portalURL, 'project_management/reports/', report_id, '/'),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function downloadReportAPI(token, report_id) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/reports/',
            `${report_id}`,
            '/download/'
        ),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
            responseType: 'arraybuffer',
        }
    )
}

export function sendReportRequestAPI(token, assetID, damageIDs, username) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/reports/custom-download-request/'
        ),
        {
            headers:{
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'applications/json',
            },

            params: {
                assetID,
                'damageIDs[]': damageIDs,
                username
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            },
        }
    )
}

export function checkReportFinishedAPI(token, task_id) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/reports/poll-async-results/', `${task_id}`, '/'
        ),
        {
            headers:{
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'applications/json',
            }
        }
    )
}

export function downloadCustomedReport(token, reportPath) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/reports/poll-async-download/'
        ),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
            responseType: 'blob',
            params: {
                reportPath
            },
            //paramsSerializer: function(params) {
            //    return qs.stringify(params, {arrayFormat: 'repeat'})
            //},
        }
    )
}

export function downloadPDFReportAPI(token, assetID, damageIDs, username) {
    return axios.get(
        urljoin(
            portalURL,
            'project_management/reports/custom-download/'
        ),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
            responseType: 'blob',
            params: {
                assetID,
                'damageIDs[]': damageIDs,
                username
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            },
        }
    )
}


export function deleteReportAPI(token, report_id) {
    return axios.delete(
        urljoin(portalURL, 'project_management/reports/', `${report_id}`, '/'),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function createReportAPI(token, asset_id) {
    const data = {
        asset_id,
        name: 'name',
        file: null,
    }
    return axios.post(
        urljoin(
            portalURL,
            'project_management/assets/',
            `${asset_id}`,
            '/reports/'
        ),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function fetchUserReportsAPI(token, params) {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params: parsePaginationParams(params),
    }
    return axios.get(urljoin(portalURL, 'project_management/reports/'), config)
}

export function uploadReportAPI(token, asset_id, files) {
    // Todo allow multiple files
    const formData = new FormData()
    formData.append('asset_id', asset_id)
    formData.append('file', files[0])
    formData.append('name', files[0].name)

    return axios.post(
        `${portalURL}project_management/reports/upload/`,
        formData,
        {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

/* COORDINATE DATA */

export function fetchCoordinateDataAPI(
    token,
    asset_id,
    data_limit,
    severity_filter,
    data_id
) {
    let url = urljoin(
        portalURL,
        'project_management/assets/',
        asset_id,
        '/data/'
    )
    if (data_id !== null && data_limit !== 'None') {
        url += `${data_id}/`
    } else if (data_limit !== null && data_limit !== 'None') {
        url += `?limit=${data_limit}&`
        if (severity_filter !== null && severity_filter !== 'None') {
            url += `severity_score=${severity_filter}`
        }
    } else if (severity_filter !== null && severity_filter !== 'None') {
        url += `?severity_score=${severity_filter}`
    }
    return axios.get(url, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

/* DAMAGE SPHERES */

export function fetchSpheresAPI(
    token,
    params
) {
    const url = urljoin(
        portalURL,
        'project_management/assets/',
        params.asset_id,
        `/spheres/`
    )

    return axios.get(url, {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
        params: {
            ...parsePaginationParams(params),
            ...params,
        },
        paramsSerializer: function(params) {
            return qs.stringify(params, {arrayFormat: 'comma'})
        },
    })
}

export function createSphereAPI(token, asset_id, data) {
    return axios.post(
        urljoin(portalURL, 'project_management/assets/', asset_id, '/spheres/'),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function deleteSphereAPI(token, sphere_id) {
    return axios.delete(
        urljoin(portalURL, 'project_management/spheres/', `${sphere_id}`, '/'),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
        }
    )
}

export function updateSphereAPI(token, sphere_id, data, params = {}) {
    return axios.patch(
        urljoin(portalURL, 'project_management/spheres/', `${sphere_id}`, '/'),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
            params
        }
    )
}

export function updateSpheresAPI(token, assetId, params, data) {
    return axios.patch(
        urljoin(
            portalURL,
            'project_management/assets/',
            `${assetId}`,
            '/spheres/'
        ),
        data,
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'application/json',
                'content-type': 'application/json',
            },
            params,
        }
    )
}

export function exportSpheresAPI(token, assetId) {
    return axios.get(
        urljoin(portalURL, 'project_management/assets/', `${assetId}`, '/export_damages/'),
        {
            headers: {
                Authorization: `Token ${token}`,
                accept: 'text/csv',
                'content-type': 'application/json',
            },
            responseType: 'arraybuffer',
        }
    );
}

/* DASHBOARD DATA */

export function fetchDashboardAPI(token) {
    return axios.get(urljoin(portalURL, 'project_management/dashboard/'), {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}

/* LABELS */

export function fetchLabelsAPI(token) {
    return axios.get(urljoin(portalURL, 'dataset_management/labels/'), {
        headers: {
            Authorization: `Token ${token}`,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    })
}
