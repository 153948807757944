// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import { DataGrid, GridToolbarContainer } from '@material-ui/data-grid'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

import Chip from '@material-ui/core/Chip'

// import MaterialTable from '../../MaterialTable'
import DataBreadcrumbs from './DataBreadcrumbs'
// import Dropdown from 'components/Common/Dropdown/Dropdown'
// import Table from 'components/Common/Table/Table'
// import Pagination from 'components/Common/Pagination/Pagination'
// import FolderActions from 'components/Asset/Data/FolderActions'
// import DataActions from 'components/Asset/Data/DataActions'
// import WithSpinner from 'components/Common/WithSpinner/WithSpinner'
import { ReactComponent as FileIcon } from '../../../assets/table/file.svg'
// import { ReactComponent as DeleteIcon } from '../../../assets/table/delete.svg'
// import { ReactComponent as MoreIcon } from '../../../assets/table/more.svg'
// import { ReactComponent as LessIcon } from '../../../assets/table/less.svg'
import { ReactComponent as FolderIcon } from '../../../assets/table/folder_solid.svg'
import { dateFormatter } from '../../../utils/utils'
// import {
//     DataTableContainer,
//     TableBar,
//     FilterContainer,
//     Icon,
//     Image,
//     Badge,
// } from './DataTable.styles'

const useStyles = makeStyles({
    thummbnail: {
        width: '35px',
        height: 'auto',
    },
})

const DataTable = ({
    crumbs, // folderPaths
    onCrumbClick,
    sortColumn,
    onSort,
    onClick,
    // onSelect,
    damageFilter,
    damagelabels,
    onFilterDamage,
    ...paginationProps
}): JSX.Element => {
    const classes = useStyles()
    let labels = damagelabels
    const loading = useSelector((state) => state.folder_data.loading)
    const data = useSelector((state) => state.folder_data.data)
    const totalCount = useSelector((state) => state.folder_data.totalCount)

    const renderDataIcon = ({
        data_type,
        file_type,
        image_preview,
    }): JSX.Element => {
        if (data_type === 'Folder')
            return <SvgIcon component={FolderIcon} color="primary" />
        if (file_type === 'image')
            return (
                <img
                    src={`data:image/png;base64,${image_preview.slice(1, -1)}`}
                    alt="asset preview thumbnail"
                    className={classes.thummbnail}
                />
            )
        return <SvgIcon component={FileIcon} color="primary" />
    }

    const renderStatusBadge = ({
        data_type,
        ai_checked,
    }): JSX.Element | null => {
        if (data_type === 'Folder') return null
        const status = ai_checked ? 'checked' : 'unchecked'
        return (
            <Chip
                label={status}
                size="small"
                color={ai_checked ? 'primary' : 'default'}
            />
        )
    }

    const columns = [
        {
            field: 'dataType',
            headerName: ' ',
            renderCell: (params) => renderDataIcon(params.row),
            filterable: false,
        },
        { headerName: 'Name', field: 'name', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => renderStatusBadge(params.row),
            flex: 1,
        },
        {
            headerName: 'Creator',
            field: 'username',
            valueGetter: (params) => params.row?.user?.username,
            flex: 1,
        },
        {
            headerName: 'Created',
            field: 'created_at',
            valueGetter: (params) => dateFormatter(params.row?.created_at),
            flex: 1,
        },
        {
            headerName: 'Updated',
            field: 'updated_at',
            valueGetter: (params) => dateFormatter(params.row?.updated_at),
            flex: 1,
        },
    ]

    // const { id, name } = crumbs[crumbs.length - 1]
    // const actionConfig = {
    //     onChangeFolderPath: onCrumbClick,
    //     currentFolder: { id: id ? parseInt(id, 10) : id, name },
    // }

    // const actionColumn = {
    //     key: 'actions',
    //     content: (item) =>
    //         item.data_type === 'Folder' ? (
    //             <FolderActions data={item} {...actionConfig} />
    //         ) : (
    //             <DataActions data={item} {...actionConfig} />
    //         ),
    // }

    // if (role !== 'unauthorized') columns.push(actionColumn)

    if (labels.length > 0) {
        labels = labels.filter((label) => label.label__id !== 'None')

        // eslint-disable-next-line
        labels = labels.map(({ label__id: id, label__name: type }) => ({
            id,
            type: type === 'All' ? 'All Types' : type,
        }))
    }

    return (
        <Box display="flex" flexDirection="column">
            {labels.length > 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box pb={2}>
                            <FormControl
                                fullWidth
                                className={classes.formControl}
                            >
                                <InputLabel id="damage-type-select-label">
                                    Select Damage Type
                                </InputLabel>
                                <Select
                                    labelId="damage-type-select-label"
                                    id="damage-type-select"
                                    value={damageFilter}
                                    onChange={(event) =>
                                        onFilterDamage(event.target.value)
                                    }
                                >
                                    {/* eslint-disable-next-line */}
                                    {labels.map(({ id, type }) => (
                                        <MenuItem key={type} value={id}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <DataGrid
                paginationMode="server"
                sortingMode="server"
                sortModel={sortColumn}
                onSortModelChange={onSort}
                rows={loading ? [] : data}
                columns={columns}
                pageSize={paginationProps.dataPerPage}
                rowCount={totalCount}
                loading={loading}
                pagination
                rowsPerPageOptions={[5, 10, 20]}
                autoHeight
                page={paginationProps.currentPage - 1}
                onPageChange={({ page }) => {
                    paginationProps.onChangePage(page + 1)
                }}
                onPageSizeChange={({ pageSize }) => {
                    paginationProps.onChangeDataPerPage(pageSize)
                }}
                onRowClick={(params) => onClick(params.row)}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer>
                            <DataBreadcrumbs
                                crumbs={crumbs}
                                onCrumbClick={onCrumbClick}
                            />
                        </GridToolbarContainer>
                    ),
                }}
                // disableColumnMenu
            />
        </Box>
    )
}

export default DataTable
