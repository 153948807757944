// @ts-nocheck
import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useForm, Controller, useWatch } from 'react-hook-form'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import { severityScore } from '../../config/constants.js'
import { colorTriangle, colorDot } from '../../utils/utils'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
)

export interface IValidationFilterForm {
    severity: Array<number>
    damageType: Array<number>
    severityType: number | string
}

interface IFilters {
    onUpdate: (a: IValidationFilterForm) => void
}

const Filters = ({ onUpdate }: IFilters): JSX.Element => {
    const classes = useStyles()
    // @ts-ignore
    let damageLabels = useSelector((state) => state.asset_data.data.labels)
    damageLabels = damageLabels?.filter(
        // @ts-ignore
        ({ label__name }) => label__name !== 'All'
    )
    let severityTypes = useSelector((state) => state.img_data.severity_labels)
    severityTypes = severityTypes.map(
        // eslint-disable-next-line
        ({ severity_label__id: id, severity_label__name: type }) => ({
            id,
            label: type === 'All' ? 'All Severity Types' : type,
        })
    )

    const { control, handleSubmit } = useForm<IValidationFilterForm>({
        defaultValues: {
            damageType: damageLabels.map((item) => item.label__id),
            severity: severityScore.map((item) => item.value),
            severityType: 'None',
        },
    })
    const watchAllFields = useWatch({
        control,
    })

    // watch filter updates
    useEffect(() => {
        onUpdate(watchAllFields)
    }, [watchAllFields])

    return (
        <Box>
            <Controller
                name="damageType"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <FormControl
                        size="small"
                        margin="dense"
                        className={classes.formControl}
                    >
                        <InputLabel id="validation-filter-damageType-label">
                            Damage type
                        </InputLabel>
                        <Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            labelId="validation-filter-damageType-label"
                            id="validation-filter-damageType"
                            multiple
                            input={<Input />}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            renderValue={(selected: any) =>
                                selected
                                    .map(
                                        (val) =>
                                            damageLabels.find(
                                                (element) =>
                                                    element.label__id === val
                                            )?.label__name
                                    )
                                    .join(', ')
                            }
                            // prevent dropdown from jumping up/down
                            MenuProps={{
                                getContentAnchorEl: null,
                            }}
                        >
                            {damageLabels?.map(
                                ({ label__colour, label__id, label__name }) => (
                                    <MenuItem
                                        key={label__name}
                                        value={label__id}
                                    >
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            checked={
                                                field.value.indexOf(label__id) >
                                                -1
                                            }
                                        />
                                        <ListItemText primary={label__name} />
                                        {colorDot(label__colour)}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                )}
            />
            <Controller
                name="severityType"
                control={control}
                defaultValue="None"
                render={({ field }) => (
                    <FormControl
                        size="small"
                        margin="dense"
                        className={classes.formControl}
                    >
                        <InputLabel id="validation-filter-severityType-label">
                            Severity type
                        </InputLabel>
                        <Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            labelId="validation-filter-severityType-label"
                            id="validation-filter-severityType"
                            input={<Input />}
                            // prevent dropdown from jumping up/down
                            MenuProps={{
                                getContentAnchorEl: null,
                            }}
                        >
                            {severityTypes?.map(({ id, label }) => (
                                <MenuItem key={label} value={id}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />

            <Controller
                name="severity"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <FormControl
                        size="small"
                        margin="dense"
                        className={classes.formControl}
                    >
                        <InputLabel id="3d-filter-severity-label">
                            Severity label
                        </InputLabel>
                        <Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            labelId="3d-filter-severity-label"
                            id="3d-filter-severity"
                            multiple
                            input={<Input />}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            renderValue={(selected: any) =>
                                selected
                                    .map((val) => severityScore[val].label)
                                    .join(', ')
                            }
                            // prevent dropdown from jumping up/down
                            MenuProps={{
                                getContentAnchorEl: null,
                            }}
                        >
                            {severityScore.map(({ label, value, color }) => (
                                <MenuItem key={label} value={value}>
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        checked={
                                            field.value.indexOf(value) > -1
                                        }
                                    />
                                    <ListItemText primary={label} />
                                    {colorTriangle(color)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
        </Box>
    )
}

export default Filters
