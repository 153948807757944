import { takeLatest, call, put } from 'redux-saga/effects'
import {
    getSpheresSuccess,
    getSpheresFailed,
    createSphereSuccess,
    createSphereFailed,
    deleteSphereSuccess,
    deleteSphereFailed,
    updateSphereSuccess,
    updateSphereFailed,
} from '../actions/sphere.js'
import {
    fetchSpheresAPI,
    createSphereAPI,
    deleteSphereAPI,
    updateSphereAPI,
    fetchDamageCropAPI,
} from '../../config/api.js'
import {
    GET_SPHERES_REQUEST,
    ADD_SPHERE_REQUEST,
    DELETE_SPHERE_REQUEST,
    UPDATE_SPHERE_REQUEST,
    GET_DAMAGE_CROP_REQUEST,
    GET_DAMAGE_CROP_SUCCEEDED,
    GET_DAMAGE_CROP_FAILED,
} from '../types/sphere.js'

function* getSpheres(action) {
    try {
        const damage_spheres = yield call(
            fetchSpheresAPI,
            action.token,
            action.params
        )
        yield put(
            getSpheresSuccess(
                action.params.no_page
                    ? damage_spheres.data
                    : damage_spheres.data.results
            )
        )
    } catch (error) {
        yield put(getSpheresFailed(error.message))
    }
}

export function* getSpheresSaga() {
    yield takeLatest(GET_SPHERES_REQUEST, getSpheres)
}

function* createSphere(action) {
    try {
        const damage_sphere = yield call(
            createSphereAPI,
            action.token,
            action.asset_id,
            action.data
        )
        yield put(createSphereSuccess(damage_sphere.data))
    } catch (error) {
        yield put(createSphereFailed(error))
    }
}

export function* createSphereSaga() {
    yield takeLatest(ADD_SPHERE_REQUEST, createSphere)
}

function* deleteSphere(action) {
    try {
        yield call(deleteSphereAPI, action.token, action.sphere_id)
        yield put(deleteSphereSuccess(action.sphere_id))
    } catch (error) {
        yield put(deleteSphereFailed(error))
    }
}

export function* deleteSphereSaga() {
    yield takeLatest(DELETE_SPHERE_REQUEST, deleteSphere)
}

function* updateSphere(action) {
    try {
        const damage_sphere = yield call(
            updateSphereAPI,
            action.token,
            action.sphere_id,
            action.data
        )
        yield put(updateSphereSuccess(damage_sphere.data))
    } catch (error) {
        yield put(updateSphereFailed(error))
    }
}

export function* updateSphereSaga() {
    yield takeLatest(UPDATE_SPHERE_REQUEST, updateSphere)
}

function* getDamageCrop(action) {
    try {
        const image_data = yield call(
            fetchDamageCropAPI,
            action.token,
            action.id,
            action.mask,
            action.damage_type,
            action.severity_type,
            action.bbox
        )
        yield put({ type: GET_DAMAGE_CROP_SUCCEEDED, image_data })
    } catch (e) {
        yield put({ type: GET_DAMAGE_CROP_FAILED, message: e.message })
    }
}

export function* getDamageCropSaga() {
    yield takeLatest(GET_DAMAGE_CROP_REQUEST, getDamageCrop)
}
