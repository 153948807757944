// @ts-nocheck
import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const DonutChart = ({
    data,
    showCenterMetric,
    onClick,
    chartType,
}): JSX.Element => {
    const materialTheme = useTheme()
    const smDevice = useMediaQuery(materialTheme.breakpoints.down('sm'))

    const theme = {
        fontSize: '0.75rem', // materialTheme.typography.fontSize,
        fontFamily: materialTheme.typography.fontFamily,
        tooltip: {
            container: {
                padding: 5,
                background: materialTheme.palette.primary.dark,
                color: materialTheme.palette.primary.contrastText,
            },
        },
        // textColor: materialTheme.palette.text.primary,
    }

    const CenteredMetric = ({ dataWithArc, centerX, centerY }): JSX.Element => {
        let total = 0
        dataWithArc.forEach((datum) => {
            total += datum.value
        })

        if (chartType === 'area') {
            total = `${String(Math.round(total))} m2`
        }

        return (
            <text
                onClick={() => {
                    const id = 'All Damages'
                    return onClick({ id })
                }}
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: smDevice
                        ? materialTheme.typography.body1.fontSize
                        : materialTheme.typography.h5.fontSize,
                    fontWeight: smDevice
                        ? materialTheme.typography.body1.fontWeight
                        : materialTheme.typography.h5.fontWeight,
                    fill: materialTheme.palette.text.primary,
                }}
            >
                {total}
            </text>
        )
    }

    const legends = [
        {
            onClick: (d) => {
                return onClick(d)
            },
            fontSize: '0.5rem',
            anchor: 'right',
            direction: 'column',
            translateX: 140,
            itemWidth: 120,
            itemHeight: 20,
            itemsSpacing: 8,
            symbolSize: 16,
            itemTextColor: materialTheme.palette.text.primary,
            itemDirection: 'left-to-right',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 0.75,
                    },
                },
            ],
        },
    ]

    const layers = ['arcLinkLabels', 'arcs', 'arcLabels', 'legends']

    if (showCenterMetric) {
        layers.push(CenteredMetric)
    }

    return (
        <ResponsivePie
            data={data}
            margin={{
                top: 12,
                right: 150,
                bottom: 12,
                left: 12,
            }}
            innerRadius={0.4}
            padAngle={0}
            cornerRadius={0}
            colors={(node) => {
                return node.data.color
            }}
            borderWidth={0}
            enableArcLinkLabels={false}
            sliceLabelsTextColor={materialTheme.palette.text.primary}
            animate
            motionStiffness={90}
            motionDamping={15}
            legends={legends}
            theme={theme}
            layers={layers}
            activeOuterRadiusOffset={10}
            arcLabelsSkipAngle={10}
            onClick={onClick}
            valueFormat={(value) => {
                return chartType === 'count' ? value : `${Math.round(value)} m2`
            }}
        />
    )
}

export default DonutChart
