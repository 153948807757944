import {
    getMousePointCloudIntersection,
    getRealWordlPoint,
    drawSphere,
    drawLine,
    checkAnnotation,
    uncheckAnnotation,
} from './helper.js'

export function removeDamagesImage(image_data) {
    const removeValFromSpheres = []
    const removeValFromLines = []

    for (let i = 0; i < window.damage_points.length; i++) {
        const rem = window.damage_points[i]

        if (
            rem.userData.image_data.name === image_data.name &&
            !rem.userData.sphere_backend
        ) {
            window.viewer.scene.scene.remove(rem)
            const index = window.damage_points.indexOf(rem)
            if (index > -1) removeValFromSpheres.push(index)
        }
    }

    for (let i = 0; i < window.all_damage_lines.length; i++) {
        const rem = window.all_damage_lines[i]
        if (
            rem.image_data.name === image_data.name &&
            !rem.userData.sphere_backend
        ) {
            window.viewer.scene.scene.remove(rem)
            const index = window.all_damage_lines.indexOf(rem)
            if (index > -1) removeValFromLines.push(index)
        }
    }

    for (let i = removeValFromSpheres.length - 1; i >= 0; i--)
        window.damage_points.splice(removeValFromSpheres[i], 1)

    for (let i = removeValFromLines.length - 1; i >= 0; i--)
        window.all_damage_lines.splice(removeValFromLines[i], 1)
}

function checkOverlap(point3d, severity) {
    for (let i = 0; i < window.damage_points.length; i++) {
        const damage = window.damage_points[i]
        const dist = damage.position.distanceTo(point3d)
        if (dist < 0.5 && !damage.userData.backend_id) {
            if (damage.userData.severity_score < severity) {
                damage.userData.severity_score = severity
                damage.material.color.set(`#${serverityColors[severity]}`)
            }
            return true
        }
    }
    return false
}

const serverityColors = {
    0: '0062b1',
    1: '3fcc46',
    2: 'ffcf00',
    3: 'fe7600',
    4: 'd33115',
}

export function showDamagesImage(
    image_data,
    damage_size,
    isDamageVisible = false
) {
    const anPosition = [image_data.X, image_data.Y, image_data.Z]

    const origin = new window.THREE.Vector3(...anPosition)
    for (const dmg in image_data.damage_points) {
        const { width } = image_data.damage_points[dmg]
        const { height } = image_data.damage_points[dmg]
        const { x } = image_data.damage_points[dmg]
        const { y } = image_data.damage_points[dmg]
        const direction_ = getRealWordlPoint({ x, y }, image_data)
        const direction = new window.THREE.Vector3(...direction_)

        const camera = window.camera.clone()
        camera.position.set(origin.x, origin.y, origin.z)
        camera.lookAt(direction)
        camera.updateProjectionMatrix()
        const { renderer } = window.viewer
        const mouse = {
            x: renderer.domElement.clientWidth / 2,
            y: renderer.domElement.clientHeight / 2,
        }

        const damage3d = getMousePointCloudIntersection(
            mouse,
            camera,
            window.viewer,
            window.viewer.scene.pointclouds
        )

        if (damage3d) {
            const severity_score =
                image_data.damage_points[dmg].severity_interval
            const isOverlaped = checkOverlap(damage3d.location, severity_score)
            if (isOverlaped) continue
            let wireframe = false
            if (isDamageVisible) wireframe = true

            const sphere = drawSphere(
                image_data,
                damage3d.location,
                damage_size,
                serverityColors[severity_score],
                wireframe,
                null
            )

            drawLine(image_data, origin, damage3d.location, null)

            let translation = new window.THREE.Vector3()
            translation = translation.subVectors(damage3d.location, direction)

            const box_points = []
            const point_list = [
                [parseInt(x - width / 2), parseInt(y - height / 2)],
                [parseInt(x + width / 2), parseInt(y + height / 2)],
            ]

            for (let i = 0; i < point_list.length; i++) {
                const box_pt = point_list[i]
                const bx = box_pt[0]
                const by = box_pt[1]

                const direction_ = getRealWordlPoint(
                    { x: bx, y: by },
                    image_data
                )
                const direction = new window.THREE.Vector3(...direction_)

                let final_pos = new window.THREE.Vector3()
                final_pos = final_pos.addVectors(direction, translation)

                box_points.push(final_pos)
            }
            const box_points_x = box_points.map((point) => point.x)
            const box_points_y = box_points.map((point) => point.y)
            const box_points_z = box_points.map((point) => point.z)

            const dmg_width =
                Math.max(...box_points_x) - Math.min(...box_points_x)
            const dmg_length =
                Math.max(...box_points_z) - Math.min(...box_points_z)
            const dmg_height =
                Math.max(...box_points_y) - Math.min(...box_points_y)

            sphere.userData.sphere_data.severity_score = severity_score
            sphere.userData.sphere_data.width = dmg_width
            sphere.userData.sphere_data.heigth = dmg_height
            sphere.userData.sphere_data.length = dmg_length
        }
    }
}

export function removeSavedDamages(ai_generated) {
    const removeValFromDamagePoints = []
    const removeValFromDamageFav = []
    const removeValFromLineDamage = []

    for (let i = 0; i < window.damage_points.length; i++) {
        const rem = window.damage_points[i]
        if (
            rem.userData.sphere_backend &&
            rem.userData.sphere_backend.ai_generated === ai_generated
        ) {
            window.viewer.scene.scene.remove(rem)
            const index_damage_points = window.damage_points.indexOf(rem)
            if (index_damage_points > -1)
                removeValFromDamagePoints.push(index_damage_points)
            const index_fav_points = window.damage_fav.indexOf(rem)
            if (index_fav_points > -1)
                removeValFromDamageFav.push(index_fav_points)
        }
    }
    for (let i = 0; i < window.all_damage_lines.length; i++) {
        const rem = window.all_damage_lines[i]
        if (
            rem.userData.sphere_backend &&
            rem.userData.sphere_backend.ai_generated === ai_generated
        ) {
            window.viewer.scene.scene.remove(rem)
            // let text = rem.userData.image_data.name;
            // uncheckAnnotation(text);
            const index = window.all_damage_lines.indexOf(rem)
            if (index > -1) removeValFromLineDamage.push(index)
        }
    }

    for (let i = removeValFromLineDamage.length - 1; i >= 0; i--)
        window.all_damage_lines.splice(removeValFromLineDamage[i], 1)
    for (let i = removeValFromDamagePoints.length - 1; i >= 0; i--)
        window.damage_points.splice(removeValFromDamagePoints[i], 1)
    for (let i = removeValFromDamageFav.length - 1; i >= 0; i--)
        window.damage_fav.splice(removeValFromDamageFav[i], 1)
}

export function showSavedDamages(
    sphere_data,
    damage_size,
    isDamageVisible,
    areImagesConnected = false
) {
    for (const key in sphere_data) {
        const item = sphere_data[key]
        const image_data = item.data
        const sphere_backend = { ...item }
        delete sphere_backend.data

        const point3d = new window.THREE.Vector3(...[item.X, item.Y, item.Z])
        const origin = new window.THREE.Vector3(
            ...[image_data.X, image_data.Y, image_data.Z]
        )

        let wireframe = false
        if (isDamageVisible || item.important) wireframe = true
        const damage_sphere = drawSphere(
            image_data,
            point3d,
            damage_size,
            item.colour,
            wireframe,
            sphere_backend
        )
        window.viewer.scene.scene.add(damage_sphere)
        window.damage_points.push(damage_sphere)
        window.damage_fav.push(damage_sphere)

        if (areImagesConnected) {
            const damage_line = drawLine(
                image_data,
                point3d,
                origin,
                sphere_backend
            )
            window.viewer.scene.scene.add(damage_line)

            checkAnnotation(image_data.name)
        }
    }
}

export function removeAllDamages() {
    if (window.damage_points && window.damage_points.length > 0) {
        for (let i = 0; i < window.damage_points.length; i++) {
            window.viewer.scene.scene.remove(window.damage_points[i])
            const text = window.damage_points[i].userData.image_data.name
            uncheckAnnotation(text)
        }
    }
    if (window.all_damage_lines && window.all_damage_lines.length > 0) {
        for (let i = 0; i < window.all_damage_lines.length; i++) {
            window.viewer.scene.scene.remove(window.all_damage_lines[i])
        }
    }
    window.damage_fav = []
    window.all_damage_lines = []
    window.saved_damage_lines = []
    window.count_damage_points = 0
    window.damage_points = []
}

export function changeDamageIconAllImages(coordinate_data) {
    if (window.viewer.scene.annotations.children.length > 0) {
        for (const key in coordinate_data.data) {
            const item = coordinate_data.data[key]
            if (item.damage_points !== null) {
                if (item.damage_points.length > 0) {
                    const damageIcon = document.getElementById(
                        `damage-icon-${item.name}`
                    )
                    if (damageIcon.classList.contains('alert-triangle-solid')) {
                        damageIcon.src =
                            '/images/threeDmodel/alert-triangle-line.svg'
                        damageIcon.classList.remove('alert-triangle-solid')
                        damageIcon.classList.add('alert-triangle-line')
                    } else {
                        damageIcon.src =
                            '/images/threeDmodel/alert-triangle-solid.svg'
                        damageIcon.classList.remove('alert-triangle-line')
                        damageIcon.classList.add('alert-triangle-solid')
                    }
                }
            }
        }
    }
}

export function makeNotExtraVisible(dmg_pt) {
    dmg_pt.userData.sphere_data.fav = false
    dmg_pt.renderOrder = 0
    dmg_pt.material.depthTest = true
    dmg_pt.geometry.dispose()

    dmg_pt.geometry = new window.THREE.SphereGeometry(0.1, 10, 10)
    dmg_pt.remove(dmg_pt.children[0])
}

export function makeExtraVisible(dmg_pt) {
    dmg_pt.userData.sphere_data.fav = true
    dmg_pt.renderOrder = 1
    dmg_pt.material.depthTest = false
    dmg_pt.geometry.dispose()
    dmg_pt.geometry = new window.THREE.SphereGeometry(0.1, 10, 10)
    const geo = new window.THREE.EdgesGeometry(dmg_pt.geometry)
    const mat = new window.THREE.LineBasicMaterial({
        color: 0xffffff,
        linewidth: 2,
    })
    const wireframe = new window.THREE.LineSegments(geo, mat)
    dmg_pt.add(wireframe)

    wireframe.renderOrder = 1
    wireframe.material.depthTest = false
}
