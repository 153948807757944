import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { getToken } from './services/authService.js'
import { PrivateRoute, PublicRoute } from './components/Common/Routes.js'
import {
    checkToken,
    getCurrentUserGroupsAction,
} from './redux/actions/login.js'
import { adminUrl } from './config/api.js'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import ProjectsPage from './pages/ProjectsPage'
import AssetPage from './pages/AssetPage'
import ReportsPage from './pages/ReportsPage'
import ValidationPage from './pages/ValidationPage'
import SettingsPage from './pages/SettingsPage'
import NoMatchPage from './pages/NoMatchPage'
import Layout from './components/Common/Layout'
import 'fontsource-roboto'
import './App.css'

function App(): JSX.Element {
    const token = getToken()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(checkToken(token))
        if (token) {
            dispatch(getCurrentUserGroupsAction(token))
        }
    }, [dispatch, token])

    return (
        <Switch>
            <PublicRoute path="/login" component={LoginPage} exact />
            <Route
                exact
                path="/admin"
                render={() => {
                    window.location.href = adminUrl
                    return null
                }}
            />
            <Route>
                <Layout>
                    <Switch>
                        <PrivateRoute
                            path="/projects"
                            component={ProjectsPage}
                        />
                        <PrivateRoute path="/assets" component={AssetPage} />
                        <PrivateRoute
                            path="/reports"
                            component={ReportsPage}
                            exact
                        />
                        <PrivateRoute
                            path="/validations"
                            component={ValidationPage}
                        />
                        <PrivateRoute
                            path="/settings"
                            component={SettingsPage}
                            exact
                        />
                        <Route path="/logout" component={LogoutPage} />
                        <Route path="/404" component={NoMatchPage} />
                        <Redirect exact from="/" to="/projects" />
                        <Redirect to="/404" />
                    </Switch>
                </Layout>
            </Route>
        </Switch>
    )
}

export default App
