// @ts-nocheck
import { useRef, useEffect } from 'react'

// eslint-disable-next-line
const usePrevious = (value) => {
    const ref = useRef(value)

    useEffect(() => {
        ref.current = value
    })

    return ref.current
}

export default usePrevious
