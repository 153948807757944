// @ts-nocheck
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fileDownload from 'js-file-download'

// import DataControls from 'components/Asset/Data/DataControls'
import DataTable from './DataTable'
import ImageDialog from '../../ImageDialog'
import usePrevious from '../../Hook/usePrevious'
import { getToken } from '../../../services/authService.js'
import { getFolderData, getFolderPaths } from '../../../redux/actions/asset.js'

const Data = ({ asset_id }): JSX.Element => {
    const dispatch = useDispatch()
    const folder_data = useSelector((state) => state.folder_data)
    const folder_change = useSelector((state) => state.folder_change)
    const data_change = useSelector((state) => state.data_change)
    const file_data = useSelector((state) => state.file_data)
    const damagelabels = useSelector((state) => state.asset_data.data.labels)
    const [sortColumn, setSortColumn] = useState([
        { field: 'name', sort: 'asc' },
    ])
    const [folderPath, setFolderPath] = useState([
        { id: null, name: 'My Data' },
    ])
    // Select "All" by default
    const defaultFilter = damagelabels.find(
        (element) => element.label__name === 'All'
    )
    const [damageFilter, setDamageFilter] = useState(
        defaultFilter ? defaultFilter.label__id : damagelabels[0]?.label__id
    )

    const [currentIndex, setCurrentIndex] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(10)

    const [isImageOpen, setIsImageOpen] = useState(false)
    const [imagePopupData, setImagePopupData] = useState(null)

    const prevFile = usePrevious(file_data.file)
    const prevData = usePrevious(folder_data.data)

    const handleCrumbClick = (index): void => {
        setFolderPath(folderPath.slice(0, index + 1))
    }

    const handleSort = (params): void => {
        if (params.sortModel !== sortColumn) {
            setSortColumn(params.sortModel)
            setCurrentPage(1)
        }
    }

    const handleChangePage = (newPage): void => {
        setCurrentPage(parseInt(newPage, 10))
        setCurrentIndex(null)
    }

    const handleChangeDataPerPage = (size): void => {
        const pageSize = parseInt(size, 10)
        setDataPerPage(pageSize)
        setCurrentPage(1)
    }

    const onFilterDamage = (id): void => {
        setDamageFilter(id)
        setCurrentPage(1)
    }

    const toggleImagePopup = (): void => {
        setIsImageOpen((preState) => !preState)
    }

    const handleClick = (item): void => {
        if (item.data_type === 'Folder') {
            setFolderPath([...folderPath, item])
        } else {
            // eslint-disable-next-line
            const currentIndex = folder_data.data.findIndex(
                ({ id }) => id === item.id
            )
            setCurrentIndex(currentIndex)
            toggleImagePopup()
        }
    }

    const handleImageNavigation = (skip): void => {
        const newIndex = currentIndex + skip
        if (newIndex >= dataPerPage) {
            setCurrentPage((preState) => preState + 1)
        } else if (newIndex < 0) {
            setCurrentPage((preState) => preState - 1)
        }
        setCurrentIndex(newIndex)
    }

    // eslint-disable-next-line
    const parseIndex = (index, dataPerPage): number =>
        index >= dataPerPage ? index % dataPerPage : index + dataPerPage

    useEffect(() => {
        if (currentIndex === null) return
        const { data } = folder_data
        const noFolderData = data.filter(
            ({ data_type }) => data_type !== 'Folder'
        )

        if (currentIndex >= dataPerPage || currentIndex < 0) {
            if (prevData === data) return
            const newIndex = parseIndex(currentIndex, dataPerPage)
            setCurrentIndex(newIndex)
            setImagePopupData(data[newIndex])
        } else {
            const firstPage = currentPage === 1
            const lastPage = currentPage === folder_data.totalPages
            const noDataIndex = noFolderData.findIndex(
                ({ id }) => id === data[currentIndex].id
            )

            setImagePopupData({
                ...noFolderData.find(({ id }) => id === data[currentIndex].id),
                isFirst: firstPage && noDataIndex === 0,
                isLast: lastPage && noDataIndex === noFolderData.length - 1,
            })
        }
    }, [currentPage, currentIndex, dataPerPage, folder_data, prevData])

    useLayoutEffect(() => {
        const token = getToken()
        const lastIndex = folderPath.length - 1
        const params = {
            folder_id: folderPath[lastIndex].id,
            label_id: damageFilter,
            sortColumn: sortColumn?.map((col) => ({
                column: col.field,
                order: col.sort,
            }))[0],
            dataPerPage,
            currentPage,
        }
        dispatch(getFolderData(token, asset_id, params))
        dispatch(getFolderPaths(token, asset_id))
    }, [
        asset_id,
        folderPath,
        damageFilter,
        dataPerPage,
        currentPage,
        sortColumn,
        folder_change,
        data_change,
        dispatch,
    ])

    useEffect(() => {
        const token = getToken()
        getFolderPaths(token, asset_id)
  }, []); // eslint-disable-line

    useEffect(() => {
        if (file_data.file.length === 0) return
        if (prevFile !== file_data.file) {
            fileDownload(file_data.file, file_data.name)
        }
    }, [prevFile, file_data])

    useEffect(() => {
        if (!isImageOpen) return
        document.body.style.overflow = 'hidden'
        const hasScroll =
            document.documentElement.scrollHeight >
            document.documentElement.clientHeight
        if (hasScroll) document.body.style.paddingRight = '15px'
        // eslint-disable-next-line
        return () => {
            document.body.style.overflow = 'unset'
            if (hasScroll) document.body.style.paddingRight = '0px'
        }
    }, [isImageOpen])

    const tableProps = {
        sortColumn,
        onSort: handleSort,
        onClick: handleClick,
    }

    const paginationProps = {
        currentPage,
        onChangePage: handleChangePage,
        dataPerPage,
        onChangeDataPerPage: handleChangeDataPerPage,
    }

    return (
        <>
            <DataTable
                crumbs={folderPath}
                onCrumbClick={handleCrumbClick}
                damagelabels={damagelabels}
                damageFilter={damageFilter}
                onFilterDamage={onFilterDamage}
                // eslint-disable-next-line
                {...tableProps}
                // eslint-disable-next-line
                {...paginationProps}
            />
            {isImageOpen && imagePopupData && (
                <ImageDialog
                    open={isImageOpen}
                    data={imagePopupData}
                    onClose={toggleImagePopup}
                    onNavigation={handleImageNavigation}
                />
            )}
        </>
    )
}

export default Data
