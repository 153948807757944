import { combineReducers } from 'redux'
import companyReducer from './company.js'
import { projectDetailsReducer } from './project.js'

import { spheresReducer, getDamageCropReducer } from './sphere.js'
import {
    getReportsReducer,
    getReportReducer,
    downloadReportReducer,
    userReportsReducer,
} from './report.js'
import {
    assetDetailsReducer,
    downloadFileReducer,
    folderDataReducer,
    folderChangeReducer,
    uploadFileReducer,
    uploadDataReducer,
    coordinateDataReducer,
    getUserAssetsReducer,
    getFolderPathsReducer,
    // createAssetReducer,
} from './asset.js'
import { getImageReducer } from './data.js'
import { usersReducer, getUserReducer, changePasswordReducer } from './users.js'
import {
    projectsReducer,
    dashboardReducer,
    labelsReducer,
} from './dashboard.js'
import { loginReducer, abilityReducer } from './login.js'
import { systemReducer } from './system.js'

export const rootReducer = combineReducers({
    login: loginReducer,
    role: abilityReducer,
    users: usersReducer,
    companies: companyReducer,
    projects: projectsReducer,
    project_data: projectDetailsReducer,
    asset_data: assetDetailsReducer,
    folder_paths: getFolderPathsReducer,
    file_data: downloadFileReducer,
    file_change: uploadFileReducer,
    folder_data: folderDataReducer,
    folder_change: folderChangeReducer,
    data_change: uploadDataReducer,
    img_data: getImageReducer,
    user_data: getUserReducer,
    password_data: changePasswordReducer,
    report_data: getReportsReducer,
    report_detail_data: getReportReducer,
    report_download: downloadReportReducer,
    coordinate_data: coordinateDataReducer,
    user_asset_data: getUserAssetsReducer,
    user_reports_data: userReportsReducer,
    dashboard_data: dashboardReducer,
    sphere_data: spheresReducer,
    labels_data: labelsReducer,
    damage_crop: getDamageCropReducer,
    system: systemReducer,
})
