import {
    GET_SPHERES_REQUEST,
    GET_SPHERES_SUCCEEDED,
    GET_SPHERES_FAILED,
    ADD_SPHERE_REQUEST,
    ADD_SPHERE_SUCCEEDED,
    ADD_SPHERE_FAILED,
    DELETE_SPHERE_REQUEST,
    DELETE_SPHERE_SUCCEEDED,
    DELETE_SPHERE_FAILED,
    UPDATE_SPHERE_REQUEST,
    UPDATE_SPHERE_SUCCEEDED,
    UPDATE_SPHERE_FAILED,
    TOGGLE_SPHERE_FORM_OPEN,
    GET_DAMAGE_CROP_REQUEST,
} from '../types/sphere.js'

export const toggleFormOpen = () => ({
    type: TOGGLE_SPHERE_FORM_OPEN,
})

export const getSpheres = (
    token,
    params,
    // asset_id,
    // limit = 1200,
    // ai_generate=null,
    // labels,
    // severity_filter,
    // component,
    // size_range,
    // checked,
) => ({
    type: GET_SPHERES_REQUEST,
    token,
    params,
    // asset_id,
    // limit,
    // ai_generate,
    // labels,
    // severity_filter,
    // component,
    // size_range,
    // checked,
})

export const getSpheresSuccess = (data) => ({
    type: GET_SPHERES_SUCCEEDED,
    data,
})

export const getSpheresFailed = (errorMessage) => ({
    type: GET_SPHERES_FAILED,
    errorMessage,
})

export const createSphere = (token, asset_id, data) => ({
    type: ADD_SPHERE_REQUEST,
    token,
    asset_id,
    data,
})

export const createSphereSuccess = (data) => ({
    type: ADD_SPHERE_SUCCEEDED,
    data,
})

export const createSphereFailed = (errorMessage) => ({
    type: ADD_SPHERE_FAILED,
    errorMessage,
})

export const deleteSphere = (token, sphere_id, asset_id) => ({
    type: DELETE_SPHERE_REQUEST,
    token,
    sphere_id,
    asset_id,
})

export const deleteSphereSuccess = (data) => ({
    type: DELETE_SPHERE_SUCCEEDED,
    data,
})

export const deleteSphereFailed = (errorMessage) => ({
    type: DELETE_SPHERE_FAILED,
    errorMessage,
})

export const updateSphere = (token, sphere_id, data, asset_id) => ({
    type: UPDATE_SPHERE_REQUEST,
    token,
    sphere_id,
    data,
    asset_id,
})

export const updateSphereSuccess = (data) => ({
    type: UPDATE_SPHERE_SUCCEEDED,
    data,
})

export const updateSphereFailed = (errorMessage) => ({
    type: UPDATE_SPHERE_FAILED,
    errorMessage,
})

export const getDamageCrop = (token, id, bbox) => {
    return {
        type: GET_DAMAGE_CROP_REQUEST,
        token,
        id,
        mask: false,
        filters: [],
        bbox,
    }
}

export const getOverlayDamageCrop = (
    token,
    id,
    damage_type,
    severity_type,
    bbox
) => {
    return {
        type: GET_DAMAGE_CROP_REQUEST,
        token,
        id,
        mask: true,
        damage_type,
        severity_type,
        bbox,
    }
}
