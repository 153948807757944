/* eslint-disable no-console */
import {
    GET_REPORTS_REQUEST,
    GET_REPORTS_SUCCEEDED,
    GET_REPORTS_FAILED,
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCEEDED,
    GET_REPORT_FAILED,
    GET_CREATE_REPORT_REQUEST,
    GET_CREATE_REPORT_SUCCEEDED,
    GET_CREATE_REPORT_FAILED,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCEEDED,
    DELETE_REPORT_FAILED,
    GET_DOWNLOAD_REPORT_REQUEST,
    GET_DOWNLOAD_REPORT_SUCCEEDED,
    GET_DOWNLOAD_REPORT_FAILED,
    GET_USER_REPORTS_REQUEST,
    GET_USER_REPORTS_SUCCEEDED,
    GET_USER_REPORTS_FAILED,
    GET_UPLOAD_REPORT_REQUEST,
    GET_UPLOAD_REPORT_SUCCEEDED,
    GET_UPLOAD_REPORT_FAILED,
} from '../actions/report.js'

const initialState = {
    reports: [],
    totalCount: null,
    totalPages: null,
    dataPerPage: null,
    prev: null,
    next: null,
    loading: false,
    errorMessage: undefined,
}

export const getReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CREATE_REPORT_SUCCEEDED:
        case GET_REPORTS_SUCCEEDED:
        case GET_UPLOAD_REPORT_SUCCEEDED: {
            const { data } = action.report_data
            const { params } = action.report_data.config
            const totalCount = parseInt(data.count, 10)
            const dataPerPage = parseInt(params.limit, 10) || 10
            const totalPages = Math.ceil(totalCount / dataPerPage) || 1
            return {
                ...state,
                loading: false,
                reports: data.results || [],
                prev: data.previous,
                next: data.next,
                totalCount,
                totalPages,
                dataPerPage,
                errorMessage: false,
            }
        }
        case DELETE_REPORT_SUCCEEDED: {
            const totalCount = state.totalCount - 1
            const totalPages = Math.ceil(totalCount / state.dataPerPage) || 1
            return {
                ...state,
                reports: state.reports.filter(({ id }) => action.id !== id),
                totalCount,
                totalPages,
                errorMessage: false,
                loading: false,
            }
        }
        case GET_CREATE_REPORT_FAILED:
        case GET_REPORTS_FAILED:
        case GET_UPLOAD_REPORT_FAILED:
        case DELETE_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
            }
        case GET_CREATE_REPORT_REQUEST:
        case GET_REPORTS_REQUEST:
        case GET_UPLOAD_REPORT_REQUEST:
        case DELETE_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const initialReportState = {
    report: '',
    name: '',
    num_pages: 0,
    loading: false,
}

export const getReportReducer = (state = initialReportState, action) => {
    switch (action.type) {
        case GET_REPORT_SUCCEEDED:
            return {
                ...state,
                report: action.report_data.data.file_data.report,
                num_pages: action.report_data.data.file_data.num_pages,
                name: action.report_data.data.name,
                loading: false,
            }

        case GET_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                report: '',
                name: '',
                num_pages: 0,
            }
        case GET_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                report: '',
                name: '',
                num_pages: 0,
            }
        default:
            return state
    }
}

const downloadReportState = {
    loading: false,
    file: '',
    name: '',
}

export const downloadReportReducer = (state = downloadReportState, action) => {
    switch (action.type) {
        case GET_DOWNLOAD_REPORT_SUCCEEDED:
            return {
                ...state,
                file: action.download_data.data,
                loading: false,
                name: action.name,
            }
        case GET_DOWNLOAD_REPORT_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_DOWNLOAD_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const userReportsState = {
    loading: false,
    reports: [],
    totalCount: null,
    totalPages: null,
    prev: null,
    next: null,
    user: '',
}

export const userReportsReducer = (state = userReportsState, action) => {
    switch (action.type) {
        case GET_USER_REPORTS_SUCCEEDED: {
            const { data } = action.user_reports
            const { params } = action.user_reports.config
            const totalCount = parseInt(data.count, 10)
            const dataPerPage = parseInt(params.limit, 10) || 15
            const totalPages = Math.ceil(totalCount / dataPerPage) || 1
            return {
                ...state,
                loading: false,
                reports: data.results || [],
                prev: data.previous,
                next: data.next,
                totalCount,
                totalPages,
            }
        }
        case GET_USER_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_USER_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
