// @ts-nocheck
import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { ResponsiveBar } from '@nivo/bar'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { severityScheme } from '../../../config/color_palette.js'

const BarChart = ({ data = [], keys = [], chartType }): JSX.Element => {
    const materialTheme = useTheme()
    const theme = {
        // fontSize: materialTheme.typography.fontSize,
        fontFamily: materialTheme.typography.fontFamily,
        tooltip: {
            container: {
                padding: 5,
                background: materialTheme.palette.primary.dark,
                color: materialTheme.palette.primary.contrastText,
            },
        },
        textColor: materialTheme.palette.text.primary,
    }
    const colors = severityScheme.map(({ color }) => color)
    const legends = [
        {
            dataFrom: 'keys',
            anchor: 'right',
            direction: 'column',
            translateX: 140,
            itemWidth: 120,
            itemHeight: 20,
            itemsSpacing: 1,
            symbolSize: 16,
            itemTextColor: materialTheme.palette.text.primary,
            itemDirection: 'left-to-right',
        },
    ]

    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            margin={{ top: 0, right: 130, bottom: 10, left: 80 }}
            innerPadding={1}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={colors}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: 'Very high',
                    },
                    id: 'dots',
                },
                {
                    match: {
                        id: 'Medium',
                    },
                    id: 'lines',
                },
            ]}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legend: '',
                legendOffset: 0,
                itemHeight: 20,
                itemWidth: 120,
            }}
            enableLabel
            enableGridY={false}
            labelSkipWidth={20}
            legends={legends}
            theme={theme}
            animate
            motionStiffness={90}
            motionDamping={15}
            valueFormat={(value) => {
                return chartType === 'count' ? value : `${Math.round(value)} m2`
            }}
        />
    )
}

export default BarChart
