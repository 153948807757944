import {
    TOGGLE_USER_FORM_OPEN,
    TOGGLE_ASSET_FORM_OPEN,
    SET_ASSET_FORM_DATA,
    GET_PROJECT_DETAILS_REQUEST,
    GET_PROJECT_DETAILS_SUCCEEDED,
    GET_PROJECT_DETAILS_FAILED,
    ADD_ASSET_SUCCEEDED,
    ADD_ASSET_REQUEST,
    ADD_ASSET_FAILED,
    DELETE_ASSET_REQUEST,
    DELETE_ASSET_SUCCEEDED,
    DELETE_ASSET_FAILED,
    UPDATE_ASSET_REQUEST,
    UPDATE_ASSET_SUCCEEDED,
    UPDATE_ASSET_FAILED,
    CREATE_PROJECT_USER_REQUEST,
    CREATE_PROJECT_USER_SUCCEEDED,
    CREATE_PROJECT_USER_FAILED,
    UPDATE_PROJECT_USER_SUCCEEDED,
    UPDATE_PROJECT_USER_REQUEST,
    UPDATE_PROJECT_USER_FAILED,
    // ADD_PROJECT_USER_SUCCEEDED,
    // ADD_PROJECT_USER_FAILED,
    REMOVE_PROJECT_USER_SUCCEEDED,
    REMOVE_PROJECT_USER_FAILED,
    REMOVE_PROJECT_USER_REQUEST,
    GET_TASKS_REQUEST,
    GET_SECTIONS_REQUEST,
} from '../types/project.js'

export const toggleUserFormOpen = (form) => ({
    type: TOGGLE_USER_FORM_OPEN,
    form,
})

export const toggleFormOpen = () => ({
    type: TOGGLE_ASSET_FORM_OPEN,
})

export const setFormData = (data) => ({
    type: SET_ASSET_FORM_DATA,
    data,
})

// =========================
//      PROJECT DETAILS
// =========================

export const getProjectDetails = (token, id) => ({
    type: GET_PROJECT_DETAILS_REQUEST,
    token,
    id,
})

export const getProjectDetailsSuccess = (data) => ({
    type: GET_PROJECT_DETAILS_SUCCEEDED,
    data,
})

export const getProjectDetailsFailed = (errorMessage) => ({
    type: GET_PROJECT_DETAILS_FAILED,
    errorMessage,
})

// ========================
//      PROJECT ASSETS
// ========================

export const createAsset = (token, data) => ({
    type: ADD_ASSET_REQUEST,
    token,
    data,
})

export const createAssetSuccess = (data) => ({
    type: ADD_ASSET_SUCCEEDED,
    data,
})

export const createAssetFailed = (errorMessage) => ({
    type: ADD_ASSET_FAILED,
    errorMessage,
})

export const deleteAsset = (token, id, project_id) => ({
    type: DELETE_ASSET_REQUEST,
    token,
    id,
    project_id,
})

export const deleteAssetSuccess = (id) => ({
    type: DELETE_ASSET_SUCCEEDED,
    id,
})

export const deleteAssetFailed = (errorMessage) => ({
    type: DELETE_ASSET_FAILED,
    errorMessage,
})

export const updateAsset = (token, id, data) => ({
    type: UPDATE_ASSET_REQUEST,
    token,
    id,
    data,
})

export const updateAssetSuccess = (data) => ({
    type: UPDATE_ASSET_SUCCEEDED,
    data,
})

export const updateAssetFailed = (errorMessage) => ({
    type: UPDATE_ASSET_FAILED,
    errorMessage,
})

// =======================
//      PROJECT USERS
// =======================

export const createProjectUser = (token, project_id, data) => ({
    type: CREATE_PROJECT_USER_REQUEST,
    token,
    project_id,
    data,
})

export const createProjectUserSuccess = (data) => ({
    type: CREATE_PROJECT_USER_SUCCEEDED,
    data,
})

export const createProjectUserFailed = (errorMessage) => ({
    type: CREATE_PROJECT_USER_FAILED,
    errorMessage,
})

export const removeProjectUser = (token, project_id, id) => ({
    type: REMOVE_PROJECT_USER_REQUEST,
    token,
    project_id,
    id,
})

export const removeProjectUserSuccess = (id) => ({
    type: REMOVE_PROJECT_USER_SUCCEEDED,
    id,
})

export const removeProjectUserFailed = (errorMessage) => ({
    type: REMOVE_PROJECT_USER_FAILED,
    errorMessage,
})

export const updateProjectUser = (token, project_id, data) => ({
    type: UPDATE_PROJECT_USER_REQUEST,
    token,
    project_id,
    data,
})

export const updateProjectUserFailed = (errorMessage) => ({
    type: UPDATE_PROJECT_USER_FAILED,
    errorMessage,
})

export const updateProjectUserSuccess = (data) => ({
    type: UPDATE_PROJECT_USER_SUCCEEDED,
    data,
})

// ==========================
//          SECTIONS
// ==========================

export const getSections = (id, token) => {
    return {
        type: GET_SECTIONS_REQUEST,
        id,
        token,
    }
}

export const getTasks = (id, token) => {
    return {
        type: GET_TASKS_REQUEST,
        id,
        token,
    }
}
