import {
    FETCH_COMPANY_START,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILED,
} from '../types/company.js'

const INITIAL_STATE = {
    data: [],
    errorMessage: undefined,
    loading: false,
}

const companyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPANY_START:
            return {
                ...state,
                loading: true,
            }

        case FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                errorMessage: false,
                loading: false,
            }

        case FETCH_COMPANY_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            }

        default:
            return state
    }
}

export default companyReducer
