// @ts-nocheck
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { DataGrid } from '@material-ui/data-grid'
import Typography from '@material-ui/core/Typography'

import { assetsTableColumns } from '../config/tableColumns.js'
import Spinner from '../components/Common/Spinner'
import AssetsMap from '../components/AssetsMap'
import { getUserAssets } from '../redux/actions/asset.js'
import { getClearBreadcrumbsPath } from '../redux/actions/system.js'
import { getToken } from '../services/authService.js'

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        height: '400px',
    },
    datagrid: {
        '& .assets-table-cell.hoverActive': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
    },
}))

const AssetsPage = ({ history, match: { url } }): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { data, totalCount, loading } = useSelector(
        (state) => state.user_asset_data
    )
    let columns = [...assetsTableColumns]
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(5)
    const [sortColumn, setSortColumn] = useState([
        { field: 'updated_at', sort: 'desc' },
    ])

    const [hoveredId, setHoveredId] = useState(null)

    columns = columns.map((colDef) => ({
        ...colDef,
        cellClassName: (params: GridCellClassParams) =>
            clsx('assets-table-cell', {
                hoverActive: params.row?.id === hoveredId,
            }),
    }))

    const handleSort = (params): void => {
        setSortColumn(params.sortModel)
    }

    const handleChangePage = (newPage): void => {
        setCurrentPage(parseInt(newPage, 10))
    }

    const handleChangeDataPerPage = (size): void => {
        const pageSize = parseInt(size, 10)
        setDataPerPage(pageSize)
        setCurrentPage(1)
    }

    const paginationProps = {
        currentPage,
        onChangePage: handleChangePage,
        dataPerPage,
        onChangeDataPerPage: handleChangeDataPerPage,
    }

    const handleClick = (id): void => {
        history.push(`${url}/${id}/overview`)
    }

    const handleHover = (id): void => {
        setHoveredId(id)
    }

    useEffect(() => {
        const token = getToken()
        const params = {
            sortColumn: sortColumn?.map((col) => ({
                column: col.field,
                order: col.sort,
            }))[0],
            dataPerPage,
            currentPage,
        }
        dispatch(getUserAssets(token, params))
	}, [sortColumn, dataPerPage, currentPage]); // eslint-disable-line

    //   update breadcrumbs
    useEffect(() => {
        dispatch(getClearBreadcrumbsPath())
    }, [])

    return (
        <>
            {!loading && data ? (
                <Box clone p={2}>
                    <Paper>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                            >
                                Assets
                            </Typography>
                        </Box>
                        <DataGrid
                            className={classes.datagrid}
                            paginationMode="server"
                            sortingMode="server"
                            sortModel={sortColumn}
                            onSortModelChange={handleSort}
                            rows={loading ? [] : data}
                            columns={columns}
                            pageSize={paginationProps.dataPerPage}
                            rowCount={totalCount}
                            loading={loading}
                            pagination
                            rowsPerPageOptions={[5, 10, 20]}
                            autoHeight
                            page={paginationProps.currentPage - 1}
                            onPageChange={({ page }) => {
                                paginationProps.onChangePage(page + 1)
                            }}
                            onPageSizeChange={({ pageSize }) => {
                                paginationProps.onChangeDataPerPage(pageSize)
                            }}
                            onRowClick={({ row }) => {
                                handleClick(row?.id)
                            }}
                            disableColumnMenu
                            onRowEnter={(params) => handleHover(params.row?.id)}
                            onRowLeave={() => handleHover(null)}
                        />
                        <Box pt={2} className={classes.mapContainer}>
                            <AssetsMap
                                data={data}
                                hoveredId={hoveredId}
                                onHover={handleHover}
                            />
                        </Box>
                    </Paper>
                </Box>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default AssetsPage
