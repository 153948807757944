// @ts-nocheck
import React, { useCallback } from 'react'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'

const DataBreadcrumbs = ({ crumbs, onCrumbClick }): JSX.Element => {
    const lastIndex = crumbs.length - 1
    const raiseClick = useCallback(
        (index) => {
            if (index === lastIndex) return
            onCrumbClick(index)
        },
        [onCrumbClick, lastIndex]
    )

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {crumbs.map(({ id, name }, index) => {
                const last = index === lastIndex
                return last ? (
                    // eslint-disable-next-line
                    <Typography color="textPrimary" key={index + id + name}>{name}</Typography>
                ) : (
                    // eslint-disable-next-line
                    <Link underline="always" href="#" color="textSecondary" key={index + id + name}  onClick={() => raiseClick(index)}>
                        {name}
                    </Link>
                )
            })}
        </Breadcrumbs>
    )
}

export default DataBreadcrumbs
