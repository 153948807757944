// @ts-nocheck
import React from 'react'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

// import { Indicator } from 'components/Common/Indicator/Indicator.styles'
import { dateFormatter } from '../../../utils/utils'
import {
    text,
    primary,
    boxShadow,
    statusColorScheme,
} from '../../../config/color_palette.js'

const MetaInfoTable = ({ onDownload, onExport }): JSX.Element => {
    const { name, asset_status, data_uploaded, latest_report } = useSelector(
        (state) => state.asset_data.data
    )
    const metaDataLists = [
        { 'Asset Name': name },
        { 'Asset Status': asset_status },
        { 'Last Inspection': !!latest_report && latest_report.created_at },
        { 'Latest Report': !!latest_report && latest_report.name },
        { 'Export Damages': 'CSV' },
        { 'Data Uploaded': data_uploaded },
    ]

    const renderMetaData = (key, value): JSX.Element | string => {
        switch (key.toLowerCase()) {
            case 'asset status':
                return (
                    <>
                        <div
                            style={{
                                display: 'inline-block',
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                marginRight: '8px',
                                backgroundColor:
                                    statusColorScheme[value]?.color,
                            }}
                        />
                        <span
                            style={{ color: statusColorScheme[value]?.color }}
                        >
                            <strong>{value}</strong>
                        </span>
                    </>
                )
            case 'last inspection':
                return value ? dateFormatter(value) : 'NA'
            case 'latest report':
                if (value === null) return 'No Report Available'
                return (
                    // eslint-disable-next-line
                    <Link
                        component="button"
                        variant="body2"
                        color="secondary"
                        underline="always"
                        onClick={() => onDownload(latest_report)}
                    >
                        {value}
                    </Link>
                )
            case 'export damages':
                return (
                    <Typography>
                        {/* eslint-disable-next-line */}
                        <Link
                            component="button"
                            variant="body2"
                            color="secondary"
                            underline="always"
                            onClick={() => onExport('json')}
                        >
                            JSON
                        </Link>{' '}
                        {/* eslint-disable-next-line */}
                        <Link
                            component="button"
                            variant="body2"
                            color="secondary"
                            underline="always"
                            onClick={() => onExport('csv')}
                        >
                            CSV
                        </Link>{' '}
                        {/* eslint-disable-next-line */}
                        <Link
                            component="button"
                            variant="body2"
                            color="secondary"
                            underline="always"
                            onClick={() => onExport('xls')}
                        >
                            XLS
                        </Link>{' '}
                        {/* eslint-disable-next-line */}
                        <Link
                            component="button"
                            variant="body2"
                            color="secondary"
                            underline="always"
                            onClick={() => onExport('xml')}
                        >
                            XML
                        </Link>
                    </Typography>
                )
            default:
                return value
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {metaDataLists.map((list) => {
                        const key = Object.keys(list).toString()
                        return (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="right">
                                    {renderMetaData(key, list[key])}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MetaInfoTable
