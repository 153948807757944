import { call, put, takeLatest } from 'redux-saga/effects'
import {
    fetchUsersAPI,
    fetchUserDetailsAPI,
    fetchChangePasswordAPI,
} from '../../config/api.js'
import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCEEDED,
    GET_USERS_FAILED,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCEEDED,
    GET_USER_DETAILS_FAILED,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCEEDED,
    CHANGE_PASSWORD_FAILED,
} from '../types/users.js'

function* getUsers(action) {
    try {
        const users = yield call(fetchUsersAPI, action.token)
        yield put({ type: GET_USERS_SUCCEEDED, users_data: users })
    } catch (e) {
        yield put({ type: GET_USERS_FAILED, message: e.message })
    }
}

function* getUserDetails(action) {
    try {
        const user_data = yield call(fetchUserDetailsAPI, action.token)
        yield put({
            type: GET_USER_DETAILS_SUCCEEDED,
            user_data: user_data.data,
        })
    } catch (e) {
        yield put({ type: GET_USER_DETAILS_FAILED, message: e.message })
    }
}

function* changePassword(action) {
    try {
        const user_data = yield call(
            fetchChangePasswordAPI,
            action.token,
            action.current_password,
            action.new_password
        )
        yield put({
            type: CHANGE_PASSWORD_SUCCEEDED,
            user_data: user_data.data,
        })
    } catch (e) {
        yield put({ type: CHANGE_PASSWORD_FAILED, message: e.message })
    }
}

export function* changePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)
}

export function* getUserSaga() {
    yield takeLatest(GET_USER_DETAILS_REQUEST, getUserDetails)
}

export function* getUsersSaga() {
    yield takeLatest(GET_USERS_REQUEST, getUsers)
}
