import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'

import Spinner from './Spinner'

export const PrivateRoute = ({ component, ...options }) => {
    const isAuthenticated = useSelector((state) => state.login.isAuthenticated)
    const isLoading = useSelector((state) => state.login.loading)
    const location = useLocation()

    if (isLoading) {
        return <Spinner />
    }

    if (isAuthenticated) {
        return <Route {...options} component={component} />
    }

    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
}

export const PublicRoute = ({ component, ...options }) => {
    return <Route {...options} component={component} />
}
