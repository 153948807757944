import {
    GET_SET_BREADCRUMBS_PATH_REQUEST,
    GET_SET_BREADCRUMBS_PATH_SUCCEEDED,
    GET_SET_BREADCRUMBS_PATH_FAILED,
    GET_CLEAR_BREADCRUMBS_PATH_REQUEST,
    GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED,
    TOGGLE_DARK_MODE,
} from '../actions/system.js'

const initialState = {
    parent: null,
    crumbs: [],
    darkMode: false,
}

export const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SET_BREADCRUMBS_PATH_SUCCEEDED:
            return {
                ...state,
                parent: action.parent,
                crumbs: action.crumbs,
            }
        case GET_SET_BREADCRUMBS_PATH_FAILED:
            return {
                ...state,
            }
        case GET_SET_BREADCRUMBS_PATH_REQUEST:
        case GET_CLEAR_BREADCRUMBS_PATH_REQUEST:
            return {
                ...state,
            }
        case GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED:
            return {
                ...state,
                parent: null,
                crumbs: [],
            }
        case TOGGLE_DARK_MODE:
            return {
                ...state,
                darkMode: action.payload,
            }
        default:
            return state
    }
}
