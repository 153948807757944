import { call, put, takeLatest } from 'redux-saga/effects'
import {
    GET_LOGIN_REQUEST,
    GET_LOGIN_SUCCEEDED,
    GET_LOGIN_FAILED,
    GET_LOGOUT_REQUEST,
    GET_LOGOUT_SUCCEEDED,
    GET_LOGOUT_FAILED,
    GET_CHECK_TOKEN_REQUEST,
    GET_CHECK_TOKEN_SUCCEEDED,
    GET_CHECK_TOKEN_FAILED,
    GET_CURRENT_USER_GROUPS_REQUEST,
    GET_CURRENT_USER_GROUPS_SUCCEEDED,
    GET_CURRENT_USER_GROUPS_FAILED,
} from '../actions/login.js'
import {
    fetchLoginAPI,
    fetchCheckTokenAPI,
    fetchCurrentUserGroups,
} from '../../config/api.js'

// const delay_seconds = 1000

function* login(action) {
    try {
        const login_data = yield call(fetchLoginAPI, action.user_data)
        yield put({ type: GET_LOGIN_SUCCEEDED, login_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_LOGIN_FAILED, message: e.message })
    }
}

export function* loginSaga() {
    yield takeLatest(GET_LOGIN_REQUEST, login)
}

function* logout(action) {
    try {
        yield put({ type: GET_LOGOUT_SUCCEEDED, data: [] })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_LOGOUT_FAILED, message: e.message })
    }
}

export function* logoutSaga() {
    yield takeLatest(GET_LOGOUT_REQUEST, logout)
}

function* checkToken(action) {
    try {
        const token_data = yield call(fetchCheckTokenAPI, action.token)
        yield put({ type: GET_CHECK_TOKEN_SUCCEEDED, payload: {...token_data, token: action.token } })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_CHECK_TOKEN_FAILED, message: e.message })
    }
}

export function* checkTokenSaga() {
    yield takeLatest(GET_CHECK_TOKEN_REQUEST, checkToken)
}

function* getCurrentUserGroups(action) {
    try {
        const response = yield call(fetchCurrentUserGroups, action.token)
        yield put({
            type: GET_CURRENT_USER_GROUPS_SUCCEEDED,
            response_data: response.data,
        })
    } catch (e) {
        yield put({ type: GET_CURRENT_USER_GROUPS_FAILED, message: e.message })
    }
}

export function* getCurrentUserGroupsSaga() {
    yield takeLatest(GET_CURRENT_USER_GROUPS_REQUEST, getCurrentUserGroups)
}
