import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
// import CardMedia from '@material-ui/core/CardMedia'
// import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined'
import Box from '@material-ui/core/Box'
import { Link as RouterLink } from 'react-router-dom'

import LinearProgressWithLabel from '../../Common/LinearProgressWithLabel'

const useStyles = makeStyles((theme) => ({
    icon: {
        ...theme.typography.h2,
    },
    card: {
        display: 'flex',
        width: '100%',
        height: '100px',
        [theme.breakpoints.up('md')]: {
            height: '200px',
        },
    },
    link: {
        textDecoration: 'none',
    },
}))

const ValidationOverview = (): JSX.Element => {
    // @ts-ignore
    const { data: assetData } = useSelector((state) => state.asset_data)
    const classes = useStyles()

    return (
        <RouterLink
            to={`/validations/${assetData.id}`}
            className={classes.link}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                className={classes.card}
                p={2}
            >
                <Typography variant="h5" gutterBottom color="textPrimary">
                    Damages Validated
                </Typography>
                <FindInPageOutlinedIcon
                    className={classes.icon}
                    color="primary"
                />
                <div style={{ width: '100%' }}>
                    <LinearProgressWithLabel
                        value={assetData.validation_progress}
                    />
                </div>
            </Box>
        </RouterLink>
    )
}

export default ValidationOverview
