// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },
}))

// eslint-disable-next-line
const DeleteModal = ({
    folderName,
    fileName = 'data',
    open,
    onDelete,
    onClose,
}) => {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                Delete {folderName}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This will delete{' '}
                    <strong className={classes.error}>{folderName}</strong> and
                    its {fileName} completely. We can not recover them once you
                    delete.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={onDelete}
                    color="secondary"
                    autoFocus
                    variant="contained"
                    startIcon={<DeleteOutlinedIcon />}
                >
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteModal
