import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import fileDownload from 'js-file-download'
import { withTheme } from '@material-ui/core/styles'

import ImageDialog from '../ImageDialog'
import CropDialog from './CropDialog'
import { getToken } from '../../services/authService.js'
import { connectCSS } from '../../utils/utils'
import Spinner from '../Common/Spinner'
import { getImage, getOverlay } from '../../redux/actions/data.js'

import {
    getSpheres,
    toggleFormOpen,
    getDamageCrop,
} from '../../redux/actions/sphere.js'
import { token_name } from '../../config/constants.js'
import {
    addAnnotations,
    init,
    drawSphere,
    drawLine,
    getMousePos,
    getRealWordlPoint,
    getMousePointCloudIntersection,
    checkAnnotation,
    uncheckAnnotation,
} from './helper.js'
import { removeDamagesImage, showDamagesImage } from './damagesHelper.js'
import { getCoordinateData } from '../../redux/actions/asset.js'
import SphereSideBar from './SphereSideBar.jsx'
import SideBar from './SideBar.js'

import './ThreeDModel.scss'

/*
import Unity, { UnityContext } from "react-unity-webgl";
const unityContext = new UnityContext({
    loaderUrl: "/unityGame/build.loader.js",
    dataUrl: "/unityGame/build.data",
    frameworkUrl: "/unityGame/build.framework.js",
    codeUrl: "/unityGame/build.wasm",
  });
*/

class ThreeDModel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            clicked: null,
            icon_hover: null,
            image_options_dialog_show: false,
            mouse: null,
            image_data: null,
            num_images_show: null,
            num_max_images_show: null,
            mouse_position: null,
            showAnnotations: false,
            coordinateData: [],
            isDeleteSphereDisable: true,
            label: null,
            sev_label: null,
            dialogShow: false,
            moveToCameraView: false,
            showCameraIcon: false,
        }

        this.handleImageClick = this.handleImageClick.bind(this)
        this.handleImageFullScreenClick = this.handleImageFullScreenClick.bind(
            this
        )
        // this.toggleDamagesImage = this.toggleDamagesImage.bind(this)
        this.handleEyeClick = this.handleEyeClick.bind(this)
        this.toggleSphereSidebar = this.toggleSphereSidebar.bind(this)

        const head = document.getElementsByTagName('head')[0]
        connectCSS('/libs/potree/potree_MODIFIED.css', head)
        connectCSS('/style/fontawesome-free-5.10.2-web/css/all.css', head)
        connectCSS('/libs/jquery-ui/jquery-ui.min.css', head)
        connectCSS('/libs/openlayers3/ol.css', head)
        connectCSS('/libs/spectrum/spectrum.css', head)
        connectCSS('/libs/jstree/themes/mixed/style.css', head)
    }

    componentDidMount() {
        const { asset_id, asset_data } = this.props

        const sev_label = asset_data.severity_labels.filter(
            ({ severity_label__name: name }) => name === 'All'
        )[0]?.severity_label__id        

        const label = asset_data.labels.filter(
            ({ label__name: name }) => name === 'All'
        )[0]?.label__id
        this.setState({ label, sev_label })
        
        // Delay to prevent interfering with materialUI tab animation
        setTimeout(() => {
            this.pointcloudInit(asset_data.three_d_model)

            this.props.getCoordinateData(
                localStorage.getItem(token_name),
                asset_id,
                500,
                null,
                null
            )

            this.props.getSpheres(localStorage.getItem(token_name), { asset_id , no_page: true })
        }, 0)
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            coordinate_data,
            img_data,
            data_change,
            asset_id,
            sphere_data,
        } = this.props
        const {
            num_images_show,
            num_max_images_show,
            label,
            sev_label,
            dialogShow,
        } = this.state
        const token = getToken()

        if (
            (!prevProps.sphere_data.formOpen && sphere_data.formOpen) ||
            (prevProps.sphere_data.formOpen && !sphere_data.formOpen)
        ) {
            this.toggleSphereSidebar()
        }
        if (!prevProps.sphere_data.loading && sphere_data.loading) {
            if (sphere_data.formOpen)
                this.setState({ isDeleteSphereDisable: true })
            else this.setState({ isDeleteSphereDisable: false })
        }

        if (prevProps.data_change.loading && !data_change.loading) {
            this.props.getCoordinateData(token, asset_id, num_images_show)
            this.props.getSpheres(token, { asset_id , no_page: true })
        }

        if (prevProps.coordinate_data.loading && !coordinate_data.loading) {
            if (num_max_images_show === null) {
                const num_images = parseInt(coordinate_data.data.length)
                this.setState({
                    num_max_images_show: coordinate_data.data.length,
                    num_images_show: num_images,
                    coordinateData: coordinate_data.data,
                })
            }

            // Delete previous data
            for (const key in prevProps.coordinate_data.data) {
                const item = prevProps.coordinate_data.data[key]
                const text = item.name
                uncheckAnnotation(text)
            }

            for (const key in coordinate_data.data) {
                const item = coordinate_data.data[key]
                const annElement = addAnnotations(item)
                annElement.addEventListener('mouseenter', () => {
                    const labelIcon = document.getElementById(
                        `label-icon-${item.name}`
                    )
                    if (labelIcon.classList.contains('fa-eye')) {
                        this.props.getOverlay(token, item.id, label, sev_label)
                    } else {
                        this.props.getImage(token, item.id)
                    }

                    const imgDesc = document.getElementById(item.name)
                    const loader = annElement.getElementsByClassName(
                        'loader'
                    )[0]
                    if (loader === undefined && imgDesc.src === '') {
                        const descriptionConten = annElement.getElementsByClassName(
                            'annotation-description-content'
                        )[0]
                        const spinner = document.createElement('span')
                        spinner.innerHTML = '<div class="loader"></div>'
                        descriptionConten.appendChild(spinner)
                    }
                    this.setState({ image_data: item, icon_hover: annElement })
                })
            }
        }
        if (prevProps.img_data.loading && !img_data.loading && !dialogShow) {
            this.annotationsHover()
        }

        if (prevProps.file_data.file !== this.props.file_data.file) {
            fileDownload(this.props.file_data.file, this.props.file_data.name)
        }
    }

    componentWillUnmount() {
        const token = getToken()
        const { asset_id } = this.props

        const element = document.getElementById(
            'three_d_model_external_scripts_css'
        )
        if (typeof element !== 'undefined' && element != null) {
            element.remove()
        }
        window.top.location = window.top.location
    }

    annotationsHover() {
        try {
            const { image_data, icon_hover } = this.state
            const { img_data } = this.props

            const descriptionElement = icon_hover.getElementsByClassName(
                'annotation-description'
            )[0]

            descriptionElement.style.removeProperty('top')
            descriptionElement.style.removeProperty('left')

            const loader = icon_hover.getElementsByClassName('loader')[0]
            const imgDesc = document.getElementById(image_data.name)
            if (loader !== undefined) {
                loader.parentNode.removeChild(loader)
            }
            imgDesc.src = `data:image/png;base64,${img_data.img_data.img.slice(
                1,
                -1
            )}`

            document
                .getElementById(image_data.name)
                .addEventListener('click', this.handleImageClick)
            document
                .getElementById(`label-icon-${image_data.name}`)
                .addEventListener('click', this.handleEyeClick)
            document
                .getElementById(`expand-icon-${image_data.name}`)
                .addEventListener('click', this.handleImageFullScreenClick)
            // if (image_data.damage_points)
            //     document
            //         .getElementById(`damage-icon-${image_data.name}`)
            //         .addEventListener('click', this.toggleDamagesImage)

            const titlebarElement = icon_hover.getElementsByClassName(
                'annotation-titlebar'
            )[0]
            const rect_t = titlebarElement.getBoundingClientRect()
            const rect_w = document
                .getElementById('rendererCanvas')
                .getBoundingClientRect()

            if (rect_w.bottom < descriptionElement.getBoundingClientRect().bottom) {
                const he = -descriptionElement.getBoundingClientRect().height
                const wi =
                    -descriptionElement.getBoundingClientRect().width / 2 +
                    rect_t.width / 2
                descriptionElement.style.left = `${wi.toString()}px`
                descriptionElement.style.top = `${he.toString()}px`
            } else {
                const wi =
                    -descriptionElement.getBoundingClientRect().width / 2 +
                    rect_t.width / 2
                descriptionElement.style.left = `${wi.toString()}px`
                const he = rect_t.height + 10
                descriptionElement.style.top = `${he.toString()}px`
            }
            if (rect_w.right < descriptionElement.getBoundingClientRect().right) {
                const diff = Math.abs(
                    rect_w.right - descriptionElement.getBoundingClientRect().right
                )
                const wi =
                    -descriptionElement.getBoundingClientRect().width / 2 +
                    rect_t.width / 2 -
                    diff
                descriptionElement.style.left = `${wi.toString()}px`
            } else if (
                rect_w.left > descriptionElement.getBoundingClientRect().left
            ) {
                const diff = Math.abs(
                    rect_w.left - descriptionElement.getBoundingClientRect().left
                )
                const wi =
                    -descriptionElement.getBoundingClientRect().width / 2 +
                    rect_t.width / 2 +
                    diff
                descriptionElement.style.left = `${wi.toString()}px`
            }
        } catch (e) {
            console.error(e)
        }
    }

    // toggleDamagesImage(event) {
    //     const { image_data } = this.state
    //     const damageIcon = event.target
    //     damageIcon.src = event.target.classList.contains('alert-triangle-line')
    //         ? '/images/threeDmodel/alert-triangle-solid.svg'
    //         : '/images/threeDmodel/alert-triangle-line.svg'
    //     if (event.target.classList.contains('alert-triangle-line')) {
    //         removeDamagesImage(image_data)
    //         showDamagesImage(image_data, 1, false)

    //         damageIcon.classList.remove('alert-triangle-line')
    //         damageIcon.classList.add('alert-triangle-solid')
    //     } else {
    //         removeDamagesImage(image_data)
    //         damageIcon.classList.remove('alert-triangle-solid')
    //         damageIcon.classList.add('alert-triangle-line')
    //     }
    // }

    handleImageFullScreenClick() {
        this.setState({ image_options_dialog_show: true })
    }

    handleEyeClick(event) {
        const { image_data, label, sev_label } = this.state
        const token = getToken()
        const imgDesc = document.getElementById(image_data.name)

        if (event.target.classList.contains('fa-eye')) {
            this.props.getImage(token, image_data.id)
            event.target.classList.remove('fa-eye')
            event.target.classList.add('fa-eye-slash')
        } else {
            this.props.getOverlay(token, image_data.id, label, sev_label)
            event.target.classList.remove('fa-eye-slash')
            event.target.classList.add('fa-eye')
        }
        imgDesc.src = `data:image/png;base64,${this.props.img_data.img_data.img.slice(
            1,
            -1
        )}`
    }

    handleImageClick(event) {
        const { image_data } = this.state
        const imgDesc = event.target
        const pt2d = getMousePos(imgDesc, event)

        const w_old = imgDesc.getBoundingClientRect().width
        const h_old = imgDesc.getBoundingClientRect().height
        const anPosition = [image_data.X, image_data.Y, image_data.Z]

        const direction_ = getRealWordlPoint(pt2d, image_data, w_old, h_old)
        const direction = new window.THREE.Vector3(...direction_)
        const origin = new window.THREE.Vector3(...anPosition)

        const camera = window.camera.clone()
        camera.position.set(origin.x, origin.y, origin.z)
        camera.lookAt(direction)
        camera.updateProjectionMatrix()

        const { renderer } = window.viewer
        const mouse = {
            x: renderer.domElement.clientWidth / 2,
            y: renderer.domElement.clientHeight / 2,
        }

        const point3d = getMousePointCloudIntersection(
            mouse,
            camera,
            window.viewer,
            window.viewer.scene.pointclouds
        )
        if (point3d) {
            const damage_sphere = drawSphere(
                image_data,
                point3d.location,
                1,
                'f8f8ff',
                false,
                null
            )

            drawLine(image_data, origin, point3d.location, null)
            damage_sphere.userData.sphere_data.note = ''
        }
    }

    handleSeverityChange = (values) => {
        this.setState({ severity_scores: values })
    }

    handleImageShowChange = (value) => {
        this.setState({ num_images_show: value })
        const { asset_id } = this.props
        this.props.getCoordinateData(
            localStorage.getItem(token_name),
            asset_id,
            value,
            null,
            null
        )
    }

    handleShereHover(event) {
        if (!event.target.matches('#rendererCanvas')) return

        const pt2d = getMousePos(
            document.getElementById('rendererCanvas'),
            event
        )

        window.mouse.x = (pt2d.x / window.$('#rendererCanvas').width()) * 2 - 1
        window.mouse.y =
            -(pt2d.y / window.$('#rendererCanvas').height()) * 2 + 1
        const camera = window.viewer.scene.cameraP.clone()
        camera.position.set(
            window.viewer.scene.view.position.x,
            window.viewer.scene.view.position.y,
            window.viewer.scene.view.position.z
        )
        camera.lookAt(window.viewer.scene.view.position)
        camera.updateProjectionMatrix()

        window.raycaster.setFromCamera(window.mouse, camera)

        const intersects = window.raycaster.intersectObjects(
            window.damage_points
        )
        if (intersects.length > 0) {
            if (window.INTERSECTED) {
                window.INTERSECTED.material.opacity =
                    window.INTERSECTED.userData.sphere_data.last_opacity
            }

            window.INTERSECTED = intersects[0].object
            window.INTERSECTED.userData.sphere_data.hover = true

            window.INTERSECTED.userData.sphere_data.last_opacity =
                window.INTERSECTED.material.opacity

            window.INTERSECTED.material.opacity = 1.0
        } else {
            if (window.INTERSECTED) {
                window.INTERSECTED.material.opacity =
                    window.INTERSECTED.userData.sphere_data.last_opacity
            }

            window.INTERSECTED = null
        }
    }

    toggleSphereSidebar() {
        const { sphere_data } = this.props

        const renderArea = window.$('#potree_render_area')
        const $sphereSidebar = window.$('.sphereButton')

        if (!sphere_data.formOpen) {
            $sphereSidebar.css('visibility', 'hidden')
            renderArea.css('right', '0')
        } else {
            $sphereSidebar.css('visibility', 'visible')
            renderArea.css('right', '250px')
        }
    }

    handlePotreeClick = (event) => {
        if (!event.target.matches('#rendererCanvas')) {
            if (
                this.props.sphere_data.formOpen &&
                !event.target.closest('.sphereButton')
            ) {
                this.state.clicked.material.opacity = 1.0
                this.props.toggleFormOpen()
            }
            return
        }
        const pt2d = getMousePos(
            document.getElementById('rendererCanvas'),
            event
        )
        window.mouse.x = (pt2d.x / window.$('#rendererCanvas').width()) * 2 - 1
        window.mouse.y =
            -(pt2d.y / window.$('#rendererCanvas').height()) * 2 + 1

        const camera = window.viewer.scene.cameraP.clone()
        camera.position.set(
            window.viewer.scene.view.position.x,
            window.viewer.scene.view.position.y,
            window.viewer.scene.view.position.z
        )
        camera.lookAt(window.viewer.scene.view.position)
        camera.updateProjectionMatrix()

        window.raycaster.setFromCamera(window.mouse, camera)

        const intersects = window.raycaster.intersectObjects(
            window.damage_points
        )

        if (intersects.length > 0) {
            if (window.INTERSECTED_CLICK) {
                window.INTERSECTED_CLICK.material.opacity = 0.5
            }
            window.INTERSECTED_CLICK = intersects[0].object
            const { image_data } = window.INTERSECTED_CLICK.userData

            if (this.state.moveToCameraView) {
                let annotations = window.viewer.scene.getAnnotations()
                const annotation = annotations.children.filter(
                    (el) => el._title === image_data.name
                )
                annotation[0].clickTitle()
            }
            const backendData = window.INTERSECTED_CLICK.userData.sphere_backend
                ? window.INTERSECTED_CLICK.userData.sphere_backend
                : null

            const token = getToken()
            if (backendData)
                this.props.getDamageCrop(token, backendData.id, false)
            if (this.state.showCameraIcon) {
                checkAnnotation(image_data.name)
                const origin = new window.THREE.Vector3(
                    ...[image_data.X, image_data.Y, image_data.Z]
                )
                drawLine(
                    image_data,
                    origin,
                    window.INTERSECTED_CLICK.position,
                    backendData
                )
            }

            if (!this.props.sphere_data.formOpen) {
                this.props.toggleFormOpen()
            }

            this.setState({
                clicked: window.INTERSECTED_CLICK,
                mouse_position: pt2d,
                isDeleteSphereDisable: false,
            })
            window.INTERSECTED_CLICK.material.opacity = 1.0

            window.INTERSECTED_CLICK.userData.sphere_data.last_opacity =
                window.INTERSECTED_CLICK.material.opacity
        }
    }

    pointcloudInit(path) {
        init(path)
    }

    handleCheckShowCameraIcon = () => {
        if (this.state.showCameraIcon) {
            for (let i = 0; i < window.all_damage_lines.length; i++) {
                const rem = window.all_damage_lines[i]
                const { image_data } = rem.userData
                window.viewer.scene.scene.remove(rem)
                uncheckAnnotation(image_data.name)
            }
            window.all_damage_lines = []
        }
        this.setState({
            showCameraIcon: !this.state.showCameraIcon,
        })
    }

    handleCheckCameraView = () => {
        this.setState({
            moveToCameraView: !this.state.moveToCameraView,
        })
    }

    toggleDialogShow = () => {
        this.setState({
            dialogShow: !this.state.dialogShow,
        })
    }

    toggleImageDialogShow = () => {
        this.setState({
            image_options_dialog_show: !this.state.image_options_dialog_show,
        })
    }

    render() {
        const {
            image_data,
            image_options_dialog_show,
            num_images_show,
            num_max_images_show,
            clicked,
            mouse_position,
            coordinateData,
            severity_score,
            isDeleteSphereDisable,
            dialogShow,
        } = this.state

        const { darkMode, theme } = this.props

        return (
            <div className="three_d_section_full">
                {dialogShow && (
                    <CropDialog
                        open={dialogShow}
                        sphereData={clicked.userData.sphere_backend}
                        imageData={clicked.userData.image_data}
                        onClose={this.toggleDialogShow}
                    />
                )}
                {image_options_dialog_show && (
                    <ImageDialog
                        data={image_data}
                        open={image_options_dialog_show}
                        onClose={this.toggleImageDialogShow}
                    />
                )}
                <div className="potree_container">
                    <div
                        id="potree_render_area"
                        onClick={this.handlePotreeClick}
                        onMouseMove={this.handleShereHover}
                    />
                    <SphereSideBar
                        clickedSphere={clicked}
                        mousePos={mouse_position}
                        coordinateData={coordinateData}
                        toggleSphereSidebar={this.toggleSphereSidebar}
                        isDisable={isDeleteSphereDisable}
                        toggleDialogShow={this.toggleDialogShow}
                    />
                    <div id="potree_sidebar_container" style={{
                        backgroundColor: theme.palette.background.default
                    }}>
                        <SideBar
                            severity_score={severity_score}
                            handleSeverityChange={this.handleSeverityChange}
                            handleImageShowChange={this.handleImageShowChange}
                            num_images={num_images_show}
                            num_max_images={num_max_images_show}
                            moveToCameraView={this.state.moveToCameraView}
                            handleCheckCameraView={this.handleCheckCameraView}
                            showCameraIcon={this.state.showCameraIcon}
                            handleCheckShowCameraIcon={
                                this.handleCheckShowCameraIcon
                            }
                        />
                    </div>
                    {this.props.sphere_data.loading &&
                        !this.props.sphere_data.formOpen && (
                            <Spinner opacity={0.7} />
                        )}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getImage,
            getOverlay,
            getCoordinateData,
            getSpheres,
            toggleFormOpen,
            getDamageCrop,
        },
        dispatch
    )
}

const mapStateToProps = ({
    img_data,
    asset_data: { data },
    coordinate_data,
    sphere_data,
    file_data,
    data_change,
    system: { darkMode }
}) => ({
    img_data,
    asset_data: data,
    coordinate_data,
    sphere_data,
    file_data,
    data_change,
    darkMode,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ThreeDModel))
