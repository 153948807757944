// @ts-nocheck
import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import SvgIcon from '@material-ui/core/SvgIcon'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
    Link as RouterLink,
    withRouter,
    RouteComponentProps,
} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'

import {
    mainListItems,
    secondaryListItems,
    logoClickPage,
} from '../config/navigations.js'
import PortOfAntwerpClientImage from '../assets/logo/PoA_Logo_pos_rgb.png'
import NierdersachsenClientImage from '../assets/logo/NPO_Logo_RZ_sRGB.png'
import NorthSeaPortClientImage from '../assets/logo/NortSeaPort_logo.jpg'
import MavisoftClientImage from '../assets/logo/mavisoft_black.png'
import MavisoftClientImageDark from '../assets/logo/mavisoft_white.png'
import PerformanceRotorsClientImage from '../assets/logo/Performance_rotots_logo.png'
import PortofAmsterdamClientImage from '../assets/logo/port-of-amsterdam.png'
import PortofZeebruggeClientImage from '../assets/logo/Zeebrugge-LOGO.png'

import ListItemLink from './Common/ListItemLink'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(7) + 1,
            flexShrink: 0,
        },
    },
    drawerPaperOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    hidden: {
        display: 'none',
    },
}))

type SidebarProps = {
    isOpen: boolean
    handleDrawerClose: () => void
    setDrawerState: (boolean) => void
}

const Sidebar = ({
    handleDrawerClose,
    isOpen,
    history,
    setDrawerState,
}: SidebarProps & RouteComponentProps): JSX.Element => {
    const classes = useStyles()
    const username = useSelector((state) => state.user_data.user.username)
    const isDarkMode = useSelector((state) => state.system.darkMode)
    const { parent, crumbs } = useSelector((state) => state.system)
    const logos = [
        {
            username: 'PerformanceRotors',
            src: PerformanceRotorsClientImage,
            alt: 'PerformanceRotors logo',
        },
        {
            username: 'AquaSmartXL',
            src: isDarkMode ? MavisoftClientImageDark : MavisoftClientImage,
            alt: 'AquasmartXL logo',
        },
        {
            username: 'teddy',
            src: NierdersachsenClientImage,
            alt: 'Nierdersachsen logo',
        },
        {
            username: 'portofantwerp',
            src: PortOfAntwerpClientImage,
            alt: 'Port of Antwerp logo',
        },
        {
            username: 'NorthSeaPort',
            src: NorthSeaPortClientImage,
            alt: 'North Sea Port logo',
        },
        {
            username: 'NPorts',
            src: NierdersachsenClientImage,
            alt: 'Nports logo',
        },
        {
            username: 'PortofAmsterdam',
            src: PortofAmsterdamClientImage,
            alt: 'PortofAmsterdam logo',
        },
        {
            username: 'PortofZeebrugge',
            src: PortofZeebruggeClientImage,
            alt: 'PortofZeebrugge logo',
        },
    ]

    const logo = logos.filter((item) => item.username === username)[0]
    const drawer = (
        <div
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
            className={classes.drawerContainer}
        >
            <Box>
                <RouterLink to={logoClickPage.url}>
                    {logo ? (
                        <img
                            alt={logo.alt}
                            src={logo.src}
                            className={classes.logo}
                            style={{ padding: '10px' }}
                        />
                    ) : (
                        <img
                            alt="mavisoft logo"
                            src={
                                isDarkMode
                                    ? MavisoftClientImageDark
                                    : MavisoftClientImage
                            }
                            className={classes.logo}
                            style={{ padding: '10px' }}
                        />
                    )}
                </RouterLink>
            </Box>

            <Divider />
            <List dense>
                {mainListItems.map((item) => {
                    return (
                        <React.Fragment key={item.text}>
                            <ListItemLink
                                button
                                to={item.url}
                                primary={item.text}
                                icon={
                                    <SvgIcon
                                        component={item.icon}
                                        color={item.color}
                                    />
                                }
                            />
                            <Collapse
                                in={item.url === parent}
                                timeout="auto"
                                unmountOnExit
                                className={clsx({
                                    [classes.hidden]: !isOpen,
                                })}
                            >
                                <List component="div" disablePadding dense>
                                    {item.url === parent &&
                                        crumbs.map(({ label, path }, index) => {
                                            return (
                                                <ListItem
                                                    key={label}
                                                    button
                                                    onClick={() => {
                                                        history.push(path)
                                                    }}
                                                    disabled={
                                                        index ===
                                                        crumbs.length - 1
                                                    }
                                                    className={classes.nested}
                                                >
                                                    <ListItemText
                                                        primary={label}
                                                    />
                                                </ListItem>
                                            )
                                        })}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                })}
            </List>
            <div style={{ flexGrow: 1 }} />
            <Divider />
            <Box p={1}>
                <Avatar />
            </Box>
            <List dense>
                {secondaryListItems.map((item) => {
                    return (
                        <ListItemLink
                            button
                            key={item.text}
                            to={item.url}
                            primary={item.text}
                            icon={
                                <SvgIcon
                                    component={item.icon}
                                    color={item.color}
                                />
                            }
                        />
                    )
                })}
            </List>
        </div>
    )

    return (
        <nav
            onMouseEnter={() => {
                setDrawerState(true)
            }}
            onMouseLeave={() => {
                setDrawerState(false)
            }}
            className={classes.drawer}
            aria-label="primary navigation"
        >
            {/* Small screens */}
            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={isOpen}
                    onClose={handleDrawerClose}
                    classes={{
                        paper: classes.drawerPaperOpen,
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            {/* Large screens */}
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                        paper: clsx({
                            [classes.drawerPaperOpen]: isOpen,
                            [classes.drawerPaperClose]: !isOpen,
                        }),
                    }}
                    variant="permanent"
                    PaperProps={{
                        elevation: 6,
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    )
}

export default withRouter(Sidebar)
