// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import fileDownload from 'js-file-download'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import LaunchIcon from '@material-ui/icons/Launch'
import IconButton from '@material-ui/core/IconButton'
import exportFromJSON from 'export-from-json'

import DamageChart from './DamageChart'
import SeverityChart from './SeverityChart'
import AssetsMap from '../../AssetsMap'
import MetaInfoTable from './MetaInfoTable'
import DamageImages from './DamageImages'
import ValidationOverview from './ValidationOverview'
import usePrevious from '../../Hook/usePrevious'
import { getImage } from '../../../redux/actions/data.js'
import { getToken } from '../../../services/authService.js'
import { downloadReport } from '../../../redux/actions/report.js'
import { exportSpheresAPI } from '../../../config/api.js'
import ThreeDModel from '../../ThreeDModel/ThreeDModel.js'

const useStyles = makeStyles({
    gridPaper: {
        flexGrow: 1,
        height: '100%',
    },
    gridContainer: {
        maxHeight: '340px',
    },
    launch3DBtn: {
        position: 'absolute',
        zIndex: 100,
    },
})

const Overview = (): JSX.Element => {
    const token = getToken()
    const classes = useStyles()
    const history = useHistory()
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const { data: assetData } = useSelector((state) => state.asset_data)
    const isDarkMode = useSelector((state) => state.system.darkMode)
    const imgData = useSelector((state) => state.img_data)
    const reportDownload = useSelector((state) => state.report_download)
    const [imageIndex, setImageIndex] = useState(0)
    const [imageAmount, setImageAmount] = useState(0)
    const [isPotreeReady, setIsPotreeReady] = useState(false)
    const [isViewerReady, setIsViewerReady] = useState(false)
    const prevDownload = usePrevious(reportDownload.file)
    const [dmgCountClicked, setDmgCountClicked] = useState('All Damages')
    const [dmgAreaClicked, setDmgAreaClicked] = useState('All Damages')
    const { enqueueSnackbar } = useSnackbar()

    const handleDownload = ({ id, name }): void => {
        dispatch(downloadReport(token, id, name))
    }

    const handleDamagesExport = (fmt): void => {
        enqueueSnackbar(
            'We are creating your export! This might take a while. Please wait.',
            {
                variant: 'info',
            }
        )
        exportSpheresAPI(token, assetData?.id).then((response) => {
            const fileName = `asset_${assetData?.id}_damages_${format(
                new Date(),
                "dd-MM-yyyy'T'HH:mm:ss"
            )}`
            exportFromJSON({
                data: response.data,
                fileName,
                exportType: fmt,
            })
        })
    }

    const handleImageNavigation = (number): void => {
        setImageIndex(number)
    }

    const valueLabel = {
        0: 'A (Good)',
        1: 'B (Low)',
        2: 'C (Medium)',
        3: 'D (High)',
        4: 'E (Very high)',
    }

    const handleDmgAreaClick = (node): void => {
        if (dmgAreaClicked === node.id) {
            setDmgAreaClicked('All Damages')
        } else {
            setDmgAreaClicked(node.id)
        }
    }

    const handleDmgCountClick = (node): void => {
        if (dmgCountClicked === node.id) {
            setDmgCountClicked('All Damages')
        } else {
            setDmgCountClicked(node.id)
        }
    }
    const handlePotreeEvent = (): void => {
        setTimeout(() => {
            setIsPotreeReady(true)
        }, 0)
    }

    const handlePotreeSidebarEvent = (): void => {
        setIsViewerReady(true)
    }

    // Check whether potree has been loaded
    useEffect(() => {
        if (window.viewer) {
            handlePotreeEvent()
        } else {
            window.addEventListener('potree-viewer-init', handlePotreeEvent)
        }
        window.addEventListener(
            'potree-pointcloud-init',
            handlePotreeSidebarEvent
        )

        return () => {
            window.removeEventListener(
                'potree-pointcloud-init',
                handlePotreeSidebarEvent
            )
            window.removeEventListener('potree-viewer-init', handlePotreeEvent)
        }
    }, [])

    useEffect(() => {
        if (isViewerReady) {
            // show damages in pointcloud
            setTimeout(() => {
                document.getElementById('3d-sidebar-ai-damages').click()
                document.getElementById('3d-sidebar-extra-vision').click()
            }, 1000)
        }
    }, [isViewerReady])

    useEffect(() => {
        const amount = assetData.scroll_damage_images.length
        if (amount === 0) return
        setImageAmount(amount)
    }, [])

    useEffect(() => {
        if (!imageAmount) return
        const damageImages = assetData.scroll_damage_images
        dispatch(getImage(token, damageImages[imageIndex].id))
    }, [assetData, imageIndex, imageAmount, getImage, token])

    useEffect(() => {
        if (reportDownload.file.length === 0) return
        if (prevDownload !== reportDownload.file) {
            const { file, name } = reportDownload
            fileDownload(file, name)
        }
    }, [prevDownload, reportDownload])

    return (
        <Grid
            container
            spacing={2}
            style={{
                backgroundColor: isDarkMode ? '#212121' : 'inherit',
            }}
        >
            <Grid item xs={12} md={6} lg={4}>
                <Paper variant="outlined">
                    <DamageChart
                        data={assetData.severity_dmg_counts}
                        onClick={handleDmgCountClick}
                        chartType="count"
                    />
                    <SeverityChart
                        data={assetData.severity_dmg_counts}
                        keys={Object.values(valueLabel)}
                        dmgClicked={dmgCountClicked}
                        chartType="count"
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Paper variant="outlined">
                    <DamageChart
                        data={assetData.area_per_label}
                        onClick={handleDmgAreaClick}
                        chartType="area"
                    />
                    <SeverityChart
                        data={assetData.area_per_label}
                        keys={Object.values(valueLabel)}
                        dmgClicked={dmgAreaClicked}
                        chartType="area"
                    />
                </Paper>
            </Grid>
            <Grid item container xs={12} md={6} lg={4}>
                <Paper className={classes.gridPaper} variant="outlined">
                    <AssetsMap data={[assetData]} />
                </Paper>
            </Grid>
            <Grid
                item
                container
                xs={12}
                md={6}
                lg={4}
                className={classes.gridContainer}
            >
                <Paper className={classes.gridPaper} variant="outlined">
                    <DamageImages
                        imageAmount={imageAmount}
                        imageIndex={imageIndex}
                        imgUrl={imgData.img_data.img}
                        imgName={imgData.name}
                        loading={imgData.loading}
                        onClick={handleImageNavigation}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.gridContainer}>
                <Paper variant="outlined">
                    <MetaInfoTable
                        onDownload={handleDownload}
                        onExport={handleDamagesExport}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.gridContainer}>
                <Paper className={classes.gridPaper} variant="outlined">
                    <ValidationOverview />
                </Paper>
            </Grid>
            <Grid
                item
                container
                xs={12}
                md={6}
                lg={8}
                className={classes.gridContainer}
            >
                <Paper
                    className={`${classes.gridPaper} overview-grid`}
                    variant="outlined"
                >
                    <IconButton
                        aria-label="launch 3D viewer"
                        color="primary"
                        className={classes.launch3DBtn}
                        onClick={() => {
                            history.push(url.replace('overview', '3dmodel'))
                        }}
                    >
                        <LaunchIcon />
                    </IconButton>
                    {assetData?.id && isPotreeReady && (
                        <ThreeDModel asset_id={`${assetData.id}`} />
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Overview
