// @ts-nocheck
import React from 'react'

import Projects from '../components/Projects'
import Assets from '../components/Assets'
import AssetInfoPage from './AssetInfoPage'
import { PrivateRoute } from '../components/Common/Routes.js'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ProjectsPage = ({ match: { path } }): JSX.Element => (
    <>
        <PrivateRoute path={`${path}`} component={Projects} exact />
        <PrivateRoute path={`${path}/:id`} component={Assets} exact />
        <PrivateRoute
            path={[`${path}/:projectid/assets/:id`]}
            component={AssetInfoPage}
        />
    </>
)

export default ProjectsPage
