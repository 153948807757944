// @ts-nocheck
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SvgIcon from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'

import { ReactComponent as EditIcon } from '../assets/actions/edit.svg'
import { ReactComponent as DeleteIcon } from '../assets/actions/trash.svg'
import { ReactComponent as CancelIcon } from '../assets/actions/cancel.svg'
import DeleteModal from './Common/Modal/DeleteModal'
import {
    deleteAsset,
    toggleFormOpen,
    setFormData,
} from '../redux/actions/project.js'
import { getToken } from '../services/authService.js'

const useStyles = makeStyles({
    actionBox: {
        width: '100%',
    },
})

// eslint-disable-next-line
const AssetsActions = ({ data, onHover }): JSX.Element => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleOptionsClick = (event): void => {
        setAnchorEl(event.currentTarget)
        setOpen('menu')
        dispatch(setFormData(data))
    }

    const handleOptionsClose = (): void => {
        setAnchorEl(null)
        setOpen(null)
        onHover(null)
    }

    const handleModalOpen = (): void => {
        setOpen('modal')
    }

    const handleEdit = (): void => {
        dispatch(toggleFormOpen())
        handleOptionsClose()
    }

    const handleDelete = (): void => {
        const token = getToken()
        dispatch(deleteAsset(token, data.id))
        handleOptionsClose()
    }

    const actionLists = [
        {
            icon: EditIcon,
            label: 'Edit Asset',
            onAction: handleEdit,
            color: 'primary',
        },
        {
            icon: DeleteIcon,
            label: 'Delete',
            onAction: handleModalOpen,
            color: 'error',
        },
        {
            icon: CancelIcon,
            label: 'Cancel',
            onAction: handleOptionsClose,
            color: 'primary',
        },
    ]

    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.actionBox}
            >
                <IconButton
                    color="inherit"
                    aria-label="more options"
                    aria-controls={`assets-actions-menu-${data.id}`}
                    aria-haspopup="true"
                    onClick={handleOptionsClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>

            <Menu
                id={`assets-actions-menu-${data.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleOptionsClose}
            >
                {actionLists.map((item) => {
                    return (
                        <MenuItem key={item.label} onClick={item.onAction}>
                            <ListItemIcon>
                                <SvgIcon
                                    component={item.icon}
                                    color={item.color}
                                />
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </MenuItem>
                    )
                })}
            </Menu>
            <DeleteModal
                folderName={data.name}
                fileName="files"
                open={open === 'modal'}
                onDelete={handleDelete}
                onClose={handleOptionsClose}
            />
        </>
    )
}

export default AssetsActions
