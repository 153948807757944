// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import GetAppIcon from '@material-ui/icons/GetApp'

import Action from '../../Common/Action'
import DeleteModal from '../../Common/Modal/DeleteModal'
import { ReactComponent as DeleteIcon } from '../../../assets/actions/trash.svg'
import { ReactComponent as CancelIcon } from '../../../assets/actions/cancel.svg'
import { deleteReport, downloadReport } from '../../../redux/actions/report.js'
import { getToken } from '../../../services/authService.js'

const ReportActions = ({ report }): JSX.Element => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(null)

    const handleMenuOpen = (): void => {
        setOpen('menu')
    }

    const handleModalOpen = (): void => {
        setOpen('modal')
    }

    const handleClose = (): void => {
        setOpen(null)
    }

    const handleDelete = (): void => {
        const token = getToken()
        dispatch(deleteReport(token, report.id))
    }

    const handleDownload = (): void => {
        const token = getToken()
        dispatch(downloadReport(token, report.id, report.name))
    }

    const actionLists = [
        {
            icon: GetAppIcon,
            label: 'Download',
            color: 'primary',
            onAction: handleDownload,
            closeOnClick: true,
        },
        {
            icon: DeleteIcon,
            label: 'Delete',
            color: 'error',
            onAction: handleModalOpen,
        },
        {
            icon: CancelIcon,
            label: 'Cancel',
            color: 'primary',
            onAction: handleClose,
            closeOnClick: true,
        },
    ]

    return (
        <>
            <Action
                name={`report-actions-${report.id}`}
                list={actionLists}
                onActionButtonClick={handleMenuOpen}
                onOptionsClose={handleClose}
            />
            <DeleteModal
                folderName={report.name}
                fileName="files"
                open={open === 'modal'}
                onDelete={handleDelete}
                onClose={handleClose}
            />
        </>
    )
}

export default ReportActions
