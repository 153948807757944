import {
    TOGGLE_PROJECT_FORM_OPEN,
    SET_PROJECT_FORM_DATA,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCEEDED,
    GET_PROJECTS_FAILED,
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_SUCCEEDED,
    ADD_PROJECT_FAILED,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCEEDED,
    UPDATE_PROJECT_FAILED,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCEEDED,
    DELETE_PROJECT_FAILED,
    GET_LABELS_SUCCEEDED,
    GET_LABELS_REQUEST,
    GET_LABELS_FAILED,
} from '../types/dashboard.js'
import {
    GET_DASHBOARD_FAILED,
    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCEEDED,
} from '../actions/dashboard.js'
import { FETCH_COMPANY_START } from '../types/company.js'

const projectsInitialState = {
    formOpen: false,
    formData: undefined,
    totalCount: null,
    totalPages: null,
    prev: null,
    next: null,
    data: [],
    errorMessage: undefined,
    loading: false,
}

export const projectsReducer = (state = projectsInitialState, action) => {
    switch (action.type) {
        case TOGGLE_PROJECT_FORM_OPEN:
            return {
                ...state,
                formOpen: !state.formOpen,
                // errorMessage: null,
            }
        case SET_PROJECT_FORM_DATA:
            return {
                ...state,
                formData: action.data,
                errorMessage: undefined,
            }
        case FETCH_COMPANY_START:
        case GET_PROJECTS_REQUEST:
        case ADD_PROJECT_REQUEST:
        case DELETE_PROJECT_REQUEST:
        case UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                // errorMessage: null,
            }
        case GET_PROJECTS_SUCCEEDED:
            const { data } = action.projects_data
            const totalCount = parseInt(data.count)
            const dataPerPage =
                parseInt(action.projects_data.config.params.limit) || 5
            const totalPages = Math.ceil(totalCount / dataPerPage) || 1
            for (let i = 0; i < data.results.length; i++) {
                data.results[i].colour = 'red'
            }
            return {
                ...state,
                data: data.results || [],
                prev: data.previous,
                next: data.next,
                totalCount,
                totalPages,
                loading: false,
            }
        case ADD_PROJECT_SUCCEEDED:
            return {
                ...state,
                data: action.data,
                errorMessage: false,
                loading: false,
            }
        case DELETE_PROJECT_SUCCEEDED:
            return {
                ...state,
                data: state.data.filter(({ id }) => id !== action.id),
                errorMessage: false,
                loading: false,
            }
        case UPDATE_PROJECT_SUCCEEDED:
            const projects = [...state.data]
            const index = projects.findIndex(({ id }) => id === action.data.id)
            const { users, assets, ...project } = action.data
            projects[index] = project
            return {
                ...state,
                data: projects,
                errorMessage: false,
                loading: false,
            }
        case GET_PROJECTS_FAILED:
        case ADD_PROJECT_FAILED:
        case DELETE_PROJECT_FAILED:
        case UPDATE_PROJECT_FAILED:
            return {
                ...state,
                errorMessage: action.errorMessage,
                loading: false,
            }
        default:
            return state
    }
}

const dashboardInitialState = {
    assets: '',
    damages: '',
    reports: '',
    errorMessage: undefined,
    loading: false,
}

export const dashboardReducer = (state = dashboardInitialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_FAILED:
            return {
                ...state,
                errorMessage: action.message,
                loading: false,
            }
        case GET_DASHBOARD_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
                loading: true,
            }
        case GET_DASHBOARD_SUCCEEDED:
            return {
                ...state,
                assets: action.dashboard_data.data.assets,
                damages: action.dashboard_data.data.damages,
                reports: action.dashboard_data.data.reports,
                errorMessage: false,
                loading: false,
            }
        default:
            return state
    }
}

const labelsInitialState = {
    data: undefined,
    errorMessage: undefined,
    loading: false,
  };
  
  export const labelsReducer = (state = labelsInitialState, action) => {
    switch (action.type) {
      case GET_LABELS_FAILED:
        return {
          ...state,
          errorMessage: action.message,
          loading: false,
        };
      case GET_LABELS_REQUEST:
        return {
          ...state,
          errorMessage: undefined,
          loading: true,
        };
      case GET_LABELS_SUCCEEDED:
        return {
          ...state,
          data: action.labels.data.results,
          errorMessage: false,
          loading: false,
        };
      default:
        return state;
    }
  };
  