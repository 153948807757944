// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
// import Tabs from '../Common/Tabs'

import AssetsExistingUserForm from './AssetsExistingUserForm'
import AssetsUserForm from './AssetsUserForm'
import AssetsUserList from './AssetsUserList'
import { userForms } from '../../config/constants.js'
import { toggleUserFormOpen } from '../../redux/actions/project.js'
import { getToken } from '../../services/authService.js'

const TabPanel = (props): JSX.Element => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} // eslint-disable-line
        >
            {value === index && <Box p={2}>{children}</Box>}
        </div>
    )
}

const AssetsUserModal = ({
    match: {
        params: { id: project_id },
    },
}): JSX.Element => {
    const dispatch = useDispatch()
    const { name, form } = useSelector((state) => state.project_data)
    const [value, setValue] = useState(0)
    const token = getToken()
    const config = { token, project_id }

    const handleChange = (event, newValue): void => {
        setValue(newValue)
    }

    const handleClose = (): void => {
        dispatch(toggleUserFormOpen(null))
    }

    return (
        <Dialog
            open={!!form}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">Team {name}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        {/* TODO: extract Tabs to a common component */}
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            {userForms.map(({ key, subtitle }) => {
                                return <Tab key={key} label={subtitle} />
                            })}
                        </Tabs>
                        {userForms.map(({ key }, index) => {
                            return (
                                <TabPanel key={key} value={value} index={index}>
                                    <Box p={2}>
                                        {form === userForms[index].key ? (
                                            <AssetsExistingUserForm
                                                {...config} // eslint-disable-line
                                            />
                                        ) : (
                                            <AssetsUserForm
                                                {...config} // eslint-disable-line
                                            />
                                        )}
                                    </Box>
                                </TabPanel>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AssetsUserList
                            {...config} // eslint-disable-line
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withRouter(AssetsUserModal)
