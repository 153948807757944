import { put, takeLatest, call, all } from 'redux-saga/effects'
import {
    getProjectDetailsSuccess,
    getProjectDetailsFailed,
    createAssetSuccess,
    createAssetFailed,
    deleteAssetSuccess,
    deleteAssetFailed,
    updateAssetSuccess,
    updateAssetFailed,
    createProjectUserSuccess,
    createProjectUserFailed,
    removeProjectUserSuccess,
    removeProjectUserFailed,
    updateProjectUserFailed,
    updateProjectUserSuccess,
} from '../actions/project.js'
import {
    GET_PROJECT_DETAILS_REQUEST,
    ADD_ASSET_REQUEST,
    UPDATE_ASSET_REQUEST,
    DELETE_ASSET_REQUEST,
    GET_PROJECT_USERS_REQUEST,
    GET_PROJECT_USERS_SUCCEEDED,
    GET_PROJECT_USERS_FAILED,
    CREATE_PROJECT_USER_REQUEST,
    ADD_PROJECT_USER_REQUEST,
    REMOVE_PROJECT_USER_REQUEST,
    GET_SECTIONS_REQUEST,
    GET_SECTIONS_SUCCEEDED,
    GET_SECTIONS_FAILED,
    // UPDATE_PROJECT_USER_SUCCEEDED,
    UPDATE_PROJECT_USER_REQUEST,
    // UPDATE_PROJECT_USER_FAILED,
    // GET_TASKS_REQUEST,
    // GET_TASKS_SUCCEEDED,
    // GET_TASKS_FAILED,
} from '../types/project.js'
import {
    fetchProjectDetailsAPI,
    fetchProjectUsersAPI,
    createAssetAPI,
    updateAssetAPI,
    deleteAssetAPI,
    fetchSectionsAPI,
    createProjectUserAPI,
    addProjectUserAPI,
    deleteProjectUserAPI,
    updateProjectUserAPI,
} from '../../config/api.js'

function* getProjectDetails(action) {
    try {
        const project_detail_data = yield call(
            fetchProjectDetailsAPI,
            action.token,
            action.id
        )
        yield put(getProjectDetailsSuccess(project_detail_data.data))
    } catch (e) {
        // console.log('Error e ', e);
        yield put(getProjectDetailsFailed(e.response.status))
    }
}

export function* getProjectDetailsSaga() {
    yield takeLatest(GET_PROJECT_DETAILS_REQUEST, getProjectDetails)
}

// ========================
//      PROJECT ASSETS
// ========================

function* createAsset(action) {
    try {
        yield call(createAssetAPI, action.token, action.data)
        const project_details = yield call(
            fetchProjectDetailsAPI,
            action.token,
            action.data.project_id
        )
        yield put(createAssetSuccess(project_details.data))
    } catch (error) {
        yield put(createAssetFailed(error.response.data))
    }
}

export function* createAssetSaga() {
    yield takeLatest(ADD_ASSET_REQUEST, createAsset)
}

function* updateAsset(action) {
    try {
        yield call(updateAssetAPI, action.token, action.id, action.data)
        const project_details = yield call(
            fetchProjectDetailsAPI,
            action.token,
            action.data.project_id
        )
        yield put(updateAssetSuccess(project_details.data))
    } catch (error) {
        yield put(updateAssetFailed(error.response.data))
    }
}

export function* updateAssetSaga() {
    yield takeLatest(UPDATE_ASSET_REQUEST, updateAsset)
}

function* deleteAsset(action) {
    try {
        yield call(deleteAssetAPI, action.token, action.id)
        yield put(deleteAssetSuccess(action.id))
    } catch (error) {
        yield put(deleteAssetFailed(error.response.data))
    }
}

export function* deleteAssetSaga() {
    yield takeLatest(DELETE_ASSET_REQUEST, deleteAsset)
}

export function* projectAssetsSagas() {
    yield all([
        call(createAssetSaga),
        call(updateAssetSaga),
        call(deleteAssetSaga),
    ])
}

// =======================
//      PROJECT USERS
// =======================

function* getProjectUsers(action) {
    try {
        const project_users = yield call(
            fetchProjectUsersAPI,
            action.token,
            action.project_id
        )
        yield put({
            type: GET_PROJECT_USERS_SUCCEEDED,
            project_users: project_users.results,
        })
    } catch (e) {
        yield put({ type: GET_PROJECT_USERS_FAILED, message: e.message })
    }
}

export function* getProjectUsersSaga() {
    yield takeLatest(GET_PROJECT_USERS_REQUEST, getProjectUsers)
}

function* createProjectUser(action) {
    try {
        const user_data = yield call(
            createProjectUserAPI,
            action.token,
            action.project_id,
            action.data
        )
        yield put(createProjectUserSuccess(user_data.data))
    } catch (error) {
        yield put(createProjectUserFailed(error.response.data))
    }
}

export function* createProjectUserSaga() {
    yield takeLatest(CREATE_PROJECT_USER_REQUEST, createProjectUser)
}

function* addProjectUser(action) {
    try {
        const project_users = yield call(
            addProjectUserAPI,
            action.token,
            action.project_id,
            action.id
        )
        yield put({
            type: GET_PROJECT_USERS_SUCCEEDED,
            project_users: project_users.results,
        })
    } catch (e) {
        yield put({ type: GET_PROJECT_USERS_FAILED, message: e.message })
    }
}

export function* addProjectUserSaga() {
    yield takeLatest(ADD_PROJECT_USER_REQUEST, addProjectUser)
}

function* removeProjectUser(action) {
    try {
        yield call(
            deleteProjectUserAPI,
            action.token,
            action.project_id,
            action.id
        )
        yield put(removeProjectUserSuccess(action.id))
    } catch (error) {
        console.log(error)
        yield put(removeProjectUserFailed(error.response.data))
    }
}

export function* removeProjectUsersSaga() {
    yield takeLatest(REMOVE_PROJECT_USER_REQUEST, removeProjectUser)
}

function* updateProjectUser(action) {
    try {
        const project_user = yield call(
            updateProjectUserAPI,
            action.token,
            action.project_id,
            action.data
        )
        yield put(updateProjectUserSuccess(project_user.data))
    } catch (error) {
        console.log(error)
        yield put(updateProjectUserFailed(error.response.data))
    }
}

export function* updateProjectUserSaga() {
    yield takeLatest(UPDATE_PROJECT_USER_REQUEST, updateProjectUser)
}

export function* projectUsersSagas() {
    yield all([
        call(getProjectUsersSaga),
        call(createProjectUserSaga),
        call(addProjectUserSaga),
        call(removeProjectUsersSaga),
        call(updateProjectUserSaga),
    ])
}

// ==========================
//      PROJECT SECTIONS
// ==========================

function* getSections(action) {
    try {
        const section_data = yield call(
            fetchSectionsAPI,
            action.token,
            action.id
        )
        yield put({ type: GET_SECTIONS_SUCCEEDED, section_data })
    } catch (e) {
        console.log('Error e ', e)
        yield put({ type: GET_SECTIONS_FAILED, message: e.message })
    }
}

export function* getSectionsSaga() {
    yield takeLatest(GET_SECTIONS_REQUEST, getSections)
}
