import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { ILinearProgressWithLabel } from '../../interface'

const LinearProgressWithLabel = (
    props: ILinearProgressWithLabel
): JSX.Element => {
    const { value } = props
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    )
}

export default LinearProgressWithLabel
