export const GET_SET_BREADCRUMBS_PATH_REQUEST =
    'GET_SET_BREADCRUMBS_PATH_REQUEST'
export const GET_SET_BREADCRUMBS_PATH_SUCCEEDED =
    'GET_SET_BREADCRUMBS_PATH_SUCCEEDED'
export const GET_SET_BREADCRUMBS_PATH_FAILED = 'GET_SET_BREADCRUMBS_PATH_FAILED'
export const GET_CLEAR_BREADCRUMBS_PATH_REQUEST = 'GET_CLEAR_BREADCRUMBS_PATH_REQUEST'
export const GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED = 'GET_CLEAR_BREADCRUMBS_PATH_SUCCEEDED'
export const GET_CLEAR_BREADCRUMBS_PATH_FAILED = 'GET_CLEAR_BREADCRUMBS_PATH_FAILED'
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE'

export const getSetBreadcrumbsPath = ({parent, crumbs}) => {
    return {
        type: GET_SET_BREADCRUMBS_PATH_REQUEST,
        parent,
        crumbs,
    }
}

export const getClearBreadcrumbsPath = () => {
    return {
        type: GET_CLEAR_BREADCRUMBS_PATH_REQUEST,
    }
}

export const toggleDarkMode = (darkMode) => {
    return {
        type: TOGGLE_DARK_MODE,
        payload: darkMode
    }
}
