import { GET_PROJECTS_REQUEST } from '../types/dashboard.js'
import {
    GET_ASSET_DETAILS_REQUEST,
    GET_ASSET_DETAILS_SUCCEEDED,
    GET_ASSET_DETAILS_FAILED,
    GET_DOWNLOAD_FILE_REQUEST,
    GET_DOWNLOAD_FILE_SUCCEEDED,
    GET_DOWNLOAD_FILE_FAILED,
    GET_UPLOAD_FILE_REQUEST,
    GET_UPLOAD_FILE_SUCCEEDED,
    GET_UPLOAD_FILE_FAILED,
    GET_DELETE_FILE_REQUEST,
    GET_DELETE_FILE_SUCCEEDED,
    GET_DELETE_FILE_FAILED,
    GET_UPLOAD_DATA_REQUEST,
    GET_UPLOAD_DATA_SUCCEEDED,
    GET_UPLOAD_DATA_FAILED,
    GET_DELETE_DATA_REQUEST,
    GET_DELETE_DATA_SUCCEEDED,
    GET_DELETE_DATA_FAILED,
    GET_FOLDER_DATA_REQUEST,
    GET_FOLDER_DATA_SUCCEEDED,
    GET_FOLDER_DATA_FAILED,
    GET_ADD_FOLDER_REQUEST,
    GET_ADD_FOLDER_SUCCEEDED,
    GET_ADD_FOLDER_FAILED,
    GET_DELETE_FOLDER_REQUEST,
    GET_DELETE_FOLDER_SUCCEEDED,
    GET_DELETE_FOLDER_FAILED,
    GET_UPDATE_FOLDER_REQUEST,
    GET_UPDATE_FOLDER_SUCCEEDED,
    GET_UPDATE_FOLDER_FAILED,
    GET_COORDINATE_DATA_REQUEST,
    GET_COORDINATE_DATA_SUCCEEDED,
    GET_COORDINATE_DATA_FAILED,
    GET_USER_ASSETS_REQUEST,
    GET_USER_ASSETS_SUCCEEDED,
    GET_USER_ASSETS_FAILED,
    GET_FOLDER_PATHS_REQUEST,
    GET_FOLDER_PATHS_SUCCEEDED,
    GET_FOLDER_PATHS_FAILED,
    GET_MOVE_FOLDER_REQUEST,
    GET_MOVE_FOLDER_SUCCEEDED,
    GET_MOVE_FOLDER_FAILED,
    GET_MOVE_DATA_REQUEST,
    GET_MOVE_DATA_SUCCEEDED,
    GET_MOVE_DATA_FAILED,
} from '../actions/asset.js'

const assetDetailsInitialState = {
    data: {
        name: '',
        location: '',
        lon: 4.4777,
        lat: 51.9244,
        model_link: null,
        data_uploaded: '',
        detections: '',
        ai_coverage: 0,
        damages_found: [],
        severity_dmg_counts: [],
        data_uploaded_per_month: [],
        project: { name: '', id: '', project_type: '' },
        location_info: [],
        latest_report: { name: null, created_date: '', id: null },
        labels: [],
        severity_labels: [],
        three_d_model: null,
        damage_location_info: [],
        severity_location_info: [],
        scroll_damage_images: [],
        severity_scores_found: [],
    },
    errorMessage: undefined,
    loading: false,
}

export const assetDetailsReducer = (
    state = assetDetailsInitialState,
    action
) => {
    switch (action.type) {
        case GET_PROJECTS_REQUEST:
        case GET_USER_ASSETS_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
            }
        case GET_ASSET_DETAILS_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
                loading: true,
            }
        case GET_ASSET_DETAILS_SUCCEEDED:
            const { data } = action.asset_detail_data
            data.severity_labels.unshift({
                severity_label__name: 'None',
                severity_label__id: 'None',
            })
            // data["latest_report"] = data["latest_report"];
            return {
                ...state,
                loading: false,
                data,
            }
        case GET_ASSET_DETAILS_FAILED:
            return {
                ...state,
                errorMessage: action.status,
                loading: false,
            }
        default:
            return state
    }
}

const downloadedFileInitialState = {
    file: [],
    name: '',
    loading: false,
}

export const downloadFileReducer = (
    state = downloadedFileInitialState,
    action
) => {
    switch (action.type) {
        case GET_DOWNLOAD_FILE_SUCCEEDED:
            return {
                ...state,
                loading: false,
                file: action.file_data.data,
                name: action.file_name,
            }
        case GET_DOWNLOAD_FILE_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const uploadFileInitialState = {
    file: [],
    name: '',
    loading: false,
}

export const uploadFileReducer = (state = uploadFileInitialState, action) => {
    switch (action.type) {
        case GET_UPLOAD_FILE_SUCCEEDED:
        case GET_DELETE_FILE_SUCCEEDED:
            return {
                ...state,
                loading: false,
            }
        case GET_UPLOAD_FILE_FAILED:
        case GET_DELETE_FILE_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_UPLOAD_FILE_REQUEST:
        case GET_DELETE_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const uploadDataInitialState = {
    file: [],
    loading: false,
}

export const uploadDataReducer = (state = uploadDataInitialState, action) => {
    switch (action.type) {
        case GET_UPLOAD_DATA_SUCCEEDED:
        case GET_DELETE_DATA_SUCCEEDED:
        case GET_MOVE_DATA_SUCCEEDED:
            return {
                ...state,
                loading: false,
            }
        case GET_UPLOAD_DATA_FAILED:
        case GET_DELETE_DATA_FAILED:
        case GET_MOVE_DATA_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_UPLOAD_DATA_REQUEST:
        case GET_DELETE_DATA_REQUEST:
        case GET_MOVE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const folderDataInitialState = {
    data: [],
    totalCount: null,
    totalPages: null,
    prev: null,
    next: null,
    loading: false,
}

export const folderDataReducer = (state = folderDataInitialState, action) => {
    switch (action.type) {
        case GET_FOLDER_DATA_SUCCEEDED:
            const { data } = action.folder_data
            const { params } = action.folder_data.config
            const totalCount = parseInt(data.count)
            const dataPerPage = parseInt(params.limit) || 10
            const totalPages = Math.ceil(totalCount / dataPerPage) || 1
            return {
                ...state,
                loading: false,
                data: data.results,
                prev: data.previous,
                next: data.next,
                totalCount,
                totalPages,
            }
        case GET_FOLDER_DATA_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_FOLDER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const folderChangeInitialState = {
    loading: false,
    errorMessage: false,
}

export const folderChangeReducer = (
    state = folderChangeInitialState,
    action
) => {
    switch (action.type) {
        case GET_ADD_FOLDER_SUCCEEDED:
        case GET_DELETE_FOLDER_SUCCEEDED:
        case GET_UPDATE_FOLDER_SUCCEEDED:
        case GET_MOVE_FOLDER_SUCCEEDED:
            return {
                ...state,
                loading: false,
            }
        case GET_ADD_FOLDER_FAILED:
        case GET_DELETE_FOLDER_FAILED:
        case GET_UPDATE_FOLDER_FAILED:
        case GET_MOVE_FOLDER_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_ADD_FOLDER_REQUEST:
        case GET_DELETE_FOLDER_REQUEST:
        case GET_UPDATE_FOLDER_REQUEST:
        case GET_MOVE_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const coordinateDataInitialState = {
    data: [],
    loading: false,
}

export const coordinateDataReducer = (
    state = coordinateDataInitialState,
    action
) => {
    switch (action.type) {
        case GET_COORDINATE_DATA_SUCCEEDED:
            if (action.coordinate_data.data.hasOwnProperty('results'))
                var data = action.coordinate_data.data.results
            else var data = [action.coordinate_data.data]

            return {
                ...state,
                loading: false,
                data,
            }
        case GET_COORDINATE_DATA_FAILED:
            return {
                ...state,
                loading: false,
            }
        case GET_COORDINATE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const userAssetsState = {
    loading: false,
    data: undefined,
    totalCount: null,
    totalPages: null,
    prev: null,
    next: null,
}

export const getUserAssetsReducer = (state = userAssetsState, action) => {
    switch (action.type) {
        case GET_USER_ASSETS_SUCCEEDED:
            const { data } = action.user_assets
            const { params } = action.user_assets.config
            const totalCount = parseInt(data.count)
            const dataPerPage = parseInt(params.limit) || 5
            const totalPages = Math.ceil(totalCount / dataPerPage) || 1
            return {
                ...state,
                loading: false,
                data: data.results,
                prev: data.previous,
                next: data.next,
                totalCount,
                totalPages,
            }
        case GET_USER_ASSETS_FAILED:
            return {
                ...state,
                loading: false,
                data: [],
            }
        case GET_USER_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
            }
        default:
            return state
    }
}

const folderPathState = {
    loading: false,
    paths: [],
}

export const getFolderPathsReducer = (state = folderPathState, action) => {
    switch (action.type) {
        case GET_FOLDER_PATHS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                paths: action.folder_paths,
            }
        case GET_FOLDER_PATHS_REQUEST:
            return {
                ...state,
                loading: true,
                paths: [],
            }
        case GET_FOLDER_PATHS_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
            }
        default:
            return state
    }
}
