import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { useLocalStorage } from '@rehooks/local-storage'

import { lightTheme, darkTheme } from '../App.theme.js'
import { token_name_theme } from '../config/constants.js'
import { toggleDarkMode } from '../redux/actions/system.js'

interface IThemeHandler {
    children: React.ReactNode
}

const ThemeHandler = ({ children }: IThemeHandler): JSX.Element => {
    const dispatch = useDispatch()
    // @ts-ignore
    const isDarkMode = useSelector((state) => state.system.darkMode)
    // @ts-ignore
    const appliedTheme = createMuiTheme(isDarkMode ? darkTheme : lightTheme)

    const [theme] = useLocalStorage<{
        darkMode: boolean
    }>(token_name_theme)

    useEffect(() => {
        if (theme?.darkMode !== undefined) {
            dispatch(toggleDarkMode(theme?.darkMode))
        }
    }, [])

    return <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>
}

export default ThemeHandler
